import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import http from "../services/httpService.js";
import CardComponent from "./cardCompo.jsx";
import Loading from "./loading.jsx";

exporting(Highcharts);

const ChartOverview = () => {
  const activedata = [];
  const active = [];
  const [organizationName, setOgranizationName] = useState("All"); //name of a perticular organization
  const [relief, setRelief] = useState([]);
  const [dataset, setDataset] = useState({});
  const [dataset2, setDataset2] = useState({});
  const [callset, setCallset] = useState({});
  const [organization, setOrganization] = useState({}); //organization list
  const [activecases, setActiveCases] = useState({});
  const [inbounOutbound, setInboundOutbound] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  const makeSearchString = (options) => {
    let { organization, page, perPage, status, search } = options;
    let searchStr = "";
    searchStr = addToQueryString(searchStr, "organization", organization);
    searchStr = addToQueryString(searchStr, "page", page);
    searchStr = addToQueryString(searchStr, "perPage", perPage);
    searchStr = addToQueryString(searchStr, "status", status);
    searchStr = addToQueryString(searchStr, "search", search);
    console.log(searchStr);
    return searchStr;
  };
  const addToQueryString = (str, paramName, paramValue) => {
    return paramValue
      ? str
        ? `${str}&${paramName}=${paramValue}`
        : `${paramName}=${paramValue}`
      : str;
  };
  const handleChange = (e) => {
    console.log(e.target.value);
    setOgranizationName(e.target.value);
  };

  const fetchAllInfoData = async () => {
    // const datafilter=e.target.value;
    if (organizationName === "All") {
      await http
        .get(`/getAllInfo`)
        .then((res) => {
          setDataset(res.data);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // console.log(datafilter)
      await http
        .get(
          `/getAllInfo${
            organizationName == "All" ? "" : "?organization=" + organizationName
          }`
        )
        .then((res) => {
          setDataset(res.data);
          console.log(res.data);
          // console.log(chartData)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchInboundOutbound = async () => {
    await http
      .get(
        `/getInboundOutbound${
          organizationName == "All" ? "" : "?organization=" + organizationName
        }`
      )
      .then((res) => {
        setInboundOutbound(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchRecoveredCases = async () => {
    await http
      .get(
        `/getRecoveredCases${
          organizationName == "All" ? "" : "?organization=" + organizationName
        }`
      )
      .then((res) => {
        setDataset2(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUsersWithPendingAmount = (date) => {
    return relief
      .filter((item) => item.reliefDate === date)
      .map((item) => ({
        name: item.name,
        pendingAmt: parseInt(item.pendingAmt),
      }));
  };
  //a function which get a date send total amount
  const calculateTotalPendingAmount = (date) => {
    return relief
      .filter((item) => item.reliefDate === date)
      .reduce((total, item) => total + parseInt(item.pendingAmt), 0);
  };
  //creating relief date users
  const reliefDateCounts = {};
  relief.forEach((item) => {
    const date = item.reliefDate;
    if (reliefDateCounts[date]) {
      reliefDateCounts[date]++;
    } else {
      reliefDateCounts[date] = 1;
    }
  });
  Object.keys(activecases).map((item) => {
    activedata.push(item);
  });
  Object.values(activecases).map((item) => {
    active.push(item);
  });
  // console.log(reliefDateCounts)
  const reliefDates = Object.keys(reliefDateCounts);
  const reliefCounts = Object.values(reliefDateCounts);
  //add new
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Relief Date and Amount",
    },
    xAxis: {
      categories: reliefDates,
      title: {
        text: "Relief Date",
      },
    },
    yAxis: {
      title: {
        text: "Number of People",
      },
    },
    tooltip: {
      formatter: function () {
        let tooltip = "<b>" + this.x + "</b><br/>";
        tooltip += "Number of People: " + this.y + "<br/>";
        const pendingAmount = calculateTotalPendingAmount(this.x);
        tooltip += "Total Pending Amount: " + pendingAmount + "Rs.<br/>";
        // return tooltip;
        const usersWithPending = getUsersWithPendingAmount(this.x);
        if (usersWithPending.length > 0) {
          tooltip += "<br/>Users with Pending Amount:";
          usersWithPending.forEach((user) => {
            tooltip += "<br/>" + user.name + ": " + user.pendingAmt;
          });
        }
        return tooltip;
      },
    },
    series: [
      {
        name: "Number of People",
        data: reliefCounts,
      },
    ],
  };

  const options4 = {
    chart: {
      type: "pie",
      //   backgroundColor: '#f5f5f5'
    },
    title: {
      text: "Call Status Distribution",
    },
    plotOptions: {
      pie: {
        size: "80%",
      },
    },
    series: [
      {
        name: "Call Status",
        data: [
          {
            name: "Connected",
            y: dataset.totalStatusAnswered,
          },
          {
            name: "Unable to Connect",
            y: dataset.totalStatusMissed,
          },
          {
            name: "Incorrect Numbers",
            y: dataset.totalNeverCalled,
          },
        ],
      },
    ],
  };

  const options7 = {
    chart: {
      type: "pie",
      //   backgroundColor: '#f5f5f5'
    },
    title: {
      text: "Email Status Distribution",
    },
    plotOptions: {
      pie: {
        size: "80%",
      },
    },
    series: [
      {
        name: "Email Status",
        data: [
          {
            name: "Sent",
            y: 11000,
          },
          {
            name: "Bounced",
            y: 2000,
          },
          {
            name: "Incorrect Email",
            y: 500,
          },
        ],
      },
    ],
  };

  const options8 = {
    chart: {
      type: "pie",
      //   backgroundColor: '#f5f5f5'
    },
    title: {
      text: "Recovery Status Distribution",
    },
    plotOptions: {
      pie: {
        size: "80%",
      },
    },
    series: [
      {
        name: "Recovery Status",
        data: [
          {
            name: "Case Settled",
            y: 100,
          },
          {
            name: "Expected",
            y: dataset.totalStatusAnswered,
          },
          {
            name: "Unable to connect",
            y: dataset.totalNeverCalled,
          },
        ],
      },
    ],
  };
  const options10 = {
    chart: {
      type: "pie",
      //   backgroundColor: '#f5f5f5'
    },
    title: {
      text: "Mode of Connection",
    },
    plotOptions: {
      pie: {
        size: "80%",
      },
    },
    series: [
      {
        name: "Recovery Status",
        data: [
          {
            name: "Email",
            y: 1000,
          },
          {
            name: "Phone",
            y: 2000,
          },
          {
            name: "Unable to connect",
            y: 400,
          },
        ],
      },
    ],
  };
  const chartData = Object.values(callset).map((obj) => [
    new Date(obj.prevInfo.lastCall).getTime(),
    obj.prevInfo.duration,
  ]);
  // console.log(chartData)
  const options5 = {
    chart: {
      type: "line",
    },
    title: {
      text: "Last Call Duration",
    },
    xAxis: {
      type: "datetime",
      title: {
        text: "Month",
      },
    },
    yAxis: {
      title: {
        text: "Call Duration",
      },
    },
    series: [
      {
        name: "call Duration",
        data: chartData,
      },
    ],
  };

  const options6 = {
    chart: {
      type: "column",
    },
    title: {
      text: "Actice Cases according to Organizations",
    },
    xAxis: {
      categories: activedata,
    },
    yAxis: {
      title: {
        text: "Number of Cases",
      },
    },
    series: [
      {
        name: "Active Cases",
        data: active,
        color: "purple", // Set color for active cases
      },
    ],
  };
  const options9 = {
    chart: {
      type: "column",
    },
    title: {
      text: "Inbound Outbound Calls",
    },
    xAxis: {
      categories: ["inbound", "outbound"],
    },
    yAxis: {
      title: {
        text: "Number of calls ",
      },
    },
    series: [
      {
        name: "Total",
        data: inbounOutbound,
        color: "#304F9D", // Set color for inbound/outbound cases
      },
    ],
  };
  //get All organizaions details
  const fetchData = async () => {
    await http
      .get("/getAllOrganizations")
      .then((res) => {
        console.log(res.data);
        setOrganization(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //   {
  //     "totalCases": 2,
  //     "totalClosedAmt": 8000
  // }
  const cardsData = [
    {
      route: "/disputes",
      title: dataset2.totalCases,
      text: "Cases Settled",
    },
    {
      route: "/disputes",
      title: dataset2.totalClosedAmt,
      text: "Case Settled Amount",
    },
    {
      route:
        `/disputes?` +
        makeSearchString({
          organization: organizationName == "All" ? "" : organizationName,
          status: "answered",
        }),
      title: dataset.totalStatusAnswered,
      text: "Case Connected",
    },
    {
      route:
        "/disputes?" +
        makeSearchString({
          organization: organizationName == "All" ? "" : organizationName,
        }),
      title: dataset.totalDocuments,
      text: "Total No. of cases",
    },
    {
      route:
        "/disputes?" +
        makeSearchString({
          organization: organizationName == "All" ? "" : organizationName,
        }),
      title: dataset.totalCaseValue,
      text: "Total case Value",
    },
    {
      route: "/disputes",
      title: dataset2.totalRecoverdCases,
      text: "Total Recovered cases",
    },
    {
      route:
        `/disputes?` +
        makeSearchString({
          organization: organizationName == "All" ? "" : organizationName,
          status: "answered",
        }),
      title: dataset.totalExpectedRecovery,
      text: "Total expected Recovery",
    },
  ];

  // const fetchData1 = async () => {
  //   await http
  //     .get(
  //       "/getUsersdata?page=1&perPage=10"
  //     )
  //     .then((res) => {
  //       // setDataset(res.data);
  //       // console.log(res.data);
  //       setEmail(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleInvoices=async()=>{
    let response=await http.get2('/updateAreementsInvoices');
    if(response.status!=400){
      alert('Invoices are updated!');
    }
  }

  useEffect(() => {
    fetchData();
    fetchAllInfoData();
    fetchInboundOutbound();
    fetchRecoveredCases();
  }, [organizationName]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    isLoading?<Loading></Loading>:
    <div>
      <div className="container">
        <div className="row">
          <div className="col-4">
            <form className="d-flex align-items-center">
              <select
                className="form-select shadow-sm h-100 border-2 border-primary flex-grow-1"
                onChange={(e) => handleChange(e)}
              >
                <option>All</option>
                {Array.from(organization).map((item) => (
                  <option key={item}>{item}</option>
                ))}
              </select>
            </form>
          </div>
          <div className="col-4 text-center">
            {/* <button className="btn btn-primary">Update Invoices</button> */}
          </div>
          <div className="col-4 text-end pe-5">
            <button className="btn btn-outline-primary btn-lg" onClick={handleInvoices}>Update Invoices</button>
          </div>
        </div>
        <br></br>
        <br></br>
      </div>
      {/* <div className=" flex lg:flex flex-shrink md:block sm:block justify-center mt-32">
      {/* <div className="w-96 shadow-md">
      <HighchartsReact highcharts={Highcharts} options={options4} />
      </div>

      <div className="w-96 shadow-md mt-0 lg:mt-0 sm:mt-4">
      <HighchartsReact highcharts={Highcharts} options={options5} />
      </div> *
      </div> */}
      <div className="container">
        <div className="row row-cols-1 row-cols-md-4 px-4">
          {" "}
          {/* Added row-cols-1 and row-cols-md-4 */}
          {cardsData.map((card, index) => (
            <div className="col" key={index}>
              <CardComponent {...card} />
            </div>
          ))}
        </div>

        {/* <div className="mt-5">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4">
            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <div className="shadow p-3">
                <HighchartsReact highcharts={Highcharts} options={options4} />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <div className="shadow p-3">
                <HighchartsReact highcharts={Highcharts} options={options7} />
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4">
            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <div className="shadow p-3">
                <HighchartsReact highcharts={Highcharts} options={options8} />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <div className="shadow p-3">
                <HighchartsReact highcharts={Highcharts} options={options10} />
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4">
            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <div className="shadow p-3">
                <HighchartsReact highcharts={Highcharts} options={options9} />
              </div>
            </div>
          </div>
        </div> */}

        {/* ///////////////// */}
        <div className="container mt-5">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4 justify-content-center">
            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <div className="shadow p-3">
                <HighchartsReact highcharts={Highcharts} options={options4} />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <div className="shadow p-3">
                <HighchartsReact highcharts={Highcharts} options={options7} />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <div className="shadow p-3">
                <HighchartsReact highcharts={Highcharts} options={options8} />
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4 justify-content-center">
            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <div className="shadow p-3">
                <HighchartsReact highcharts={Highcharts} options={options10} />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <div className="shadow p-3">
                <HighchartsReact highcharts={Highcharts} options={options9} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartOverview;
