import React, { Component } from "react";
import { Typography } from "@mui/material";
import http from "../services/httpService.js";
import CsvUploader from "./csvUplode.jsx";
import auth from "../services/authService.js";
import queryString from "query-string";
import { Link } from "react-router-dom";
import Navbar2 from "./navbar2.jsx";
import Loading from "./loading.jsx";

class UsersData extends Component {
  state = {
    users: [],
    selNumbers: [],
    organizationList: [],
    selAll: false,
    organization: "",
    page: 1,
    perPage: 10,
    status: "",
    usersLength: 0,
    callStatus: false,
    search: "",
    settleAmt: "",
    caseStatus: "",
    removeReason: "",
    isOpen: true,
    minAmt:1,
    maxAmt:1000000,
    startDate:'',
    endDate:'',
    isLoading:true,
  };
  timer = null;
  // {name:'privateCourt'}
  async fetchData() {
    try {
      let queryParams = queryString.parse(this.props.location.search);
      let searchStr = this.makeSearchString(queryParams);
      let response = await http.get(
        `/getUsersData${searchStr ? "?" + searchStr : ""}`
      );
      let s1 = { ...this.state };
      if (Array.isArray(response.data)) {
        s1.users = response.data;
        s1.usersLength = response.data.lenght;
        console.log(response.data);
      } else {
        s1.users = [];
      }

      s1.organization = queryParams.organization;
      s1.page = queryParams.page ? queryParams.page : 1;
      s1.perPage = queryParams.perPage;
      s1.status = queryParams.status;
      s1.search = queryParams.search;
      s1.minAmt=+queryParams.minAmt;
      s1.maxAmt=+queryParams.maxAmt;
      s1.startDate=queryParams.startDate;
      s1.endDate=queryParams.endDate;

      this.setState(s1);
    } catch (err) {
      console.log(err);
    }
  }

  async fetchData2() {
    try {
      let response = await http.get(`/getAllOrganizations`);
      let s1 = { ...this.state };
      s1.organizationList = response.data;
      this.setState(s1);
    } catch (err) {
      console.log(err);
    }
  }

  componentDidMount() {
    this.fetchData();
    this.fetchData2();
    this.handleTimer();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps != this.props) {
      this.fetchData();
      this.fetchData2();
    }
  }

  handleTimer=() => {
    const timer2 = setTimeout(() => {
      this.setState({isLoading:false})
    }, 1000);
    return () => clearTimeout(timer2);
  }

  callURL = (url, options) => {
    let searchString = this.makeSearchString(options);
    this.props.history.push({
      pathname: url,
      search: searchString,
    });
  };

  makeSearchString = (options) => {
    let { organization, page, perPage, status, search, minAmt, maxAmt, startDate, endDate} = options;
    let searchStr = "";
    searchStr = this.addToQueryString(searchStr, "organization", organization);
    searchStr = this.addToQueryString(searchStr, "page", page);
    searchStr = this.addToQueryString(searchStr, "perPage", perPage);
    searchStr = this.addToQueryString(searchStr, "status", status);
    searchStr = this.addToQueryString(searchStr, "search", search);
    searchStr = this.addToQueryString(searchStr, "minAmt", minAmt);
    searchStr = this.addToQueryString(searchStr, "maxAmt", maxAmt);
    searchStr = this.addToQueryString(searchStr, "startDate", startDate);
    searchStr = this.addToQueryString(searchStr, "endDate", endDate);
    return searchStr;
  };

  // "/usersData?organization=pvtcourt&page=1&perPage=1&status=missed"

  addToQueryString = (str, paramName, paramValue) => {
    return paramValue
      ? str
        ? `${str}&${paramName}=${paramValue}`
        : `${paramName}=${paramValue}`
      : str;
  };

  handleChange = (e) => {
    const { currentTarget: input } = e;
    let s1 = { ...this.state };
    s1.selNumbers = this.updateCBs(s1.selNumbers, input.checked, input.value);
    this.setState(s1);
  };

  handleChange2 = (e) => {
    const { currentTarget: input } = e;
    // let s1 = { ...this.state };
    // s1.perPage = input.value;
    let queryParams = queryString.parse(this.props.location.search);
    queryParams.perPage = input.value;
    queryParams.search = "";
    this.callURL(`/disputes`, queryParams);
  };

  handlechange3 = (e) => {
    const { currentTarget: input } = e;
    let s1 = { ...this.state };
    s1.search = input.value;
    let queryParams = queryString.parse(this.props.location.search);
    queryParams.search = input.value;
    this.setState(s1);
    clearTimeout(this.timer);
    this.timer = setTimeout(
      () => this.callURL(`/disputes`, queryParams),
      1000
    );
  };

  handleChange4 = (e) => {
    const { currentTarget: input } = e;
    let s1 = { ...this.state };
    if (input.name == "settleAmt") {
      s1.settleAmt = input.value;
    } else if (input.name == "removeReason") {
      s1.removeReason = input.value;
    }
    this.setState(s1);
  };

  handlechange5 = (e) => {
    if (e.target.checked) {
      let s1 = { ...this.state };
      s1.selAll = true;
      this.setState(s1);
      this.handleSelectAll();
    } else {
      let s1 = { ...this.state };
      s1.selAll = false;
      this.setState(s1);
      this.handleSelectAll();
    }
  };
  handleChange6 = (e) => {
    const { currentTarget: input } = e;
    let s1 = { ...this.state };
    s1.caseStatus = input.value;
    this.setState(s1);
  };

  handleChange7=(e)=>{
    const { currentTarget: input } = e;
    let queryParams = queryString.parse(this.props.location.search);
    if(input.name=='minAmt'){
      queryParams.minAmt = input.value;
      queryParams.search = "";
      this.callURL(`/disputes`, queryParams);
    }
    else if(input.name=='maxAmt'){
      queryParams.maxAmt = input.value;
      queryParams.search = "";
      this.callURL(`/disputes`, queryParams);
    }
  }
  handleChange8=(e)=>{
    const {currentTarget:input}=e;
    let queryParams = queryString.parse(this.props.location.search);
    console.log("h8")
    if(input.name=='startDate'){
      console.log(input.value);
      queryParams.startDate =input.value;
      queryParams.search = "";
      this.callURL(`/disputes`, queryParams);
    }
    else if(input.name=='endDate'){
      console.log(input.value);
      queryParams.endDate =input.value;
      queryParams.search = "";
      this.callURL(`/disputes`, queryParams);
    }
  }

  handleOptionChange = (event) => {
    if (event.target.value != "All Organizations") {
      let queryParams = queryString.parse(this.props.location.search);
      queryParams.organization = event.target.value;
      queryParams.page = 1;
      queryParams.status = "";
      queryParams.search = "";
      this.callURL(`/disputes`, queryParams);
    } else this.props.history.push("/disputes");
  };

  handleOptionStatusChange = (event) => {
    if (event.target.value != "All Status") {
      let queryParams = queryString.parse(this.props.location.search);
      queryParams.status = event.target.value;
      queryParams.search = "";
      this.callURL(`/disputes`, queryParams);
      // this.setState({search:''});
    } else this.props.history.push("/disputes");
  };

  updateCBs = (inpArr, checked, value) => {
    if (checked) inpArr.push(value);
    else {
      let index = inpArr.findIndex((ele) => ele === value);
      if (index >= 0) inpArr.splice(index, 1);
    }
    return inpArr;
  };

  handleSelectAll = () => {
    let s1 = { ...this.state };
    s1.selAll = !s1.selAll;
    if (s1.selAll && s1.users.length > 0) {
      let arr = s1.users.map((user) => user._id + "");
      console.log(arr);
      s1.selNumbers = arr;
    } else {
      s1.selNumbers = [];
    }
    this.setState(s1);
  };

  async handleCalls() {
    console.log(this.state.selNumbers, "nums");
    let response = await http.post("/callSelectedNumbers", {
      data: this.state.selNumbers,
    });

    if (response.data.lenght == this.state.selNumbers.length) {
      this.setState({ selNumbers: [], callStatus: false });
    } else this.setState({ callStatus: true });
    console.log(response.data);
  }

  async handleDelete(_id) {
    try {
      let response = await http.post(`/deleteById/${_id}`, {
        data: {
          caseStatus: this.state.caseStatus,
          settleAmt: this.state.settleAmt,
          removeReason: this.state.removeReason,
        },
      });
      console.log(response.data);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  }

  handleModalDelete = (_id) => {
    let s1 = { ...this.setState };
    s1.caseStatus = "";
    s1.settleAmt = "";
    s1.id = _id;
    this.setState(s1);
  };

  handlePagination = (n) => {
    let queryParams = queryString.parse(this.props.location.search);
    let page = this.state.page;
    queryParams.page = +page + +n;
    this.callURL(`/disputes`, queryParams);
  };

  handleReminderCalls = async () => {
    try {
      let response = await http.get(`/reminderCalls`);
      console.log(response.data);
      alert("Reminder Calls started...");
    } catch (err) {
      console.log(err);
    }
  };

  capitalizeFirstLetter(str) {
    // return str.charAt(0).toUpperCase() + str.slice(1);
    return str;
  }
  
  handleIsOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  dateToTimestamp(dateString) {
    const dateObject = new Date(dateString + "T00:00:00");
    const timestamp = dateObject.getTime();
    return timestamp;
  }

  timestampToDate(timestamp) {
    const timestampMS = timestamp * 1000;
    const date = new Date(timestampMS);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  sortBy=(name, type)=>{
    let s1={...this.state}
    s1.users=s1.users.sort((a, b) => {
      const valueA = a[name];
      const valueB = b[name];
  
      if (type === 'up') {
        return valueA.localeCompare(valueB);
      } else if (type === 'down') {
        return valueB.localeCompare(valueA);
      }
    })
    this.setState(s1);
  }
  

  render() {
    let {
      status,
      organization,
      users,
      selNumbers,
      organizationList,
      perPage = 10,
      page,
      id,
      selAll,
      usersLength,
      callStatus,
      search,
      settleAmt,
      caseStatus,
      removeReason,
      isOpen,
      minAmt=1,
      maxAmt=1000000,
      startDate,
      endDate,
      isLoading,
    } = this.state;
    let queryParams = queryString.parse(this.props.location.search);
    // console.log(perPage);
    const userlog = auth.getUser();
    // console.log(this.dateToTimestamp("08/12/2023"));
    // console.log(this.timestampToDate(1701993600))
    if (!users) {
      return <>Loading Data....</>;
    } else {
      return (
        isLoading?<Loading></Loading>:
        <>
          <Typography>
            {/* {userlog && userlog.role === "admin" && (
              <CsvUploader type="usersData" />
            )} */}
          </Typography>
          {/* ////second new Layouts */}
          <div className="main-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-3">
                  <div className="left-wrapper">
                  {<Navbar2/>}
                    {/* <button
                      className="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                      onClick={this.handleIsOpen}
                    >
                      Filter
                    </button> */}
                    <div
                      // className={isOpen ? "" : "collapse  d-lg-block"}
                      // className='collapse d-lg-block'
                      id="collapseExample"
                    >
                      <div className="data-filter mt-4">
                        <div className="form-floating mb-3">
                          <select
                            className="form-select"
                            id="floatingSelect"
                            aria-label="Floating label select example"
                            value={organization}
                            onChange={this.handleOptionChange}
                          >
                            <option value="All Organizations">
                              All Organizations
                            </option>
                            {organizationList &&
                              organizationList.map((org, index) => (
                                <option value={org} key={org}>
                                  {org}
                                </option>
                              ))}
                          </select>
                          <label htmlFor="floatingSelect">
                            Filter By Organization
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <select
                            className="form-select"
                            id="floatingSelect"
                            value={status ? status : "All Status"}
                            onChange={this.handleOptionStatusChange}
                            aria-label="Floating label select example"
                          >
                            <option value="All Status">All Status</option>
                            <option value="missed">Missed</option>
                            <option value="answered">Answered</option>
                            <option value="neverCalled">Never Called</option>
                          </select>
                          <label htmlFor="floatingSelect">
                            Filter By Status
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="searchInput"
                            placeholder="Search By Email, Name or Phone"
                            value={search ? search : ""}
                            onChange={this.handlechange3}
                          />
                          <label htmlFor="searchInput">
                            Search By Email, Name or Phone
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Start Date"
                            name='startDate'
                            value={startDate}
                            onChange={this.handleChange8}

                          />
                          <label htmlFor="floatingInput">Start Date</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="date"
                            className="form-control"
                            placeholder="End Date"
                            name='endDate'
                            value={endDate}
                            onChange={this.handleChange8}

                          />
                          <label htmlFor="floatingInput">End Date</label>
                        </div>

                        <div className="form-floating mb-3 min-max-form bg-white">
                          <input
                            type="range"
                            className="form-range form-control text-primary"
                            min="1"
                            max="1000000"
                            Value={minAmt}
                            onChange={this.handleChange7}
                            name="minAmt"
                          />
                          <span id="range-number">{+minAmt}</span>
                          <label htmlFor="floatingInput">Minimum Amount</label>
                        </div>
                        <div className="form-floating mb-3 min-max-form bg-white">
                          <input
                            type="range"
                            className="form-range form-control text-primary"
                            min="1"
                            max="1000000"
                            Value={maxAmt}
                            onChange={this.handleChange7}
                            name="maxAmt"
                          />
                          <span id="range-number">{+maxAmt}</span>
                          <label htmlFor="floatingInput">Maximum Amount</label>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                {/* //////////////////////// */}
                <div className="col-xl-9">
                  <div className="right-wrapper">
                    <div className="org-data pb-5">
                      <div className="row">
                        <div className="col mb-5">
                        {userlog && userlog.role === "admin" && <CsvUploader type="usersData" />}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="flexCheckChecked"
                                      checked={
                                        users.length == selNumbers.length
                                      }
                                      onChange={this.handlechange5}
                                    />
                                  </div>
                                </th>
                                <th scope="col">
                                  Name
                                  <a href="#" class="sort-icon float-end">
                                    <i class="bi bi-caret-up-fill" onClick={()=>this.sortBy('name', 'up')}></i>
                                    <i class="bi bi-caret-down-fill"  onClick={()=>this.sortBy('name', 'down')}></i>
                                  </a>
                                </th>
                                <th scope="col">
                                  Organization{" "}
                                  <a href="#" class="sort-icon float-end">
                                    <i class="bi bi-caret-up-fill"  onClick={()=>this.sortBy('organization', 'up')}></i>
                                    <i class="bi bi-caret-down-fill" onClick={()=>this.sortBy('organization', 'down')}></i>
                                  </a>
                                </th>
                                <th scope="col">
                                  Amount Due{" "}
                                  <a href="#" class="sort-icon float-end">
                                    <i class="bi bi-caret-up-fill" onClick={()=>this.sortBy('pendingAmt', 'up')}></i>
                                    <i class="bi bi-caret-down-fill" onClick={()=>this.sortBy('pendingAmt', 'down')}></i>
                                  </a>
                                </th>
                                <th scope="col">
                                  Status{" "}
                                  <a href="#" class="sort-icon float-end">
                                    <i class="bi bi-caret-up-fill" onClick={()=>this.sortBy('status', 'up')}></i>
                                    <i class="bi bi-caret-down-fill" onClick={()=>this.sortBy('status', 'down')}></i>
                                  </a>
                                </th>
                                <th scope="col">Close</th>
                              </tr>
                            </thead>
                            <tbody>
                              {users.length > 0
                                ? users.map((user, index) => {
                                    return (
                                      <tr key={user._id}>
                                        <td>
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              checked={selNumbers.includes(
                                                user._id + ""
                                              )}
                                              onChange={this.handleChange}
                                              id={"checkbox_" + user._id}
                                              value={user._id + ""}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={"checkbox_" + user._id}
                                            >
                                              {/* Add your label text here if needed */}
                                            </label>
                                          </div>
                                        </td>
                                        <td data-label="Name">{user.name}</td>
                                        <td data-label="Organization">
                                          {user.organization}
                                        </td>
                                        <td data-label="Amount Due">
                                          &#8377; {user.pendingAmt}
                                        </td>
                                        <td data-label="Status">
                                          {user.prevInfo
                                            ? this.capitalizeFirstLetter(
                                                user.prevInfo.status
                                              )
                                            : ""}
                                        </td>
                                        <td data-label="Close">
                                          <i
                                            className="bi bi-x-circle"
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop"
                                            onClick={() =>
                                              this.handleModalDelete(user._id)
                                            }
                                          ></i>
                                        </td>
                                      </tr> 
                                    );
                                  })
                                : ""}
                            </tbody>
                          </table>{" "}
                          {users.length <= 0 ? (
                            <p className="text-muted">
                              Sorry, no results match your search criteria at
                              the moment. Please try again with different
                              keywords or filters, or check back later for
                              updated information. If you have any questions or
                              need assistance, feel free to contact our support
                              team for help.
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="row pt-4">
                        <div className="col-lg-6">
                          <button
                            type="button"
                            className="btn btn-primary me-sm-3 mb-2"
                            onClick={() => this.handleCalls()}
                          >
                            Call Selected Numbers
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary mb-2"
                            onClick={() => this.handleReminderCalls()}
                          >
                            Call Todays Reminder
                          </button>
                        </div>
                        <div className="col-lg-6">
                          <div className="data-pagi float-lg-end mt-lg-0 mt-2">
                            <select
                              className="form-control"
                              id="floatingSelect"
                              value={perPage ? perPage : 10}
                              onChange={this.handleChange2}
                              aria-label="Floating label select example"
                            >
                              {/* <option value="All Status">All Status</option> */}
                              <option value={10}>10</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                            <div
                              className="btn-toolbar"
                              role="toolbar"
                              aria-label="Toolbar with button groups"
                            >
                              <div
                                className="btn-group me-1"
                                role="group"
                                aria-label="First group"
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => this.handlePagination(-1)}
                                  disabled={page <= 1}
                                >
                                  <i className="bi bi-chevron-left text-white"></i>
                                </button>
                              </div>
                              <div
                                className="btn-group me-1"
                                role="group"
                                aria-label="Second group"
                              >
                                <button
                                  type="button"
                                  className="btn text-primary"
                                >
                                  {page}
                                </button>
                              </div>
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Third group"
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={users.length < +perPage}
                                  onClick={() => this.handlePagination(1)}
                                >
                                  <i className="bi bi-chevron-right text-white"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ///////////////////////////////////////////////// */}
          <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-keyboard="false"
            tabindex="1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title text-danger"
                    id="staticBackdropLabel"
                  >
                    Update Case Status
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <h5>Case Status</h5>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="caseStatus"
                          value="Settle"
                          checked={caseStatus == "Settle"}
                          onChange={this.handleChange6}
                        />
                        <label className="form-check-label">Settle</label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="caseStatus"
                          value="Remove"
                          checked={caseStatus == "Remove"}
                          onChange={this.handleChange6}
                        />
                        <label className="form-check-label">Remove</label>
                      </div>
                    </div>
                  </div>
                  {caseStatus == "Settle" ? (
                    <div className="text-center row">
                      <div className="col-2"></div>
                      <div className="col-8">
                        <input
                          type="number"
                          className="form-control m-1 form-group-inline"
                          id="settleAmt"
                          name="settleAmt"
                          value={settleAmt}
                          placeholder="Enter settle Amount"
                          onChange={this.handleChange4}
                        />
                      </div>
                      <div className="col-2"></div>
                    </div>
                  ) : caseStatus == "Remove" ? (
                    <div className="text-center row">
                      <div className="col-2"></div>
                      <div className="col-8">
                        <input
                          type="text"
                          className="form-control m-1 form-group-inline"
                          id="removeReason"
                          name="removeReason"
                          value={removeReason}
                          placeholder="Enter reason"
                          onChange={this.handleChange4}
                        />
                      </div>
                      <div className="col-2"></div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    disabled={
                      !(
                        (caseStatus == "Settle" && settleAmt) ||
                        (caseStatus == "Remove" && removeReason)
                      )
                    }
                    onClick={() => this.handleDelete(id)}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="modal-backdrop" style={{ opacity: "0.3", backgroundColor: "#fff" }}></div> */}
          </div>
        </>
      );
    }
  }
}

export default UsersData;
