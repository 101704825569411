import { useEffect, useState } from "react";
import { Country, State, City } from "country-state-city";
import auth from "../services/authService";
import http from "../services/httpService";
import Navbar2 from "./navbar2";
import { Link } from "react-router-dom";
// import {useNavigate} from 'react-router-dom';
import "./style.css";

import { withRouter } from "react-router-dom";
const SalesForm = (props) => {
  // const navigate=useNavigate();
  const email1=auth.getUser().email;
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    source: "",
    organization: "",
    user: "",
    detail: "",
  });
  const { name, email, phone, source, organization, user, detail } = formData;
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const updatedFormData = {
    ...formData,
    country: selectedCountry?.name,
    state: selectedState?.name,
    city: selectedCity?.name,
  };
  console.log(updatedFormData)
  const handlerforSubmit = (e) => {
    e.preventDefault();
    http.post2('/createLead', updatedFormData)
    .then((response) => {
      // Handle the success response here
      alert("Succesfully Added");
      setTimeout(()=>{
        // navigate('/leads')
        props.history.push('/leadTable');
      },2000)
    })
    .catch((error) => {
      // Handle errors here
      console.error('Error:', error);
    });
  };

  const fetchData2 = async () => {
    try {
      const response = await http.post2("/getCompanyName", {
        email,
      });
      if (response.status != 400) {
        setCompanyName(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // fetchData();
    fetchData2();
  }, []);

  return (
    <div class="main-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-3">
          <div className="left-wrapper">
              <Navbar2 />
              <h2 className="admin-name">
                {companyName ? companyName : email1}
              </h2>
              <Link to="/dashboard">
                <button
                  type="button"
                  className="btn btn-light btn-sm back-btn mb-3"
                >
                  <i className="bi bi-arrow-left"></i> Back
                </button>
              </Link>
              {/* <h3 className="small-des">
                   You’ve an upcoming hearing in 3 hrs
                </h3>
                <button className="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    Notifications
                </button> */}
              <div className="" id="collapseExample">
                <div className="dot-bg">
                  <h2 className="d-lg-block d-none">Notifications</h2>
                  <div
                    className="alert alert-primary bg-transparent"
                    role="alert"
                  >
                    No notification
                  </div>
                  {/* <div className="alert alert-primary bg-transparent" role="alert">
                        New Member <strong>‘Akasha Seth’</strong> added to the team. 
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        You have won the dispute against Deepak Jain. See the <a href="#" className="alert-link">Final Terms.</a>
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        2 upcoming Hearings tomorrow. <a href="#" className="alert-link">See full Schedule</a>
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        <div className="d-flex justify-content-between">
                            <div>
                                12 new in last 2 days
                            </div>
                            <div>
                                <a href="#" className="alert-link">view all</a>
                            </div>
                        </div>

                    </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- /.col-lg-12 --> */}
          <div class="col-xl-9">
            <div class="right-wrapper">
              <div class="conciliation-application-form">
                <form onSubmit={handlerforSubmit}>
                  <div class="col-lg-12">
                    <div class="card card-new h-100">
                      <h2>New Lead Form</h2>
                      <div class="row mb-3">
                        <div class="col-lg-4 mb-3">
                          <label class="form-label form-label-new">Name</label>
                          <input
                            type="text"
                            name="name"
                            value={name}
                            onChange={handleInputChange}
                            class="form-control form-control-new"
                            placeholder=""
                          />
                        </div>
                        <div class="col-lg-4 mb-3">
                          <label class="form-label form-label-new">
                            Email ID:
                          </label>
                          <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={handleInputChange}
                            class="form-control form-control-new"
                            placeholder=""
                          />
                        </div>
                        <div class="col-lg-4 mb-3">
                          <label class="form-label form-label-new">
                            Phone No:
                          </label>
                          <input
                            type="text"
                            name="phone"
                            value={phone}
                            onChange={handleInputChange}
                            class="form-control form-control-new"
                            placeholder=""
                          />
                        </div>                     
                        <div class="col-lg-4 mb-3">
                          <label class="form-label form-label-new">
                            Select a Country
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={selectedCountry ? selectedCountry.name : ""}
                            onChange={(e) => {
                              const selectedCountryValue = e.target.value;
                              const country = Country.getAllCountries().find(
                                (country) =>
                                  country.name === selectedCountryValue
                              );
                              setSelectedCountry(country);
                            }}
                          >
                            <option value="">Select a Country</option>
                            {Country.getAllCountries().map((country) => (
                              <option
                                key={country.isoCode}
                                value={country.name}
                              >
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div class="col-lg-4 mb-3">
                          <label class="form-label form-label-new">
                            Select a State
                          </label>
                          <select
                            className="form-select mb-3"
                            value={selectedState ? selectedState.name : ""}
                            onChange={(e) => {
                              const selectedStateValue = e.target.value;
                              const state = State.getStatesOfCountry(
                                selectedCountry?.isoCode
                              ).find(
                                (state) => state.name === selectedStateValue
                              );
                              setSelectedState(state);
                            }}
                          >
                            <option value="">Select a State</option>
                            {selectedCountry &&
                              State.getStatesOfCountry(
                                selectedCountry.isoCode
                              ).map((state) => (
                                <option key={state.isoCode} value={state.name}>
                                  {state.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div class="col-lg-4 mb-3">
                          <label class="form-label form-label-new">
                            Select a City
                          </label>
                          <select
                            className="form-select"
                            value={selectedCity ? selectedCity.name : ""}
                            onChange={(e) => {
                              const selectedCityValue = e.target.value;
                              const city = City.getCitiesOfState(
                                selectedState?.countryCode,
                                selectedState?.isoCode
                              ).find((city) => city.name === selectedCityValue);
                              setSelectedCity(city);
                            }}
                          >
                            <option value="">Select a City</option>
                            {selectedState &&
                              City.getCitiesOfState(
                                selectedState.countryCode,
                                selectedState.isoCode
                              ).map((city) => (
                                <option key={city.id} value={city.name}>
                                  {city.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div class="col-lg-4 mb-3">
                          <label class="form-label form-label-new">
                            Source
                          </label>
                          <select
                            class="form-select"
                            name="source"
                            value={source}
                            onChange={handleInputChange}
                            aria-label="Default select example"
                          >
                            <option selected>Company </option>
                            <option >Call</option>
                            <option >Email</option>
                            <option >Google</option>
                            <option >LinkedIn</option>
                            <option >Instagram</option>
                            <option >X.com</option>
                            <option >Campaign</option>
                          </select>
                        </div>
                        <div class="col-lg-4 mb-3">
                          <label class="form-label form-label-new">
                            Organisation Name
                          </label>
                          <input
                            type="text"
                            name="organization"
                            value={organization}
                            onChange={handleInputChange}
                            class="form-control form-control-new"
                            placeholder=""
                          />
                        </div>
                        <div class="col-lg-4 mb-3">
                          <label class="form-label form-label-new">
                            User Type
                          </label>
                          <select
                            class="form-select"
                            name="user"
                            value={user}
                            onChange={handleInputChange}
                            aria-label="Default select example"
                          >
                            <option selected>Select Type </option>
                            <option >MSME</option>
                            <option >SME</option>
                            <option >NBFC</option>
                          </select>
                        </div>
                      </div>
                      <h2 class="mb-3">Additional Information</h2>
                      <div class="row mb-3">
                        <div class="col-md-12">
                          <label class="form-label form-label-new">
                            Details
                          </label>
                          <textarea class="form-control"
                           name="detail"
                           value={detail}
                           onChange={handleInputChange} rows="6"></textarea>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <button type="submit" class="btn btn-primary">
                            Create Lead
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /.card card-new --> */}
                  </div>
                </form>
              </div>
              {/* <!-- /.party-form --> */}
            </div>
            {/* <!-- /.right-wrapper --> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(SalesForm);
