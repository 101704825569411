
const buildTextForPdf=(TextHead, fonts, firstPage, currentX, currentY, addressX, fontSize=11, wordSize=11)=>{
    if(!TextHead){
        return {currentX, currentY};
    }
    TextHead=TextHead.replace(/(?:\s|\n)+/g, ' ').trim();
    console.log(TextHead);
    const maxWidth = 530;
    let lines = [''];
    let currentLineIndex = 0;
      TextHead.split(' ').forEach(word => {
      const currentLine = lines[currentLineIndex];
      const widthWithWord = fonts.widthOfTextAtSize(currentLine + ' ' + word, wordSize);
      if(currentLine.split(' ').length>=14){
        currentLineIndex++;
        lines[currentLineIndex] = word;
      }     
      else if (currentX+widthWithWord < maxWidth) {
        lines[currentLineIndex] += (currentLine ? ' ' : '') + word;
      } else {
        currentLineIndex++;
        lines[currentLineIndex] = word;
      }
    });
    let x=currentX;
    let y=currentY;
    let len=lines.length;
    lines.forEach((line, index) => {
      let yPosition = currentY - (index) * (fontSize+3);
      const extraSpace = maxWidth - fonts.widthOfTextAtSize(line, wordSize);
      const wordCount = line.split(' ').length - 1;
      let spaceBetweenWords = 0;
      if(index!=len-1){
        spaceBetweenWords=wordCount > 0 ? extraSpace / wordCount : 0;
      }
      else {
        spaceBetweenWords=4;
      }
      let xPosition =currentX;
      line.split(' ').forEach(word => {
        // console.log(word, 'word');
        
        firstPage.drawText(word, {
          x: xPosition,
          y: yPosition,
          size: fontSize,
          font: fonts,
        //   color: rgb(0, 0, 0)
        });
        xPosition += fonts.widthOfTextAtSize(word, wordSize) + spaceBetweenWords;
        x=xPosition;
        y=yPosition;
      });
      currentX=36;
    });
    return {currentX:x, currentY:y};
  }

// const LRNbuildTextForPdf=(TextHead, fonts, firstPage, currentX, currentY,pdfDoc,pdfDoc1, addressX, fontSize=11, wordSize=11)=>{
//     const maxWidth = 530;
//     let currentPage;
//     let lines = [''];
//     let currentLineIndex = 0;
//     // console.log(TextHead);
//     // const word1="PLOT NO.136-137, FLAT NO. 102, RANCHHOD NAGAR SOCIETY, ANJANA BHATHENA, CHORASI.Contact: 9016172438. Email Id: mohammedjahogir@rediffmail.com"/
//       TextHead.split(' ').forEach(word => {
//       //currentLine 
//       const currentLine = lines[currentLineIndex];
//       const widthWithWord = fonts.widthOfTextAtSize(currentLine + ' ' + word, wordSize);

//       //It Checks the whether the word length should not be greater than 14
//       if(currentLine.split(' ').length>=18){
//         currentLineIndex++;
//         lines[currentLineIndex] = word;
//       }     
//       else if (currentX+widthWithWord < maxWidth) {
//         lines[currentLineIndex] += (currentLine ? ' ' : '') + word;
//       } else {
//         currentLineIndex++;
//         lines[currentLineIndex] = word;
//       }
//     });
//     let startX=currentX;
//     let x=currentX;
//     let y=currentY;
//     let len=lines.length;
//     // console.log(lines)
//     let tempIndex=0;
//     lines.forEach((line, index) => { 
//       let yPosition = currentY - (tempIndex) * (fontSize+3); 
//       if (yPosition <= 100) {
//         tempIndex=0;
//         // Move to a new page
//       yPosition = firstPage.getSize().height - 40; // Adjust as needed
//       //This Rest the Current Y to Top Position for the new Page 
//       currentY=yPosition;
//       //in this we assign new page with firstPage Overwrtie
//       firstPage = pdfDoc.addPage();
//       currentX = startX; //Set Same Position of X which given for next line 
//       } 
//       // const yPosition=currentY;
//       const extraSpace = maxWidth - fonts.widthOfTextAtSize(line, wordSize);
//       // const widthWithWord = fonts.widthOfTextAtSize(line + ' ' + word, wordSize);
//       const wordCount = line.split(' ').length - 1;
//       let spaceBetweenWords = 0;
//       if(index!=len-1){
//         spaceBetweenWords=wordCount > 0 ? extraSpace / wordCount : 0;
//       }
//       else {
//         spaceBetweenWords=4;
//       }
//       let xPosition =currentX;
//       line.split(' ').forEach(word => {
//         firstPage.drawText(word, {
//           x: xPosition,
//           y: yPosition,
//           size: fontSize,
//           font: fonts,
//           // color: rgb(0, 0, 0)
//         });
//         xPosition += fonts.widthOfTextAtSize(word, wordSize) + spaceBetweenWords;
//         x=xPosition;
//         y=yPosition;
//       });
//       tempIndex++;
//       // currentX=36;
//       // yPosition = currentY - (index) * fontSize;
//     });
//     return {currentX:x, currentY:y,currentPage:firstPage};
//   }

  const InvocationTextForPdf=(TextHead, fonts, firstPage, currentX, currentY,pdfDoc, addressX, fontSize=11, wordSize=11)=>{
    if(!TextHead){
      return {currentX, currentY};
    }
    TextHead=TextHead.replace(/(?:\s|\n)+/g, ' ').trim();
    const maxWidth = 530;
    let currentPage;
    let lines = [''];
    let currentLineIndex = 0;
    console.log(TextHead);

      TextHead.split(' ').forEach(word => {
      //currentLine 
      const currentLine = lines[currentLineIndex];
      const widthWithWord = fonts.widthOfTextAtSize(currentLine + ' ' + word, wordSize);

      //It Checks the whether the word length should not be greater than 14
      if(currentLine.split(' ').length>=18){
        currentLineIndex++;
        lines[currentLineIndex] = word;
      }     
      else if (currentX+widthWithWord < maxWidth) {
        lines[currentLineIndex] += (currentLine ? ' ' : '') + word;
      } else {
        currentLineIndex++;
        lines[currentLineIndex] = word;
      }
    });
    let startX=currentX;
    let x=currentX;
    let y=currentY;
    let len=lines.length;
    // console.log(lines)
    let tempIndex=0;
    lines.forEach((line, index) => { 
      let yPosition = currentY - (tempIndex) * (fontSize+3); 
      const extraSpace = maxWidth - fonts.widthOfTextAtSize(line, wordSize);
      // const widthWithWord = fonts.widthOfTextAtSize(line + ' ' + word, wordSize);
      const wordCount = line.split(' ').length - 1;
      let spaceBetweenWords = 0;
      if(index!=len-1){
        spaceBetweenWords=wordCount > 0 ? extraSpace / wordCount : 0;
      }
      else {
        spaceBetweenWords=4;
      }
      let xPosition =currentX;
      line.split(' ').forEach(word => {
        firstPage.drawText(word, {
          x: xPosition,
          y: yPosition,
          size: fontSize,
          font: fonts,
          // color: rgb(0, 0, 0)
        });
        xPosition += fonts.widthOfTextAtSize(word, wordSize) + spaceBetweenWords;
        x=xPosition;
        y=yPosition;
      });
      tempIndex++;
      // currentX=36;
      // yPosition = currentY - (index) * fontSize;
    });
    return {currentX:x, currentY:y};
  }

  const LRNbuildTextForPdf=(TextHead, fonts, firstPage, currentX, currentY,pdfDoc, addressX, fontSize=11, wordSize=11)=>{
    console.log(TextHead);
    if(!TextHead){
      return {currentX, currentY};
    }
    TextHead=TextHead.replace(/(?:\s|\n)+/g, ' ').trim();
    const maxWidth = 530;
    let currentPage;
    let lines = [''];
    let currentLineIndex = 0;
    // console.log(TextHead);
    // const word1="PLOT NO.136-137, FLAT NO. 102, RANCHHOD NAGAR SOCIETY, ANJANA BHATHENA, CHORASI.Contact: 9016172438. Email Id: mohammedjahogir@rediffmail.com"/
      TextHead.split(' ').forEach(word => {
      //currentLine 
      const currentLine = lines[currentLineIndex];
      const widthWithWord = fonts.widthOfTextAtSize(currentLine  + word, wordSize);

      //It Checks the whether the word length should not be greater than 14
      if(currentLine.split(' ').length>=25){
        currentLineIndex++;
        lines[currentLineIndex] = word;
      }     
      else if (currentX+widthWithWord < maxWidth) {
        lines[currentLineIndex] += (currentLine ? ' ' : '') + word;
      } else {
        currentLineIndex++;
        lines[currentLineIndex] = word;
      }
    });
    let startX=currentX;
    let x=currentX;
    let y=currentY;
    let len=lines.length;
    // console.log(lines)
    let tempIndex=0;
    lines.forEach((line, index) => { 
      let yPosition = currentY - (tempIndex) * (fontSize+3); 
      const extraSpace = maxWidth - fonts.widthOfTextAtSize(line, wordSize);
      // const widthWithWord = fonts.widthOfTextAtSize(line + ' ' + word, wordSize);
      const wordCount = line.split(' ').length - 1;
      let spaceBetweenWords = 0;
      if(index!=len-1){
        spaceBetweenWords=wordCount > 0 ? extraSpace / wordCount : 0;
      }
      else {
        spaceBetweenWords=4;
      }
      let xPosition =currentX;
      line.split(' ').forEach(word => {
        console.log(word);
        firstPage.drawText(word, {
          x: xPosition,
          y: yPosition,
          size: fontSize,
          font: fonts,
          // color: rgb(0, 0, 0)
        });
        xPosition += fonts.widthOfTextAtSize(word, wordSize) + spaceBetweenWords;
        x=xPosition;
        y=yPosition;
      });
      tempIndex++;
      // currentX=36;
      // yPosition = currentY - (index) * fontSize;
    });
    return {currentX:x, currentY:y};
  }

const ArbitrationbuildTextForPdf=(TextHead, fonts, firstPage, currentX, currentY,pdfDoc,combinedPdf, addressX, fontSize=11, wordSize=11)=>{
    const maxWidth = 530;
    let currentPage;
    let lines = [''];
    let currentLineIndex = 0;
    TextHead=TextHead.replace(/(?:\s|\n)+/g, ' ').trim();
    // const word1="PLOT NO.136-137, FLAT NO. 102, RANCHHOD NAGAR SOCIETY, ANJANA BHATHENA, CHORASI.Contact: 9016172438. Email Id: mohammedjahogir@rediffmail.com"/
      TextHead.split(' ').forEach(word => {
      //currentLine 
      const currentLine = lines[currentLineIndex];
      const widthWithWord = fonts.widthOfTextAtSize(currentLine + ' ' + word, wordSize);

      //It Checks the whether the word length should not be greater than 14
      if(currentLine.split(' ').length>=18){
        currentLineIndex++;
        lines[currentLineIndex] = word;
      }     
      else if (currentX+widthWithWord < maxWidth) {
        lines[currentLineIndex] += (currentLine ? ' ' : '') + word;
      } else {
        currentLineIndex++;
        lines[currentLineIndex] = word;
      }
    });
    let startX=currentX;
    let x=currentX;
    let y=currentY;
    let len=lines.length;
    // console.log(lines)
    let tempIndex=0;
    lines.forEach((line, index) => { 
      let yPosition = currentY - (tempIndex) * (fontSize+3); 
      if (yPosition <= 100) {
        tempIndex=0;
        // Move to a new page
      yPosition = firstPage.getSize().height - 40; // Adjust as needed
      //This Rest the Current Y to Top Position for the new Page 
      currentY=yPosition;
      //in this we assign new page with firstPage Overwrtie
      combinedPdf.addPage();
      firstPage = combinedPdf.getPages()[combinedPdf.getPages().length-1];
      currentX = startX; //Set Same Position of X which given for next line 
      console.log(combinedPdf.getPages().length, 'combined len');
      } 
      // const yPosition=currentY;
      const extraSpace = maxWidth - fonts.widthOfTextAtSize(line, wordSize);
      // const widthWithWord = fonts.widthOfTextAtSize(line + ' ' + word, wordSize);
      const wordCount = line.split(' ').length - 1;
      let spaceBetweenWords = 0;
      if(index!=len-1){
        spaceBetweenWords=wordCount > 0 ? extraSpace / wordCount : 0;
      }
      else {
        spaceBetweenWords=4;
      }
      let xPosition =currentX;
      line.split(' ').forEach(word => {
        firstPage.drawText(word, {
          x: xPosition,
          y: yPosition,
          size: fontSize,
          font: fonts,
        });
        xPosition += fonts.widthOfTextAtSize(word, wordSize) + spaceBetweenWords;
        x=xPosition;
        y=yPosition;
      });
      tempIndex++;
      // currentX=36;
      // yPosition = currentY - (index) * fontSize;
    });
    return {currentX:x, currentY:y,currentPage:firstPage};
  }

const AcceptancebuildTextForPdf2=(TextHead, fonts, firstPage, currentX, currentY,pdfDoc,pdfDoc1, addressX, fontSize=11, wordSize=11)=>{
    const maxWidth = 530;
    let currentPage;
    let lines = [''];
    let currentLineIndex = 0;
    TextHead=TextHead.replace(/(?:\s|\n)+/g, ' ').trim();
    // const word1="PLOT NO.136-137, FLAT NO. 102, RANCHHOD NAGAR SOCIETY, ANJANA BHATHENA, CHORASI.Contact: 9016172438. Email Id: mohammedjahogir@rediffmail.com"/
      TextHead.split(' ').forEach(word => {
      //currentLine 
      const currentLine = lines[currentLineIndex];
      const widthWithWord = fonts.widthOfTextAtSize(currentLine + ' ' + word, wordSize);

      //It Checks the whether the word length should not be greater than 14
      if(currentLine.split(' ').length>=18){
        currentLineIndex++;
        lines[currentLineIndex] = word;
      }     
      else if (currentX+widthWithWord < maxWidth) {
        lines[currentLineIndex] += (currentLine ? ' ' : '') + word;
      } else {
        currentLineIndex++;
        lines[currentLineIndex] = word;
      }
    });
    let startX=currentX;
    let x=currentX;
    let y=currentY;
    let len=lines.length;
    // console.log(lines)
    let tempIndex=0;
    lines.forEach((line, index) => { 
      let yPosition = currentY - (tempIndex) * (fontSize+3); 
      if (yPosition <= 100) {
        tempIndex=0;
        // Move to a new page
      yPosition = firstPage.getSize().height - 40; // Adjust as needed
      //This Rest the Current Y to Top Position for the new Page 
      currentY=yPosition;
      //in this we assign new page with firstPage Overwrtie
      firstPage = pdfDoc.addPage();
      currentX = startX; //Set Same Position of X which given for next line 
      } 
      // const yPosition=currentY;
      const extraSpace = maxWidth - fonts.widthOfTextAtSize(line, wordSize);
      // const widthWithWord = fonts.widthOfTextAtSize(line + ' ' + word, wordSize);
      const wordCount = line.split(' ').length - 1;
      let spaceBetweenWords = 0;
      if(index!=len-1){
        spaceBetweenWords=wordCount > 0 ? extraSpace / wordCount : 0;
      }
      else {
        spaceBetweenWords=4;
      }
      let xPosition =currentX;
      line.split(' ').forEach(word => {
        firstPage.drawText(word, {
          x: xPosition,
          y: yPosition,
          size: fontSize,
          font: fonts,
          // color: rgb(0, 0, 0)
        });
        xPosition += fonts.widthOfTextAtSize(word, wordSize) + spaceBetweenWords;
        x=xPosition;
        y=yPosition;
      });
      tempIndex++;
      // currentX=36;
      // yPosition = currentY - (index) * fontSize;
    });
    return {currentX:x, currentY:y,currentPage:firstPage};
  }

const AcceptancebuildTextForPdf=(TextHead, fonts, firstPage, currentX, currentY, addressX, fontSize=11, wordSize=11)=>{
    const maxWidth = 530;
    let startX=currentX;
    let lines = [''];
    let currentLineIndex = 0;
    // const word1="PLOT NO.136-137, FLAT NO. 102, RANCHHOD NAGAR SOCIETY, ANJANA BHATHENA, CHORASI.Contact: 9016172438. Email Id: mohammedjahogir@rediffmail.com"/
      TextHead.split(' ').forEach(word => {
      //currentLine 
      const currentLine = lines[currentLineIndex];
      const widthWithWord = fonts.widthOfTextAtSize(currentLine + ' ' + word, wordSize);

      //It Checks the whether the word length should not be greater than 14
      if(currentLine.split(' ').length>=18){
        currentLineIndex++;
        lines[currentLineIndex] = word;
      }     
      else if (currentX+widthWithWord < maxWidth) {
        lines[currentLineIndex] += (currentLine ? ' ' : '') + word;
      } else {
        currentLineIndex++;
        lines[currentLineIndex] = word;
      }
    });
    let x=currentX;
    let y=currentY;
    let len=lines.length;
    // console.log(lines)
    lines.forEach((line, index) => { 
      let yPosition = currentY - (index) * (fontSize+3); 
      // const yPosition=currentY;
      const extraSpace = maxWidth - fonts.widthOfTextAtSize(line, wordSize);
      // const widthWithWord = fonts.widthOfTextAtSize(line + ' ' + word, wordSize);
      const wordCount = line.split(' ').length - 1;
      let spaceBetweenWords = 0;
      if(index!=len-1){
        spaceBetweenWords=wordCount > 0 ? extraSpace / wordCount : 0;
      }
      else {
        spaceBetweenWords=4;
      }
      let xPosition =currentX;
      line.split(' ').forEach(word => {
        firstPage.drawText(word, {
          x: xPosition,
          y: yPosition,
          size: fontSize,
          font: fonts,
          // color: rgb(0, 0, 0)
        });
        xPosition += fonts.widthOfTextAtSize(word, wordSize) + spaceBetweenWords;
        x=xPosition;
        y=yPosition;
      });
      currentX=startX;
      // yPosition = currentY - (index) * fontSize;
    });
    return {currentX:x, currentY:y,currentPage:firstPage};
  }

const buildTextForPdf3=(TextHead, fonts, firstPage, currentX, currentY, addressX, fontSize=10, wordSize=11)=>{
  if(!TextHead){
      return {currentX, currentY};
  }

  const maxWidth = 530;
  let lines = [''];
  let currentLineIndex = 0;
  TextHead=TextHead.replace(/(?:\s|\n)+/g, ' ').trim();
  
  TextHead.split(' ').forEach(word => {
    const currentLine = lines[currentLineIndex];
    const widthWithWord = fonts.widthOfTextAtSize(currentLine + '' + word, wordSize);
    let len1=currentLine.split(' ').length;
    if(currentLine.split(' ').length>=17){
      currentLineIndex++;
      lines[currentLineIndex] = word;
    }
    else if (widthWithWord < maxWidth) {
      lines[currentLineIndex] += (currentLine ? ' ' : '') + word;
    } else {
      currentLineIndex++;
      lines[currentLineIndex] = word;
    }
  });
  let x=currentX;
  let y=currentY;
  let len=lines.length;
  
  lines.forEach((line, index) => {
    let yPosition = currentY - (index) * (fontSize+4);
    const extraSpace = maxWidth - fonts.widthOfTextAtSize(line, wordSize);

    const wordCount = line.split(' ').length - 1;
    let spaceBetweenWords = 0;
    if(index!=len-1){
      spaceBetweenWords=wordCount > 0 ? extraSpace / wordCount : 0;
    }
    else {
      spaceBetweenWords=4;
    }
    let xPosition = currentX;
    line.split(' ').forEach(word => {
      // console.log(word)
      firstPage.drawText(word, {
        x: xPosition,
        y: yPosition,
        size: fontSize,
        font: fonts,
      //   color: rgb(0, 0, 0)
      });
      xPosition += fonts.widthOfTextAtSize(word, wordSize) + spaceBetweenWords;
      x=xPosition;
      y=yPosition;
    });
  });
  return {currentX:x, currentY:y};
}

const ConciliationPayrupikbuildTextForPdf=(TextHead, fonts, firstPage, currentX, currentY, addressX, fontSize=11, wordSize=11)=>{
  if(!TextHead){
      return {currentX, currentY};
  }
  TextHead=TextHead.replace(/(?:\s|\n)+/g, ' ').trim();
  console.log(TextHead);
  const maxWidth = 530;
  let lines = [''];
  let currentLineIndex = 0;
    TextHead.split(' ').forEach(word => {
    const currentLine = lines[currentLineIndex];
    const widthWithWord = fonts.widthOfTextAtSize(currentLine + ' ' + word, wordSize);
    if(currentLine.split(' ').length>=20){
      currentLineIndex++;
      lines[currentLineIndex] = word;
    }     
    else if (currentX+widthWithWord < maxWidth) {
      lines[currentLineIndex] += (currentLine ? ' ' : '') + word;
    } else {
      currentLineIndex++;
      lines[currentLineIndex] = word;
    }
  });
  let x=currentX;
  let y=currentY;
  let len=lines.length;
  lines.forEach((line, index) => {
    let yPosition = currentY - (index) * (fontSize+2);
    const extraSpace = maxWidth - fonts.widthOfTextAtSize(line, wordSize);
    const wordCount = line.split(' ').length - 1;
    let spaceBetweenWords = 0;
    if(index!=len-1){
      spaceBetweenWords=wordCount > 0 ? extraSpace / wordCount : 0;
    }
    else {
      spaceBetweenWords=3;
    }
    let xPosition =currentX;
    line.split(' ').forEach(word => {
      // console.log(word, 'word');
      firstPage.drawText(word, {
        x: xPosition,
        y: yPosition,
        size: fontSize,
        font: fonts,
      //   color: rgb(0, 0, 0)
      });
      xPosition += fonts.widthOfTextAtSize(word, wordSize) + spaceBetweenWords;
      x=xPosition;
      y=yPosition;
    });
    currentX=currentX;
  });
  return {currentX:x, currentY:y};
}

const buildTextForPdfAddress=(TextHead, fonts, firstPage, currentX, currentY, addressX, fontSize=11, wordSize=11)=>{
  if(!TextHead){return {currentX, currentY};}
  TextHead=TextHead.replace(/(?:\s|\n)+/g, ' ').trim();
  console.log(TextHead);
  const maxWidth = 350;
  let lines = [''];
  let currentLineIndex = 0;
    TextHead.split(' ').forEach(word => {
    const currentLine = lines[currentLineIndex];
    const widthWithWord = fonts.widthOfTextAtSize(currentLine + ' ' + word, wordSize);
    if(currentLine.split(' ').length>=17){
      currentLineIndex++;
      lines[currentLineIndex] = word;
    }     
    else if (currentX+widthWithWord < maxWidth) {
      lines[currentLineIndex] += (currentLine ? ' ' : '') + word;
    } else {
      currentLineIndex++;
      lines[currentLineIndex] = word;
    }
  });
  let x=currentX;
  let y=currentY;
  let len=lines.length;
  lines.forEach((line, index) => {
    let yPosition = currentY - (index) * (fontSize+3);
    const extraSpace = maxWidth - fonts.widthOfTextAtSize(line, wordSize);
    const wordCount = line.split(' ').length - 1;
    let spaceBetweenWords = 0;
    if(index!=len-1){
      spaceBetweenWords=wordCount > 0 ? extraSpace / wordCount : 0;
    }
    else {
      spaceBetweenWords=4;
    }
    let xPosition =currentX;
    line.split(' ').forEach(word => {
      // console.log(word, 'word');
      
      firstPage.drawText(word, {
        x: xPosition,
        y: yPosition,
        size: fontSize,
        font: fonts,
      //   color: rgb(0, 0, 0)
      });
      xPosition += fonts.widthOfTextAtSize(word, wordSize) + spaceBetweenWords;
      x=xPosition;
      y=yPosition;
    });
    currentX=36;
  });
  return {currentX:x, currentY:y};
}

  /////////////////////////
const getFormattedDate=()=> {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const today = new Date();
  const day = today.getDate();
  const month = months[today.getMonth()];
  const year = today.getFullYear();
  return `${day}-${month}-${year}`;
}
  
const getTimestamp=() =>{
  // Get the current time in milliseconds since the epoch
  const timestamp = new Date().getTime();
  return timestamp;
}

  // const calculateDateAfter15Days=() =>{
  //   const months = [
  //     "January",
  //     "Febrary",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December",
  //   ];
  //   const currentDate = new Date();
  //   const futureDate = new Date(currentDate);
  //   futureDate.setDate(currentDate.getDate() + 15);
  
  //   //Logic for Avoiding Saturday and Sunday Notice to schdule
  //   const res = futureDate.getDay();
  //   if (res == "5") {
  //     futureDate.setDate(currentDate.getDate() + 17);
  //   } else if (res == "6") {
  //     futureDate.setDate(currentDate.getDate() + 16);
  //   }
  //   // Format the date as "DD/MM/YYYY" (change as needed)
  //   const formattedDate = `${futureDate.getDate()} ${
  //     months[futureDate.getMonth()]
  //   } ${futureDate.getFullYear()}`;
  
  //   return formattedDate;
  // }

function calculateDateAfter15Days() {
  const months = ["January", "Febrary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December",];
  const currentDate = new Date();
  const futureDate = new Date(currentDate);
  futureDate.setDate(currentDate.getDate() + 15);
  const dayOfWeek = futureDate.getDay();
  if (dayOfWeek === 0) {
    futureDate.setDate(futureDate.getDate() + 1);
  } else if (dayOfWeek === 6) {
    futureDate.setDate(futureDate.getDate() + 2);
  }
  const formattedDate = `${futureDate.getDate()} ${months[futureDate.getMonth()]} ${futureDate.getFullYear()}`;
  return formattedDate;
}

const generateRandomInvoiceID=()=> {
  const timestamp = Date.now().toString(); // Get current timestamp as a string
  const invoiceID = timestamp;
  return invoiceID;
}

function generatedConcilationCaseID(x) {
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  // const paddedNumber = x.toString().padStart(5, '0');
  const result = `CONNORLF/${currentMonth}/${currentYear}/${x}`;
  return result;
}
function convertToInteger(floatString) {
  floatString = floatString.replace(/,/g, '');
  const parts = floatString.split('.');
  const result = parts[0] ;
  return result;
}
  
module.exports={buildTextForPdf,convertToInteger, buildTextForPdfAddress, ConciliationPayrupikbuildTextForPdf, generatedConcilationCaseID ,InvocationTextForPdf, LRNbuildTextForPdf,AcceptancebuildTextForPdf, ArbitrationbuildTextForPdf,buildTextForPdf3, getFormattedDate, getTimestamp, calculateDateAfter15Days, generateRandomInvoiceID};
