import React, { useEffect, useState } from "react";
import axios from "axios";
import BillingPdf from "../asset/BillingForm1.pdf";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
// import loadingIcon from '../asset/loading.gif';
import Loading from "./loading";
import http from "../services/httpService";
import auth from "../services/authService";
// import { Link } from "react-router-dom";

// import { useNavigate } from "react-router-dom"; // Step 1: Import useHistory
//To Generate the InvoiceID
// import viewIcon from "../asset/viewIcon.svg";
import { Link } from "react-router-dom";
import Navbar2 from "./navbar2";
// function generateRandomInvoiceID(length) {
//   const min = Math.pow(10, length - 1);
//   const max = Math.pow(10, length) - 1;
//   return Math.floor(Math.random() * (max - min + 1)) + min;
// }
const Billing = () => {
  // const navigate = useNavigate();
  const email = auth.getUser().email;
  const role=auth.getUser().role;
  const [data, setData] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [Invoices, setInvoices] = useState([]);
  const [totalFeesByMonth, setTotalFeesByMonth] = useState([]);
  const [filterByMonth, setFilterByMonth] = useState("All");
  const [filterByYear, setFilterByYear] = useState("All");
  const [filterByPaymentStatus, setFilterByPaymentStatus] = useState("All");
  const [selectedStatuses, setSelectedStatuses] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [companyName, setCompanyName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const pageSize = 10; // Number of invoices per page
  const years = ["2019", "2020", "2021", "2022", "2023"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // Modify the onClick handler for the "View" button
  // const handleViewClick = () => {
  //   // Navigate to the /dashboard path
  //   navigate("/dashboard"); // Step 5: Navigate to /dashboard
  // };
  const handlePageChange = (newPage) => {
    // Update the current page when changing pages
    setCurrentPage(newPage);
  };
  //condition for filter Month and Status Wise
  const filteredData = Invoices.filter((item) => {
    const monthMatch =
      filterByMonth === "All" ||
      months[item.month - 1]
        .toLowerCase()
        .includes(filterByMonth.toLowerCase());
    const YearMatch =
      filterByYear === "All" || String(item.year) === filterByYear;
    const paymentStatusMatch =
      filterByPaymentStatus === "All" ||
      (filterByPaymentStatus.toLowerCase() === "paid" &&
        item.status === "Paid") ||
      (filterByPaymentStatus.toLowerCase() === "unpaid" &&
        item.status === "Unpaid");
    return monthMatch && YearMatch && paymentStatusMatch;
  });

  const fetchData = async () => {
    try {
      const response = await http.post2("/getCompanyName", {
        email,
      });
      if (response.status != 400) {
        // console.log(response.data, 'response.data gstComponent');
        setCompanyName(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchData4 = async () => {
    try {
      const response = await http.post2("/getAllCompanies", {
        email,
      });
      if (response.status != 400) {
        // console.log(response.data, 'response.data gstComponent');
        setAllCompanies(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchData2 = () => {
    http
      .post2("/getInvoices", {
        page: currentPage,
        perPage: pageSize,
        email: auth.getUser().email,
      })
      .then((response) => {
        // const { data, allCompanies, totalFeesByMonth, Invoices } =
        //   response.data;
        console.log(response.data);
        setData(response.data);
        // setAllCompanies(allCompanies);
        // setTotalFeesByMonth(totalFeesByMonth);
        // setInvoices(Invoices);
        
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const fetchData3 = () => {
    http
      .post2("/update-invoice-status", { selectedStatuses })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    fetchData2();
    fetchData4();
  }, []);

  const formatTimestamp=(timestamp)=> {
    const date = new Date(timestamp);
  
    console.log(timestamp);
    // Extracting day, month, and year components
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    // Creating the formatted date string
    const formattedDate = `${day}/${month}/${year}`;
    console.log(formattedDate)
    return formattedDate;
}

  const generateAndDownloadPDF = async (item, data) => {
    // console.log(gstInfo);
    const existingPdfBytes = await fetch(BillingPdf).then((res) =>
      res.arrayBuffer()
    );
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const FormData2 = [
      {
        text: String(allCompanies[0].gst?.result.source_output.legal_name),
        y: 622,
      },
      //
      {
        text: `GSTIN:${String(
          allCompanies[0]?.gst?.result.source_output.gstin
        )}`,
        y: 540,
      },
      //     // text: String(panInfo[0]?.result.source_output.address.full),y:600},
      {
        text: `PAN:${String(
          allCompanies[0]?.pan?.result.source_output.pan_number
        )}`,
        y: 520,
      },
    ];
    const maxLineWidth = 500 - 320; // Maximum width before breaking to a new line
    const addressX = 320; // X-coordinate where the address should start
    const addressY = 600; // Y-coordinate where the address should start
    const addressText = allCompanies[0]?.pan?.result.source_output.address.full; // Your address text here

    let currentX = addressX;
    let currentY = addressY;

    for (const word of addressText.split(" ")) {
      const wordWidth = boldFont.widthOfTextAtSize(word, 10);
      if (currentX + wordWidth > 500) {
        // Move to a new line if the word would exceed the maximum width
        currentX = addressX;
        currentY -= 12; // Adjust the line spacing as needed
      }

      firstPage.drawText(word, {
        x: currentX,
        y: currentY,
        font: boldFont,
        size: 10,
        color: rgb(48 / 255, 79 / 255, 157 / 255),
      });

      currentX += wordWidth + 5; // Add 5 units of spacing between words
    }
    FormData2.map(({ text, y }) => {
      const textString = typeof text === "number" ? text.toString() : text;
      firstPage.drawText(textString, {
        x: 320,
        y,
        font: boldFont,
        size: 10,
        color: rgb(48 / 255, 79 / 255, 157 / 255),
      });
    });
    //Addition of Invoice Id
    firstPage.drawText(`${item.invoiceId}`, {
      x: 160,
      y: 714,
      font: boldFont,
      size: 10,
      color: rgb(48 / 255, 79 / 255, 157 / 255),
    });
    //Addition of Date of Agreement
    firstPage.drawText(`${formatTimestamp(+item.timestamp)}`, {
      x: 160,
      y: 695,
      font: boldFont,
      size: 10,
      color: rgb(48 / 255, 79 / 255, 157 / 255),
    });
    //Addition of Year
    firstPage.drawText(`${months[item.month - 1]},${item.year}`, {
      x: 100,
      y: 418,
      font: boldFont,
      size: 10,
      color: rgb(48 / 255, 79 / 255, 157 / 255),
    });
    //Addition of Quantity of Agreement
    firstPage.drawText(`${item.feeCount}`, {
      x: 260,
      y: 418,
      font: boldFont,
      size: 10,
      color: rgb(48 / 255, 79 / 255, 157 / 255),
    });
    //Addition of Total Amount
    firstPage.drawText(`Rs ${item.totalFee}`, {
      x: 320,
      y: 418,
      font: boldFont,
      size: 10,
      color: rgb(48 / 255, 79 / 255, 157 / 255),
    });

    // Calculate the GST amount
    const gstAmount = (18 / 100) * item.totalFee;
    firstPage.drawText(`Rs ${gstAmount}`, {
      x: 405,
      y: 418,
      font: boldFont,
      size: 10,
      color: rgb(48 / 255, 79 / 255, 157 / 255),
    });
    // Calculate the total amount (fee + GST)
    const totalAmount = item.totalFee + gstAmount;
    firstPage.drawText(`Rs ${totalAmount}`, {
      x: 470,
      y: 418,
      font: boldFont,
      size: 10,
      color: rgb(48 / 255, 79 / 255, 157 / 255),
    });

    firstPage.drawText(`Rs ${item.totalFee}`, {
      x: 500,
      y: 384,
      font: boldFont,
      size: 10,
      color: rgb(48 / 255, 79 / 255, 157 / 255),
    });

    // Calculate the GST amount
    firstPage.drawText(`Rs ${gstAmount}`, {
      x: 500,
      y: 368,
      font: boldFont,
      size: 10,
      color: rgb(48 / 255, 79 / 255, 157 / 255),
    });

    firstPage.drawText(`Rs ${totalAmount}`, {
      x: 500,
      y: 340,
      font: boldFont,
      size: 10,
      color: rgb(48 / 255, 79 / 255, 157 / 255),
    });

    firstPage.drawText(`0`, {
      x: 500,
      y: 316,
      font: boldFont,
      size: 10,
      color: rgb(48 / 255, 79 / 255, 157 / 255),
    });
    firstPage.drawText(`${formatTimestamp(+item.timestamp)}`, {
      x: 75,
      y: 110,
      font: boldFont,
      size: 10,
      color: rgb(48 / 255, 79 / 255, 157 / 255),
    });
    firstPage.drawText(`${item.status}`, {
      x: 190,
      y: 110,
      font: boldFont,
      size: 10,
      color: rgb(48 / 255, 79 / 255, 157 / 255),
    });
    firstPage.drawText(`Rs.6490`, {
      x: 290,
      y: 110,
      font: boldFont,
      size: 10,
      color: rgb(48 / 255, 79 / 255, 157 / 255),
    });
    firstPage.drawText(`ONLINE_TRANSFER`, {
      x: 400,
      y: 110,
      font: boldFont,
      size: 10,
      color: rgb(48 / 255, 79 / 255, 157 / 255),
    });
    // const pdfBytes1 = await pdfDoc.saveAsBase64();
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "BillingInvoice.pdf";
    link.click();
  };
  
  return isLoading ? (
    <>
      <Loading></Loading>
    </>
  ) : (
    <>
      <div className="main-wrapper">
        <div className=" container-fluid">
          <div className="row">
            <div className="col-xl-3">
              <div className="left-wrapper">
                <Navbar2 />
                <h2 className="admin-name">
                  {companyName ? companyName : email}
                </h2>
                <Link to="/dashboard">
                  <button
                    type="button"
                    className="btn btn-light btn-sm back-btn mb-3"
                  >
                    <i className="bi bi-arrow-left"></i> Back
                  </button>
                </Link>

                {/* <button
                className="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Filter
              </button> */}
                <div className="" id="collapseExample">
                  <div className="data-filter mt-4">
                    <div className="form-floating mb-3">
                      <select
                        className="form-select"
                        id="floatingSelect"
                        value={filterByMonth}
                        onChange={(e) => setFilterByMonth(e.target.value)}
                        aria-label="Floating label select example"
                      >
                        <option>All</option>
                        {months.map((item, index) => (
                          <option key={index}>{item}</option>
                        ))}
                      </select>
                      <label>Select the Months</label>
                    </div>
                    <div className="form-floating mb-3">
                      <select
                        className="form-select"
                        id="floatingSelect"
                        value={filterByYear}
                        onChange={(e) => setFilterByYear(e.target.value)}
                        aria-label="Floating label select example"
                      >
                        <option>All</option>
                        {years.map((item, index) => (
                          <option key={index}>{item}</option>
                        ))}
                      </select>
                      <label>Select the Year</label>
                    </div>
                    <div className="form-floating mb-3">
                      <select
                        className="form-select"
                        id="floatingSelect"
                        value={filterByPaymentStatus}
                        onChange={(e) =>
                          setFilterByPaymentStatus(e.target.value)
                        }
                        aria-label="Floating label select example"
                      >
                        <option>All</option>
                        <option>Paid</option>
                        <option>UnPaid</option>
                      </select>
                      <label>Select the Status</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9">
              <div className="right-wrapper ">
                <div className="org-data pb-5">
                  <div className="row mt-5">
                    <div className="col-lg-12">
                      {data.length > 0 ? (
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">S.NO</th>
                              <th scope="col">Invoice ID</th>
                              <th scope="col">Status</th>
                              <th scope="col">Month</th>
                              <th scope="col">Total Value</th>
                              <th scope="col">View</th>
                              <th scope="col">Download</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => (
                              <tr key={index}>
                                <th data-label="S.NO">{index + 1}</th>
                                <td data-label="Invoice ID">{item.invoiceId}</td>
                                {/* <td>
                                  <div className="main-div-status">
                                    <div className="div-status">
                                      {role=='admin'?<select
                                        className="paidstatus"
                                        value={selectedStatuses[index]?.status || ""} 
                                        onChange={(e) => { 
                                          const value = e.target.value;
                                          setSelectedStatuses({
                                            [index]: {
                                              status: value,
                                              invoiceId: item.invoiceId,
                                            },
                                          });
                                        }}
                                      >
                                        <option value=''></option>
                                        <option value='paid'>Paid</option>
                                        <option value='unpaid'>Unpaid</option>
                                      </select>:item.status}
                                    </div>
                                  </div>
                                </td> */}
                                <td data-label="Status"><select
                                          className="form-select"
                                          aria-label="Default select example"
                                          // value={selectedStatuses[index]?.status || ""}
                                          value={item.status}
                                          onChange={(e) => { 
                                            const value = e.target.value;
                                            setSelectedStatuses({
                                              [index]: {
                                                status: value,
                                                invoiceId: item.invoiceId,
                                              },
                                            });
                                          }}
                                        >
                                          {/* <option value=''></option> */}
                                        <option value='paid'>Paid</option>
                                        <option value='unpaid'>Unpaid</option>
                                        </select></td>
                                
                                <td data-label="Month">{months[item.month - 1]}</td>
                                <td data-label="Total Value">{item.totalFee}</td>
                                <td data-label="View">
                                  <Link
                                    to={"/usersDashboard"}
                                    className="text-primary"
                                  >
                                    <button className="btn-primary">
                                    <i
                                      className="bi bi-eye text-primary"
                                      style={{
                                        width: "60px",
                                        height: "60px",
                                        cursor: "pointer",
                                        paddingBottom: "10px",
                                        textDecoration: "none",
                                      }}
                                    ></i></button>
                                  </Link>
                                </td>
                                <td data-label="Download">
                                  <button
                                    className="btn btn-primary fw-bold"
                                    onClick={() =>
                                      generateAndDownloadPDF(
                                        item,
                                        data
                                      )
                                    }
                                  >
                                    Download
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <p>
                          Discover the simplicity of our monthly postpaid
                          billing system. All invoices are meticulously
                          generated on the 1st of each month, ensuring a
                          transparent and efficient financial process. Trust us
                          for a seamless billing experience that allows you to
                          focus on what truly matters.
                        </p>
                      )}
                      <div className="row justify-content-center">
                        <div className="col-lg-5"></div>
                        <div className="col-lg-2 justify-content-center">
                          <div
                            className="btn-toolbar"
                            role="toolbar"
                            aria-label="Toolbar with button groups"
                          >
                            <div
                              className="btn-group me-1"
                              role="group"
                              aria-label="First group"
                            >
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() =>
                                  handlePageChange(currentPage - 1)
                                }
                                disabled={currentPage === 1}
                              >
                                <i className="bi bi-chevron-left text-white"></i>
                              </button>
                            </div>
                            <div
                              className="btn-group me-1"
                              role="group"
                              aria-label="Second group"
                            >
                              <button
                                // type="button"
                                className="text-primary ms-2 me-2"
                              >
                                {currentPage}
                              </button>
                            </div>
                            <div
                              className="btn-group"
                              role="group"
                              aria-label="Third group"
                            >
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() =>
                                  handlePageChange(currentPage + 1)
                                }
                                disabled={filteredData.length < pageSize}
                              >
                                <i className="bi bi-chevron-right text-white"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-5"></div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*<!-- /.org-data -->*/}
              </div>
              {/*<!-- /.right-wrapper -->*/}
            </div>
            {/* <!-- /.col-lg-9 --> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default Billing;
