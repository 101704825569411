import React, { useState, useEffect } from "react";
import http from "../services/httpService.js";
import auth from "../services/authService.js";
import Papa from "papaparse";

const CsvUploader2 = (props) => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(true);
  const [actionType, setActionType] = useState("");
  const [action, setAction] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [disputeResolutionClause, setDisputeResolutionClause] = useState("");
  const email = auth.getUser().email;

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
  };

  const handleSubmit = async (event) => {
    if (!file) {
      alert("Please select a CSV file.");
      return;
    }
    setFlag(false);
    const formData = new FormData();
    formData.append("csvFile", file);
    try {
      const now = new Date();
      const timeStamp = now.getTime();
      const response = await http.post("/csvUpload3", {
        email,
        data: { csvData: data },
        timeStamp,
      });
      const response2 = await http.post("/setNoticeInfo", {
        data: {
          email,
          timeStamp,
          actionType,
          action,
          letterhead: "",
          approval: "No",
          bulkApproval:"No",
          status: "",
          companyName,
          disputeResolutionClause
        },
      });
      console.log(response.data, "response");
      console.log(response2.data, "response2");
      if (response.data === "Data Saved successfully") {
        setFlag(true);
        alert("CSV file uploaded successfully!");
      } else {
        alert("Failed to save data");
      }
    } catch (error) {
      setFlag(true);
      console.error("Error uploading CSV file:", error);
      alert("Failed to upload CSV file. Please try again.");
    }
  };

  const fetchData2 = async () => {
    try {
      const response = await http.post2("/getCompanyName", {
        email,
      });
      if (response.status != 400) {
        // console.log(response.data, 'response.data gstComponent');
        setCompanyName(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFileRead = (event) => {
    const csvData = event.target.result;
    const parsedData = Papa.parse(csvData, { header: true });
    console.log(parsedData.data, "parseData");
    setData(parsedData.data);
  };

  const handleFileUpload = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    if (!file) {
      alert("Please select a CSV file.");
      return;
    }
    const reader = new FileReader();
    reader.onloadend = handleFileRead;
    reader.readAsText(file);
  };

  useEffect(() => {
    fetchData2();
  }, []);

  return (
    <>
      <div className="row mb-4">
        <div class="col-md-4">
                  <label class="form-label form-label-new">Upload CSV</label>

          <input
            type="file"
            accept=".csv"
            className="form-control"
            onChange={handleFileChange}
            id="csv-file-input"
          />
        </div>
        <div className="col-md-2 mt-4">

          <button
            className="btn btn-primary"
            disabled={!file}
            onClick={handleFileUpload}
          >
            Upload
          </button>
        </div>
        <div className="col-md-2 mt-4">
        <button
            className="btn btn-primary"
            disabled={
              !file ||
              !flag 
            }
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="row mb-4">

      </div>
      <div className="col-md-12 pt-3">
      <div className="col-md-2">
        </div>
      </div>
    </>
  );
};

export default CsvUploader2;
