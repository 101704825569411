import { useEffect, useState } from "react";
// import "./Account.css";
import axios, { all } from "axios";
import http from "../services/httpService";
import auth from "../services/authService";
import { Link } from "react-router-dom";
import AllAccount from "./accounts/allAccouts";
import Navbar2 from "./navbar2";
import Loading from "./loading";

const Account2 = (props) => {
  const email = auth.getUser().email;
  // const [email, setEmail]=useState(auth.getUser().email);
  // const email='ps432241@gmail.com';
  const [allCompanies, setAllCompanies]=useState([{company:"1", gst:{}, pan:{}, mca:{}, bank:{}},]);
  const [companyName, setCompanyName]=useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [id, setId]=useState('');

  const handleAddMore=()=>{
    let n=allCompanies.length;
    let id=allCompanies[n-1];
    let company="1";
    if(n>0){
      company=+id.company+1+"";
    }
    let newCompany={company:company, gst:{}, pan:{}, mca:{}, bank:{}};
    setAllCompanies([...allCompanies, newCompany]);
  }

  const onRemove = (str) => {
    console.log(str, 'str');
    const index = allCompanies.findIndex(n => n.company === str);
  
    if (index !== -1 && allCompanies.length>1) {
      // Create a new array by excluding the element at the found index
      const newArr = [...allCompanies.slice(0, index), ...allCompanies.slice(index + 1)];
      
      // Update state with the new array
      setAllCompanies(newArr);
  
      // Log the removed company
      console.log(allCompanies[index], "company");
    } else {
      console.log("Company not found");
    }
  }
  
  const fetchData = async () => {
    try {
      const response = await http.post2('/getCompanyName', {
        email,
      });
      if (response.status != 400) {
        // console.log(response.data, 'response.data gstComponent');
        setCompanyName(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  
  const fetchData2 = async () => {
    const { id } = props.match.params;
    try {
      const response = await http.post2('/getAllCompanies', {
        email,
        id
      });
      if (response.status != 400) {
        setAllCompanies(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(()=>{
    fetchData2();
  }, [allCompanies[0].company]);

  useEffect(() => {
    const { id } = props.match.params;
    console.log(id, 'id account')
    setId(id);
  }, [id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    isLoading? <Loading></Loading>:
    <div className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3">
            <div className="left-wrapper">
              {<Navbar2/>}
              <h2 className="admin-name">{companyName?companyName:email}</h2>
              <Link to='/dashboard'>
                <button type="button" className="btn btn-light btn-sm back-btn mb-3">
                  <i className="bi bi-arrow-left"></i> Back
                </button>
              </Link>
              
              {/* <h3 className="small-des">You’ve an upcoming hearing in 3 hrs</h3> */}
              {/* <button
                className="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none mb-3"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Notifications
              </button> */}
              <div className="" id="collapseExample">
                <div className="dot-bg">
                  <h2 className="d-lg-block d-none">Notifications</h2>
                  <div className="alert alert-primary bg-transparent" role="alert">
                    No new notification
                  </div>
                  {/* <div className="alert alert-primary bg-transparent" role="alert">
                    New Member <strong>‘Akasha Seth’</strong> added to the team.
                  </div>
                  <div className="alert alert-primary bg-transparent" role="alert">
                    You have won the dispute against Deepak Jain. See the{" "}
                    <a href="#" className="alert-link">
                      Final Terms.
                    </a>
                  </div>
                  <div className="alert alert-primary bg-transparent" role="alert">
                    2 upcoming Hearings tomorrow.{" "}
                    <a href="#" className="alert-link">
                      See full Schedule
                    </a>
                  </div>
                  <div className="alert alert-primary bg-transparent" role="alert">
                    <div className="d-flex justify-content-between">
                      <div>12 new in last 2 days</div>
                      <div>
                        <a href="#" className="alert-link">
                          view all
                        </a>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="col-lg-12 text-end mb-3">
              {!id?
                              <button
                type="button"
                className="btn btn-outline-primary btn-sm"
                onClick={handleAddMore}
              >
                <i className="bi bi-plus-lg text-primary"></i>{" "}
                Add More Company
              </button>:''
              }
            </div>
            <div className="right-wrapper">
              {allCompanies.map((company, index)=>{
                return <AllAccount company={company} onRemove={onRemove} index={index} id={id}/>
              })}
            </div>
          </div>
          {/*<!-- right wrapper --> */}
        </div>
        {/*<!--  /.col-lg-9 --> */}
      </div>
      {/*<!-- /.row --> */}
    </div>
  );
};
export default Account2;
