import React from 'react'
import { useEffect, useState } from "react";
import http from '../../services/httpService';
import auth from '../../services/authService';
import { Link } from "react-router-dom";

function PanComponent(props) {
  const email = auth.getUser().email;
  const [panno, setPanNo] = useState("");
  const [pan, setPan] = useState({});
  const [loading, setLoading]=useState(false);
  const [verify, setVerify]=useState(false);
  const [wrongNo, setWrongNo]=useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if(panno.length===10){
    try {
      const res = await http.post2(
        "/getPanVerify",
        {
          panno,
          email,
          company:props.company.company
        }
      );
      console.log(res.data.status);
      if(res.data.status=='completed'){
        setVerify(true);
        setPan(res.data);
        setLoading(false);
      }
      else if(res.status==400){
        setWrongNo(true);
        setLoading(false);
      }
    } catch (err) {
      setWrongNo(true);
      setLoading(false);
      console.log(err);
    }
  }
  else{
    alert("Please Insert Correct Pan Number");
  }
  };

  const handleChange = (e) => {
    const input = e.target.value;
    const lastChar = input.slice(-1); 
    setWrongNo(false);
    const alphanumericInput = input.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
    setPanNo(alphanumericInput);
  };

  const fetchData = async () => {
    try {
      const response = await http.post2('/companiesPanDetails', {
        email,
        id:props.id,
        company:props.company.company
      });
      // console.log(response, 'response1')
      if (response.status !=400) {
        // console.log(response.data, 'response.data gstComponent');
        setPan(response.data);
        // props.onReload(false);
        // console.log(response.data);
        // setV(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const fetchData2 = async () => {

    try {
      const response = await http.post2('/companiesPanDetails', {
        email,
        id:props.id,
        company: props.company.company
      });
      if (response.data.status === 'completed') {
        setPan(response.data);
        props.onReload(false);
      } else {
        setTimeout(fetchData2, 1000); // Retry after 1 second
      }
    } catch (error) {
      props.onReload(false);
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    // console.log(props, "pan");
    // console.log(props.reload, 'reload pan')
    console.log(props.id, 'props.id pan')
    fetchData();
    fetchData2();
  }, [props.reload]);

  return (
    <div
    className="tab-pane fade"
    id={"nav-profile"+props.company.company}
    role="tabpanel"
    aria-labelledby={"nav-profile-tab"+props.company.company}
    tabIndex="1"
  >
    {
    !props.id?
    <form onSubmit={handleSubmit}>
        <div className="row align-items-center">
          <div className="col-md-5 mb-3">
            <label className="form-label form-label-new">
              PAN Number
            </label>
            <input
              type="text"
              className="form-control form-control-new"
              placeholder=""
              value={(pan.result?.source_output?.pan_number || panno)}
              onChange={handleChange} 
              disabled={pan.status=='completed'}
            />
          </div>
          <div className="col-md-2 mb-3">
            <button
              type="submit"
              className="btn verify btn-primary"
              disabled={(pan.status=='completed' || panno.length!=10 || loading || props.reload)}
            >
              {(pan.status=='completed') ? "Verified" : "Verify"}
            </button>
          </div>
        </div>
    </form>:''
    }

    {pan.status=="completed" && verify && !props.id?
    <div
        className="alert alert-success alert-dismissible fade show"
        role="alert"
      >
        Verification successfully Completed!
        <button
          type="button"
          className="btn-close top-0 right-0"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
    :''}

    {pan.status!=="completed" && !wrongNo && !props.id ?
      <div
        className="alert alert-danger alert-dismissible fade show"
        role="alert"
      >
       Please Verify Your Pan!
        <button
          type="button"
          className="btn-close top-0 right-0"
          data-bs-dismiss="alert" // Close the alert
          aria-label="Close"
        ></button>
      </div>
    :""}
    {
      wrongNo && !props.id ?
      <div
        className="alert alert-danger alert-dismissible fade show"
        role="alert"
      >
       Verification unsuccessfull please check PAN number!
        <button
          type="button"
          className="btn-close top-0 right-0"
          data-bs-dismiss="alert" // Close the alert
          aria-label="Close"
        ></button>
      </div>
      :""
    }
  
    
    {loading || props.reload && <>Loading.....</>}

    { pan.status=='completed' ? 
      <table className="table table-bordered">
        <tbody className="bg-white">
          <tr>
            <th scope="row">Full Name:</th>
            <td>
              {pan.status=='completed' ? (pan?.result.source_output?.full_name || 'N/A') : "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Gender:</th>
            <td>
              {pan.status=='completed' ? (pan?.result.source_output?.gender ||"N/A"): "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Name Split:</th>
            <td>
              {pan.status=='completed' ? (pan.result.source_output?.full_name_split || "N/A") :"N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Masked Aadhaar:</th>
            <td>
              {pan.status=='completed' ? (pan?.result.source_output?.masked_aadhaar || "N/A") :"N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Phone Number:</th>
            <td>
              {pan.status=='completed' ? (pan?.result.source_output?.phone_number || "N/A") : "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Full Address:</th>
            <td>
              {pan.status=='completed' ? (pan?.result.source_output.address?.full || "N/A") : "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Country:</th>
            <td>
              {pan.status=='completed' ? (pan?.result.source_output.address?.country || "N/A") : "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">State:</th>
            <td>
              {pan.status=='completed' ? (pan?.result.source_output.address?.state || "N/A") : "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Pin Code:</th>
            <td>
              {pan.status=='completed' ? (pan?.result.source_output.address?.zip ||"N/A") : "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Zip:</th>
            <td>
              {pan.status=='completed' ? (pan?.result.source_output.address?.zip || "N/A") : "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Father Name:</th>
            <td>
              {pan.status=='completed' ? (pan?.result.source_output?.father_name || "N/A") : "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Link Status:</th>
            <td>
              {pan.status=='completed' ? (pan?.result.source_output?.status || "N/A") : "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Category:</th>
            <td>
              {pan.status=='completed' ? (pan?.result.source_output?.category ||
                "N/A") : "N/A"}
            </td>
          </tr>
        </tbody>
      </table>
     :""}
  </div>
  )
}

export default PanComponent