import React from 'react'
import { useEffect, useState } from "react";
import http from '../../services/httpService';
import auth from '../../services/authService';
import { Link } from "react-router-dom";

function BankVerifyComponent(props) {
    const email = auth.getUser().email;

    const [accno,setAccNo]=useState("");
    const [bank,setBank]= useState({});
    const [ifsc,setIFSC]=useState("");
    const [verify, setVerify]=useState(false);
    const [wrongNo,setWrongNo]=useState(false);
    const [loading, setLoading]=useState(false);


    const handlerforBankValidate= async(e)=>{
        e.preventDefault();
        setLoading(true)
        try {
          const res = await http.post2(
            "/getBankVerify",
            {
              ifsc,
              accno,
              email,
              company:props.company.company,
            }
          );
          if(res.data.status=='completed'){
            setVerify(true);
            setBank(res.data);
            setLoading(false)
          }
          else if(res.status==400){
            setWrongNo(true);
            setLoading(false);
          }
          console.log(res);
        } catch (err) {
          setWrongNo(true);
          setLoading(false)
          console.log(err);
        }
      }

      const handleChange = (e) => {
        const input = e.target.value;
        const lastChar = input.slice(-1); 
        setWrongNo(false);
        const alphanumericInput = input.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
        setIFSC(alphanumericInput);
    };

    const handleChange2 = (e) => {
      const input = e.target.value;
      setWrongNo(false);
      setAccNo(input);
    };

    const fetchData = async () => {
      try {
        const response = await http.post2('/companiesBankDetails', {
          email,
          id:props.id,
          company:props.company.company
        });
        console.log(response, 'response1')
        if (response.status != 400) {
          // console.log(response.data, "bank")
          setBank(response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    useEffect(() => {
        // console.log(props, 'bank');
        fetchData();
    }, [ bank?.status]);

  return (
    <div
    className="tab-pane fade"
    id={"nav-disabled"+props.company.company}
    role="tabpanel"
    aria-labelledby={"nav-disabled-tab"+props.company.company}
    tabIndex="3"
  >
    {!props.id?
    <form onSubmit={handlerforBankValidate}>
    <div className="row align-items-center">
      <div className="col-md-5 mb-3">
        <label className="form-label form-label-new">
          Bank A/C No
        </label>
        <input
          type="number"
          className="form-control form-control-new"
          placeholder=""
          value={(bank.result?.bank_account_number || accno)}
          disabled={bank.status=='completed'}
          onChange={handleChange2}
        />
      </div>
      <div className="col-md-5 mb-3">
        <label className="form-label form-label-new">
          IFSC Code
        </label>
        <input
          type="text"
          className="form-control form-control-new"
          placeholder=""
          value={(bank.result?.ifsc_code || ifsc)}
          onChange={handleChange}
          disabled={bank.status=='completed'}
        />
      </div>
      <div className="col-md-2 mb-3">
      <button
              type="submit"
              className="btn verify btn-primary"
              disabled={(bank.status=='completed'|| loading)}
            >
              {(bank.status=='completed') ? "Verified" : "Verify"}
      </button>
      </div>
    </div>
    </form>:''}
    {verify && !props.id? <div
      className="alert alert-success alert-dismissible fade show"
      role="alert"
    >
      Verification has Completed!
      <button
        type="button"
        className="btn-close top-0 right-0"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>:''}
    {wrongNo  && !props.id ?<div
      className="alert alert-danger alert-dismissible fade show"
      role="alert"
    >
    Verification unsuccessfull please check Account number or IFSC code!
      <button
        type="button"
        className="btn-close top-0 right-0"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>:''}
    {bank.status!='completed'  && !props.id ?<div
      className="alert alert-danger alert-dismissible fade show"
      role="alert"
    >
    Please Verify Your Bank Details!

      <button
        type="button"
        className="btn-close top-0 right-0"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>:''}

    {loading && <>Loading...</>}
    
    {bank.status=="completed"?
    <table className="table table-bordered">
      <tbody className="bg-white">
        <tr>
          <th scope="row">A/C exists:</th>
          <td> {bank.status=='completed'? (bank?.result.account_exists || "N/A"):"N/A"}</td>
        </tr>
        <tr>
          <th scope="row">Name:</th>
          <td>{bank.status=='completed'? (bank?.result.name_at_bank || "N/A"):"N/A"}</td>
        </tr>
        <tr>
          <th scope="row">Bank A/C No:</th>
          <td>{bank.status=='completed'? (bank?.result.bank_account_number || "N/A"):"N/A"}</td>
        </tr>
        <tr>
          <th scope="row">Amount Deposited:</th>
          <td>{bank.status=='completed'? (bank?.result.amount_deposited || "N/A"):"N/A"}</td>
        </tr>
        <tr>
          <th scope="row">IFSC Code:</th>
          <td>{bank.status=='completed'? (bank?.result.ifsc_code || "N/A"):"N/A"}</td>
        </tr>
        <tr>
          <th scope="row">Status:</th>
          <td>{bank.status=='completed'? (bank?.result.status || "N/A") : "N/A"}</td>
        </tr>
        <tr>
          <th scope="row">Message:</th>
          <td>{bank.status=='completed'? (bank?.result.message || "N/A") : "N/A"}</td>
        </tr>
      </tbody>
    </table>
    :""}
  </div>
  )
}

export default BankVerifyComponent