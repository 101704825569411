import searchImg from '../asset/search.svg';
import { CiMenuKebab } from 'react-icons/ci';
import batch1 from '../asset/icons/Group 57.svg'
import docs from '../asset/icons/Group 11.svg'
import docs1 from '../asset/icons/doc.svg'
import status from '../asset/icons/Group 56.svg'
import batch from '../asset/icons/Group 53.svg'
import dispute from '../asset/icons/Group 54.svg'
import profile from '../asset/icons/profile.svg'
import Navbar2 from './navbar2';
import { Link } from 'react-router-dom';
import auth from '../services/authService';
import { useState, useEffect } from 'react';
import http from '../services/httpService';

const HomeDashBoard = () => {
  const email=auth.getUser().email;
  const [companyName, setCompanyName]=useState('');

  const fetchData3 = async () => {
    try {
      const response = await http.post2('/getCompanyName', {
        email,
      });
      if (response.status != 400) {
        console.log(response.data, 'response.data gstComponent');
        setCompanyName(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(()=>{
    fetchData3();
  },[])

  return (
    <div className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3">
            <div className="left-wrapper">
              {Navbar2}
              <h2 className="admin-name">{companyName?companyName:email}</h2>
              <Link to='/dashboard'>
                <button type="button" className="btn btn-light btn-sm back-btn mb-3">
                  <i className="bi bi-arrow-left"></i> Back
                </button>
              </Link>

              <h3 className="small-des">You’re in Dispute Section. Here you can file disputes on a single agreement or in batches.</h3>
              {/* <button
                className="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none mb-3"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Notifications
              </button> */}
              <div className="" id="collapseExample">
                <div className="dot-bg">
                  <h2 className="d-lg-block d-none">Perform Tasks</h2>
                  <div className="row row-cols-2">
                    <div className="col">
                      <Link to='' className="white-circle">
                        <img src={batch} alt="batch" style={{ display: "block", margin: "0 auto" }}/>
                        <h2>Batch</h2>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to='/disputes' className="white-circle">
                        <img src={dispute} alt="dispute" style={{ display: "block", margin: "0 auto" }}/>
                        <h2>Dispute</h2>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to='' className="white-circle">
                        <img src={batch1} alt="batchUpdate" style={{ display: "block", margin: "0 auto" }}/>
                        <h2>Batch</h2>
                      </Link>
                    </div>
                    <div className="col">
                      <Link to='' className="white-circle">
                        <img src={status} alt="status" style={{ display: "block", margin: "0 auto" }}/>
                        <h2>Status</h2>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="right-wrapper">
              <div className="row">
                <div className="col-xl-4 col-lg-12">
                  <div className="card card-new update-status-card">
                    <h2>Previous Batches</h2>
                    <div className="update-status-list d-flex align-items-center mb-3">
                      <div className="c-pic me-md-3 me-1">
                        <img
                          className="shadow"
                          src={docs}
                          style={{ height: "50px" }}
                          alt=""
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <h2 style={{ fontSize: "16px" }}>TC_DS_345.xls</h2>
                        <div className="d-flex justify-content-between">
                          <div className="resolved">
                            <p className="mb-0" >UPLOADED</p>
                            <p style={{fontSize:"12px"}}>24.06.23</p>
                          </div>
                          <div className="resolved ms-auto">
                            <p className="mb-0">ENTRIES</p>
                            <p style={{fontSize:"12px"}}>200/396</p>
                          </div>
                          <div className="resolved ms-auto">
                            <p className="mb-0">STATUS</p>
                            <p style={{fontSize:"12px"}}>PROCESSING</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="update-status-list d-flex align-items-center mb-3">
                      <div className="c-pic me-md-3 me-1">
                        <img
                          className="shadow"
                          src={docs}
                          style={{ height: "50px" }}
                          alt=""
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <h2 style={{ fontSize: "16px" }}>TC_DS_345.xls</h2>
                        <div className="d-flex justify-content-between">
                          <div className="resolved">
                            <p className="mb-0">UPLOADED</p>
                            <p style={{fontSize:"12px"}}>24.06.23</p>
                          </div>
                          <div className="resolved ms-auto">
                            <p className="mb-0">ENTRIES</p>
                            <p style={{fontSize:"12px"}}>200/396</p>
                          </div>
                          <div className="resolved ms-auto">
                            <p className="mb-0">STATUS</p>
                            <p style={{fontSize:"12px"}}>PROCESSING</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="update-status-list d-flex align-items-center mb-3">
                      <div className="c-pic me-md-3 me-1">
                        <img
                          className="shadow"
                          src={docs}
                          style={{ height: "50px" }}
                          alt=""
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <h2 style={{ fontSize: "16px" }}>TC_DS_345.xls</h2>
                        <div className="d-flex justify-content-between">
                          <div className="resolved">
                            <p className="mb-0">UPLOADED</p>
                            <p style={{fontSize:"12px"}}>24.06.23</p>
                          </div>
                          <div className="resolved ms-auto">
                            <p className="mb-0">ENTRIES</p>
                            <p style={{fontSize:"12px"}}>200/396</p>
                          </div>
                          <div className="resolved ms-auto">
                            <p className="mb-0">STATUS</p>
                            <p style={{fontSize:"12px"}}>PROCESSING</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="update-status-list d-flex align-items-center mb-3">
                      <div className="c-pic me-md-3 me-1">
                        <img
                          className="shadow"
                          src={docs}
                          style={{ height: "50px" }}
                          alt=""
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <h2 style={{ fontSize: "16px" }}>TC_DS_345.xls</h2>
                        <div className="d-flex justify-content-between">
                          <div className="resolved">
                            <p className="mb-0">UPLOADED</p>
                            <p style={{fontSize:"12px"}}>24.06.23</p>
                          </div>
                          <div className="resolved ms-auto">
                            <p className="mb-0">ENTRIES</p>
                            <p style={{fontSize:"12px"}}>200/396</p>
                          </div>
                          <div className="resolved ms-auto">
                            <p className="mb-0">STATUS</p>
                            <p style={{fontSize:"12px"}}>PROCESSING</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="update-status-list d-flex align-items-center mb-3">
                      <div className="c-pic me-md-3 me-1">
                        <img
                          className="shadow"
                          src={docs}
                          style={{ height: "50px" }}
                          alt=""
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <h2 style={{ fontSize: "16px" }}>TC_DS_345.xls</h2>
                        <div className="d-flex justify-content-between">
                          <div className="resolved">
                            <p className="mb-0">UPLOADED</p>
                            <p style={{fontSize:"12px"}}>24.06.23</p>
                          </div>
                          <div className="resolved ms-auto">
                            <p className="mb-0">ENTRIES</p>
                            <p style={{fontSize:"12px"}}>200/396</p>
                          </div>
                          <div className="resolved ms-auto">
                            <p className="mb-0">STATUS</p>
                            <p style={{fontSize:"12px"}}>PROCESSING</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt-2">
                      <a href="#" className="btn btn-outline-primary">
                        view all <strong>Batches</strong> cases
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-12">
                  <div className="card card-new update-status-card corporate">
                    <h2>Batch Upload</h2>
                    <div className="d-flex justify-content-left pb-4 mx-4">
                      <div className="upload-className mt-4">
                        <input
                          type="file"
                          style={{ display: "none" }}
                          id="file"
                        />
                        <label
                          className="label-upload mt-2"
                          for="file"
                          style={{ color: "white" }}
                        >
                          +
                        </label>
                      </div>
                      <div className="mt-5" style={{ marginLeft: "40px" }}>
                        <h6 style={{ fontWeight: "600" }}>Upload</h6>
                        <h6>.xls /.csv file</h6>
                      </div>
                    </div>
                  </div>
                  <div className="card card-new update-status-card w-100" >
                    <h2>File a Dispute</h2>
                    <form action="#" className="form-search mb-3 d-flex">
                        <input
                          type="text"
                          className="form-control form-control-new border-right-0"
                          placeholder="Search Case"
                        />
                        <img src={searchImg} className='ms-4'/>
                    </form>
                    <div className="update-status-list d-flex align-items-center mb-3">
                      <div className="c-pic me-md-3 me-1">
                        <img className="shadow" src={docs1} alt="" />
                      </div>
                      <div className="c-content">
                        <h2>Samar Chand Das</h2>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button type="button" className="btn">
                            Case ID
                          </button>
                          <button type="button" className="btn">
                            TC-46377-36366
                          </button>
                        </div>
                      </div>
                      <div className="resolved ms-auto">
                        <p className="mb-0">LAST EMI</p>
                        <p>3 months ago</p>
                      </div>
                    </div>

                    <div className="update-status-list d-flex align-items-center mb-3">
                      <div className="c-pic me-md-3 me-1">
                        <img className="shadow" src={docs1} alt="" />
                      </div>
                      <div className="c-content">
                        <h2>Samar Chand Das</h2>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button type="button" className="btn">
                            Case ID
                          </button>
                          <button type="button" className="btn">
                            TC-46377-36366
                          </button>
                        </div>
                      </div>
                      <div className="resolved ms-auto">
                        <p className="mb-0">LAST EMI</p>
                        <p>3 months ago</p>
                      </div>
                    </div>

                    <div className="update-status-list d-flex align-items-center mb-3">
                      <div className="c-pic me-md-3 me-1">
                        <img className="shadow" src={docs1} alt="" />
                      </div>
                      <div className="c-content">
                        <h2>Samar Chand Das</h2>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button type="button" className="btn">
                            Case ID
                          </button>
                          <button type="button" className="btn">
                            TC-46377-36366
                          </button>
                        </div>
                      </div>
                      <div className="resolved ms-auto">
                        <p className="mb-0">LAST EMI</p>
                        <p>3 months ago</p>
                      </div>
                    </div>

                    <div className="update-status-list d-flex align-items-center mb-3">
                      <div className="c-pic me-md-3 me-1">
                        <img className="shadow" src={docs1} alt="" />
                      </div>
                      <div className="c-content">
                        <h2>Swapna Pandey</h2>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button type="button" className="btn">
                            Case ID
                          </button>
                          <button type="button" className="btn">
                            TC-46377-36366
                          </button>
                        </div>
                      </div>
                      <div className="resolved ms-auto">
                        <p className="mb-0">LAST EMI</p>
                        <p>3 months ago</p>
                      </div>
                    </div>
                    <div className="text-center mt-2">
                      <a href="#" className="btn btn-outline-primary">
                        view all <strong>Dispute</strong> cases
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-12">
                <div className="card card-new update-status-card w-100" >
                    <h2>Update status</h2>
                    <form action="#" className="form-search mb-3 d-flex">
                        <input
                          type="text"
                          className="form-control form-control-new"
                          placeholder="Search Case"
                        />
                        <img className='ms-4' src={searchImg}/>
                    </form>
                    <div className="update-status-list d-flex align-items-center mb-3">
                      <div className="c-pic me-md-3 me-1">
                        <img className="shadow" src={docs1} alt="" />
                      </div>
                      <div className="c-content">
                        <h2>Samar Chand Das</h2>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button type="button" className="btn">
                            Case ID
                          </button>
                          <button type="button" className="btn">
                            TC-46377-36366
                          </button>
                        </div>
                      </div>
                      <div className="resolved ms-auto">
                        <p className="mb-0">Resolved</p>
                        <p>3 months ago</p>
                      </div>
                      <div>
                      <button style={{background:"white",width:"30px",border:"none"}}>
                            <CiMenuKebab />
                        </button>
                     </div>
                    </div>

                    <div className="update-status-list d-flex align-items-center mb-3">
                      <div className="c-pic me-md-3 me-1">
                        <img className="shadow" src={docs1} alt="" />
                      </div>
                      <div className="c-content">
                        <h2>Samar Chand Das</h2>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button type="button" className="btn">
                            Case ID
                          </button>
                          <button type="button" className="btn">
                            TC-46377-36366
                          </button>
                        </div>
                      </div>
                      <div className="resolved ms-auto">
                      <p className="mb-0">Resolved</p>
                        <p>3 months ago</p>
                      </div>
                      <div>
                      <button style={{background:"white",width:"30px",border:"none"}}>
                      <CiMenuKebab />
                        </button>
                     </div>
                    </div>

                    <div className="update-status-list d-flex align-items-center mb-3">
                      <div className="c-pic me-md-3 me-1">
                        <img className="shadow" src={docs1} alt="" />
                      </div>
                      <div className="c-content">
                        <h2>Samar Chand Das</h2>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button type="button" className="btn">
                            Case ID
                          </button>
                          <button type="button" className="btn">
                            TC-46377-36366
                          </button>
                        </div>
                      </div>
                      <div className="resolved ms-auto">
                      <p className="mb-0">Resolved</p>
                        <p>3 months ago</p>
                      </div>
                      <div>
                      <button style={{background:"white",width:"30px",border:"none"}}>
                      <CiMenuKebab />
                        </button>
                     </div>
                    </div>

                    <div className="update-status-list d-flex align-items-center mb-3">
                      <div className="c-pic me-md-3 me-1">
                        <img className="shadow" src={docs1} alt="" />
                      </div>
                      <div className="c-content">
                        <h2>Swapna Pandey</h2>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button type="button" className="btn">
                            Case ID
                          </button>
                          <button type="button" className="btn">
                            TC-46377-36366
                          </button>
                        </div>
                      </div>
                      <div className="resolved ms-auto">
                      <p className="mb-0">Resolved</p>
                        <p>3 months ago</p>
                      </div>
                     <div>
                     <button style={{background:"white",width:"30px",border:"none"}}>
                     <CiMenuKebab />
                        </button>
                     </div>

                    </div>
                    <div className="text-center mt-2">
                      <a href="#" className="btn btn-outline-primary">
                        view all <strong>resolved</strong> cases
                      </a>
                    </div>
                  </div>
                  <div className="card card-new update-status-card corporate">
                    <h2>Plan Hearings</h2>
                    <img className="m-auto" src="images/api-connected.svg" alt="" />
                    {/* <div className="d-flex justify-content-evenly mt-3 pb-2">
                                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomeDashBoard;
