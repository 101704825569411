import Navbar2 from "./navbar2";
import { Link } from "react-router-dom";
import auth from "../services/authService";
import http from "../services/httpService";
import { useState, useEffect } from "react";
import Loading from "./loading";

const AdminUsersPage = (props) => {
  const email = auth.getUser().email;
  const [noticeInfos, setNoticeInfo] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const fetchData3 = async () => {
    try {
      const response = await http.post2("/getUsersInfo", {
        email,
        page,
        perPage,
      });
      if (response.status != 400) {
        setNoticeInfo(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData2 = async () => {
    try {
      const response = await http.post2("/getCompanyName", {
        email,
      });
      if (response.status != 400) {
        setCompanyName(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    fetchData2();
  }, []);

  useEffect(() => {
    fetchData3();
  }, [page, perPage]);

  return isLoading ? (
    <Loading></Loading>
  ) : (
    <div className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3">
            <div className="left-wrapper">
              <Navbar2 />
              <h2 className="admin-name">
                {companyName ? companyName : email}
              </h2>
              <Link to="/dashboard">
                <button
                  type="button"
                  className="btn btn-light btn-sm back-btn mb-3"
                >
                  <i className="bi bi-arrow-left"></i> Back
                </button>
              </Link>
              {/* <h3 className="small-des">
                   You’ve an upcoming hearing in 3 hrs
                </h3>
                <button className="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    Notifications
                </button> */}
              <div className="" id="collapseExample">
                <div className="dot-bg">
                  <h2 className="d-lg-block d-none">Notifications</h2>
                  <div
                    className="alert alert-primary bg-transparent"
                    role="alert"
                  >
                    No notification
                  </div>
                  {/* <div className="alert alert-primary bg-transparent" role="alert">
                        New Member <strong>‘Akasha Seth’</strong> added to the team. 
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        You have won the dispute against Deepak Jain. See the <a href="#" className="alert-link">Final Terms.</a>
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        2 upcoming Hearings tomorrow. <a href="#" className="alert-link">See full Schedule</a>
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        <div className="d-flex justify-content-between">
                            <div>
                                12 new in last 2 days
                            </div>
                            <div>
                                <a href="#" className="alert-link">view all</a>
                            </div>
                        </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="right-wrapper">
              <div className="conciliation-application-form">
                <form id="regForm" action="#">
                  <div className="col-lg-12">
                    <div className="card card-new h-100">
                      <h2 className="mb-3">Users Info</h2>
                      <div className="org-data nbfc-table mt-3">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                                {/* <th scope="col">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="flexCheckChecked"
                                      checked={
                                        noticeInfos.length == selNotice.length
                                      }
                                      onChange={handlechange5}
                                    />
                                  </div>
                                </th> */}
                              {/* <th scope="col">Action Date</th> */}
                              <th scope='col'>Email</th>
                              <th scope='col'>Total Agreement</th>
                              <th scope='col'>Company Name</th>
                              <th scope="col">View Company Details</th>
                              <th scope='col'>View Agreement Details</th>
                              {/* <th scope="col">Communication Type</th> */}
                              {/* <th scope="col">Letterhead</th>
                              <th scope="col">  Approve</th>
                              <th scope="col">Status</th>
                             
                              <th scope="col">Send email</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {noticeInfos.length>0 ? (
                              <>
                                {noticeInfos.map((item, index) => {
                                  return (
                                    <tr>
                                      {/* <td>
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              checked={selNotice.includes(
                                                item._id + ""
                                              )}
                                              onChange={()=>handleChange(item._id)}
                                              id={"checkbox_" + item._id}
                                              value={item._id + ""}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={"checkbox_" + item._id}
                                            >
                                            </label>
                                          </div>
                                        </td> */}
                                      <td data-label='Email'>
                                        {item.email}
                                      </td>
                                      <td data-label='Total Agreement'>
                                        {item.totalAgreements}
                                      </td>
                                      <td data-label='Company Name'>
                                        {item.companyName}
                                      </td>
                                      {/* <td data-label="View details">
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          data-bs-toggle="modal"
                                          data-bs-target="#updates-1"
                                        >
                                          <i className="bi bi-eye text-white"></i>
                                        </button>
                                      </td> */}
                                      <td data-label="View Comapany Details">
                                        <button type="button" class="btn btn-primary"
                                        onClick={()=>{(props.history.push(`/usersCompanyDetail/${item.id}`))}}
                                        >
                                        <i class="bi bi-eye text-white"></i>
                                        </button>
                                      </td>
                                      <td data-label="View Agreement Details">
                                        <button type="button" class="btn btn-primary"
                                        onClick={()=>{(props.history.push(`/usersAgrrementDetail/${item.id}`))}}
                                        >
                                        <i class="bi bi-eye text-white"></i>
                                        </button>
                                      </td>
                                      {/* <td data-label="Send email">
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          disabled={item.approval=='No'}
                                          onClick={()=>handleEmailer(item.email, item.timeStamp, item.letterhead)}
                                        >
                                          <i className="bi bi-envelope-fill text-white"></i>
                                        </button>
                                      </td> */}
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                        <div className="row">
                          <div className="col-lg-3">
                            {/* <button className="btn btn-primary" disabled={selNotice.length==0} onClick={handleApprove}>
                                Approve Selected
                            </button> */}
                          </div>
                          {/* <div className="col-lg-3">
                            <button className="btn btn-primary" disabled={selNotice.length==0}>
                                Send Email
                            </button>
                          </div> */}
                          <div className="col-lg-4">
                            <div className="data-pagi float-lg-end mt-lg-0 mt-2">
                              <select
                                className="form-control"
                                id="floatingSelect"
                                value={perPage ? perPage : 10}
                                onChange={(e) => setPerPage(e.target.value)}
                                aria-label="Floating label select example"
                              >
                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                              <div
                                className="btn-toolbar"
                                role="toolbar"
                                aria-label="Toolbar with button groups"
                              >
                                <div
                                  className="btn-group me-1"
                                  role="group"
                                  aria-label="First group"
                                >
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => setPage(+page - 1)}
                                    disabled={page <= 1}
                                  >
                                    <i className="bi bi-chevron-left text-white"></i>
                                  </button>
                                </div>
                                <div
                                  className="btn-group me-1"
                                  role="group"
                                  aria-label="Second group"
                                >
                                  <button
                                    type="button"
                                    className="btn text-primary"
                                  >
                                    {page}
                                  </button>
                                </div>
                                <div
                                  className="btn-group"
                                  role="group"
                                  aria-label="Third group"
                                >
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={noticeInfos.length < +perPage}
                                    onClick={() => setPage(+page + 1)}
                                  >
                                    <i className="bi bi-chevron-right text-white"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>  
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminUsersPage;