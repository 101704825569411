import ChartOverview from "./ChartOverview";

const Dashboard =()=>{
    return(
     <div className="main-wrapper">
         <br></br><br></br><br></br>
         <ChartOverview />
     </div>
    )
}
export default Dashboard;