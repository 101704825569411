import React from "react";
import loadingIcon from "../asset/loading3.gif";

function Loading() {
  return (
    <div className="main-wrapper d-flex justify-content-center align-items-center">
      <div className="container-fluid">
        <div className="row">
      <div className="col-lg d-flex justify-content-center align-items-center"  style={{backgroundColor: 'rgba(255, 255, 255, 0.2)'}}>
        <img src={loadingIcon} alt='loading image' style={{marginTop:"40vh", backgroundColor: 'rgba(255, 255, 255, 0.2)'}} />
      </div>
    </div>
        {/* <div className="page-loader">
          <div className="spinner"></div>
        </div> */}
      </div>
    </div>
  );
}

export default Loading;


// import React from "react";
// import loadingIcon from "../asset/loading3.gif";

// function Loading() {
//   return (
//     <div className="main-wrapper d-flex justify-content-center align-items-center" style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}>
//       <div className="container-fluid">
//         <div className="row">
//           <div className="col-lg d-flex justify-content-center align-items-center">
//             <img src={loadingIcon} alt='loading image' style={{ marginTop: "40vh", backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Loading;
