import { useEffect, useState } from "react";
import auth from "../services/authService";
import http from "../services/httpService";
import Navbar2 from "./navbar2";
import { Link } from "react-router-dom";
import Loading from "./loading.jsx";

const BKTTable = (props) => {
  const email = auth.getUser().email;
  const [level, setLevel] = useState({});
  // const [timeStamp, setTimeStamp] = useState(null);
  const [names, setNames] = useState([""]);
  const [buckets, setBuckets] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const [noticeTimeStamp, setNoticeTimeStamp] = useState(props.match.params.timeStamp);
  const [noticeInfos, setNoticeInfo] = useState([]);


  // console.log(buckets);
  // console.log(names);

  const fetchData2 = async () => {
    try {
      const response = await http.post2("/getCompanyName", {
        email,
      });
      if (response.status != 400) {
        setCompanyName(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const BKT = async () => {
    http
      .post2("/allbktlevercalculate", { email })
      .then((res) => {
        const newNames = res.data.uniqueBktValues;
        const uniqueNames = Array.from(new Set([...names, ...newNames]));
        setNames(uniqueNames);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchData = async () => {
    const { timeStamp } = props.match.params;
    setNoticeTimeStamp(timeStamp);
    const promises = names.map((name) =>
      http.post2("/CalculatesLevels", {
        email: email,
        bkt: name !== "" ? name : "",
        timeStamp: timeStamp,
      })
    );
    try {
      const results = await Promise.all(promises);
      const data = Object.fromEntries(
        results.map((res, index) => [names[index], res.data])
      );
      setBuckets(data);
      setLevel(data["All"]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData3 = async () => {
    try {
      const response = await http.post("/getNoticeInfo", {
        email,
      });
      if (response.status != 400) {
        setNoticeInfo(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    BKT();
    fetchData();
    fetchData3();
    // fetchData2();
  }, [buckets, noticeTimeStamp]);

  useEffect(() => {
    fetchData2();
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [noticeTimeStamp]);

  return isLoading ? (
    <Loading></Loading>
  ) : (
    <div className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3">
            <div className="left-wrapper">
              <Navbar2 />
              <h2 className="admin-name">
                {companyName ? companyName : email}
              </h2>
              <Link to="/reporting">
                  <button
                    type="button"
                    className="btn btn-light btn-sm back-btn mb-3"
                  >
                    <i className="bi bi-arrow-left"></i> Back
                  </button>
                </Link>

              <div className="" id="collapseExample">
                {/* <div className="dot-bg"> */}
                <div className="data-filter">
                    
                    <div className="form-floating mb-3">
                      <select
                        className="form-select"
                        id="floatingSelect"
                        value={+noticeTimeStamp}
                        // onChange={(e) => {
                        //   setNoticeTimeStamp(e.target.value)
                        //   props.history.push(
                        //     `/bkttable/${e.target.value
                        //     }`
                        //   );
                        // }}
                        onChange={(e) => {
                          console.log("onChange triggered");
                          setNoticeTimeStamp(e.target.value);
                          props.history.push(`/bkttable/${e.target.value}`);
                          window.location.reload();
                        }}
                        aria-label="Floating label select example"
                      >
                        <option value={null}>All</option>
                        {noticeInfos.length > 0
                          ? noticeInfos.map((noticeItem, index) => (
                              <option key={index} value={noticeItem.timeStamp}>
                                {noticeItem.sheetName}
                              </option>
                            ))
                          : ""}
                      </select>
                      <label>Select Sheet Name</label>
                    </div>
                    
                  </div>
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="right-wrapper">
              <div className="col-lg-12">
                <div className="card card-new h-100">
                  <h2 className="mb-3">NBFC DISPUTE</h2>
                  <div className="org-data pb-5">
                    <div className="row">
                      <div className="col-lg-11">
                        {Object.values(buckets).map((item, index) => {
                          return (item.total>0?<>
                            <table className="table table-bordered" key={index}>
                              <thead>
                                <tr>
                                  <th>
                                    BKT {names[index] ? names[index] : "All"}
                                  </th>
                                  <td>Total = {item.total}</td>
                                </tr>
                                <tr>
                                  <th scope="col">Communication</th>
                                  <th scope="col">Count</th>
                                  <th scope="col">WhatsApp</th>
                                  <th scope="col">Count</th>
                                  <th scope="col">Email</th>
                                  <th scope="col">Count</th>
                                  <th scope="col">Call</th>
                                  <th scope="col">Count</th>
                                  <th scope="col">Postal</th>
                                  <th scope="col">Count</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th scope="L0">L0</th>
                                  <td>{item.L0}</td>
                                  <td>Read</td>
                                  <td>{item.wRead}</td>
                                  <td>Read</td>
                                  <td>{item.read}</td>
                                  <td>YES</td>
                                  <td>{item.callYes}</td>
                                  <td>YES</td>
                                  <td>{item.postalYes?item.postalYes:"N/A"}</td>
                                </tr>
                                <tr>
                                  <th scope="L1">L1</th>
                                  <td>{item.L1}</td>
                                  <td>Sent</td>
                                  <td>{item.wSent}</td>
                                  <td>Sent</td>
                                  <td>{item.deliver}</td>
                                  <td>NO</td>
                                  <td>{item.callNo}</td>
                                  <td>NO</td>
                                  <td>{item.postalNo?item.postalNo:"N/A"}</td>
                                </tr>
                                <tr>
                                  <th scope="L2">L2</th>
                                  <td>{item.L2}</td>
                                  <td>Enqueued</td>
                                  <td>{item.wEnqueue}</td>
                                  <td>Bounced</td>
                                  <td>{item.bounce}</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  {/*<td>NO</td>
                                 <td>count of NO</td>
                                 <td>NO</td>
                                 <td>count of NO</td> */}
                                </tr>
                                <tr>
                                  <th scope="L3">L3</th>
                                  <td>{item.L3}</td>
                                  <td>Delivered</td>
                                  <td>{item.wDelivered}</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <th scope="L4">L4</th>
                                  <td>{item.L4}</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                            </>:<></>
                          );
                        })}
                        <div className="mt-4">
                          <h3>Note:</h3>
                          <hr />
                          <p>
                            <strong>L0 - No Communication</strong>
                          </p>
                          <p>
                            <strong>L1 - Communicated by Single Method</strong>
                          </p>
                          <p>
                            <strong>L2 - Communicated by Two Method</strong>
                          </p>
                          <p>
                            <strong>L3 - Communicated by Three Method</strong>
                          </p>
                          <p>
                            <strong>L4 - All Four Way Communication</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BKTTable;
