import React, { useState } from 'react';
import http from '../services/httpService.js';
import Papa from 'papaparse';
import { Button, Container, Grid, Typography } from '@mui/material';

const CsvUploader = (props) => {
  let {type}=props;
  const [file, setFile] = useState(null);
  const [data, setData]=useState([]);

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
  };

  const handleSubmit = async () => {
    if (!file) {
      alert('Please select a CSV file.');
      return;
    }

    const formData = new FormData();
    formData.append('csvFile', file);

    try {
      console.log(data, 'data')
      let data1=data.filter(item=>item.phone);
      let data2=data1.map(item=>{
        item.setDtmf='dtmf1';
        item.prevInfo={}
        if(item.phone.length>=12){
          return item;
        }
        else {
          item.phone='91'+item.phone;
          return item;
        }
      })
      if(type=='usersData'){
        const response = await http.post('/csvUpload',{data:data2});
        console.log(response.data, "usersUploaded");
      } 
      else if(type=='salesData'){
        const response = await http.post('/csvUpload2',{data:data2});
        console.log(response.data, "salesUploaded");
      }
      alert('CSV file uploaded successfully!');
      window.location.reload();
    } catch (error) {
      console.error('Error uploading CSV file:', error);
      alert('Failed to upload CSV file.');
    }
  };

  const handleFileRead = (event) => {
    const csvData = event.target.result;
    const parsedData = Papa.parse(csvData, { header: true });
    console.log(parsedData.data, 'parseData');
    setData(parsedData.data);
  };

  const handleFileUpload = () => {
    if (!file) {
      alert('Please select a CSV file.');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = handleFileRead;
    reader.readAsText(file);
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        CSV Uploader
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="csv-file-input"
          />
          <label htmlFor="csv-file-input">
            <Button component="span" variant="contained" color="primary">
              Select CSV File
            </Button>
          </label>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={!file}
            onClick={handleFileUpload}
            fullWidth
          >
            Upload
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={!file}
            onClick={handleSubmit}
            fullWidth
          >
            Submit to MongoDB
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CsvUploader;
