import { Link, withRouter } from "react-router-dom";
import auth from "../services/authService";
import http from "../services/httpService.js";
import Navbar2 from "./navbar2";
import CsvUploader2 from "./csvUplode2";
import { useEffect, useState } from "react";
import Loading from "./loading";
import PDFfile from "../asset/DRPAgreement.pdf";
import disputeIcon from "../asset/icons/disputes.svg";
import agreementsIcon from "../asset/icons/agreements.svg";
import accountIcon from "../asset/icons/account.svg";

import {
  buildTextForPdf,
  buildTextForPdf3,
  getFormattedDate,
  getTimestamp,
  calculateDateAfter15Days,
  generateRandomInvoiceID,
} from "../logic/DownloadAll.js";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import axios from "axios";
import fontkit from "@pdf-lib/fontkit";
import Thane from "../files/LETTERHEAD ALL ADDRESS-Thane Concilation.pdf";
import fontPathRegular from "../files/Poppins-Regular.ttf";
import fontPathMedium from "../files/Poppins-Medium.ttf";

const NoticeTable = (props) => {
  const email = auth.getUser().email;
  const [page, setPage] = useState(1);
  const [noticeInfo, setNoticeInfo] = useState({});
  const [search, setSearch] = useState("");
  const [loanType, setLoanType] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [usersData, setUsersData] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [closeLoanID, setCloseLoanID] = useState("");
  const [caseStatus, setCaseStatus] = useState("");
  const [removeReason, setRemoveReason] = useState("");
  const [settleAmt, setSettleAmt] = useState("");

  const fetchData2 = async () => {
    try {
      const response = await http.post2("/getCompanyName", {
        email,
      });
      if (response.status != 400) {
        // console.log(response.data, 'response.data gstComponent');
        setCompanyName(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    const { timeStamp } = props.match.params;
    try {
      let response = await http.post("/getNoticeInfoDatas", {
        email,
        page,
        perPage,
        search,
        loanType,
        timeStamp,
      });
      console.log(response.data);
      setUsersData(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchData3 = async () => {
    let { timeStamp } = props.match.params;
    timeStamp = +timeStamp;
    try {
      let response = await http.post2("/getNoticeInfo", { email, timeStamp });
      console.log(response.data);
      setNoticeInfo(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const downloadPDFLink = (loanID) => {
    const pdfUrl = `https://privatecourtdocs.s3.ap-south-1.amazonaws.com/lrnbp0823/${loanID}+-+LRN.pdf`;
    const newTab = window.open(pdfUrl, "_blank");
    if (newTab) {
      newTab.focus();
    } else {
      console.error("Failed to open PDF in a new tab.");
    }
  };

  const DownloadPdf = async (item) => {
    let letterHead = noticeInfo.letterhead;
    console.log(letterHead);
    // setIsLoading(true);
    let pdfFilePath;
    if (letterHead === "Joylin Rego, Mumbai") {
      //   pdfFilePath = path.join(__dirname, "..", "files", "letterhead.pdf");
      const letterheadResponse = await axios.get("letterhead.pdf");
      pdfFilePath = await letterheadResponse.data;
    } else {
      if (letterHead === "Gurugaon") {
        // pdfFilePath = path.join(
        //   __dirname,
        //   "..",
        //   "files",
        //   "Letterhead Gurgaon Concilation.pdf"
        // );
        const letterheadResponse = await axios.get(
          "LETTERHEAD ALL ADDRESS-Thane Concilation.pdf"
        );
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Bangalore") {
        // pdfFilePath = path.join(
        //   __dirname,
        //   "..",
        //   "files",
        //   "LETTERHEAD ALL ADDRESS- Bangalore Concilation.pdf"
        // );
        const letterheadResponse = await axios.get(
          "LETTERHEAD ALL ADDRESS-Thane Concilation.pdf"
        );
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Ambernath") {
        // pdfFilePath = path.join(
        //   __dirname,
        //   "..",
        //   "files",
        //   "LETTERHEAD ALL ADDRESS-Ambernath Concilation.pdf"
        // );
        const letterheadResponse = await axios.get(
          "LETTERHEAD ALL ADDRESS-Thane Concilation.pdf"
        );
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Hyderabad") {
        // pdfFilePath = path.join(
        //   __dirname,
        //   "..",
        //   "files",
        //   "LETTERHEAD ALL ADDRESS-Hyderabad Concilation.pdf"
        // );
        const letterheadResponse = await axios.get(
          "LETTERHEAD ALL ADDRESS-Thane Concilation.pdf"
        );
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Thane") {
        // pdfFilePath = path.join(__dirname, "..", "files", "LETTERHEAD ALL ADDRESS-Thane Concilation.pdf");
        // const letterheadResponse = await axios.get(Thane);
        // pdfFilePath = await letterheadResponse.data;
        pdfFilePath = await fetch(Thane).then((res) => res.arrayBuffer());
      } else if (letterHead === "Delhi") {
        // pdfFilePath = path.join(
        //   __dirname,
        //   "..",
        //   "files",
        //   "LETTERHEAD ALL ADDRESs Delhi Concilation.pdf"
        // );
        const letterheadResponse = await axios.get(
          "LETTERHEAD ALL ADDRESS-Thane Concilation.pdf"
        );
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "New Delhi") {
        // pdfFilePath = path.join(
        //   __dirname,
        //   "..",
        //   "files",
        //   "LETTERHEAD ALL ADDRESs Delhi New Concilation.pdf"
        // );
        const letterheadResponse = await axios.get(
          "LETTERHEAD ALL ADDRESS-Thane Concilation.pdf"
        );
        pdfFilePath = await letterheadResponse.data;
      }
    }
    const data = [item];

    console.log(data);
    const combinedPdf = await PDFDocument.create();
    combinedPdf.registerFontkit(fontkit);
    let len = data.length;
    // len=len>101?101:len;
    for (let i = 0; i < data.length; i++) {
      console.log(i);
      // console.log(data[i])
      const NewRef = generateRandomInvoiceID();
      const loanID = data[i].loanID;

      if (data[i].refNo === undefined || data[i].refNo === null) {
        const newData = {
          ...data[i],
          refNo: NewRef,
        };
        axios
          .post("https://server1-jlfh.onrender.com/saveDataByLoanID", {
            email,
            loanID: item.loanID,
            data: newData,
          })
          .then((res) => {
            console.log("changes");
          })
          .catch((err) => {
            console.log(err);
          });
      }
      const scheduleDate = calculateDateAfter15Days();
      const pdfDoc = await PDFDocument.load(pdfFilePath);
      pdfDoc.registerFontkit(fontkit);
      const [fontDataRegular, fontDataMedium] = await Promise.all([
        fetch(fontPathRegular).then((response) => response.arrayBuffer()),
        fetch(fontPathMedium).then((response) => response.arrayBuffer()),
      ]);

      const font = await combinedPdf.embedFont(fontDataRegular, {
        subset: true,
      });
      const font1 = await combinedPdf.embedFont(fontDataMedium, {
        subset: true,
      });
      const boldFont = await combinedPdf.embedFont(
        StandardFonts.TimesRomanBold
      );
      const boldFont1 = await combinedPdf.embedFont(StandardFonts.TimesRoman);
      const [newPage] = await combinedPdf.copyPages(pdfDoc, [0]);
      combinedPdf.addPage(newPage);
      let pageindex = i * 2;
      const firstPage = combinedPdf.getPages()[pageindex];
      const [SecondnewPage] = await combinedPdf.copyPages(pdfDoc, [1]);
      combinedPdf.addPage(SecondnewPage);
      let pageindex1 = i * 2 + 1;
      const secondPage = combinedPdf.getPages()[pageindex1];

      const claimantCompanyAddress = data[i].claimantCompanyAddress;
      const word1 = `${data[i].borrowerAddress}`;
      const word2 = `Contact: ${data[i].borrowerPhone} Email: ${data[i].borrowerEmail}`;

      const word31 = `${data[i].coBorrowerAddress}`;
      let str = "";
      str += data[i].phone ? `Contact: ${data[i].phone}` : "";
      str += data[i].coBorrowerEmail
        ? ` Email: ${data[i].coBorrowerEmail}`
        : "";
      const word3 = str;
      const word4 =
        "Subject: Letter of invitation as per the provisions of Section 62 of the Arbitration and Conciliation Act, 1996, for the resolution/conciliation of disputes arising out of your outstanding loan.";
      const word5 = "Sir/Madam,";
      const word6 = `The Claimant for ${data[i].claimantCompany}, having an address at ${claimantCompanyAddress}, is hereby directed to give the following notice to you: -`;
      const word7 = `You have obtained a loan from the Claimant vide Loan Agreement No.${data[i].loanID}. As per the terms and conditions of the loan granted to you by the Claimant, the terms of, which were specifically and expressly agreed upon between you and the Claimant, you were bound to repay the loan amount together with interest and other expenses. However, you have failed to comply with the financial terms and payments are due on your account. The total amount of Rs. ${data[i].totalLoan}/- as on 01st November 2023 is outstanding in your loan account. Several demands have been made to you by the Claimant regarding the amount due, apart from other amounts/charges that you have avoided paying. Therefore, the Claimant has initiated/started to initiate appropriate legal proceedings as per the legal process to recover the above principal, as well as interest and other costs/fees.`;
      const word8 =
        "However, before taking any legal action, the Claimant has expressed its intention to explore the possibility of settlement through the Conciliation Process and, accordingly, intends to refer the dispute to PrivateCourt (Arbitration & Conciliation Organization) (CONTACT NO 8976955540) which will work towards the amicable settlement of the dispute.";
      const word9 = `You are hereby directed to attend the physical hearing scheduled on ${scheduleDate}, at PrivateCourt: SB18, 2nd Floor, High Street Mall, Majiwada, Thane West-400607, between 10:00 AM to 05:00 PM. For further information please contact 8976955540 or e-mail at legal@privatecourt.in `;
      const hindiWord1 =
        "विषय: आपके बकाया ऋण से उत्पन्न विवादों के समाधान/समाधान के लिए मध्यस्थता और सुलह अधिनियम, 1996 की धारा 62 के प्रावधानों के अनुसार निमंत्रण पत्र।";
      const hindiWord2 = `दावेदार ${data[i].claimantCompany}, जिसका पता यूनिट नंबर पीएस 40 और पीएस 41, तीसरी मंजिल, बिड़ला सेंचुरियन, पांडुरंग बुधकर मार्ग, वर्ली, मुंबई, मुंबई सिटी, एमएच 400030, आईएन है, को निम्नलिखित देने का निर्देश दिया जाता है। आपके लिए सूचना:-`;
      const hindiWord3 = `आपने ऋण अनुबंध संख्या ${data[i].loanID} के माध्यम से दावेदार से ऋण प्राप्त किया है। शर्तों के अनुसार और दावेदार द्वारा आपको दिए गए ऋण की शर्तें, जिनकी शर्तों पर विशेष रूप से और स्पष्ट रूप से सहमति व्यक्त की गई थी आपके और दावेदार के बीच, आप ब्याज और अन्य सहित ऋण राशि चुकाने के लिए बाध्य थे खर्चे। हालाँकि, आप वित्तीय शर्तों का पालन करने में विफल रहे हैं और भुगतान आपके खाते पर बकाया है। कुल राशि रु. आपके ऋण खाते में 01 नवम्बर 2023 तक ${data[i].totalLoan}/- बकाया है। अनेक दावेदार द्वारा आपसे अन्य राशियों/प्रभारों के अलावा देय राशि के संबंध में मांग की गई है कि आपने भुगतान करने से परहेज किया है। इसलिए, दावेदार ने उचित कानूनी कार्रवाई शुरू कर दी है उपरोक्त मूलधन, साथ ही ब्याज और अन्य लागत/शुल्क की वसूली के लिए कानूनी प्रक्रिया के अनुसार   कार्यवाही।`;
      const hindiWord4 =
        "हालाँकि, कोई भी कानूनी कार्रवाई करने से पहले, दावेदार ने इसकी संभावना तलाशने का इरादा व्यक्त किया है सुलह प्रक्रिया के माध्यम से निपटान और, तदनुसार, विवाद को प्राइवेटकोर्ट में भेजने का इरादा है (मध्यस्थता और सुलह संगठन) (संपर्क संख्या 8976955540) जो सौहार्दपूर्ण की दिशा में काम करेगा विवाद का निपटारा.";
      const hindiWord5 = `आपको ${scheduleDate} को प्राइवेटकोर्ट: एसबी18, दूसरी मंजिल, हाई स्ट्रीट मॉल, मजीवाड़ा, ठाणे पश्चिम-400607 में सुबह 10:00 बजे से शाम 05:00 बजे के बीच निर्धारित शारीरिक सुनवाई में भाग लेने के लिए निर्देशित किया जाता है। के लिए अधिक जानकारी के लिए कृपया 8976955540 पर संपर्क करें या  legal@privatecourt.in पर ई-मेल करें।`;
      // const pages = pdfDoc.getPages();
      // const firstPage = pages[0];
      buildTextForPdf(
        "Date: " + getFormattedDate(),
        boldFont,
        firstPage,
        420,
        670
      );
      buildTextForPdf(
        `Ref No. ${data[i].refNo ? data[i].refNo : NewRef}`,
        boldFont,
        firstPage,
        36,
        685
      );
      buildTextForPdf(`To,`, boldFont, firstPage, 36, 670);
      let header = buildTextForPdf(
        `${data[i].borrowerName}`,
        boldFont,
        firstPage,
        36,
        650
      );

      header = buildTextForPdf(
        word1,
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );
      header = buildTextForPdf(
        word2,
        boldFont,
        firstPage,
        36,
        header.currentY - 20
      );

      header = buildTextForPdf(
        `${data[i].coBorrowerName}`,
        boldFont,
        firstPage,
        36,
        header.currentY - 20
      );

      header = buildTextForPdf(
        word31,
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );
      header = buildTextForPdf(
        word3,
        boldFont,
        firstPage,
        36,
        header.currentY - 20
      );
      header = buildTextForPdf(
        word4,
        boldFont,
        firstPage,
        36,
        header.currentY - 20
      );
      header = buildTextForPdf(
        word5,
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );
      header = buildTextForPdf(
        word6,
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );
      header = buildTextForPdf(
        word7,
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );
      header = buildTextForPdf(
        word8,
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );
      header = buildTextForPdf(
        word9,
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );
      // const secondPage = pages[1];
      buildTextForPdf(
        "Date: " + getFormattedDate(),
        boldFont,
        secondPage,
        420,
        670
      );
      buildTextForPdf(
        `संदर्भ संक्या: ${data[i].refNo ? data[i].refNo : NewRef}`,
        font,
        secondPage,
        36,
        685
      );
      buildTextForPdf(`To,`, boldFont, firstPage, 36, 670);
      let hindiHeader = buildTextForPdf3(
        `${data[i].borrowerName}`,
        boldFont,
        secondPage,
        36,
        650
      );
      hindiHeader = buildTextForPdf3(
        word1,
        boldFont1,
        secondPage,
        36,
        hindiHeader.currentY - 20
      );
      hindiHeader = buildTextForPdf3(
        word2,
        boldFont,
        secondPage,
        36,
        hindiHeader.currentY - 20
      );
      hindiHeader = buildTextForPdf(
        `${data[i].coBorrowerName}`,
        boldFont,
        secondPage,
        36,
        hindiHeader.currentY - 20
      );
      hindiHeader = buildTextForPdf(
        word31,
        boldFont1,
        secondPage,
        36,
        hindiHeader.currentY - 20
      );
      hindiHeader = buildTextForPdf(
        word3,
        boldFont,
        secondPage,
        36,
        hindiHeader.currentY - 20
      );
      hindiHeader = buildTextForPdf3(
        hindiWord1,
        font1,
        secondPage,
        36,
        hindiHeader.currentY - 20
      );
      hindiHeader = buildTextForPdf3(
        "महोदय / महोदया,",
        font,
        secondPage,
        36,
        hindiHeader.currentY - 20
      );
      hindiHeader = buildTextForPdf3(
        hindiWord2,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );
      hindiHeader = buildTextForPdf3(
        hindiWord3,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );
      hindiHeader = buildTextForPdf3(
        hindiWord4,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );
      hindiHeader = buildTextForPdf3(
        hindiWord5,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );
    }
    const combinedPdfBytes = await combinedPdf.save();
    // console.log(combinedPdf);
    const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Generated Concilation.pdf";
    link.click();
    // setIsLoading(false);
  };

  const handleModalDelete = (loanID) => {
    console.log(loanID);
    setCloseLoanID(loanID);
  };

  const handleChange6 = (e) => {
    setCaseStatus(e.target.value);
  };
  const handleChange4 = (e) => {
    setSettleAmt(e.target.value);
    setRemoveReason("");
  };
  const handleChange5 = (e) => {
    setRemoveReason(e.target.value);
    setSettleAmt("");
  };

  const handleDelete = async () => {
    const { timeStamp } = props.match.params;
    try {
      let response = await http.post2("/closeDispute", {
        timeStamp: +timeStamp,
        settleAmt,
        loanID: closeLoanID,
        removeReason,
        caseStatus,
        email,
      });
      console.log(response.data);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    fetchData2();
    fetchData3();
  }, []);

  useEffect(() => {
    fetchData();
  }, [page, perPage, search, loanType]);

  return isLoading ? (
    <Loading></Loading>
  ) : (
    <>
      <div class="main-wrapper">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-3">
              <div class="left-wrapper">
                <Navbar2 />
                <h2 class="admin-name">{companyName ? companyName : email}</h2>
                <Link to="/dashboard">
                  <button
                    type="button"
                    className="btn btn-light btn-sm back-btn mb-3"
                  >
                    <i className="bi bi-arrow-left"></i> Back
                  </button>
                </Link>
                <div class="left-wrapper">
                  {/* <button
                class="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Filter
              </button> */}
                  <div class="" id="collapseExample">
                    <div class="data-filter">
                      <div class="form-floating mb-3">
                        <input
                          class="form-control"
                          id="floatingSelect"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          //   value={filterByName}
                          //   onChange={(e)=>{setFilterByName(e.target.value)}}
                          aria-label="Floating label select example"
                        />
                        <label>
                          Search By Borrower Name, Loan ID, Agreement No
                        </label>
                      </div>
                      {/* <div class="form-floating mb-3">
                    <input
                      class="form-control"
                      id="floatingSelect"
                      value={filterByName}
                      onChange={(e)=>{setFilterByName(e.target.value)}}
                      aria-label="Floating label select example"
                    />
                    <label>Search By Ref No</label>
                  </div>
                  <div class="form-floating mb-3">
                    <input
                      class="form-control"
                      id="floatingSelect"
                      value={filterByName}
                      onChange={(e)=>{setFilterByName(e.target.value)}}
                      aria-label="Floating label select example"
                    />
                    <label>Search By Loan ID</label>
                  </div> */}
                      {/* <div class="form-floating mb-3">
                    <select
                      class="form-select"
                      id="floatingSelect"
                      value={loanType}
                      onChange={(e)=>setLoanType(e.target.value)}
                      aria-label="Floating label select example"
                    >
                      <option value=''>All</option>
                      <option value='New Lead'>New Lead</option>
                      <option value='Progress'>Progress</option>
                      <option value='Confirmed'>Confirmed</option>
                      <option value='Paid'>Paid</option>
                    </select>
                    <label>Search By Loan Type</label>
                  </div> */}
                    </div>
                    <div className="dot-bg">
                      <h2 className="d-lg-block d-none">Perform Tasks</h2>

                      <div className="row row-cols-2">
                        <div className="col">
                          <Link to={"/notice"} className="white-circle">
                            <img
                              src={agreementsIcon}
                              alt=""
                              style={{ display: "block", margin: "0 auto" }}
                            />
                            <h2>Notice details</h2>
                          </Link>
                        </div>
                        <div className="col">
                          <Link to="/account" className="white-circle">
                            <img
                              src={accountIcon}
                              alt=""
                              style={{ display: "block", margin: "0 auto" }}
                            />
                            <h2>Create New</h2>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-9">
              <div class="right-wrapper">
                <div class="conciliation-application-form">
                  <form id="regForm" action="#">
                    <div class="col-lg-12">
                      <div class="card card-new h-100">
                        <h2 class="mb-3">NBFC DISPUTE</h2>
                        <div class="org-data nbfc-table">
                          <table class="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">RefNo</th>
                                <th scope="col">LoanID</th>
                                {/* <th scope="col">Agreement No</th> */}
                                <th scope="col">Borrower</th>
                                <th scope="col">Claim-Amount</th>
                                <th scope="col">Claimant</th>
                                <th scope="col">Download</th>
                                <th scope="col">Close</th>
                              </tr>
                            </thead>
                            <tbody>
                              {usersData.length > 0
                                ? usersData.map((item, index) => {
                                    return (
                                      <tr>
                                        <td data-label="Ref: No">
                                          {item.refNo}
                                        </td>
                                        <td data-label="Loan ID" onClick={()=>{(props.history.push(`/nviewdetail/${item.loanID}`))}} style={{textDecoration: "underline"}}>
                                          {item.loanID}
                                        </td>
                                        {/* <td data-label="Agreement No">
                                          {item.agreementNo}
                                        </td> */}
                                        <td data-label="Borrower Name">
                                          {item.borrowerName}
                                        </td>
                                        <td data-label="Claim Amount">
                                          {item.claimAmount}
                                        </td>
                                        <td data-label="Claimant Company">
                                          {item.claimantCompany}
                                        </td>
                                        <td data-label="Download">
                                          <button
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                            // disabled={noticeInfo.approval=='No'}
                                            disabled={
                                              email !=
                                              "parveen.gandhi@bharatpe.com"
                                            }
                                            onClick={() =>
                                              downloadPDFLink(item.loanID)
                                            }
                                          >
                                            <i className="bi bi-download text-white"></i>
                                          </button>
                                        </td>
                                        <td data-label="Close">
                                          <i
                                            className="bi bi-x-circle"
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop"
                                            onClick={() =>
                                              handleModalDelete(item.loanID)
                                            }
                                          ></i>
                                        </td>
                                      </tr>
                                    );
                                  })
                                : ""}
                            </tbody>
                          </table>
                        </div>
                        <div className="row">
                          <div className="col-lg-4"></div>
                          <div className="col-lg-4">
                            <div className="data-pagi float-lg-end mt-lg-0 mt-2">
                              <select
                                className="form-control"
                                id="floatingSelect"
                                value={perPage ? perPage : 10}
                                onChange={(e) => setPerPage(e.target.value)}
                                aria-label="Floating label select example"
                              >
                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                              <div
                                className="btn-toolbar"
                                role="toolbar"
                                aria-label="Toolbar with button groups"
                              >
                                <div
                                  className="btn-group me-1"
                                  role="group"
                                  aria-label="First group"
                                >
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => setPage(+page - 1)}
                                    disabled={page <= 1}
                                  >
                                    <i className="bi bi-chevron-left text-white"></i>
                                  </button>
                                </div>
                                <div
                                  className="btn-group me-1"
                                  role="group"
                                  aria-label="Second group"
                                >
                                  <button
                                    type="button"
                                    className="btn text-primary"
                                  >
                                    {page}
                                  </button>
                                </div>
                                <div
                                  className="btn-group"
                                  role="group"
                                  aria-label="Third group"
                                >
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={usersData.length < +perPage}
                                    onClick={() => setPage(+page + 1)}
                                  >
                                    <i className="bi bi-chevron-right text-white"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4"></div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-keyboard="false"
        tabindex="1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-danger" id="staticBackdropLabel">
                Update Case Status
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <h5>Case Status</h5>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="caseStatus"
                      value="Settle"
                      checked={caseStatus == "Settle"}
                      onChange={(e) => handleChange6(e)}
                    />
                    <label className="form-check-label">Settle</label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="caseStatus"
                      value="Remove"
                      checked={caseStatus == "Remove"}
                      onChange={(e) => handleChange6(e)}
                    />
                    <label className="form-check-label">Remove</label>
                  </div>
                </div>
              </div>
              {caseStatus == "Settle" ? (
                <div className="text-center row">
                  <div className="col-2"></div>
                  <div className="col-8">
                    <input
                      type="number"
                      className="form-control m-1 form-group-inline"
                      id="settleAmt"
                      name="settleAmt"
                      value={settleAmt}
                      placeholder="Enter settle Amount"
                      onChange={(e) => handleChange4(e)}
                    />
                  </div>
                  <div className="col-2"></div>
                </div>
              ) : caseStatus == "Remove" ? (
                <div className="text-center row">
                  <div className="col-2"></div>
                  <div className="col-8">
                    <input
                      type="text"
                      className="form-control m-1 form-group-inline"
                      id="removeReason"
                      name="removeReason"
                      value={removeReason}
                      placeholder="Enter reason"
                      onChange={(e) => handleChange5(e)}
                    />
                  </div>
                  <div className="col-2"></div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                disabled={
                  !(
                    (caseStatus == "Settle" && settleAmt) ||
                    (caseStatus == "Remove" && removeReason)
                  )
                }
                onClick={handleDelete}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
        {/* <div className="modal-backdrop" style={{ opacity: "0.3", backgroundColor: "#fff" }}></div> */}
      </div>
    </>
  );
};
export default withRouter(NoticeTable);
