import React, { useState, useEffect, useRef } from "react";
// import React, { useRef } from 'react';
import { Formik, Field, ErrorMessage, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import auth from "../services/authService";
import Location from "./country";
import PDFfile from "../asset/DRPAgreement.pdf";
import disputeIcon from "../asset/icons/disputes.svg";
import agreementsIcon from "../asset/icons/agreements.svg";
import accountIcon from "../asset/icons/account.svg";
import { Link } from "react-router-dom";
import http from "../services/httpService";
import { generateOtp } from "../logic/generateOtp";
import Navbar2 from "./navbar2";
import Loading from "./loading";

const DisputeResolutionAgreement = (props) => {
  const modalRef = useRef();
  const email=auth.getUser().email;
  const referenceno = generateOtp();
  const [fee, setFee] = useState(2000);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedState, setSelectedState] = useState({});
  const [selectedCity, setSelectedCity] = useState({});
  const [updatePage, setUpdatePage] = useState("");
  const [userInfo, setuserInfo] = useState({});
  const [companyName, setCompanyName]=useState('');
  const [isLoading, setIsLoading] = useState(true);
  const getCurrentDate=()=> {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Months are zero-based
    const year = currentDate.getFullYear();

    // Pad single-digit day and month with a leading zero
    const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;

    return formattedDate;
}
  const [initialValues, setInitialValues] = useState({
    // firstParty: userInfo.firstParty||{
    //   name:'',
    //   organization:'',
    //   designation: "",
    //   address: "",
    //   govtId: "",
    //   phone: "",
    //   email: "",
    //   dateOfCommencement: "",
    // },
    secondParties: [
      {
        slectionOption: "",
        name: "",
        organization: "",
        designation: "",
        address: "",
        govtId: "",
        phone: "",
        email: "",
      },
      {
        slectionOption: "",
        name: "",
        organization: "",
        designation: "",
        address: "",
        govtId: "",
        phone: "",
        email: "",
      },
    ],

    Agreement: {
      valueOfAgreement: "",
      title: "",
      referenceAgreementNo: referenceno,
      DateOfAgreement: getCurrentDate(),
      dateOfCommencement: "",
      Fee: fee,
      phone: "",
      seatofArbitration: {
        country: selectedCountry.name,
        state: selectedState.name,
        city: selectedCity.name,
      },
    },
  });

  const validationSchema2 = Yup.object().shape({
    secondParties: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("name is required"),
        govtId: Yup.string().test(
          "unique-govtId",
          "Government ID must be unique",
          function (value) {
            if (!value) value = "$";
            const { secondParties } = this.from[1].value;
            let arr = secondParties
              ? secondParties.map((party) => {
                  if (party.govtId) return party.govtId;
                  else return "$";
                })
              : [];
            arr = arr.reduce(
              (acc, curr) => (curr !== "$" ? (acc.push(curr), acc) : acc),
              []
            );
            const govtIds = [...arr];
            govtIds.push("$");
            return govtIds.filter((id) => id == value).length == 1;
          }
        ),
        phone: Yup.string()

          .test(
            "unique-phone",
            "Phone number must be unique",
            function (value) {
              if (!value) value = "f";
              const { secondParties } = this.from[1].value;
              let arr = secondParties
                ? secondParties.map((party) => {
                    if (!party.phone) return "f";
                    else return party.phone;
                  })
                : [];

              arr = arr.reduce(
                (acc, curr) => (curr !== "f" ? (acc.push(curr), acc) : acc),
                []
              );
              const phones = [...arr];
              phones.push("f");
              return phones.filter((phone) => phone == value).length == 1;
            }
          ),
        email: Yup.string()
          .email('Enter a valid email')
          .required('email is required')
          .test("unique-email", "Email must be unique", function (value) {
            const { secondParties } = this.from[1].value;
            let arr = secondParties
              ? secondParties.map((party) => party.email)
              : [];
            const emails = [...arr];
            if(!value) return false;
            return emails.filter((email) => email == value).length == 1;
          }),
      })
    ),
    Agreement: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      referenceAgreementNo: Yup.number().required(
        "ReferenceAgreementNo is required"
      ),
    }),
  });
  
  const handleSelectChange = (country, state, city) => {
    setSelectedCountry(country);
    setSelectedState(state);
    setSelectedCity(city);
    const updatedInitialValues = { ...initialValues };
    updatedInitialValues.Agreement.seatofArbitration.city = city.name;
    updatedInitialValues.Agreement.seatofArbitration.country = country.name;
    updatedInitialValues.Agreement.seatofArbitration.state = state.name;

    setInitialValues(updatedInitialValues);
  };

  const handleChange2 = (e, index, values) => {
    const selectedPartyName = e.target.value;
    const selectedUserInfo = userInfo.secondParties.find(
      (user) => user.name === selectedPartyName
    );

    const updatedValues = { ...values };
    if (selectedUserInfo) {
      updatedValues.secondParties[index] = selectedUserInfo;
      updatedValues.secondParties[index].slectionOption = selectedPartyName;
    } else {
      updatedValues.secondParties[index] = {
        slectionOption: "",
        name: "",
        organization: "",
        designation: "",
        address: "",
        govtId: "",
        phone: "",
        email: "",
      };
    }
    setInitialValues(updatedValues);
  };

  const calculateAndDisplayFee = (formikValues) => {
    const valueOfAgreement = formikValues.Agreement.valueOfAgreement;
    let calculatedFee = 0;
    if (valueOfAgreement === "") {
      calculatedFee = 2000;
    } else if (valueOfAgreement === 0) {
      calculatedFee = 2000;
    } else if (valueOfAgreement <= 100000) {
      calculatedFee = 500;
    } else if (valueOfAgreement <= 1000000) {
      calculatedFee = 1000;
    } else if (valueOfAgreement <= 5000000) {
      calculatedFee = 2000;
    } else if (valueOfAgreement <= 10000000) {
      calculatedFee = 5000;
    } else {
      calculatedFee = Math.ceil(valueOfAgreement * 0.001).toFixed(2);
    }
    setFee(calculatedFee);
  };

  const titleCase = (input) => {
    if (typeof input == "string") {
      return input
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    } else if (typeof input == "number") {
      return input;
    } else {
      throw new Error("Input must be either a string or a number");
    }
  };

  const generateAndDownloadPDF = async (formikValues) => {
    const existingPdfBytes = await fetch(PDFfile).then((res) =>
      res.arrayBuffer()
    );
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const FormData1 = [
      { text: titleCase(String(formikValues.secondParties[0].name)), y: 575 },
      {
        text: titleCase(String(formikValues.secondParties[0].organization)),
        y: 535,
      },
      { text: formikValues.secondParties[0].email, y: 495 },
      { text: titleCase(String(formikValues.secondParties[0].phone)), y: 455 },
      { text: formikValues.secondParties[0].govtId, y: 415 },
    ];

    const FormData2 = [
      { text: titleCase(String(formikValues.secondParties[1].name)), y: 575 },
      {
        text: titleCase(String(formikValues.secondParties[1].organization)),
        y: 535,
      },
      { text: formikValues.secondParties[1].email, y: 495 },
      { text: titleCase(String(formikValues.secondParties[1].phone)), y: 455 },
      { text: formikValues.secondParties[1].govtId, y: 415 },
    ];

    FormData1.map(({ text, y }) => {
      const textString = typeof text === "number" ? text.toString() : text;
      firstPage.drawText((textString?textString:''), {
        x: 110,
        y,
        size: 10,
        color: rgb(0, 0, 0),
      });
    });

    FormData2.map(({ text, y }) => {

      const textString = typeof text === "number" ? text.toString() : text;
      firstPage.drawText((textString?textString:''), {
        x: 360,
        y,
        size: 10,
        color: rgb(0, 0, 0),
        color: rgb(0, 0, 0),
      });
    });

    firstPage.drawText(`${formikValues.Agreement.referenceAgreementNo}`, {
      x: 100,
      y: 678,
      size: 12,
      color: rgb(0, 0, 0),
    });
    firstPage.drawText(`${formikValues.Agreement.seatofArbitration.country?formikValues.Agreement.seatofArbitration.country==="India"?"Rs":"USD":"USD"} ${formikValues.Agreement.Fee}`, {
      x: 350,
      y: 678,
      size: 12,
      color: rgb(0, 0, 0),
    });
    firstPage.drawText(`${formikValues.Agreement.DateOfAgreement}`, {
      x: 470,
      y: 678,
      size: 12,
      color: rgb(0, 0, 0),
    });
    firstPage.drawText(
      `${
        formikValues.Agreement.seatofArbitration.state
          ? formikValues.Agreement.seatofArbitration.state
          : ""
      }`,
      {
        x: 450,
        y: 110,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      `Title : ${formikValues.Agreement.title} & Value : ${formikValues.Agreement.valueOfAgreement?formikValues.Agreement.valueOfAgreement:0}`,
      {
        x: 50,
        y: 328,
        size: 12,
      }
    );
   
    if (formikValues.secondParties.length > 2) {
       let thirdParty = pdfDoc.addPage([612, 792]);
      thirdParty.drawText("Other Parties Details:", {
        x: 30,
        y: 750,
        size: 20,
      });
      thirdParty.drawLine({
        start: { x: 30, y: 740 },
        end: { x: 570, y: 740 },
        thickness: 1,
      });
      let yOffset = 740;
      const addTextToPage = (page, text, x, y, fontSize) => {
        const lineHeight = fontSize + 2;
        if (yOffset <= 10) {
          // Create a new page if remaining space is not enough
          page = pdfDoc.addPage([612, 792]);
          yOffset = 740; // Start at the top of the new page
        }
        page.drawText(text, { x, y: yOffset, size: fontSize });
        yOffset -= lineHeight; // Move down for the next content, considering font size
        return page;
      };
      formikValues.secondParties.slice(2).forEach((secondParty, index) => {
        yOffset -= 30; // Add some space between parties
        thirdParty = addTextToPage(
          thirdParty,
          `Party ${index + 3} Details:`,
          50,
          yOffset,
          15
        );

        for (const [key, value] of Object.entries(secondParty)) {
          if (key !== "slectionOption") {
            // console.log(key, 'key');
            const labelText = `${
              key !== "govtId" ? titleCase(key) : "Govt ID"
            } :   ${value}`;
            yOffset -= 5;
            thirdParty = addTextToPage(thirdParty, labelText, 70, yOffset, 10);
          }
        }

        yOffset -= 10;
        thirdParty = addTextToPage(
          thirdParty,
          `Signature : _______________`,
          70,
          yOffset,
          10
        );
      });
    }

    const pdfBytes1 = await pdfDoc.saveAsBase64();
    const pdfBytes = await pdfDoc.save();

    ///////// send pdf to email
    const formData = new FormData();
    formData.append(
      "pdf",
      new Blob([pdfBytes], { type: "application/pdf" }),
      "generated.pdf"
    );
    try {
      const email = auth.getUser().email;
      formData.append("email", email);
      await http.post2("/getpdf", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Email and PDF sent successfully");
    } catch (error) {
      console.error("Error sending email and PDF:", error);
    }

    /////////
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "DisputeResolutionPaper.pdf";
    link.click();
  };

  const handleSubmit = async (values, actions) => {

    calculateAndDisplayFee(values);
    values.Agreement.Fee = fee;
    const currentDate = new Date();
    const readableDate = currentDate.toLocaleDateString();
    values.Agreement.DateOfAgreement = readableDate;
    try {
      let response = http.post2(
        "/updateData",
        { data: { email: auth.getUser().email, values: values } },
        {
          timeout: 30000,
        }
      );
      console.log(response.data);
      console.log("Email and PDF sent successfully");
      generateAndDownloadPDF(values);

      const modal = modalRef.current;
      if (modal) {
        const modalInstance = new window.bootstrap.Modal(modal);
        modalInstance.show();
      }
  
    } catch (error) {
      console.error("Error sending email and PDF:", error);
    }
    actions.setSubmitting(true);
  };
  const handleOkButton=()=>{
    props.history.push('/usersDashboard');
  }

  const fetchData = async () => {
    let user = auth.getUser();
    try {
      let response = await http.post2("/getUserInfo", { email: user.email });
      console.log(response.data);
      setuserInfo(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchData2 = async () => {
    try {
      const response = await http.post2('/getCompanyName', {
        email,
      });
      if (response.status != 400) {
        setCompanyName(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchData();
    fetchData2();
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    isLoading? <Loading></Loading>:
    <>
      <div className="main-wrapper">
        <div className="container-fluid">
                <div className="modal fade" id="updates-1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"  data-bs-backdrop="static" ref={modalRef}>
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-body">
                    {/* <div className="text-center"> */}
                      <h1 className="modal-title fs-5 text-center" id="exampleModalLabel">
                        Agreement created successfully
                      </h1>
                    {/* </div> */}
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={()=>props.history.push("/usersDashboard")}>
                        Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          <div className="row">
            <div className="col-xl-3">
              <div className="left-wrapper">
                <Navbar2 />
                <h2 className="admin-name">{companyName?companyName:email}</h2>
                <Link to="/dashboard">
                  <button
                    type="button"
                    className="btn btn-light btn-sm back-btn mb-3"
                  >
                    <i className="bi bi-arrow-left"></i> Back
                  </button>
                </Link>

                <h3 className="small-des">
                  You are in the agreement section. Here you can download
                  dispute resolution agreement.
                </h3>
                {/* <button
                  className="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none mb-3"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  Perform Tasks
                </button> */}
                <div className="" id="collapseExample">
                  <div className="dot-bg">
                    <h2 className="d-lg-block d-none">Perform Tasks</h2>
                    <div className="row row-cols-2">
                      <div className="col">
                        <Link to={"/usersDashboard"} className="white-circle">
                          <img
                            src={agreementsIcon}
                            alt=""
                            style={{ display: "block", margin: "0 auto" }}
                          />
                          <h2>Agreement List</h2>
                        </Link>
                      </div>
                      <div className="col">
                        <Link to="/account" className="white-circle">
                          <img
                            src={accountIcon}
                            alt=""
                            style={{ display: "block", margin: "0 auto" }}
                          />
                          <h2>Create New</h2>
                        </Link>
                      </div>
                      <div className="col">
                        <Link to="/disputes" className="white-circle">
                          <img
                            src={disputeIcon}
                            alt=""
                            style={{ display: "block", margin: "0 auto" }}
                          />
                          <h2>Dispute</h2>
                        </Link>
                      </div>
                      <div className="col">
                        <Link to="/account" className="white-circle">
                          <img
                            src={accountIcon}
                            alt=""
                            style={{ display: "block", margin: "0 auto" }}
                          />
                          <h2>Account</h2>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /.col-lg-12 --> */}
            <div className="col-xl-9">
              <div className="right-wrapper">
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={validationSchema2}
                  onSubmit={handleSubmit}
                >
                  {({setFieldValue, isSubmitting, values }) => (
                    <Form>
                      <div className="party-form">
                        <div className="row">
                          
                        </div>
                        <div className="row mb-3">
                          {/* <!-- /.col-lg-4 -->   */}
                          <FieldArray name="secondParties">
                            {({ push, remove }) => (
                              <>
                                <div className="col-lg-12 text-end mb-3">
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={() =>
                                      push({
                                        slectionOption: "",
                                        name: "",
                                        organization: "",
                                        designation: "",
                                        address: "",
                                        govtId: "",
                                        phone: "",
                                        email: "",
                                      })
                                    }
                                  >
                                    <i className="bi bi-plus-lg text-primary"></i>{" "}
                                    Add More Party
                                  </button>
                                </div>
                                {values.secondParties.map((party, index) => (
                                  <div className="col-lg-4">
                                    <div className="card card-new">
                                     {index>1 && <button
                                        type="button"
                                        className="btn-close"
                                        aria-label="Close"
                                        onClick={() => remove(index)}
                                      >
                                        <i className="bi bi-x-lg"></i>
                                      </button>}
                                      <h2>{index + 1}. Party </h2>
                                      {Array.isArray(userInfo.secondParties) &&
                                      userInfo.secondParties.length > 0 ? (
                                        <Field
                                          as="select"
                                          className="form-select mb-3"
                                          name={`secondParties.${index}.slectionOption`}
                                          id={`secondParties.${index}.slectionOption`}
                                          aria-label="Default select example"
                                          onChange={(e) =>
                                            handleChange2(e, index, values)
                                          }
                                        >
                                          <option value={""}>
                                            Default Party
                                          </option>
                                          {userInfo.secondParties
                                            ? userInfo.secondParties.map(
                                                (user, ind) => {
                                                  let userIndex =
                                                    values.secondParties.findIndex(
                                                      (n) =>
                                                        n.slectionOption ==
                                                        user.name
                                                    );
                                                  
                                                  return (
                                                    <option
                                                      value={user.name}
                                                      disabled={userIndex != -1}
                                                    >
                                                      {user.name}
                                                    </option>
                                                  );
                                                }
                                              )
                                            : ""}
                                        </Field>
                                      ) : (
                                        <></>
                                      )}
                                      <form action="#">
                                        <div className="mb-2">
                                          <label
                                            htmlFor={`secondParties.${index}.name`}
                                            className="form-label form-label-new"
                                          >
                                            Name
                                          </label>
                                          <Field
                                            type="text"
                                            className="form-control form-control-new"
                                            id={`secondParties.${index}.name`}
                                            name={`secondParties.${index}.name`}
                                            placeholder="Enter Name"
                                          />
                                          <ErrorMessage
                                            name={`secondParties.${index}.name`}
                                            component="div"
                                            className="error-message text-danger"
                                          />
                                        </div>
                                        <div className="mb-2">
                                          <label
                                            htmlFor={`secondParties.${index}.name`}
                                            className="form-label form-label-new"
                                          >
                                            Organization
                                          </label>
                                          <Field
                                            type="text"
                                            className="form-control form-control-new"
                                            id={`secondParties.${index}.organization`}
                                            name={`secondParties.${index}.organization`}
                                            placeholder="Enter Organization"
                                          />
                                          <ErrorMessage
                                            name={`secondParties.${index}.organization`}
                                            component="div"
                                            className="error-message text-danger"
                                          />
                                        </div>
                                        <div className="mb-2">
                                          <label
                                            htmlFor={`secondParties.${index}.phone`}
                                            className="form-label form-label-new"
                                          >
                                            Phone
                                          </label>
                                          <Field
                                            type="number"
                                            className="form-control form-control-new"
                                            id={`secondParties.${index}.phone`}
                                            name={`secondParties.${index}.phone`}
                                            placeholder="Enter Phone"
                                          />
                                          <ErrorMessage
                                            name={`secondParties.${index}.phone`}
                                            component="div"
                                            className="error-message text-danger"
                                          />
                                        </div>
                                        <div className="mb-2">
                                          <label
                                            htmlFor={`secondParties.${index}.email`}
                                            className="form-label form-label-new"
                                          >
                                            Email
                                          </label>
                                          <Field
                                            type="email"
                                            className="form-control form-control-new"
                                            id={`secondParties.${index}.email`}
                                            name={`secondParties.${index}.email`}
                                            placeholder="Enter email"
                                          />
                                          <ErrorMessage
                                            name={`secondParties.${index}.email`}
                                            component="div"
                                            className="error-message text-danger"
                                          />
                                        </div>
                                        <div className="mb-2">
                                          <label
                                            htmlFor={`secondParties.${index}.govtId`}
                                            className="form-label form-label-new"
                                          >
                                            Government ID /GST No.
                                          </label>
                                          {/* <input
                                    type="text"
                                    className="form-control form-control-new"
                                    placeholder="4561238"
                                  /> */}
                                          <Field
                                            type="text"
                                            className="form-control form-control-new"
                                            id={`secondParties.${index}.govtId`}
                                            name={`secondParties.${index}.govtId`}
                                            placeholder="Enter ID"
                                            onChange={(e) => {
                                              const input = e.target.value;
                                              const alphanumericInput = input.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
                                              setFieldValue(`secondParties.${index}.govtId`, alphanumericInput);
                                            }}
                                          />
                                          <ErrorMessage
                                            name={`secondParties.${index}.govtId`}
                                            component="div"
                                            className="error-message text-danger"
                                          />
                                        </div>
                                        <div className="mb-2">
                                          <label
                                            htmlFor={`secondParties.${index}.designation`}
                                            className="form-label form-label-new"
                                          >
                                            Designation
                                          </label>
                                          {/* <input
                                    type="text"
                                    className="form-control form-control-new"
                                    placeholder="Manager"
                                  /> */}
                                          <Field
                                            type="text"
                                            className="form-control form-control-new"
                                            id={`secondParties.${index}.designation`}
                                            name={`secondParties.${index}.designation`}
                                            placeholder="Enter designation"
                                          />
                                        </div>
                                        <div className="mb-2">
                                          <label
                                            htmlFor={`secondParties.${index}.address`}
                                            className="form-label form-label-new"
                                          >
                                            Address
                                          </label>
                                          {/* <textarea
                                    className="form-control"
                                    rows="4"
                                    placeholder="208 - Globe Business Park MIDC, Kalyan Badlapur Road Ambernath, West, Thane, Maharashtra 421505"
                                  ></textarea> */}
                                          <Field
                                            as="textarea"
                                            className="form-control"
                                            id={`secondParties.${index}.address`}
                                            name={`secondParties.${index}.address`}
                                            rows="4"
                                          />
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                ))}
                                {/* <div className="col-lg-12 text-end mb-3">
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={() =>
                                      push({
                                        slectionOption:'',
                                        name: "",
                                        organization:'',
                                        designation: "",
                                        address: "",
                                        govtId: "",
                                        phone: "",
                                        email: "",
                                      })
                                    }
                                  >
                                    <i className="bi bi-plus-lg text-primary"></i>{" "}
                                    Add More Party
                                  </button>
                                </div> */}
                              </>
                            )}
                          </FieldArray>
                        </div>
                        {/* <!-- /.row --> */}
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="card card-new">
                              <h2>Important Information Details</h2>
                              <div className="row">
                                <div className="row">
                                  <label
                                    htmlFor="Agreement.seatofArbitration"
                                    className="form-label form-label-new"
                                  >
                                    Seat of Arbitration
                                  </label>
                                  <Location
                                    handleSelectChange={handleSelectChange}
                                  />
                                </div>
                              </div>
                              <form action="#" className="row">
                                {/* <div className="col-lg-4 mb-3">
                                  <label
                                    htmlFor="Agreement.seatofArbitration"
                                    className="form-label form-label-new"
                                  >
                                    Seat of Arbitration
                                  </label> */}
                                {/* <input
                                    type="text"
                                    className="form-control form-control-new"
                                    placeholder="Enter Seat"
                                    id="Agreement.seatofArbitration"
                                    name="Agreement.seatofArbitration"
                                  /> */}
                                {/* <Location handleSelectChange={handleSelectChange}/>
                                </div> */}

                                <div className="col-lg-4 mb-3">
                                  <label
                                    htmlFor="Agreement.valueOfAgreement"
                                    className="form-label form-label-new"
                                  >
                                    Value of Agreement
                                  </label>
                                  <Field
                                    type="number"
                                    className="form-control form-control-new"
                                    onClick={calculateAndDisplayFee(values)}
                                    id="Agreement.valueOfAgreement"
                                    name="Agreement.valueOfAgreement"
                                  />
                                  <ErrorMessage
                                    name="Agreement.valueOfAgreement"
                                    component="div"
                                    className="error-message text-danger"
                                  />
                                  {fee > 0 ? (
                                    <p className="text-primary">
                                      Dispute resolution agreement fee {values.Agreement.seatofArbitration.country?values.Agreement.seatofArbitration.country==="India"?"Rs":"USD":"USD"}. {fee}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-lg-4 mb-3">
                                  <label
                                    htmlFor="Agreement.title"
                                    className="form-label form-label-new"
                                  >
                                    Title of Agreement
                                  </label>
                                  {/* <input
                                    type="text"
                                    className="form-control form-control-new"
                                    placeholder=""
                                  /> */}
                                  <Field
                                    type="text"
                                    className="form-control form-control-new"
                                    id="Agreement.title"
                                    name="Agreement.title"
                                    placeholder="Enter Title"
                                  />
                                  <ErrorMessage
                                    name="Agreement.title"
                                    component="div"
                                    className="error-message text-danger"
                                  />
                                </div>
                                
                                <div className="col-lg-4 mb-3">
                                  <label
                                    htmlFor="Agreement.dateOfCommencement"
                                    className="form-label form-label-new"
                                  >
                                    Date of Commencement Agreement
                                  </label>
                                  <Field
                                    type="date"
                                    className="form-control"
                                    id="Agreement.dateOfCommencement"
                                    name="Agreement.dateOfCommencement"
                                    placeholder=""
                                  />
                                </div>
                              </form>
                              {/* {fee > 0 ? <p className="text-primary">Dispute resolution agreement fee Rs. {fee}</p> : ""} */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container mb-3 mt-3 d-flex justify-content-center">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          // onClick={() => generateAndDownloadPDF(values)}
                          disabled={isSubmitting}
                        >
                          Generate Dispute Resolution Agreement
                        </button>
                      </div>
                      {/* <!-- /.party-form --> */}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisputeResolutionAgreement;
