import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import auth from "../services/authService";
import Dashboard from "./dashboard";
import DisputeResolutionAgreement from "./disputeResolutionAgreement";
import FooterCompo from "./footerComponent";
import Login2 from "./login2";
import Logout from "./logout";
import SalesData from "./salesData";
import UnderDevelopment from "./underDevelopment";
import UsersDashboard from "./usersDashboard";
import UsersData from "./usersData";
import http from "../services/httpService";
import Account from "./Account";
import Dashboard2 from "./Dashboard2";
import Billing from "./Billing";
import NewUserDashboard from "./newUserDashboard";
import HomeDashBoard from "./HomeDashBoard";
import NBFCTable from "./NBFCTable";
import NBFCForm from "./NBFCForm";
import NBFCInfo from "./NBFCInfo";
import NBFCNotice from "./noticePage";
import NoticeTable from "./noticeTable";
import AdminPanelNBCF from "./adminPanelNBFC";
import SalesForm from "./salesForm";
import LeadTable from "./LeadTable";
import AdminUsersPage from "./adminUsersPage";
import UsersAgreementDetails from "./usersAgreementDetails";
import UsersCompanyDetails from "./usersCompanyDetails";
import Account2 from "./account2";
import PdfGenerator from "./dynamicPdf";
import AddMembers from "./addMembers";
import Reporting from "./Reporting";
import HistoryNotice from "./HistoryNotices";
import BKTTable from "./BKTTable";
import GraphReporting from "./graphReporting";


class MainCompo extends Component {
  state={
    exists:false,
    underD:true,
  }

  async fetchData() {
    let email=auth.getUser().email;
    try {
      let response=await http.post2('/getUserExist', {email});
      this.setState({exists:response.data});
      console.log(response.data, 'userExists');
    } catch (err) {
      console.log(err);
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps != this.props) {
      this.fetchData();
    }
  }
  handleRender=(flag)=>{
    this.setState({underD:flag});
  }

  render() {
    let user= auth.getUser();

    return (
      <>
        <Switch>
          <Route path='/dashboard' render={(props)=>(user.role=='admin' || user.role=='privateCourtTeam')?<Dashboard {...props}/>:<Redirect to={this.state.exists?'/ndashboard':'/ndashboard'}/>}/>
          <Route path='/adminPanel' render={(props)=>(user.role=='admin')?<AdminPanelNBCF {...props}/>:<Redirect to={this.state.exists?'/ndashboard':'/ndashboard'}/>}/>
          <Route path='/adminUsersPage' render={(props)=>(user.role=='admin' || user.role=='privateCourtTeam')?<AdminUsersPage {...props}/>:<Redirect to={this.state.exists?'/ndashboard':'/ndashboard'}/>}/>
          <Route path='/usersAgrrementDetail/:id' render={(props)=>(user.role=='admin' || user.role=='privateCourtTeam')?<UsersAgreementDetails {...props}/>:<Redirect to={this.state.exists?'/ndashboard':'/ndashboard'}/>}/>
          {/* <Route path='/usersAccountDetail/:id' render={(props)=>(user.role=='admin' || user.role=='privateCourtTeam')?<UsersAccountDetails {...props}/>:<Redirect to={this.state.exists?'/ndashboard':'/ndashboard'}/>}/> */}
          <Route path='/usersCompanyDetail/:id' render={(props)=>(user.role=='admin' || user.role=='privateCourtTeam')?<Account2 {...props}/>:<Redirect to={this.state.exists?'/ndashboard':'/ndashboard'}/>}/>
          <Route path='/ndetail/:loanID' render={(props)=>(user.role=='admin' || user.role=='privateCourtTeam' || user.role=='NBFCBank')?<NBFCForm {...props}/>:<Redirect to={this.state.exists?'/ndashboard':'/ndashboard'}/>}/>
          <Route path='/ndetailedview' render={(props)=>(user.role=='admin' || user.role=='privateCourtTeam' || user.role=='NBFCBank')?<NBFCTable {...props}/>:<Redirect to={this.state.exists?'/ndashboard':'/ndashboard'}/>}/>
          <Route path='/nviewdetail/:loanID' render={(props)=>(user.role=='admin' || user.role=='privateCourtTeam' || user.role=='NBFCBank')?<NBFCInfo {...props}/>:<Redirect to={this.state.exists?'/ndashboard':'/ndashboard'}/>}/>
          <Route path='/noticeDetail/:timeStamp' render={(props)=>(user.role=='admin' || user.role=='privateCourtTeam' || user.role=='NBFCBank')?<NoticeTable {...props}/>:<Redirect to={this.state.exists?'/ndashboard':'/ndashboard'}/>}/>
          <Route path='/addSales' render={(props)=>(user.role=='admin' || user.role=='privateCourtTeam')?<SalesForm {...props}/>:<Redirect to={this.state.exists?'/ndashboard':'/ndashboard'}/>}/>
          <Route path='/leadTable' render={(props)=>(user.role=='admin' || user.role=='privateCourtTeam')?<LeadTable {...props}/>:<Redirect to={this.state.exists?'/ndashboard':'/ndashboard'}/>}/>
          <Route path='/notice' render={(props)=>(user.role=='admin' || user.role=='privateCourtTeam' || user.role=='NBFCBank')?<NBFCNotice {...props}/>:<Redirect to={this.state.exists?'/ndashboard':'/ndashboard'}/>}/>
          <Route path='/disputes'  render={(props)=>(user.role=='admin' || user.role=='privateCourtTeam')?<UsersData {...props}/>:user.role=='NBFCBank'?<NBFCTable/>:<Redirect to='/underconstruction'/>}/>
          <Route path='/salesData' render={(props)=>(user.role=='admin' || user.role=='privateCourtTeam')?<SalesData {...props}/>:<Redirect to='/underconstruction'/>}/>
          <Route path='/ndashboard' render={(props)=>user.role? <NewUserDashboard {...props}/> :<Redirect to='/login'/>} />
          <Route path='/dashboard2' render={(props)=>user.role? <Billing {...props}/> :<Redirect to='/login'/>} />
          <Route path='/underconstruction' render={(props)=>user?<UnderDevelopment {...props} onRender={this.handleRender} underD={this.underD}/>:<Redirect to='/login'/>}/>
          <Route path='/login' render={(props)=>(!user)?<Login2 {...props}/>:<Redirect to='/dashboard'/>}/>
          <Route path='/dispute-resolution-agreement' render={(props)=>(user.role?<DisputeResolutionAgreement {...props}/>:<Redirect to='/login'/>)}/>
          <Route path='/account' render={(props)=>(user.role?<Account {...props}/>:<Redirect to='/login'/>)}/>
          <Route path='/homePage' render={(props)=>(user.role?<HomeDashBoard {...props}/>:<Redirect to='/login'/>)}/>
          <Route path='/usersDashboard' render={(props)=>(user.role?<Dashboard2 {...props}/>:<Redirect to='/login'/>)}/>
          <Route path='/billingInformation' render={(props)=>(user.role?<Billing {...props}/>:<Redirect to='/login'/>)}/>
          <Route path='/addMembers' render={(props)=>(user.role?<AddMembers {...props}/>:<Redirect to='/login'/>)}/>
          <Route path='/historyNotice/:loanID' render={(props)=>(user.role?<HistoryNotice {...props}/>:<Redirect to='/login'/>)}/>
          <Route path='/bkttable/:timeStamp' render={(props)=>(user.role?<BKTTable {...props}/>:<Redirect to='/login'/>)}/>
          <Route path='/visualReport/:timeStamp' render={(props)=>(user.role?<GraphReporting {...props}/>:<Redirect to='/login'/>)}/>
          <Route path='/reporting' render={(props)=>(user.role?<Reporting {...props}/>:<Redirect to='/login'/>)}/>

          {/* <Route path='/dynamicPdf' component={PdfGenerator}/> */}
          <Route path='/logout' component={Logout}/>
          <Redirect to='/login'/>
        </Switch>
        {user?<><br></br>
        <br></br>
        <br></br>
        <br></br></>:''}
        {user? this.state.underD?<FooterCompo/>:'':''}
        {user?<>.</>:''}
      </>
    );
  }
}

export default MainCompo;