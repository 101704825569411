import { buildTextForPdf,convertToInteger, generatedConcilationCaseID,ConciliationPayrupikbuildTextForPdf, LRNbuildTextForPdf, AcceptancebuildTextForPdf,InvocationTextForPdf, buildTextForPdf3, ArbitrationbuildTextForPdf, getFormattedDate, getTimestamp, calculateDateAfter15Days, generateRandomInvoiceID } from "../logic/DownloadAll";
import { PDFDocument, StandardFonts } from 'pdf-lib';
import axios from 'axios';
import fontkit from '@pdf-lib/fontkit';
import Thane from '../files/LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf';
import fontPathRegular from '../files/Poppins-Regular.ttf';
import fontPathMedium from '../files/Poppins-Medium.ttf';
import Delhi from '../files/LETTERHEAD ALL ADDRESs Delhi Conciliation.pdf';

const ConciliationNoticePayrupik = async (letterHead, data) => {
    let pdfFilePath;
    if (letterHead === "Joylin Rego, Mumbai") {
    const letterheadResponse = await axios.get('letterhead.pdf');
        pdfFilePath = await letterheadResponse.data;
    } else {
      if (letterHead === "Gurugaon") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Bangalore") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Ambernath") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Hyderabad") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Thane") {
        pdfFilePath= await fetch(Thane).then((res) => res.arrayBuffer());
      } else if (letterHead === "Delhi") {
        pdfFilePath= await fetch(Delhi).then((res) => res.arrayBuffer());
      } else if (letterHead === "New Delhi") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      }
    }

    // let caseID=await axios
    // .get('https://server3-pab9.onrender.com/getConcilationCaseID?type=ConcilationCaseID')
    // .then((res)=>+res.data)
    // .catch((err)=>{
    //   console.log(err);
    //   return +1;
    // });

    // let caseID=1;
    // const data = await axios
    //   .post("https://server1-jlfh.onrender.com/getCsvByTimestamp", {
    //     email,
    //     timeStamp,
    //   })
    //   .then((res) => res.data)
    //   .catch((err) => {
    //     console.log(err);
    //     return [];
    //   });
      console.log(data.length);
      const combinedPdf = await PDFDocument.create();
      combinedPdf.registerFontkit(fontkit);
      
      // len=len>101?101:len;
      let j=0;
      // caseID=+caseID;
    for (let i = 0; i < data.length; i++){
      console.log(i);
      // let caseIDStr = generatedConcilationCaseID(caseID);
      // const scheduleDate = calculateDateAfter15Days();
      const pdfDoc = await PDFDocument.load(pdfFilePath);
      pdfDoc.registerFontkit(fontkit);
      const [fontDataRegular, fontDataMedium] = await Promise.all([
        fetch(fontPathRegular).then(response => response.arrayBuffer()),
        fetch(fontPathMedium).then(response => response.arrayBuffer())
      ]);
      const font = await combinedPdf.embedFont(fontDataRegular, { subset: true });
      const font1 = await combinedPdf.embedFont(fontDataMedium, { subset: true });
      const boldFont = await combinedPdf.embedFont(StandardFonts.TimesRomanBold);
      const boldFont1 = await combinedPdf.embedFont(StandardFonts.TimesRoman);
      const [newPage] = await combinedPdf.copyPages(pdfDoc, [0]);
      combinedPdf.addPage(newPage);
      let pageindex = j;
      const firstPage = combinedPdf.getPages()[pageindex];
      // const [SecondnewPage] = await combinedPdf.copyPages(pdfDoc, [1]);
      // combinedPdf.addPage(SecondnewPage);
      // let pageindex1 = j * 2 + 1;
      // const secondPage = combinedPdf.getPages()[pageindex1];
      const subject ="Sub: Empanelment of Conciliator and Settlement of Dispute (Outstanding Payment for SAYYAM INVESTMENTS PRIVATE LIMITED (PayRupik) (Claimant)) via Conciliation as per Arbitration and Conciliation Act , 1996.";
      const pointOne = `1. We have received the request for conducting Conciliation Proceedings through the claimant SAYYAM
                    INVESTMENTS PRIVATE LIMITED (PayRupik) to resolve the loan dispute of outstanding amount between
                    you and the Claimant for the Amount of Rs. ${data[i].totalLoan}/- along with the interest of 18% per annum from the date of
                    default.`;
      const pointTwo = `2. The PrivateCourt is an online dispute resolution body which conducts Arbitration/ Conciliation/ Mediation/
                    Negotiation though its Neutrals who are senior lawyers/ retired judges/President of the Organization. Being an
                    independent body, the PrivateCourt believes in resolving disputes with utmost fairness. These proceedings are
                    without prejudice to any/all other rights of the parties to approach any court/ forum/ tribunal/ authority. If either
                    party fails or neglect to attain the Conciliation Proceedings, then Non-Starter report will be passed by the Hon’ble
                    Sole Conciliator, pursuant to which the parties shall be at liberty to initiate court proceedings / Arbitration /
                    Criminal Complaint resolve their dispute or recover their claim`;

      const pointThree = `3. The Claimant states that you have not complied the terms and condition of the loan agreement executed between
                    you and the Claimant and hence till date an amount of Rs. ${data[i].totalLoan}/- is due and outstanidng.`;
      const pointFour = `4. You are hereby called to produce below-mentioned proof in reply to this Notice:`;
      const pointFourA = `a. Reciept of all payment made to SAYYAM INVESTMENTS PRIVATE LIMITED (PayRupik) confirming
                         all dues are clear and entire loan amount and interest is paid.`
      const pointFourB = `b. No dues certificate / confirmation of loan acount closure from SAYYAM INVESTMENTS PRIVATE
                         LIMITED (PayRupik).`
      const pointFive=`5. If you don’t have above-mentioned proofs or payment is pending to SAYYAM INVESTMENTS PRIVATE
                       LIMITED, you are directed to make payment at the below-mentioned payment link within 7 days of this notice:`
      const pointFiveLink=`https://easebuzz.in/pay/SayyamDnZU2`;
      const pointSix=`6. Kindly submit the above-mentioned proof at legal@privatecourt.in or call / whatsapp at 8976955539.`
      const pointSeven=`7. If you fail to make payment or furnish proof within 7 days of the reciept of this notice, matter shall be proceeded
                       for Concilaition / arbitration anytime thereafter by giving you intimation 24 hours prior to the hearing.`

      ConciliationPayrupikbuildTextForPdf(`Ref No. ${data[i].caseID}`, boldFont, firstPage, 36, 685);
      ConciliationPayrupikbuildTextForPdf(`Date: ${data[i].noticeDate}`, boldFont, firstPage,420,660);
      ConciliationPayrupikbuildTextForPdf(`To,`, boldFont, firstPage, 36, 660);
      let header=ConciliationPayrupikbuildTextForPdf(`${data[i].borrowerName?`1. ${data[i].borrowerName} (Borrower)`:""}`, boldFont, firstPage, 36, 635);
      header=ConciliationPayrupikbuildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      header=ConciliationPayrupikbuildTextForPdf(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      header=ConciliationPayrupikbuildTextForPdf(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont, firstPage, 36, header.currentY-15);
      if(data[i].coBorrowerName){
        header=ConciliationPayrupikbuildTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName} (Co-Borrower)`:""}`, boldFont, firstPage, 36, header.currentY-20);
      }
      if(data[i].coBorrowerAddress){
        header=ConciliationPayrupikbuildTextForPdf(`${data[i].coBorrowerAddress?`Having address at: ${data[i].coBorrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      else if(data[i].coBorrowerName){
        header=ConciliationPayrupikbuildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      if(data[i].coBorrowerEmail){
        header=ConciliationPayrupikbuildTextForPdf(`${data[i].coBorrowerEmail?`E-mail: ${data[i].coBorrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      if(data[i].phone){
        header=ConciliationPayrupikbuildTextForPdf(`${data[i].phone?`Contact: ${data[i].phone}`:""}`, boldFont, firstPage, 36, header.currentY-15);
      }

      if(data[i]['Business name']){
        header=ConciliationPayrupikbuildTextForPdf(`${data[i]['Business name']?`3. ${data[i]['Business name']}`:""}`, boldFont, firstPage, 36, header.currentY-20);
      }

      if(data[i]['borrower Business Address']){
        header=ConciliationPayrupikbuildTextForPdf(`${data[i]['borrower Business Address']?`Having address at: ${data[i]['borrower Business Address']}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      else if(data[i]['Business name']){
        header=ConciliationPayrupikbuildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }

      header = ConciliationPayrupikbuildTextForPdf(
        subject,
        boldFont,
        firstPage,
        36,
        header.currentY - 20
      );
      header = ConciliationPayrupikbuildTextForPdf(
        "Respected Sir/Madam,",
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointOne,
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointTwo,
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointThree,
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointFour,
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointFourA,
        boldFont1,
        firstPage,
        46,
        header.currentY - 14
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointFourB,
        boldFont1,
        firstPage,
        46,
        header.currentY - 14
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointFive,
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointFiveLink,
        boldFont,
        firstPage,
        36,
        header.currentY - 14
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointSix,
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointSeven,
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );


      header=ConciliationPayrupikbuildTextForPdf(
        "Adv. Vishal Tiwari",
        boldFont,
        firstPage,
        430,
        77
      )

      header=ConciliationPayrupikbuildTextForPdf(
        "PrivateCourt Registry",
        boldFont,
        firstPage,
        422,
        62
      )
      j++;
    }
    const combinedPdfBytes = await combinedPdf.save();
    const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Generated Conciliation.pdf";
    link.click();
  };
  export default ConciliationNoticePayrupik;