import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

class PdfGenerator extends React.Component {
  generatePdf = () => {
    const input = document.getElementById('pdf-content');

    html2canvas(input).then((canvas) => {
      const pdf = new jsPDF();
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0);
      pdf.save('generated-pdf.pdf');
    });
  };

  render() {
    return (
      <div>
        <div id="pdf-content">
          <h1>Hello, this is a dynamic PDF!</h1>
          <h1>Hello, this is a dynamic PDF!</h1>
          <h1>Hello, this is a dynamic PDF!</h1>
          <h1>Hello, this is a dynamic PDF!</h1>
          <h1>Hello, this is a dynamic PDF!</h1>
          <h1>Hello, this is a dynamic PDF!</h1>
          <h1>Hello, this is a dynamic PDF!</h1>
          <h1>Hello, this is a dynamic PDF!</h1>

          <h1>Hello, this is a dynamic PDF!</h1>
          <h1>Hello, this is a dynamic PDF!</h1>
        </div>
        <button onClick={this.generatePdf}>Generate PDF</button>
      </div>
    );
  }
}

export default PdfGenerator;
