import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import auth from "../services/authService";
import Navbar2 from "./navbar2";

const UsersDashboard = () => {
    const user=auth.getUser()
    return (
      <>
        <div class="main-wrapper">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-3">
              <div class="left-wrapper">
              {/* <nav className="navbar top-menu">
                  <div className="container-fluid">
                    <h1 className="logo">PRIVATE<span>COURT</span></h1>
                    <div>
                      <div className="dropdown">
                        <a className="dropdown-toggle shadow show" href="#" data-bs-toggle="dropdown" aria-expanded="true"></a>
                        <ul className="dropdown-menu dropdown-menu-end shadow border-0" data-bs-popper="static">
                        <li><Link className="dropdown-item" to="/dashboard">Dashboard</Link></li>
              <li><Link className="dropdown-item" to="/disputes">Cases</Link></li>
              <li><Link className="dropdown-item" to="/salesData">Sales</Link></li>
              <li><Link className="dropdown-item" to="/dispute-resolution-agreement">Buy Agreement</Link></li>
              <li><Link className="dropdown-item" to="/usersDashboard">Your Information</Link></li>
              <li><Link className="dropdown-item" to="/logout">Logout</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </nav> */}
                {Navbar2}

                <h2 class="admin-name">{user.email}</h2>
                <button type="button" class="btn btn-light btn-sm back-btn mb-3"><i class="bi bi-arrow-left"></i> Back</button>
                <h3 class="small-des">
                   You’ve an upcoming hearing in 3 hrs
                </h3>
                {/* <button class="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    Notifications
                </button> */}
                <div class="" id="collapseExample">
                  <div class="dot-bg">
                    <h2 class="d-lg-block d-none">Notifications</h2>
                    <div class="alert alert-primary bg-transparent" role="alert">
                        <strong>35/70</strong> entries processed from Batch Folio uploaded on 23 Sep 2023.
                    </div>
                    <div class="alert alert-primary bg-transparent" role="alert">
                        New Member <strong>‘Akasha Seth’</strong> added to the team. 
                    </div>
                    <div class="alert alert-primary bg-transparent" role="alert">
                        You have won the dispute against Deepak Jain. See the <a href="#" class="alert-link">Final Terms.</a>
                    </div>
                    <div class="alert alert-primary bg-transparent" role="alert">
                        2 upcoming Hearings tomorrow. <a href="#" class="alert-link">See full Schedule</a>
                    </div>
                    <div class="alert alert-primary bg-transparent" role="alert">
                        <div class="d-flex justify-content-between">
                            <div>
                                12 new in last 2 days
                            </div>
                            <div>
                                <a href="#" class="alert-link">view all</a>
                            </div>
                        </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /.col-lg-12 --> */}
            <div class="col-xl-9">
                <div class="right-wrapper">
                    <div class="row">
                        <div class="col-xl-4 col-lg-12">
                            <div class="card card-new update-status-card">
                                <h2>Update Status</h2>
                                <form action="#" class="mb-3">
                                    <input type="text" class="form-control form-control-new" placeholder="Search Case"/>
                                </form>
                                <div class="update-status-list d-flex align-items-center mb-3">
                                    <div class="c-pic me-md-3 me-1">
                                        <img class="shadow" src="images/c-pic.png" alt=""/>
                                    </div>
                                    <div class="c-content">
                                        <h2>Samar Chand Das</h2>
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" class="btn">Case ID</button>
                                            <button type="button" class="btn">TC-46377-36366</button>                                            
                                        </div>
                                    </div>
                                    <div class="resolved ms-auto">
                                        <p class="mb-0">RESOLVED</p>
                                        <p>3 months ago</p>    
                                    </div>
                                    <div>
                                        <div class="dropdown">
                                            <button class="btn bg-white dropdown-toggle three-dot-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="bi bi-three-dots-vertical"></i>
                                            </button>
                                            <ul class="dropdown-menu">
                                              <li><a class="dropdown-item" href="#">Action</a></li>
                                              <li><a class="dropdown-item" href="#">Another action</a></li>
                                              <li><a class="dropdown-item" href="#">Something else here</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /.update-status-list  --> */}
                                <div class="update-status-list d-flex align-items-center mb-3">
                                    <div class="c-pic me-md-3 me-1">
                                        <img class="shadow" src="images/c-pic.png" alt=""/>
                                    </div>
                                    <div class="c-content">
                                        <h2>Samar Chand Das</h2>
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" class="btn">Case ID</button>
                                            <button type="button" class="btn">TC-46377-36366</button>                                            
                                        </div>
                                    </div>
                                    <div class="resolved ms-auto">
                                        <p class="mb-0">RESOLVED</p>
                                        <p>3 months ago</p>    
                                    </div>
                                    <div>
                                        <div class="dropdown">
                                            <button class="btn bg-white dropdown-toggle three-dot-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="bi bi-three-dots-vertical"></i>
                                            </button>
                                            <ul class="dropdown-menu">
                                              <li><a class="dropdown-item" href="#">Action</a></li>
                                              <li><a class="dropdown-item" href="#">Another action</a></li>
                                              <li><a class="dropdown-item" href="#">Something else here</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /.update-status-list  --> */}
                                <div class="update-status-list d-flex align-items-center mb-3">
                                    <div class="c-pic me-md-3 me-1">
                                        <img class="shadow" src="images/c-pic.png" alt=""/>
                                    </div>
                                    <div class="c-content">
                                        <h2>Samar Chand Das</h2>
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" class="btn">Case ID</button>
                                            <button type="button" class="btn">TC-46377-36366</button>                                            
                                        </div>
                                    </div>
                                    <div class="resolved ms-auto">
                                        <p class="mb-0">RESOLVED</p>
                                        <p>3 months ago</p>    
                                    </div>
                                    <div>
                                        <div class="dropdown">
                                            <button class="btn bg-white dropdown-toggle three-dot-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="bi bi-three-dots-vertical"></i>
                                            </button>
                                            <ul class="dropdown-menu">
                                              <li><a class="dropdown-item" href="#">Action</a></li>
                                              <li><a class="dropdown-item" href="#">Another action</a></li>
                                              <li><a class="dropdown-item" href="#">Something else here</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /.update-status-list  --> */}
                                <div class="update-status-list d-flex align-items-center mb-3">
                                    <div class="c-pic me-md-3 me-1">
                                        <img class="shadow" src="images/c-pic.png" alt=""/>
                                    </div>
                                    <div class="c-content">
                                        <h2>Samar Chand Das</h2>
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" class="btn">Case ID</button>
                                            <button type="button" class="btn">TC-46377-36366</button>                                            
                                        </div>
                                    </div>
                                    <div class="resolved ms-auto">
                                        <p class="mb-0">RESOLVED</p>
                                        <p>3 months ago</p>    
                                    </div>
                                    <div>
                                        <div class="dropdown">
                                            <button class="btn bg-white dropdown-toggle three-dot-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="bi bi-three-dots-vertical"></i>
                                            </button>
                                            <ul class="dropdown-menu">
                                              <li><a class="dropdown-item" href="#">Action</a></li>
                                              <li><a class="dropdown-item" href="#">Another action</a></li>
                                              <li><a class="dropdown-item" href="#">Something else here</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /.update-status-list  --> */}
                                <div class="update-status-list d-flex align-items-center mb-3">
                                    <div class="c-pic me-md-3 me-1">
                                        <img class="shadow" src="images/c-pic.png" alt=""/>
                                    </div>
                                    <div class="c-content">
                                        <h2>Samar Chand Das</h2>
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" class="btn">Case ID</button>
                                            <button type="button" class="btn">TC-46377-36366</button>                                            
                                        </div>
                                    </div>
                                    <div class="resolved ms-auto">
                                        <p class="mb-0">RESOLVED</p>
                                        <p>3 months ago</p>    
                                    </div>
                                    <div>
                                        <div class="dropdown">
                                            <button class="btn bg-white dropdown-toggle three-dot-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="bi bi-three-dots-vertical"></i>
                                            </button>
                                            <ul class="dropdown-menu">
                                              <li><a class="dropdown-item" href="#">Action</a></li>
                                              <li><a class="dropdown-item" href="#">Another action</a></li>
                                              <li><a class="dropdown-item" href="#">Something else here</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /.update-status-list  --> */}
                                <div class="update-status-list d-flex align-items-center mb-3">
                                    <div class="c-pic me-md-3 me-1">
                                        <img class="shadow" src="images/c-pic.png" alt=""/>
                                    </div>
                                    <div class="c-content">
                                        <h2>Samar Chand Das</h2>
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" class="btn">Case ID</button>
                                            <button type="button" class="btn">TC-46377-36366</button>                                            
                                        </div>
                                    </div>
                                    <div class="resolved ms-auto">
                                        <p class="mb-0">RESOLVED</p>
                                        <p>3 months ago</p>    
                                    </div>
                                    <div>
                                        <div class="dropdown">
                                            <button class="btn bg-white dropdown-toggle three-dot-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="bi bi-three-dots-vertical"></i>
                                            </button>
                                            <ul class="dropdown-menu">
                                              <li><a class="dropdown-item" href="#">Action</a></li>
                                              <li><a class="dropdown-item" href="#">Another action</a></li>
                                              <li><a class="dropdown-item" href="#">Something else here</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /.update-status-list  --> */}
                                <div class="update-status-list d-flex align-items-center mb-3">
                                    <div class="c-pic me-md-3 me-1">
                                        <img class="shadow" src="images/c-pic.png" alt=""/>
                                    </div>
                                    <div class="c-content">
                                        <h2>Samar Chand Das</h2>
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" class="btn">Case ID</button>
                                            <button type="button" class="btn">TC-46377-36366</button>                                            
                                        </div>
                                    </div>
                                    <div class="resolved ms-auto">
                                        <p class="mb-0">RESOLVED</p>
                                        <p>3 months ago</p>    
                                    </div>
                                    <div>
                                        <div class="dropdown">
                                            <button class="btn bg-white dropdown-toggle three-dot-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="bi bi-three-dots-vertical"></i>
                                            </button>
                                            <ul class="dropdown-menu">
                                              <li><a class="dropdown-item" href="#">Action</a></li>
                                              <li><a class="dropdown-item" href="#">Another action</a></li>
                                              <li><a class="dropdown-item" href="#">Something else here</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /.update-status-list  --> */}
                                <div class="update-status-list d-flex align-items-center mb-3">
                                    <div class="c-pic me-md-3 me-1">
                                        <img class="shadow" src="images/c-pic.png" alt=""/>
                                    </div>
                                    <div class="c-content">
                                        <h2>Samar Chand Das</h2>
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" class="btn">Case ID</button>
                                            <button type="button" class="btn">TC-46377-36366</button>                                            
                                        </div>
                                    </div>
                                    <div class="resolved ms-auto">
                                        <p class="mb-0">RESOLVED</p>
                                        <p>3 months ago</p>    
                                    </div>
                                    <div>
                                        <div class="dropdown">
                                            <button class="btn bg-white dropdown-toggle three-dot-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="bi bi-three-dots-vertical"></i>
                                            </button>
                                            <ul class="dropdown-menu">
                                              <li><a class="dropdown-item" href="#">Action</a></li>
                                              <li><a class="dropdown-item" href="#">Another action</a></li>
                                              <li><a class="dropdown-item" href="#">Something else here</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /.update-status-list  --> */}
                                <div class="text-center mt-2">
                                    <a href="#" class="btn btn-outline-primary">view all <strong>resolved</strong> cases</a>
                                </div>
                            </div>
                            {/* <!-- /.card --> */}
                        </div>
                        {/* <!-- /.col-lg-4 --> */}
                        <div class="col-xl-4 col-lg-12">
                            <div class="card card-new update-status-card mb-3">
                                <h2>Results</h2>
                                
                            </div>
                            {/* <!-- /.card --> */}
                            <div class="card card-new update-status-card mb-3">
                                <h2>Status</h2>
                                
                            </div>
                            {/* <!-- /.card --> */}
                            <div class="card card-new update-status-card corporate">
                                <h2>Corporate</h2>
                                <img class="m-auto" src="images/api-connected.svg" alt=""/>
                                <div class="d-flex justify-content-evenly mt-3 pb-2">
                                    <div>
                                        <a href="#" class="btn btn-outline-primary">Manage Rules</a>
                                    </div>
                                    <div>
                                        <a href="#" class="btn btn-outline-primary">Billing History</a>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /.card --> */}
                        </div>
                        {/* <!-- /.col-lg-4 --> */}
                        <div class="col-xl-4 col-lg-12">                          
                            <div class="card card-new w-100 mb-3">
                              <h2>Members</h2>
                              <div class="d-flex member-list mb-3">
                                <div class="flex-shrink-0">
                                  <div class="bg-img bg-cover"></div>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                  <h2>Sanjay Mukherjee</h2>
                                  <p>Processor</p>
                                </div>
                              </div> 
                              <div class="d-flex member-list mb-3">
                                <div class="flex-shrink-0">
                                  <div class="bg-img bg-cover"></div>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                  <h2>Sanjay Mukherjee</h2>
                                  <p>Processor</p>
                                </div>
                              </div>
                              <div class="d-flex member-list mb-3">
                                <div class="flex-shrink-0">
                                  <div class="bg-img bg-cover"></div>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                  <h2>Sanjay Mukherjee</h2>
                                  <p>Processor</p>
                                </div>
                              </div>
                              <div class="d-flex member-list mb-3">
                                <div class="flex-shrink-0">
                                  <div class="bg-img bg-cover"></div>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                  <h2>Sanjay Mukherjee</h2>
                                  <p>Processor</p>
                                </div>
                              </div>             
                              <div class="text-center mt-2">
                                  <a href="#" class="btn btn-outline-primary">view all <strong>12</strong> members</a>
                              </div>
                            </div>
                            {/* <!-- /.card --> */}
                            <div class="card card-new update-status-card w-100">
                                  <h2>File a Dispute</h2>
                                  <form action="#" class="mb-3">
                                      <input type="text" class="form-control form-control-new" placeholder="Search Case"/>
                                  </form>
                                  <div class="update-status-list d-flex align-items-center mb-3">
                                      <div class="c-pic me-md-3 me-1">
                                          <img class="shadow" src="images/c-pic.png" alt=""/>
                                      </div>
                                      <div class="c-content">
                                          <h2>Samar Chand Das</h2>
                                          <div class="btn-group" role="group" aria-label="Basic example">
                                              <button type="button" class="btn">Case ID</button>
                                              <button type="button" class="btn">TC-46377-36366</button>                                            
                                          </div>
                                      </div>
                                      <div class="resolved ms-auto">
                                          <p class="mb-0">LAST EMI</p>
                                          <p>3 months ago</p>    
                                      </div>                                    
                                  </div>
                                  {/* <!-- /.update-status-list  --> */}
                                  <div class="update-status-list d-flex align-items-center mb-3">
                                      <div class="c-pic me-md-3 me-1">
                                          <img class="shadow" src="images/c-pic.png" alt=""/>
                                      </div>
                                      <div class="c-content">
                                          <h2>Samar Chand Das</h2>
                                          <div class="btn-group" role="group" aria-label="Basic example">
                                              <button type="button" class="btn">Case ID</button>
                                              <button type="button" class="btn">TC-46377-36366</button>                                            
                                          </div>
                                      </div>
                                      <div class="resolved ms-auto">
                                          <p class="mb-0">LAST EMI</p>
                                          <p>3 months ago</p>    
                                      </div>                                    
                                  </div>
                                  {/* <!-- /.update-status-list  --> */}
                                  <div class="update-status-list d-flex align-items-center mb-3">
                                      <div class="c-pic me-md-3 me-1">
                                          <img class="shadow" src="images/c-pic.png" alt=""/>
                                      </div>
                                      <div class="c-content">
                                          <h2>Samar Chand Das</h2>
                                          <div class="btn-group" role="group" aria-label="Basic example">
                                              <button type="button" class="btn">Case ID</button>
                                              <button type="button" class="btn">TC-46377-36366</button>                                            
                                          </div>
                                      </div>
                                      <div class="resolved ms-auto">
                                          <p class="mb-0">LAST EMI</p>
                                          <p>3 months ago</p>    
                                      </div>                                    
                                  </div>
                                  {/* <!-- /.update-status-list  --> */}
                                  <div class="update-status-list d-flex align-items-center mb-3">
                                      <div class="c-pic me-md-3 me-1">
                                          <img class="shadow" src="images/c-pic.png" alt=""/>
                                      </div>
                                      <div class="c-content">
                                          <h2>Swapna Pandey</h2>
                                          <div class="btn-group" role="group" aria-label="Basic example">
                                              <button type="button" class="btn">Case ID</button>
                                              <button type="button" class="btn">TC-46377-36366</button>                                            
                                          </div>
                                      </div>
                                      <div class="resolved ms-auto">
                                          <p class="mb-0">LAST EMI</p>
                                          <p>3 months ago</p>    
                                      </div>                                    
                                  </div>
                                  {/* <!-- /.update-status-list  -->                                 */}
                                  <div class="text-center mt-2">
                                      <a href="#" class="btn btn-outline-primary">view all <strong>resolved</strong> cases</a>
                                  </div>
                            </div>
                            {/* <!-- /.card -->                           */}
                        </div>
                        {/* <!-- /.col-lg-4 --> */}
                    </div>
                    {/* <!-- /.row --> */}
                </div>
                {/* <!-- /.right-wrapper --> */}
            </div>
            {/* <!-- /.col-lg-9 --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </div>
      {/* <!-- /.main-wrapper -->   */}
      </>
    );
  };

  export default withRouter(UsersDashboard);