import React, { Component } from "react";
import {
  Container,
  Divider,
  Typography,
  Checkbox,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@mui/material";
import http from "../services/httpService.js";
import CsvUploader from "./csvUplode.jsx";
import auth from "../services/authService.js";
import queryString from "query-string";

class SalesData extends Component {
    state={
        form:{index1:'', index2:''},
    }

    salesCalls=async(url, obj)=>{
        try {
            let response = await http.post(url, obj);
            console.log(response.data);
          }
        catch (err) {
            console.log(err);
          }
    }

    handlechange = (e) => {
        const { name, value } = e.target;
        let s1={...this.state};
        s1.form[name]=value;
        this.setState(s1);
      };
    
    handleStartCalls=()=>{
        let s1={...this.state};
        this.salesCalls('/salesCalls', s1.form);
    }

  render() {
    let {form}=this.state;
    let {index1, index2}=form;
    const user = auth.getUser();
      return (
        <>
          {/* <Typography
            variant="h1"
            sx={{ mt: 4, mb: 4, border: "solid 2px black" }}
          ></Typography> */}
          <Typography>
            {/* {user && user.role === "admin" && <CsvUploader type='salesData'/>} */}
          </Typography>
          <Grid 
            container
            spacing={2}
            alignItems="center"
            sx={{ marginTop: "20px" }}
            >
            <Grid item md={1}></Grid>
            <Grid item md={3}>
                <TextField
                  label="Enter start index"
                  name='index1'
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={index1}
                  onChange={this.handlechange}
                />
            </Grid>
            <Grid item md={3}>
                <TextField
                  label="Enter end index"
                  name='index2'
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={index2}
                  onChange={this.handlechange}
                />
            </Grid><Grid item md={1}></Grid>
            <Grid item md={3}>
                <Button variant="contained" color="primary" onClick={this.handleStartCalls}>
                    Start Calling
                </Button>
            </Grid>
          </Grid>

        </>
      );
    }
  }


export default SalesData;
