import { Country, State, City } from "country-state-city";
import { useEffect, useState } from "react";

export default function Location(props) {
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedState, setSelectedState] = useState({});
  const [selectedCity, setSelectedCity] = useState({});

  useEffect(() => {
    props.handleSelectChange(selectedCountry, selectedState, selectedCity);
  }, [selectedCountry, selectedCity, selectedState]);

  return (
    <>
      <div className="col-lg-4 ">
      <select className="form-select mb-3" aria-label="Default select example"
        value={selectedCountry ? selectedCountry.name : ""}
        onChange={(e) => {
          const selectedCountryValue = e.target.value;
          const country = Country.getAllCountries().find(
            (country) => country.name === selectedCountryValue
          );
          setSelectedCountry(country);
        }}
      >
        <option value="">Select a Country</option>
        {Country.getAllCountries().map((country) => (
          <option key={country.isoCode} value={country.name}>
            {country.name}
          </option>
        ))}
      </select>
      </div>

          <div className="col-lg-4">
                  <select className="form-select mb-3" aria-label="Default select example"
        value={selectedState ? selectedState.name : ""}
        onChange={(e) => {
          const selectedStateValue = e.target.value;
          const state = State.getStatesOfCountry(
            selectedCountry?.isoCode
          ).find((state) => state.name === selectedStateValue);
          setSelectedState(state);
        }}
      >
        <option value="">Select a State</option>
        {selectedCountry &&
          State.getStatesOfCountry(selectedCountry.isoCode).map((state) => (
            <option key={state.isoCode} value={state.name}>
              {state.name}
            </option>
          ))}
      </select>
          </div>


            <div className="col-lg-4">
     <select className="form-select mb-3" aria-label="Default select example"
        value={selectedCity ? selectedCity.name : ""}
        onChange={(e) => {
          const selectedCityValue = e.target.value;
          const city = City.getCitiesOfState(
            selectedState?.countryCode,
            selectedState?.isoCode
          ).find((city) => city.name === selectedCityValue);
          setSelectedCity(city);
        }}
      >
        <option value="">Select a City</option>
        {selectedState &&
          City.getCitiesOfState(
            selectedState.countryCode,
            selectedState.isoCode
          ).map((city) => (
            <option key={city.id} value={city.name}>
              {city.name}
            </option>
          ))}
      </select>
            </div>

 
    </>
  );
}
