import Navbar2 from "./navbar2";
import { Link, useNavigate, useParams} from "react-router-dom";
import auth from "../services/authService";
import http from "../services/httpService";
import { useState, useEffect } from "react";
import Loading from "./loading";

const NBFCInfo = (props) => {
  const email = auth.getUser().email;
  const [loanID, setLoanID] = useState(null);
  // const [initialValues, setInitialValues] = useState({});
  // const [companyName, setCompanyName] = useState("");

  // const navigate = useNavigate();
  const [action, setAction] = useState("");
  // const { loanID } = useParams();
  // const [loanIDs, setLoanID] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [companyName, setCompanyName] = useState("");
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal((prevShowModal) => !prevShowModal);
  };

  const fetchData2 = async () => {
    try {
      const response = await http.post2("/getCompanyName", {
        email,
      });
      if (response.status != 400) {
        // console.log(response.data, 'response.data gstComponent');
        setCompanyName(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchData = async () => {
    const { loanID } = props.match.params;
    if (loanID) {
      try {
        let response = await http.post("/getCsvDatas", {
          search: loanID,
          email: email,
        });
        console.log(response.data, "getDataByLoanID");
        setLoanID(loanID);
        setInitialValues((prevValues) => ({
          ...prevValues,
          ...response.data.data[0],
        }));
        console.log(initialValues);
      } catch (err) {
        console.log(err);
      }
    }
  };
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    fetchData();
    fetchData2();
  }, [loanID]);

  return isLoading ? (
    <Loading></Loading>
  ) : (
    <div className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3">
            <div className="left-wrapper">
              <Navbar2 />
              <h2 className="admin-name">
                {companyName ? companyName : email}
              </h2>
              <Link to="/dashboard">
                <button
                  type="button"
                  className="btn btn-light btn-sm back-btn mb-3"
                >
                  <i className="bi bi-arrow-left"></i> Back
                </button>
              </Link>
              {/* <h3 className="small-des">
                   You’ve an upcoming hearing in 3 hrs
                </h3>
                <button className="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    Notifications
                </button> */}
              <div className="" id="collapseExample">
                <div className="dot-bg">
                  <h2 className="d-lg-block d-none">Notifications</h2>
                  <div
                    className="alert alert-primary bg-transparent"
                    role="alert"
                  >
                    LRN successfully <strong>generated</strong> and{" "}
                    <strong>posted</strong>!
                  </div>
                  {/* <div className="alert alert-primary bg-transparent" role="alert">
                        New Member <strong>‘Akasha Seth’</strong> added to the team. 
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        You have won the dispute against Deepak Jain. See the <a href="#" className="alert-link">Final Terms.</a>
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        2 upcoming Hearings tomorrow. <a href="#" className="alert-link">See full Schedule</a>
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        <div className="d-flex justify-content-between">
                            <div>
                                12 new in last 2 days
                            </div>
                            <div>
                                <a href="#" className="alert-link">view all</a>
                            </div>
                        </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="right-wrapper">
              <div className="conciliation-application-form">
                <div className="col-lg-12">
                  <div className="card card-new mb-3">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="claim-amount">
                          <h2>{initialValues.totalLoan}</h2>
                          <p className="mb-0 ca-1">Claim Amout</p>
                          <p className="ca-2">{initialValues.claimAmount}</p>
                        </div>
                      </div>
                      <div className="col-lg-4 before-divide">
                        <div className="claim-emi-box text-center">
                          <h3>EMI</h3>
                          <div className="row text-center">
                            <>
                              <div className="col-lg-6 start-emi">
                                <p className="mb-0">
                                  {initialValues.emiStart
                                    ? initialValues.emiStart
                                    : "NA"}
                                </p>
                                <p>
                                  <span>Start EMI</span>
                                </p>
                              </div>
                            </>
                            <>
                              <div className="col-lg-6 end-emi">
                                <p className="mb-0">
                                  {initialValues.emiEnd
                                    ? initialValues.emiEnd
                                    : "NA"}
                                </p>
                                <p>
                                  <span>End EMI</span>
                                </p>
                              </div>
                            </>
                          </div>
                          <p className="mb-0 claim-emi-box-note">
                            {initialValues.type
                              ? initialValues.type + "Loan"
                              : ""}{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                            {initialValues.interest
                              ? "Interest Rate" + initialValues.interest
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 before-divide s-a-c">
                        {initialValues.seat ? (
                          <>
                            <div className="seat-of-arbitration text-center">
                              <h2>Seat of Arbitration</h2>
                              <p>{initialValues.seat}</p>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <hr className="mt-0" />
                        <div className="seat-of-arbitration text-center">
                          <h2>Arbitration</h2>
                          <p>
                            Stage: LRN Notice
                            {/* <Link to={`/historyNotice/${loanID}`}><button
                              class="btn btn-primary m-1"
                            >
                              history
                            </button></Link> */}
                            
                          </p>
                        </div>
                        <h6 className="dash-per"></h6>
                      </div>
                    </div>
                  </div>
                  <div className="card card-new">
                    <div className="row mt-2">
                      {initialValues.loanID ? (
                        <>
                          <div className="col-lg-4">
                            <p>
                              <strong>Loan ID: </strong> {initialValues.loanID}
                            </p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {initialValues.agreementNo ? (
                        <>
                          <div className="col-lg-4">
                            <p>
                              <strong>Agreement No: </strong>{" "}
                              {initialValues.agreementNo}
                            </p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {initialValues.totalLoan ? (
                        <>
                          <div className="col-lg-4">
                            <p>
                              <strong>Total Loan: </strong>
                              {initialValues.totalLoan}
                            </p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <hr className="mt-0 mb-1" />
                    <h2 className="mb-3 mt-3">Borrower</h2>
                    <div className="row">
                      <div className="col-lg-4">
                        <p>
                          <strong>Name: </strong>
                          {initialValues.borrowerName}
                        </p>
                      </div>
                      {initialValues.borrowerEmail ? (
                        <>
                          {" "}
                          <div className="col-lg-4">
                            <p>
                              <strong>Email: </strong>
                              {initialValues.borrowerEmail}{" "}
                            </p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {initialValues.borrowerPhone ? (
                        <>
                          <div className="col-lg-4">
                            <p>
                              <strong>Phone: </strong>
                              {initialValues.borrowerPhone}
                            </p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {initialValues.borrowerPanNumber ? (
                        <>
                          <div className="col-lg-4">
                            <p>
                              <strong>Pan: </strong>
                              {initialValues.borrowerPanNumber}
                            </p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {initialValues.borrowerAddress ? (
                        <>
                          <div className="col-lg-4">
                            <p>
                              <strong>Address: </strong>{" "}
                              {initialValues.borrowerAddress}
                            </p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {initialValues.coBorrowerName ? (
                      <>
                        <hr className="mt-0 mb-1" />
                        <h2 className="mb-3 mt-3">Co Borrower</h2>
                        <div className="row">
                          <div className="col-lg-4">
                            <p>
                              <strong>Name: </strong>{" "}
                              {initialValues.coBorrowerName}
                            </p>
                          </div>
                          {initialValues.coBorrowerEmail ? (
                            <>
                              <div className="col-lg-4">
                                <p>
                                  <strong>Email: </strong>{" "}
                                  {initialValues.coBorrowerEmail}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {initialValues.phone ? (
                            <>
                              <div className="col-lg-4">
                                <p>
                                  <strong>Phone: </strong> {initialValues.phone}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {initialValues.coPANNumber ? (
                            <>
                              <div className="col-lg-4">
                                <p>
                                  <strong>Pan: </strong>{" "}
                                  {initialValues.coPANNumber}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {initialValues.coBorrowerAddress ? (
                            <>
                              <div className="col-lg-4">
                                <p>
                                  <strong>Address: </strong>{" "}
                                  {initialValues.coBorrowerAddress}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {initialValues.gstNumber ? (
                      <>
                        <hr className="mt-0" />
                        <div className="row">
                          <div className="col-lg-12">
                            <p>
                              <strong>GST: </strong> {initialValues.gstNumber}
                            </p>
                          </div>
                        </div>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    {initialValues.vehicleNo ? (
                      <>
                        <hr className="mt-0 mb-1" />
                        <h2 className="mb-3 mt-3">Vehicle</h2>
                        <div className="row">
                          {initialValues.vehicleNo ? (
                            <>
                              <div className="col-lg-4">
                                <p>
                                  <strong>No: </strong>
                                  {initialValues.vehicleNo}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {initialValues.rcNo ? (
                            <>
                              <div className="col-lg-4">
                                <p>
                                  <strong>Re No: </strong>
                                  {initialValues.rcNo}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {initialValues.drivingLicenceNo ? (
                            <>
                              <div className="col-lg-4">
                                <p>
                                  <strong>Driving Licence: </strong>
                                  {initialValues.drivingLicenceNo}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    <hr className="mt-0 mb-1" />
                    <h2 className="mb-3 mt-3">Findings</h2>
                    <div className="row mt-2">
                      <div className="col-lg-6">
                        <h2 className="mb-4">Borrower Matches: </h2>
                        <hr className="mt-0" style={{ width: "300px" }} />
                        <p>
                          <strong>Name:</strong> Yes
                        </p>
                        <p>
                          <strong>Email:</strong> Yes
                        </p>
                        <p>
                          <strong>Phone:</strong> Yes
                        </p>
                        <p>
                          <strong>Address:</strong> Yes
                        </p>
                        {/* <p><strong>Pan:</strong> Yes</p>
                                        <p><strong>GST:</strong> Yes</p> */}
                      </div>
                      <div className="col-lg-6">
                        <h2 className="mb-4">Co Borrower Matches: </h2>
                        <hr className="mt-0" style={{ width: "300px" }} />
                        <p>
                          <strong>Name:</strong> Yes
                        </p>
                        <p>
                          <strong>Email:</strong> Yes
                        </p>
                        <p>
                          <strong>Phone:</strong> Yes
                        </p>
                        <p>
                          <strong>Address:</strong> Yes
                        </p>
                        {/* <p><strong>Pan:</strong> Yes</p> */}
                      </div>
                    </div>

                    {/* ///table// */}
                    {/* {showModal && (
                      <div className="modal show" tabindex="-1" role="dialog">
                        <div className="modal-dialog modal-lg" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">
                                Detail information of {action}
                              </h5>
                              <button
                                type="button"
                                className="close"
                                onClick={toggleModal}
                              >
                                <span>&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Notice Type</th>
                                    <th scope="col">Name of Borrower</th>
                                    <th scope="col">{action}</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Name of coBorrowerName</th>
                                    <th scope="col">{action}</th>
                                    <th scope="col">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{initialValues.LRNDate}</td>
                                    <td>LRN Notice</td>
                                    <td>{initialValues.borrowerName}</td>
                                    <td>
                                      {action === "Email"
                                        ? initialValues.borrowerEmail
                                        : action === "WhatsApp"
                                        ? initialValues.borrowerPhone
                                        : action === "Postal"
                                        ? initialValues.borrowerTrackingID
                                        : action === "Call"
                                        ? initialValues.borrowerPhone
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {action === "Email"
                                        ? initialValues.emailStatus
                                        : action === "WhatsApp"
                                        ? initialValues.whatsAppStatus
                                        : action === "Postal"
                                        ? "N/A"
                                        : action === "Call"
                                        ? "N/A"
                                        : "N/A"}
                                    </td>
                                    <td>{initialValues.coBorrowerName}</td>
                                    <td>
                                      {action === "Email"
                                        ? initialValues.coBorrowerEmailborrowerEmail
                                        : action === "WhatsApp"
                                        ? initialValues.phone
                                        : action === "Postal"
                                        ? "N/A"
                                        : action === "Call"
                                        ? initialValues.phone
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {action === "Email"
                                        ? initialValues.emailStatus
                                        : action === "WhatsApp"
                                        ? initialValues.whatsAppStatus
                                        : action === "Postal"
                                        ? "N/A"
                                        : action === "Call"
                                        ? "N/A"
                                        : "N/A"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={toggleModal}
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NBFCInfo;
