import React from "react";
import { useEffect, useState } from "react";
import http from "../../services/httpService";
import auth from "../../services/authService";
import { Link } from "react-router-dom";

function MCAComponent(props) {
  const email = auth.getUser().email;
  // const [email, setEmail]=useState(auth.getUser().email);
  //const email='ps432241@gmail.com';
  const [cin, setCIN] = useState("");
  const [mca, setMca] = useState({});
  const [verify, setVerify]=useState(false);
  const [matched, setMatched]=useState(false);
  const [wrongNo,setWrongNo]=useState(false);
  const [gstCheck, setGstCheck]=useState(false);
  const [loading, setLoading]=useState(false);

  const handlerforMca = async (e) => {
    e.preventDefault();
    setGstCheck(false);
    setLoading(true);
    if (cin.length === 21) {
      try {
        const res = await http.post2("/getMcaVerify", {
          cin,
          email,
          company:props.company.company
        });
        if(res.data.status=='completed'){
          setMca(res.data);
          setVerify(true);
          setLoading(false);
        }
        else if(res.status=='Not Matched'){
          setGstCheck(true);
          setLoading(false)
        }
        else if(res.status=='failed'){
          setWrongNo(true);
          setLoading(false);
        }
        else {
          setLoading(false);
        }

        // console.log(res);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    } else {
      setLoading(false);
      alert("Fill Correct 21 Character of CIN Number");
    }
  };

const handleChange = (e) => {
  const input = e.target.value;
  const alphanumericInput = input.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
  setCIN(alphanumericInput);
};
 
const fetchData = async () => {
  try {
    const response = await http.post2('/companiesMcaDetails', {
      email,
      id:props.id,
      company:props.company.company
    });
    console.log(response, 'response1')
    if (response.status != 400) {
      console.log(response.data, "mca")
      setMca(response.data);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
const fetchData2 = async () => {
  try {
    const response = await http.post2('/checkGstMca', {
      email,
      id:props.id,
      company:props.company.company
    });
    console.log(response, 'response1')
    if (response.status != 400) {
      console.log(response.data, "mca")
      setGstCheck(response.data);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
useEffect(() => {
    console.log(props, 'mca')
    fetchData();
    fetchData2();
}, [mca?.status]);

  return (
    <div
      className="tab-pane fade"
      id={"nav-contact" + props.company.company}
      role="tabpanel"
      aria-labelledby={"nav-contact-tab" + props.company.company}
      tabIndex="2"
    >
      {
        !props.id?
      <form onSubmit={handlerforMca}>
        <div className="row align-items-center">
          <div className="col-md-5 mb-3">
            <label className="form-label form-label-new">CIN Number</label>
            <input
              type="text"
              className="form-control form-control-new"
              placeholder=""
              value={(mca.result?.source_output?.cin || cin)}
              onChange={handleChange}
              disabled={mca.status=='completed'}
            />
          </div>
          <div className="col-md-2 mb-3">
            <button
              type="submit"
              className="btn verify btn-primary"
              disabled={mca.status=='completed' ||cin.length!=21}
            >
              {mca.status=='completed'? "Verified" : "Verify"}
            </button>
          </div>
        </div>
      </form>:''
}
      {verify && !props.id?
      <>
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          Verification Successfully Completed
          <button
            type="button"
            className="btn-close top-0 right-0"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          Your MCA and GST Name is Matched Correctly
          <button
            type="button"
            className="btn-close top-0 right-0"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      </>:""}
      {
      wrongNo && !props.id?
      <div
        className="alert alert-danger alert-dismissible fade show"
        role="alert"
      >
        Verification unsuccessfull please check CIN number
        <button
          type="button"
          className="btn-close top-0 right-0"
          data-bs-dismiss="alert"
          aria-label= "Close"
        ></button>
      </div>:''
    }
      {mca.status!='completed'  && !props.id?
      <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          Please Verify Your MCA!
          <button
            type="button"
            className="btn-close top-0 right-0"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      :""}
      {!gstCheck  && !props.id ?
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          First verify your GST No.
          <button
            type="button"
            className="btn-close top-0 right-0"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>:''
      }
      {loading && <>Loading</>}
      
      {mca.status=='completed'?
      
        <table className="table table-bordered">
          <tbody className="bg-white">
            <tr>
              <th scope="row">Name:</th>
              <td>
                {mca.status=='completed' ? (mca?.result.source_output.company_name ||
                  "N/A"):'N/A'}
              </td>
            </tr>
            <tr>
              <th scope="row">Status:</th>
              <td>
                {mca.status=='completed' ? (mca?.result.source_output.company_status || "N/A"):
                  "N/A"}
              </td>
            </tr>
            <tr>
              <th scope="row">Authorised Capital:</th>
              <td>
                {mca.status=='completed' ? (mca?.result.source_output.authorised_capital || "N/A") : "N/A"}
              </td>
            </tr>
            <tr>
              <th scope="row">Category:</th>
              <td>
                {mca.status=='completed' ? (mca?.result.source_output.company_category || "N/A" ) :"N/A"}
              </td>
            </tr>
            <tr>
              <th scope="row">Official Email ID:</th>
              <td>
                {mca.status=='completed' ? (mca?.result.source_output.email_id ||"N/A") : "N/A"}
              </td>
            </tr>
            <tr>
              <th scope="row">Reg. Address:</th>
              <td>
                {mca.status=='completed' ? (mca?.result.source_output.registered_address || "N/A" ) :"N/A"}
              </td>
            </tr>
            <tr>
              <th scope="row">Directors:</th>
              <td>
                {mca.status=='completed' ? (mca?.result.source_output.directors?.map((director) => <div key={director.din}>{director.name}</div>  ) || "N/A"):"N/A"}
              </td>
            </tr>
            <tr>
              <th scope="row">Date of Balance Sheet:</th>
              <td>
                {mca.status=='completed' ? (mca?.result.source_output.date_of_balance_sheet || "N/A") :  "N/A"}
              </td>
            </tr>
            <tr>
              <th scope="row">LLP Name:</th>
              <td>
                {mca.status=='completed' ? (mca?.result.source_output.llp_name || "N/A") :
                  "N/A"}
              </td>
            </tr>
            <tr>
              <th scope="row">No. of Partners:</th>
              <td>
                {mca.status=='completed' ? (mca?.result.source_output.no_of_partners || "N/A") :"N/A"}
              </td>
            </tr>
            <tr>
              <th scope="row">No. of Members:</th>
              <td>
                {mca.status=='completed' ? (mca?.result.source_output.number_of_members || "N/A") : "N/A"}
              </td>
            </tr>
            <tr>
              <th scope="row">Paid up capital:</th>
              <td>
                {mca.status=='completed' ? (mca?.result.source_output.paid_up_capital || "N/A"):  "N/A"}
              </td>
            </tr>
            <tr>
              <th scope="row">Related companies:</th>
              <td>
                {mca.status=='completed' ? (mca?.result.source_output.related_companies || "NA") : "N/A"}
              </td>
            </tr>
            <tr>
              <th scope="row">Reg. Number:</th>
              <td>
                {mca.status=='completed' ? (mca?.result.source_output.registration_number || "N/A") :
                  "N/A"}
              </td>
            </tr>
            <tr>
              <th scope="row">Date of last AGM:</th>
              <td>
                {mca.status=='completed' ? (mca?.result.source_output.date_of_last_agm || "N/A") :
                  "N/A"}
              </td>
            </tr>
            <tr>
              <th scope="row">LLP Pin:</th>
              <td>
                {mca.status=='completed' ? (mca?.result.source_output.llpin || "N/A") : "N/A"}
              </td>
            </tr>
            <tr>
              <th scope="row">No. of Designated Partners:</th>
              <td>
                {mca.status=='completed' ? (mca?.result.source_output.no_of_designated_partners || "N/A") :
                  "N/A"}
              </td>
            </tr>
            <tr>
              <th scope="row">ROC Code:</th>
              <td>
                {mca.status=='completed' ? (mca?.result.source_output.roc_code || "N/A") : "N/A"}
              </td>
            </tr>
            <tr>
              <th scope="row">Sub Category:</th>
              <td>
                {mca.status=='completed' ? (mca?.result.source_output.company_subcategory || "N/A") :
                  "N/A"}
              </td>
            </tr>
          </tbody>
        </table>
        :""}
    </div>
  );
}

export default MCAComponent;
