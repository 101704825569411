import React, { useEffect, useState } from "react";
import PDFfile from "../asset/DRPAgreement.pdf";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import http from "../services/httpService";
import auth from "../services/authService";
import Navbar2 from "./navbar2";
import { Link } from "react-router-dom";
import Loading from "./loading";

const Dashboard2 = () => {
  const email = auth.getUser().email;
  const [data, setData] = useState([]);
  const [filterByDate, setFilterByDate] = useState("");
  const [filterByPartyName, setFilterByPartyName] = useState("");
  const [filterByAgreementNo, setFilterByAgreementNo] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [companyName, setCompanyName]=useState('');
  const [flag, setFlag]=useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const pageSize = 10; // Number of invoices per page
  // console.log(filterByDate);

  const handlePageChange = (newPage) => {
    // Update the current page when changing pages
    setCurrentPage(newPage);
  };

  const titleCase = (input) => {
    if (typeof input == "string") {
      return input
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    } else if (typeof input == "number") {
      return input;
    } else {
      throw new Error("Input must be either a string or a number");
    }
  };

  // const generateAndDownloadPDF = async (formikValues) => {
  //   const existingPdfBytes = await fetch(PDFfile).then((res) =>
  //     res.arrayBuffer()
  //   );
  //   const pdfDoc = await PDFDocument.load(existingPdfBytes);
  //   const pages = pdfDoc.getPages();
  //   const firstPage = pages[0];
  //   const FormData1 = [
  //     { text: titleCase(String(formikValues.secondParties[0].name)), y: 575 },
  //     {
  //       text: titleCase(String(formikValues.secondParties[0].organization)),
  //       y: 535,
  //     },
  //     { text:formikValues.secondParties[0].email, y: 495 },
  //     { text: titleCase(String(formikValues.secondParties[0].phone)), y: 455 },
  //     { text: titleCase(String(formikValues.secondParties[0].govtId)), y: 415 },
  //   ];

  //   const FormData2 = [
  //     { text: titleCase(String(formikValues.secondParties[1].name)), y: 575 },
  //     {
  //       text: titleCase(String(formikValues.secondParties[1].organization)),
  //       y: 535,
  //     },
  //     { text: formikValues.secondParties[1].email, y: 495 },
  //     { text: titleCase(String(formikValues.secondParties[1].phone)), y: 455 },
  //     { text: titleCase(String(formikValues.secondParties[1].govtId)), y: 415 },
  //   ];

  //   FormData1.map(({ text, y }) => {
  //     const textString = typeof text === "number" ? text.toString() : text;
  //     firstPage.drawText(textString, {
  //       x: 110,
  //       y,
  //       size: 10,
  //       color: rgb(0, 0, 0),
  //     });
  //   });

  //   FormData2.map(({ text, y }) => {
  //     const textString = typeof text === "number" ? text.toString() : text;
  //     firstPage.drawText(textString, {
  //       x: 360,
  //       y,
  //       size: 10,
  //       color: rgb(0, 0, 0),
  //       color: rgb(0, 0, 0),
  //     });
  //   });
  //   firstPage.drawText(`${formikValues.Agreement.referenceAgreementNo}`, {
  //     x: 100,
  //     y: 678,
  //     size: 12,
  //     color: rgb(0, 0, 0),
  //   });
  //   firstPage.drawText(`Rs ${formikValues.Agreement.Fee}`, {
  //     x: 350,
  //     y: 678,
  //     size: 12,
  //     color: rgb(0, 0, 0),
  //   });
  //   firstPage.drawText(`${formikValues.Agreement.DateOfAgreement}`, {
  //     x: 470,
  //     y: 678,
  //     size: 12,
  //     color: rgb(0, 0, 0),
  //   });
  //   firstPage.drawText(
  //     `${
  //       formikValues.Agreement.seatofArbitration.city
  //         ? formikValues.Agreement.seatofArbitration.city
  //         : ""
  //     }`,
  //     {
  //       x: 450,
  //       y: 110,
  //       size: 12,
  //       color: rgb(0, 0, 0),
  //     }
  //   );
  //   firstPage.drawText(
  //     `Title : ${formikValues.Agreement.title} & Value of Agreement : Rs ${formikValues.Agreement.valueOfAgreement}`,
  //     {
  //       x: 70,
  //       y: 325,
  //       size: 12,
  //     }
  //   );
  //   let thirdParty = pdfDoc.addPage([612, 792]);
  //   if (formikValues.secondParties.length > 2) {
  //     thirdParty.drawText("Other Parties Details:", {
  //       x: 30,
  //       y: 750,
  //       size: 20,
  //     });
  //     thirdParty.drawLine({
  //       start: { x: 30, y: 740 },
  //       end: { x: 570, y: 740 },
  //       thickness: 1,
  //     });
  //     let yOffset = 740;
  //     const addTextToPage = (page, text, x, y, fontSize) => {
  //       const lineHeight = fontSize + 2;
  //       if (yOffset <= 10) {
  //         // Create a new page if remaining space is not enough
  //         page = pdfDoc.addPage([612, 792]);
  //         yOffset = 740; // Start at the top of the new page
  //       }
  //       page.drawText(text, { x, y: yOffset, size: fontSize });
  //       yOffset -= lineHeight; // Move down for the next content, considering font size
  //       return page;
  //     };
  //     formikValues.secondParties.slice(2).forEach((secondParty, index) => {
  //       yOffset -= 30; // Add some space between parties
  //       thirdParty = addTextToPage(
  //         thirdParty,
  //         `Party ${index + 3} Details:`,
  //         50,
  //         yOffset,
  //         15
  //       );
        
  //       for (const [key, value] of Object.entries(secondParty)) {
  //         if (key !== "slectionOption") {
  //           // console.log(key, 'key');
  //           const labelText = `${
  //             key !== "govtId" ? titleCase(key) : "Govt ID"
  //           } :   ${titleCase(value)}`;
  //           yOffset -= 5;
  //           thirdParty = addTextToPage(thirdParty, labelText, 70, yOffset, 10);
  //         }
  //       }

  //       yOffset -= 10;
  //       thirdParty = addTextToPage(
  //         thirdParty,
  //         `Signature : _______________`,
  //         70,
  //         yOffset,
  //         10
  //       );
  //     });
  //   }

  //   // const pdfBytes1 = await pdfDoc.saveAsBase64();
  //   const pdfBytes = await pdfDoc.save();

  //   /////////
  //   const blob = new Blob([pdfBytes], { type: "application/pdf" });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.download = "DisputeResolutionPaper.pdf";
  //   link.click();
  // };

  // const generateAndDownloadPDF = async (formikValues) => {
  //   const existingPdfBytes = await fetch(PDFfile).then((res) =>
  //     res.arrayBuffer()
  //   );
  //   const pdfDoc = await PDFDocument.load(existingPdfBytes);
  //   const pages = pdfDoc.getPages();
  //   const firstPage = pages[0];
  //   // const FormData1 = [
  //   //   { text: titleCase(String(formikValues.firstParty.name)), y: 575 },
  //   //   { text: titleCase(String(formikValues.firstParty.designation)), y: 535 },
  //   //   { text: titleCase(String(formikValues.firstParty.email)), y: 495 },
  //   //   { text: titleCase(String(formikValues.firstParty.phone)), y: 455 },
  //   //   { text: titleCase(String(formikValues.firstParty.govtId)), y: 415 },
  //   // ];
  //   const FormData1 = [
  //     { text: titleCase(String(formikValues.secondParties[0].name)), y: 575 },
  //     {
  //       text: titleCase(String(formikValues.secondParties[0].organization)),
  //       y: 535,
  //     },
  //     { text: formikValues.secondParties[0].email, y: 495 },
  //     { text: titleCase(String(formikValues.secondParties[0].phone)), y: 455 },
  //     { text: titleCase(String(formikValues.secondParties[0].govtId)), y: 415 },
  //   ];

  //   const FormData2 = [
  //     { text: titleCase(String(formikValues.secondParties[1].name)), y: 575 },
  //     {
  //       text: titleCase(String(formikValues.secondParties[1].organization)),
  //       y: 535,
  //     },
  //     { text: formikValues.secondParties[1].email, y: 495 },
  //     { text: titleCase(String(formikValues.secondParties[1].phone)), y: 455 },
  //     { text: titleCase(String(formikValues.secondParties[1].govtId)), y: 415 },
  //   ];

  //   FormData1.map(({ text, y }) => {
  //     const textString = typeof text === "number" ? text.toString() : text;
  //     firstPage.drawText((textString?textString:''), {
  //       x: 110,
  //       y,
  //       size: 10,
  //       color: rgb(0, 0, 0),
  //     });
  //   });

  //   FormData2.map(({ text, y }) => {

  //     const textString = typeof text === "number" ? text.toString() : text;
  //     firstPage.drawText((textString?textString:''), {
  //       x: 360,
  //       y,
  //       size: 10,
  //       color: rgb(0, 0, 0),
  //       color: rgb(0, 0, 0),
  //     });
  //   });

  //   firstPage.drawText(`${formikValues.Agreement.referenceAgreementNo}`, {
  //     x: 100,
  //     y: 678,
  //     size: 12,
  //     color: rgb(0, 0, 0),
  //   });
  //   firstPage.drawText(`Rs ${formikValues.Agreement.Fee}`, {
  //     x: 350,
  //     y: 678,
  //     size: 12,
  //     color: rgb(0, 0, 0),
  //   });
  //   firstPage.drawText(`${formikValues.Agreement.DateOfAgreement}`, {
  //     x: 470,
  //     y: 678,
  //     size: 12,
  //     color: rgb(0, 0, 0),
  //   });
  //   firstPage.drawText(
  //     `${
  //       formikValues.Agreement.seatofArbitration.city
  //         ? formikValues.Agreement.seatofArbitration.city
  //         : ""
  //     }`,
  //     {
  //       x: 450,
  //       y: 110,
  //       size: 12,
  //       color: rgb(0, 0, 0),
  //     }
  //   );
  //   firstPage.drawText(
  //     `Title : ${formikValues.Agreement.title} & Value : ${formikValues.Agreement.valueOfAgreement?formikValues.Agreement.valueOfAgreement:0}`,
  //     {
  //       x: 50,
  //       y: 328,
  //       size: 12,
  //     }
  //   );
  //   let thirdParty = pdfDoc.addPage([612, 792]);
  //   if (formikValues.secondParties.length > 2) {
  //     thirdParty.drawText("Other Parties Details:", {
  //       x: 30,
  //       y: 750,
  //       size: 20,
  //     });
  //     thirdParty.drawLine({
  //       start: { x: 30, y: 740 },
  //       end: { x: 570, y: 740 },
  //       thickness: 1,
  //     });
  //     let yOffset = 740;
  //     const addTextToPage = (page, text, x, y, fontSize) => {
  //       const lineHeight = fontSize + 2;
  //       if (yOffset <= 10) {
  //         // Create a new page if remaining space is not enough
  //         page = pdfDoc.addPage([612, 792]);
  //         yOffset = 740; // Start at the top of the new page
  //       }
  //       page.drawText(text, { x, y: yOffset, size: fontSize });
  //       yOffset -= lineHeight; // Move down for the next content, considering font size
  //       return page;
  //     };
  //     formikValues.secondParties.slice(2).forEach((secondParty, index) => {
  //       yOffset -= 30; // Add some space between parties
  //       thirdParty = addTextToPage(
  //         thirdParty,
  //         `Party ${index + 3} Details:`,
  //         50,
  //         yOffset,
  //         15
  //       );

  //       for (const [key, value] of Object.entries(secondParty)) {
  //         if (key !== "slectionOption") {
  //           // console.log(key, 'key');
  //           const labelText = `${
  //             key !== "govtId" ? titleCase(key) : "Govt ID"
  //           } :   ${value?titleCase(value):""}`;
  //           yOffset -= 5;
  //           thirdParty = addTextToPage(thirdParty, labelText, 70, yOffset, 10);
  //         }
  //       }

  //       yOffset -= 10;
  //       thirdParty = addTextToPage(
  //         thirdParty,
  //         `Signature : _______________`,
  //         70,
  //         yOffset,
  //         10
  //       );
  //     });
  //   }

  //   // const pdfBytes1 = await pdfDoc.saveAsBase64();
  //   const pdfBytes = await pdfDoc.save();

  //   ///////// send pdf to email
  //   // const formData = new FormData();
  //   // formData.append(
  //   //   "pdf",
  //   //   new Blob([pdfBytes], { type: "application/pdf" }),
  //   //   "generated.pdf"
  //   // );
  //   // try {
  //   //   const email = auth.getUser().email;
  //   //   formData.append("email", email);
  //   //   await http.post2("/getpdf", formData, {
  //   //     headers: {
  //   //       "Content-Type": "multipart/form-data",
  //   //     },
  //   //   });
  //   //   console.log("Email and PDF sent successfully");
  //   // } catch (error) {
  //   //   console.error("Error sending email and PDF:", error);
  //   // }

  //   /////////
  //   const blob = new Blob([pdfBytes], { type: "application/pdf" });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.download = "DisputeResolutionPaper.pdf";
  //   link.click();
  // };


  const generateAndDownloadPDF = async (formikValues) => {
    const existingPdfBytes = await fetch(PDFfile).then((res) =>
      res.arrayBuffer()
    );
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    const FormData1 = [
      { text: titleCase(String(formikValues.secondParties[0].name)), y: 575 },
      {
        text: titleCase(String(formikValues.secondParties[0].organization)),
        y: 535,
      },
      { text: formikValues.secondParties[0].email, y: 495 },
      { text: titleCase(String(formikValues.secondParties[0].phone)), y: 455 },
      { text: formikValues.secondParties[0].govtId, y: 415 },
    ];

    const FormData2 = [
      { text: titleCase(String(formikValues.secondParties[1].name)), y: 575 },
      {
        text: titleCase(String(formikValues.secondParties[1].organization)),
        y: 535,
      },
      { text: formikValues.secondParties[1].email, y: 495 },
      { text: titleCase(String(formikValues.secondParties[1].phone)), y: 455 },
      { text: formikValues.secondParties[1].govtId, y: 415 },
    ];

    FormData1.map(({ text, y }) => {
      const textString = typeof text === "number" ? text.toString() : text;
      firstPage.drawText((textString?textString:''), {
        x: 110,
        y,
        size: 10,
        color: rgb(0, 0, 0),
      });
    });

    FormData2.map(({ text, y }) => {

      const textString = typeof text === "number" ? text.toString() : text;
      firstPage.drawText((textString?textString:''), {
        x: 360,
        y,
        size: 10,
        color: rgb(0, 0, 0),
        color: rgb(0, 0, 0),
      });
    });

    firstPage.drawText(`${formikValues.Agreement.referenceAgreementNo}`, {
      x: 100,
      y: 678,
      size: 12,
      color: rgb(0, 0, 0),
    });
    firstPage.drawText(`${formikValues.Agreement.seatofArbitration.country?formikValues.Agreement.seatofArbitration.country==="India"?"Rs":"USD":"USD"} ${formikValues.Agreement.Fee}`, {
      x: 350,
      y: 678,
      size: 12,
      color: rgb(0, 0, 0),
    });
    firstPage.drawText(`${formikValues.Agreement.DateOfAgreement}`, {
      x: 470,
      y: 678,
      size: 12,
      color: rgb(0, 0, 0),
    });
    firstPage.drawText(
      `${
        formikValues.Agreement.seatofArbitration.state
          ? formikValues.Agreement.seatofArbitration.state
          : ""
      }`,
      {
        
x: 450,
        y: 110,
        size: 12,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      `Title : ${formikValues.Agreement.title} & Value : ${formikValues.Agreement.valueOfAgreement?formikValues.Agreement.valueOfAgreement:0}`,
      {
        x: 50,
        y: 328,
        size: 12,
      }
    );

    if (formikValues.secondParties.length > 2) {
      let thirdParty = pdfDoc.addPage([612, 792]);
      thirdParty.drawText("Other Parties Details:", {
        x: 30,
        y: 750,
        size: 20,
      });
      thirdParty.drawLine({
        start: { x: 30, y: 740 },
        end: { x: 570, y: 740 },
        thickness: 1,
      });
      let yOffset = 740;
      const addTextToPage = (page, text, x, y, fontSize) => {
        const lineHeight = fontSize + 2;
        if (yOffset <= 10) {
          // Create a new page if remaining space is not enough
          page = pdfDoc.addPage([612, 792]);
          yOffset = 740; // Start at the top of the new page
        }
        page.drawText(text, { x, y: yOffset, size: fontSize });
        yOffset -= lineHeight; // Move down for the next content, considering font size
        return page;
      };
      formikValues.secondParties.slice(2).forEach((secondParty, index) => {
        yOffset -= 30; // Add some space between parties
        thirdParty = addTextToPage(
          thirdParty,
          `Party ${index + 3} Details:`,
          50,
          yOffset,
          15
        );

        for (const [key, value] of Object.entries(secondParty)) {
          if (key !== "slectionOption") {
            // console.log(key, 'key');
            const labelText = `${
              key !== "govtId" ? titleCase(key) : "Govt ID"
            } :   ${value}`;
            yOffset -= 5;
            thirdParty = addTextToPage(thirdParty, labelText, 70, yOffset, 10);
          }
        }

        yOffset -= 10;
        thirdParty = addTextToPage(
          thirdParty,
          `Signature : _______________`,
          70,
          yOffset,
          10
        );
      });
    }

    const pdfBytes1 = await pdfDoc.saveAsBase64();
    const pdfBytes = await pdfDoc.save();

    ///////// send pdf to email
    const formData = new FormData();
    formData.append(
      "pdf",
      new Blob([pdfBytes], { type: "application/pdf" }),
      "generated.pdf"
    );
    try {
      const email = auth.getUser().email;
      formData.append("email", email);
      await http.post2("/getpdf", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Email and PDF sent successfully");
    } catch (error) {
      console.error("Error sending email and PDF:", error);
    }

    /////////
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "DisputeResolutionPaper.pdf";
    link.click();
  };
  const filterFunction = (data) => {
    const arr1 = data.filter((item) => {
      const filterDate = filterByDate.split("-").reverse().join("/"); // Convert "YYYY-MM-DD" to "DD/MM/YYYY"
      // Apply your filters here
      // console.log(item)
      const dateMatch =
        !filterByDate || // If filterByDate is empty, no need to filter by date
        item.Agreement.DateOfAgreement.includes(filterDate);

      const partyNameMatch =
        filterByPartyName === "" ||
        item.secondParties.some((party) =>
          party.name.toLowerCase().includes(filterByPartyName.toLowerCase())
        );

      const agreementNoMatch =
        filterByAgreementNo === "" ||
        item.Agreement.referenceAgreementNo.includes(filterByAgreementNo);
      return dateMatch && partyNameMatch && agreementNoMatch;
    });
    setFilteredData(arr1);
  };
  // const filteredData=[];

  const fetchData = async () => {
    try {
        let response = await http.post2("/getAgreement", {
          page: currentPage,
          pageSize: pageSize,         
          email: auth.getUser().email,
        });
        // console.log(response);

        const data1 = response.data;
        // console.log(data1, "data");
        if(response.status!=400){
          filterFunction(data1.data);
          setFlag(false);
        }
        
      // setData(data1.data);
      } catch (error) {
        console.log(error);
      }
  };

  const fetchData2 = async () => {
    try {
      const response = await http.post2('/getCompanyName', {
        email,
      });
      if (response.status != 400) {
        console.log(response)
        console.log(response.data, 'response.data gstComponent');
        setCompanyName(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(()=>{
    fetchData2();
  },[])
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    fetchData();
    // fetchData2();
  }, [filterByDate, filterByPartyName, filterByAgreementNo, currentPage]);

  return (
    isLoading? <Loading></Loading>:
    <div className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3">
            <div className="left-wrapper">
                <Navbar2 />
                <h2 className="admin-name">{companyName?companyName:email}</h2>
                <Link to="/dashboard">
                  <button
                    type="button"
                    className="btn btn-light btn-sm back-btn mb-3"
                  >
                    <i className="bi bi-arrow-left"></i> Back
                  </button>
                </Link>
              {/* <button
                className="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Filter
              </button> */}
              
              <div className="" id="collapseExample">
                <div className="data-filter mt-4">
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      type="date"
                      placeholder="Search by Date"
                      value={filterByDate}
                      onChange={(e) => setFilterByDate(e.target.value)}
                    />
                    <label>Search by Date</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search by Party Name"
                      value={filterByPartyName}
                      onChange={(e) => setFilterByPartyName(e.target.value)}
                    />
                    <label>Search by Party Name</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search by Agreement No"
                      value={filterByAgreementNo}
                      onChange={(e) => setFilterByAgreementNo(e.target.value)}
                    />
                    <label htmlFor="floatingInputValue">
                      Search by Agreement No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="right-wrapper ">
              <div className="org-data pb-5">
                <div className="row mt-5">
                  <div className="col-lg-12">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">S.NO</th>
                          <th scope="col">First Party</th>
                          <th scope="col">Second Party</th>
                          <th scope="col">Title</th>
                          <th scope="col">Value</th>
                          <th scope="col">Fee</th>
                          <th scope="col">Date</th>
                          <th scope="col">Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((item, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{item.secondParties[0].name}</td>
                            <td>
                              {item.secondParties
                                .slice(1)
                                .map((party, partyIndex) => (
                                  <span key={partyIndex}>
                                    {party.name}
                                    {partyIndex < item.secondParties.length - 2
                                      ? ", "
                                      : ""}
                                  </span>
                                ))}
                            </td>
                            <td>{item.Agreement.title}</td>
                            <td>{item.Agreement.valueOfAgreement}</td>
                            <td>{item.Agreement.Fee}</td>
                            <td>{item.Agreement.DateOfAgreement}</td>
                            <td>
                              <button
                                className="btn btn-primary fw-bold"
                                onClick={() => generateAndDownloadPDF(item)}
                              >
                                Download
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="row justify-content-center">
                      <div className="col-lg-5"></div>
                      <div className="col-lg-2 justify-content-center">
                        <div
                          className="btn-toolbar"
                          role="toolbar"
                          aria-label="Toolbar with button groups"
                        >
                          <div
                            className="btn-group me-1"
                            role="group"
                            aria-label="First group"
                          >
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => handlePageChange(currentPage - 1)}
                              disabled={currentPage === 1}
                            >
                              <i className="bi bi-chevron-left text-white"></i>
                            </button>
                          </div>
                          <div
                            className="btn-group me-1"
                            role="group"
                            aria-label="Second group"
                          >
                            <button
                              // type="button"
                              className="text-primary ms-2 me-2"
                            >
                              {currentPage}
                            </button>
                          </div>
                          <div
                            className="btn-group"
                            role="group"
                            aria-label="Third group"
                          >
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => handlePageChange(currentPage + 1)}
                              disabled={filteredData.length < pageSize}
                            >
                              <i className="bi bi-chevron-right text-white"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5"></div>
                    </div>
                  </div>
                </div>
              </div>
              {/*<!-- /.org-data -->*/}
            </div>
            {/*<!-- /.right-wrapper -->*/}
          </div>
          {/* <!-- /.col-lg-9 --> */}
        </div>
      </div>
    </div>
  );
};
export default Dashboard2;
