import { useEffect, useState } from "react";
import http from "../../services/httpService";
import auth from "../../services/authService";
import { Link } from "react-router-dom";
import GstComponent from "./gstComponent";
import PanComponent from "./PanComponent";
import MCAComponent from "./macComponent";
import BankVerifyComponent from "./bankInfo";

const AllAccount = (props) => {
  const email = auth.getUser().email;
  const [reload, setReload]=useState(false);
  
  const handleRemove = () => {
    console.log(props.company.company);
    props.onRemove(props.company.company);
  };

  function areAllFalsy(company) {
    try{
      return !company.gst && !company.pan && !company.mca && !company.bank;
    }
    catch{
      return false;
    }
  }

  const onReload=(flag)=>{
    setReload(flag);
  }
  useEffect(() => {
    console.log(props);
  }, []);


  return (
    <div className="row">
      <div className="col-xl">
        <div className="right-wrapper">
          <div className="card card-new account-card">
            <h2>{props.index+1}. company </h2>
            <nav>
              <div
                className="nav nav-tabs nav-justified nav-pills"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className="nav-link active"
                  id={"nav-home-tab" + props.company.company}
                  data-bs-toggle="tab"
                  data-bs-target={"#nav-home" + props.company.company}
                  type="button"
                  role="tab"
                  aria-controls={"nav-home" + props.company.company}
                  aria-selected="false"
                >
                  GST Details
                </button>
                <button
                  className="nav-link"
                  id={"nav-profile-tab" + props.company.company}
                  data-bs-toggle="tab"
                  data-bs-target={"#nav-profile" + props.company.company}
                  type="button"
                  role="tab"
                  aria-controls={"nav-profile" + props.company.company}
                  aria-selected="false"
                >
                  PAN Details
                </button>
                <button
                  className="nav-link"
                  id={"nav-contact-tab" + props.company.company}
                  data-bs-toggle="tab"
                  data-bs-target={"#nav-contact" + props.company.company}
                  type="button"
                  role="tab"
                  aria-controls={"nav-contact" + props.company.company}
                  aria-selected="false"
                >
                  MCA Company Details
                </button>
                <button
                  className="nav-link"
                  id={"nav-disabled-tab" + props.company.company}
                  data-bs-toggle="tab"
                  data-bs-target={"#nav-disabled" + props.company.company}
                  type="button"
                  role="tab"
                  aria-controls={"nav-disabled" + props.company.company}
                  aria-selected="false"
                >
                  Bank Verification Details
                </button>
              </div>
            </nav>
            <div className="tab-content pt-3" id="nav-tabContent">
              <GstComponent company={props.company} id={props.id} onReload={onReload} />
              <PanComponent company={props.company} id={props.id} reload={reload}  onReload={onReload} />
              <MCAComponent company={props.company} id={props.id} />
              <BankVerifyComponent company={props.company} id={props.id} />
            </div>
          </div>
        </div>
      </div>
      {/*<!-- right wrapper --> */}
    </div>
  );
};
export default AllAccount;
