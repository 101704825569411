import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import auth from "../services/authService";
import http from "../services/httpService.js";
import { Link } from "react-router-dom";
import Navbar2 from "./navbar2.jsx";
import Loading from "./loading.jsx";
import pivotIcon from "../asset/icons/pivot_icon.svg";
import csvIcon from "../asset/icons/csv_icon.svg";
import replyIcon from "../asset/icons/reply_icon.svg";
import graphIcon from "../asset/icons/graph_icon.svg";
import calenderIcon from "../asset/icons/calender_icon.svg";
import pdfIcon from "../asset/icons/pdf_icon.svg";
import membersIcon from "../asset/icons/members.svg";
import ConciliationNotice from "./conciliation.jsx";
import AcceptanceNotice from "./acceptance.jsx";
import ConciliationNoticePayrupik from "./ConcilationNoticePayrupik.jsx";
import ConciliationUnityBank from "./conciliationUnityBank.jsx";

// https://privatecourtdocs.s3.ap-south-1.amazonaws.com/SOC(Bharatpe)/BPL3011224002232+-+Statement+of+Claim.pdf
const Reporting = (props) => {
  let email = auth.getUser().email;
  const [usersData, setUsersData] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [loanType, setLoanType] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [companyName, setCompanyName] = useState("");
  const [cases, setCases] = useState("");
  const [whatsappcount, setWhatsappcount] = useState("");
  const [emailcount, setEmailcount] = useState("");
  const [high, setHigh] = useState(0);
  const [needupdated, setNeedupdated] = useState(0);
  const [low1, setLow1] = useState(0);
  const [low2, setLow2] = useState(0);
  const [whatsAppStatus, setWhatsAppSelect] = useState("");
  const [callStatus, setCallStatus] = useState("");
  const [emailStatus, setEmailSelect] = useState("");
  const [probability, setProbabilitySelect] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [noticeTimeStamp, setNoticeTimeStamp] = useState("");
  const [borrowerPostStatus, setBorrowePostStatus] = useState("");
  const [coBorrowerPostStatus, setCoBorrowerPostStatus] = useState("");
  const [level, setLevel] = useState("");
  const [noticeInfos, setNoticeInfo] = useState([]);
  const additionalHeaders = [
    "LoanID",
    "phone",
    "email",
    "Date",
    "Notice Type",
    "Name of Borrower",
    "WhatsApp Status",
    "Email Status",
    "Borrower postal tracking ID",
    "Borrower Postal Status",
    "Name of coBorrower",
    "Claim Amount",
    "Call Status",
    "Relief Date",
    "Call Discuss",
    "Denied",
    "SMS Status",
    "Level",
    // "Email Status",
    "coBorrower postal tracking ID",
    "coBorrower Postal Status",
    // "Borrower Address",
  ];

  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    // e.preventDefault();
    console.log(showModal);
    setShowModal(!showModal);
  };
  // Function to convert data to CSV
  const options1 = {
    accessibility: {
      enabled: true, // Set to true to include the accessibility module
    },
    chart: {
      type: "column",
      width: 300, // Set a custom width
      height: 300, // Set a custom height
    },
    title: {
      text: "Communication",
    },
    xAxis: {
      categories: ["Communication"],
    },
    yAxis: {
      title: {
        text: "Number of Communications",
      },
    },
    series: [
      {
        name: "Phone",
        data: [10],
      },
      {
        name: "WhatsApp",
        data: [whatsappcount],
      },
      {
        name: "Email",
        data: [emailcount],
      },
      {
        name: "Postal",
        data: [2],
      },
    ],
  };

  const options = {
    accessibility: {
      enabled: true, // Set to true to include the accessibility module
    },
    chart: {
      type: "pie",
      width: 300, // Set a custom width
      height: 300, // Set a custom height
    },
    title: {
      text: "Probability",
    },
    series: [
      {
        name: "Probability",
        data: [
          {
            name: "High",
            y: high,
          },
          // {
          //   name: "Mid",
          //   y: 2,
          // },
          {
            name: "Low",
            y: low1 + low2,
          },
          {
            name: "Need to Update",
            y: needupdated,
          },
        ],
      },
    ],
  };

  const handleWhatsAppChange = (value) => {
    setWhatsAppSelect(value);
    // // Reset other select values
    // setEmailSelect("");
    // setProbabilitySelect("");
  };

  const handleFilterReset = () => {
    setWhatsAppSelect("");
    // // Reset other select values
    setEmailSelect("");
    setNoticeTimeStamp("");
    setProbabilitySelect("");
    setCallStatus("");
    setBorrowePostStatus("");
    setCoBorrowerPostStatus("");
    setLevel("");
  };

  const handleEmailChange = (value) => {
    setEmailSelect(value);
  };

  const handleProbabilityChange = (value) => {
    setProbabilitySelect(value);
    // Reset other select values
    // setWhatsAppSelect("");
    // setEmailSelect("");
  };

  const downloadPDFLink = (data) => {
    console.log(data);
    if (email == "parveen.gandhi@bharatpe.com") {
      let pdfUrl = `https://privatecourtdocs.s3.ap-south-1.amazonaws.com/lrnbp0823/${data[0].loanID}+-+LRN.pdf`;
      if(data[0].timeStamp==1706339972078){
        let name=data[0].borrowerName.split(' ');
        let name2=name.join("+")
        pdfUrl=`https://privatecourtdocs.s3.ap-south-1.amazonaws.com/bp/${data[0].loanID}+-+${name2}.pdf`
      }
      const newTab = window.open(pdfUrl, "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        console.error("Failed to open PDF in a new tab.");
      }
    }
    else if (email == "icici@privatecourt.in"){
      let pdfUrl = `https://privatecourtdocs.s3.ap-south-1.amazonaws.com/iciciConciliationNotice/${data[0].loanID}+-+Notice.pdf`;
      const newTab = window.open(pdfUrl, "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        console.error("Failed to open PDF in a new tab.");
      }
    }
    else if(email=='indiaptop@privatecourt.in'){
      let pdfUrl = `https://privatecourtdocs.s3.ap-south-1.amazonaws.com/P2PConciliationJuly2024/${data[0].loanID}+-+Conciliation+Notice.pdf`;
      const newTab = window.open(pdfUrl, "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        console.error("Failed to open PDF in a new tab.");
      }
    }
    else if(email=='lendingkart@privatecourt.in'){
      let pdfUrl = `https://privatecourtdocs.s3.ap-south-1.amazonaws.com/LendingkartJuly2024/${data[0].loanID}+-+Conciliation+Notice.pdf`;
      if(data[0].timeStamp==1718961942007){
        let name=data[0].borrowerName.split(' ');
        let name2=name.join("+")
        pdfUrl=`https://privatecourtdocs.s3.ap-south-1.amazonaws.com/LendingKartJune2024/${data[0].loanID}+-+Conciliation+Notice.pdf`
      }
      const newTab = window.open(pdfUrl, "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        console.error("Failed to open PDF in a new tab.");
      }
    }
    else {
      let noticeInfo = noticeInfos.find(
        (item) => item.timeStamp == data[0].timeStamp
      );
      console.log(noticeInfo, "noticeInfo");
      if (noticeInfo.textType == "Conciliation") {
        if (email == "payrupik@privatecourt.in") {
          ConciliationNoticePayrupik(noticeInfo.letterhead, data);
        }
        else if(email=="unitybank@privatecourt.in"){
          ConciliationUnityBank(noticeInfo.letterhead, data);
        }else {
          ConciliationNotice(noticeInfo.letterhead, data);
        }
      } else if (noticeInfo.textType == "Acceptance") {
        AcceptanceNotice(noticeInfo.letterhead, data);
      }
    }
  };

  const downloadInvocationPDF = (data) => {
    // console.log(data);
    if (email == "parveen.gandhi@bharatpe.com" && data[0].timeStamp==1702362155971) {
      const pdfUrl = `https://privatecourtdocs.s3.ap-south-1.amazonaws.com/lrnbp0823/${data[0].loanID}+-+INV.pdf`;
      const newTab = window.open(pdfUrl, "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        console.error("Failed to open PDF in a new tab.");
      }
    }
    else if(email == "parveen.gandhi@bharatpe.com" && data[0].timeStamp==1706339972078){
      const pdfUrl = `https://privatecourtdocs.s3.ap-south-1.amazonaws.com/Invocation+Notices(BharatpeFeb2024)/${data[0].loanID}+-+Invocation+Letter.pdf`;
      const newTab = window.open(pdfUrl, "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        console.error("Failed to open PDF in a new tab.");
      }
    }
  };

  const downloadAcceptancePDF = (data) => {
    // console.log(data);
    if (email == "parveen.gandhi@bharatpe.com" && data[0].timeStamp==1702362155971) {
      const pdfUrl = `https://privatecourtdocs.s3.ap-south-1.amazonaws.com/bp/${data[0].loanID}+-+Acceptance+Letter.pdf`;
      const newTab = window.open(pdfUrl, "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        console.error("Failed to open PDF in a new tab.");
      }
    }
    else if(email=="parveen.gandhi@bharatpe.com" && data[0].timeStamp==1706339972078){
      const pdfUrl = `https://privatecourtdocs.s3.ap-south-1.amazonaws.com/Acceptance+Letter(BhartapeFeb2024)/${data[0].loanID}+-+Acceptance+Letter.pdf`;
      const newTab = window.open(pdfUrl, "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        console.error("Failed to open PDF in a new tab.");
      }
    }
  };

  const downloadClaimPDF = (data) => {
    // console.log(data);
    if (email == "parveen.gandhi@bharatpe.com" && data[0].timeStamp==1702362155971) {
      const pdfUrl = `https://privatecourtdocs.s3.ap-south-1.amazonaws.com/SOC(Bharatpe)/${data[0].loanID}+-+Statement+of+Claim.pdf`;
      const newTab = window.open(pdfUrl, "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        console.error("Failed to open PDF in a new tab.");
      }
    }
  };

  const handleDownloadCSV = async () => {
    let data = [];
    setIsLoading(true);
    try {
      let response = await http.post2("/getReportCsvDatas", {
        email,
        timeStamp: noticeTimeStamp,
        page: 1,
        perPage: 1000000,
        search,
        loanType,
        whatsAppStatus,
        callStatus,
        coBorrowerPostStatus,
        borrowerPostStatus,
        emailStatus,
        probability,
        level,
      });
      data = response.data.data;
    } catch (err) {
      console.log(err);
    }
    const csvHeaders = additionalHeaders.join(",");
    const csvRows = data
      .map((item) => {
        const formatValue = (value) => (value ? value : "N/A");
        const removeCommas = (numberString) => {
          return numberString.replace(/,/g, "");
        };

        const selectedValues = [
          item.loanID,
          formatValue(item.borrowerPhone),
          formatValue(item.borrowerEmail),
          formatTimestamp(item.timeStamp),
          formatValue(item.noticeStage) || "Conciliation",
          item.borrowerName,
          formatValue(item?.whatsAppStatus),
          formatValue(item.prevBrevoInfo?.Status),
          formatValue(item.borrowerTrackingID),
          // formatValue(item.probability),
          formatValue(item.borrowerPostalStatus),
          item.coBorrowerName,
          removeCommas(item.claimAmount),
          formatValue(item.telecmiCallStatus?.status),
          item.telecmiCallStatus?.reliefDate,
          item.telecmiCallStatus?.callDiscuss,
          item.telecmiCallStatus?.denied,
          formatValue(item.borrowerSMSStatus),
          item.Level,
          // formatValue(item?.prevBrevoInfo?.Status),
          formatValue(item.borrowerTrackingID),
          formatValue(item.coBorrowerPostalStatus),
          // formatValue(item.borrowerAddress),
        ];
        return selectedValues.join(",");
      })
      .join("\n");

    const csvData = `${csvHeaders}\n${csvRows}`;
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "report.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("CSV download not supported in this browser.");
    }
    setIsLoading(false);
  };

  const CalculateAllProb = async () => {
    http
      .post2(`/calculateAllProb`, { email: email })
      .then((res) => {
        setHigh(res.data.high);
        setNeedupdated(res.data.need);
        setLow1(res.data.low1);
        setLow2(res.data.low2);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const whatsappcases = async () => {
    http
      .post2(`/calculateWhatsapp`, { email: email })
      .then((res) => {
        setWhatsappcount(res.data.cases);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const emailcases = async () => {
    http
      .post2(`/calculateEmail`, { email: email })
      .then((res) => {
        setEmailcount(res.data.cases);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchData = async () => {
    try {
      let response = await http.post2("/getReportCsvDatas", {
        email,
        timeStamp: noticeTimeStamp,
        page,
        perPage,
        search,
        callStatus,
        loanType,
        coBorrowerPostStatus,
        borrowerPostStatus,
        whatsAppStatus,
        emailStatus,
        probability,
        level,
      });
      console.log(response.data, "usersData");
      setUsersData(response.data.data);
      setCases(response.data.totalCases);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchData2 = async () => {
    try {
      const response = await http.post2("/getCompanyName", {
        email,
      });
      if (response.status != 400) {
        setCompanyName(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData3 = async () => {
    try {
      const response = await http.post("/getNoticeInfo", {
        email,
      });
      if (response.status != 400) {
        setNoticeInfo(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  useEffect(() => {
    fetchData();
    fetchData2();
  }, [
    page,
    perPage,
    whatsAppStatus,
    callStatus,
    emailStatus,
    coBorrowerPostStatus,
    borrowerPostStatus,
    search,
    probability,
    noticeTimeStamp,
    level,
  ]);

  useEffect(() => {
    fetchData3();
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return isLoading ? (
    <Loading></Loading>
  ) : (
    <div className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3">
            <div className="left-wrapper">
              <h2 className="admin-name"></h2>
              <div className="left-wrapper">
                <Navbar2 />
                <h2 className="admin-name">
                  {companyName ? companyName : email}
                </h2>
                <Link to="/dashboard">
                  <button
                    type="button"
                    className="btn btn-light btn-sm back-btn mb-3"
                  >
                    <i className="bi bi-arrow-left"></i> Back
                  </button>
                </Link>
                <div className="" id="collapseExample">
                  <div className="data-filter">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        id="floatingSelect"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        aria-label="Floating label select example"
                      />
                      <label>
                        Search By Borrower Name, Loan ID, Agreement No
                      </label>
                    </div>
                    <div className="form-floating mb-3">
                      <select
                        className="form-select"
                        id="floatingSelect"
                        value={noticeTimeStamp}
                        onChange={(e) => setNoticeTimeStamp(e.target.value)}
                        aria-label="Floating label select example"
                      >
                        <option value="">All</option>
                        {noticeInfos.length > 0
                          ? noticeInfos.map((noticeItem, index) => (
                              <option key={index} value={noticeItem.timeStamp}>
                                {noticeItem.sheetName}
                              </option>
                            ))
                          : ""}
                      </select>
                      <label>Select Sheet Name</label>
                    </div>
                    {/* WhatsApp Status delivered undelivered read */}

                    <div className="form-floating mb-3">
                      <select
                        className="form-select"
                        id="floatingSelect"
                        value={whatsAppStatus}
                        onChange={(e) => handleWhatsAppChange(e.target.value)}
                        aria-label="Floating label select example"
                      >
                        <option value="">All</option>
                        <option value="read">Read</option>
                        {email != "parveen.gandhi@bharatpe.com" ? (
                          <>
                            <option value="enqueued">Enqueued</option>
                            <option value="delivered">Delivered</option>
                          </>
                        ) : (
                          <></>
                        )}
                        <option value="sent">Sent</option>
                      </select>
                      <label>Borrower WhatsApp Status</label>
                    </div>
                    {/* Email Status */}
                    <div className="form-floating mb-3">
                      <select
                        className="form-select"
                        id="floatingSelect"
                        value={emailStatus}
                        onChange={(e) => handleEmailChange(e.target.value)}
                        aria-label="Floating label select example"
                      >
                        <option value="">All</option>
                        <option value="Read">Read</option>
                        <option value="Delivered">Delivered</option>
                        <option value="Undelivered">Undelivered</option>
                      </select>
                      <label>Borrower Email Status</label>
                    </div>

                    {/* Call Status */}
                    <div className="form-floating mb-3">
                      <select
                        className="form-select"
                        id="floatingSelect"
                        value={callStatus}
                        onChange={(e) => setCallStatus(e.target.value)}
                        aria-label="Floating label select example"
                      >
                        <option value="">All</option>
                        <option value="answered">Answered</option>
                        <option value="missed">Missed</option>
                      </select>
                      <label>Borrower Call Status</label>
                    </div>

                    {/* {email == "parveen.gandhi@bharatpe.com" ? (
                      <> */}
                    <div className="form-floating mb-3">
                      <select
                        className="form-select"
                        id="floatingSelect"
                        value={borrowerPostStatus}
                        onChange={(e) => setBorrowePostStatus(e.target.value)}
                        aria-label="Floating label select example"
                      >
                        <option value="">All</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      <label>Borrower Postal Status</label>
                    </div>
                    {/* </>
                    ) : (
                      <></>
                    )} */}
                    {email == "parveen.gandhi@bharatpe.com" ? (
                      <>
                        <div className="form-floating mb-3">
                          <select
                            className="form-select"
                            id="floatingSelect"
                            value={coBorrowerPostStatus}
                            onChange={(e) =>
                              setCoBorrowerPostStatus(e.target.value)
                            }
                            aria-label="Floating label select example"
                          >
                            <option value="">All</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          <label>CoBorrower Postal Status</label>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* //////probability */}
                    <div className="form-floating mb-3">
                      <select
                        className="form-select"
                        id="floatingSelect"
                        aria-label="Floating label select example"
                        value={level}
                        onChange={(e) => {
                          setLevel(e.target.value);
                        }}
                      >
                        <option value="">All</option>
                        <option value="0">L0</option>
                        <option value="1">L1</option>
                        <option value="2">L2</option>
                        <option value="3">L3</option>
                        <option value="4">L4</option>
                      </select>
                      <label>Communication Type</label>
                    </div>
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={handleFilterReset}
                      >
                        Reset Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="right-wrapper">
              <div className="conciliation-application-form">
                <form id="regForm" action="#">
                  <div className="col-lg-12">
                    <div className="card card-new h-100">
                      {/* <h2 className="mb-3"></h2> */}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h2 class="mb-3">Reporting Status</h2>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="">
                            <h2
                              style={{ marginRight: "2px", marginTop: "5px" }}
                            >
                              <span style={{ fontSize: "12px" }}>Cases </span>(
                              {cases})
                            </h2>
                          </div>

                          {/* {email != "parveen.gandhi@bharatpe.com" ? (
                            <> */}
                          <div className="ms-2">
                            <img
                              src={pivotIcon}
                              alt=""
                              style={{
                                display: "block",
                                width: "35px",
                                height: "40px",
                                paddingBottom: "10px",
                                // margin: "0 auto",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                props.history.push(
                                  `/bkttable/${
                                    noticeTimeStamp ? noticeTimeStamp : null
                                  }`
                                );
                              }}
                            />
                          </div>
                          {/* </>
                          ) : (
                            ""
                          )} */}
                          <div className="ms-1">
                            <img
                              src={graphIcon}
                              alt=""
                              style={{
                                display: "block",
                                width: "25px",
                                height: "25px",
                                margin: "0 auto",
                                cursor: "pointer",
                              }}
                              // onClick={() => {
                              //   props.history.push(
                              //     `/visualReport/${
                              //       noticeTimeStamp
                              //         ? noticeTimeStamp
                              //         :null
                              //     }`
                              //   );
                              // }}
                            />
                          </div>
                          <div className="ms-2">
                            <img
                              src={replyIcon}
                              alt=""
                              style={{
                                display: "block",
                                width: "25px",
                                height: "25px",
                                margin: "0 auto",
                                cursor: "pointer",
                              }}
                              // onClick={() => {
                              //   props.history.push(
                              //     `/bkttable/${
                              //       noticeTimeStamp
                              //         ? noticeTimeStamp
                              //         : undefined
                              //     }`
                              //   );
                              // }}
                            />
                          </div>
                          <div className="ms-2">
                            <img
                              src={calenderIcon}
                              alt=""
                              style={{
                                display: "block",
                                width: "25px",
                                height: "25px",
                                margin: "0 auto",
                                cursor: "pointer",
                              }}
                              // onClick={() => {
                              //   props.history.push(
                              //     `/bkttable/${
                              //       noticeTimeStamp
                              //         ? noticeTimeStamp
                              //         : undefined
                              //     }`
                              //   );
                              // }}
                            />
                          </div>

                          <div className="ms-2">
                            <img
                              src={csvIcon}
                              alt=""
                              style={{
                                display: "block",
                                width: "25px",
                                height: "25px",
                                margin: "0 auto",
                                cursor: "pointer",
                              }}
                              onClick={handleDownloadCSV}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">Date</th>
                              <th scope="col">LoanID</th>
                              <th scope="col">Notice</th>
                              <th scope="col">Borrower/Company</th>
                              <th scope="col">coBorrower/Owner</th>
                              <th scope="col">Call</th>
                              <th scope="col">WhatsApp</th>
                              <th scope="col">Email</th>
                              <th scope="col">SMS</th>
                              <th scope="col">B.Post</th>
                              {email == "parveen.gandhi@bharatpe.com" ? (
                                <>
                                  <th scope="col">C.Post</th>
                                </>
                              ) : (
                                <></>
                              )}
                              {/* <th scope="col">Probability</th> */}
                              <th scope="col">
                                {email == "parveen.gandhi@bharatpe.com"
                                  ? "LRN"
                                  : "Download"}
                              </th>
                              {email == "parveen.gandhi@bharatpe.com" ? (
                                <>
                                  <th scope="col">Invocation</th>
                                </>
                              ) : (
                                <></>
                              )}
                              {email == "parveen.gandhi@bharatpe.com" ? (
                                <>
                                  <th scope="col">Acceptance</th>
                                </>
                              ) : (
                                <></>
                              )}
                              {email == "parveen.gandhi@bharatpe.com" ? (
                                <>
                                  <th scopes="col">Claim</th>
                                </>
                              ) : (
                                <></>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {usersData.length > 0
                              ? usersData.map((item, index) => {
                                  return (
                                    <tr key={item._id}>
                                      <td data-label="Date">
                                        {formatTimestamp(item.timeStamp)}
                                      </td>
                                      <td
                                        data-label="Loan ID"
                                        onClick={() => {
                                          props.history.push(
                                            `/nviewdetail/${item.loanID}`
                                          );
                                        }}
                                        style={{
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {item.loanID}
                                      </td>
                                      <td data-label="Notice">
                                        {item?.noticeStage
                                          ? item.noticeStage
                                          : "NA"}
                                      </td>
                                      <td data-label="Borrower/Company">
                                        {item.borrowerName}
                                      </td>

                                      <td data-label="coBorrower/Owner">
                                        {item.coBorrowerName}
                                      </td>
                                      <td data-label="Call">
                                        {/* {item?.borrowerCallStatus ? item?.borrowerCallStatus:"N/A"} */}
                                        {/* {(item?.borrowerCallStatus || "N/A").charAt(0).toUpperCase() + (item?.borrowerCallStatus || "N/A").slice(1)} */}
                                        {/* {(item.telecmiCallStatus.status ||"N/A").charAt(0).toUpperCase() + ( item.telecmiCallStatus.status ||"N/A" ).slice(1)} */}
                                        {(
                                          item?.telecmiCallStatus?.status ||
                                          "N/A"
                                        )
                                          .charAt(0)
                                          .toUpperCase() +
                                          (
                                            item?.telecmiCallStatus?.status ||
                                            "N/A"
                                          ).slice(1)}
                                      </td>
                                      <td data-label="WhatsApp">
                                        {/* {item?.prevWhatsAppStatus?.Status =="no WhatsApp" ? "No WhatsApp": item?.prevWhatsAppStatus?.Status} */}
                                        {item?.whatsAppStatus == "no WhatsApp"
                                          ? "N/A"
                                          : (item?.whatsAppStatus || "N/A")
                                              .charAt(0)
                                              .toUpperCase() +
                                            (
                                              item?.whatsAppStatus || "N/A"
                                            ).slice(1)}
                                        {/* {item?.whatsAppStatus} */}
                                      </td>
                                      <td data-label="Email">
                                        {/* {item.emailStatus?item.emailStatus:item.prevBrevoInfo.Status?item.prevBrevoInfo.Status:"N/A"} */}
                                        {item?.prevBrevoInfo?.Status ==
                                        "No Email Id"
                                          ? "N/A"
                                          : (
                                              item?.prevBrevoInfo?.Status ||
                                              "N/A"
                                            )
                                              .charAt(0)
                                              .toUpperCase() +
                                            (
                                              item?.prevBrevoInfo?.Status ||
                                              "N/A"
                                            ).slice(1)}
                                      </td>
                                      <th data-label="Email">{item.borrowerSMSStatus}</th>
                                      <td data-label="Borrower/Postal">
                                        {item?.borrowerPostalStatus
                                          ? item?.borrowerPostalStatus
                                          : "N/A"}
                                      </td>

                                      {email ==
                                      "parveen.gandhi@bharatpe.com" ? (
                                        <>
                                          <td data-label="CoBorrower/Postal">
                                            {item?.coBorrowerPostalStatus
                                              ? item?.coBorrowerPostalStatus
                                              : "N/A"}
                                          </td>
                                        </>
                                      ) : (
                                        <></>
                                      )}

                                      {/* <td data-label="Probability">
                                        {item.probability}
                                      </td> */}
                                      <td data-label="Download">
                                        {/* <button
                                          type="button"
                                          className="btn btn-primary btn-sm"
                                          // disabled={
                                          //   email !=
                                          //   "parveen.gandhi@bharatpe.com"
                                          // }
                                          onClick={() =>
                                            downloadPDFLink([item])
                                          }
                                        >
                                          <i className="bi bi-download text-white"></i>
                                        </button> */}

                                        <img
                                          src={pdfIcon}
                                          alt=""
                                          style={{
                                            display: "block",
                                            width: "40px",
                                            height: "40px",
                                            margin: "0 auto",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            downloadPDFLink([item])
                                          }
                                        />
                                      </td>

                                      {email ==
                                      "parveen.gandhi@bharatpe.com" ? (
                                        <>
                                          <td data-label="Download">
                                            {item.timeStamp==1702362155971?<img
                                              src={pdfIcon}
                                              alt=""
                                              style={{
                                                display: "block",
                                                width: "40px",
                                                height: "40px",
                                                margin: "0 auto",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                downloadInvocationPDF([item])
                                              }
                                            />:item.timeStamp==1706339972078?<img
                                            src={pdfIcon}
                                            alt=""
                                            style={{
                                              display: "block",
                                              width: "40px",
                                              height: "40px",
                                              margin: "0 auto",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              downloadInvocationPDF([item])
                                            }
                                          />:"N/A"}
                                          </td>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {email ==
                                      "parveen.gandhi@bharatpe.com" ? (
                                        <>
                                          <td data-label="Download">
                                            {item.timeStamp==1702362155971?<img
                                              src={pdfIcon}
                                              
                                              alt=""
                                              style={{
                                                display: "block",
                                                width: "40px",
                                                height: "40px",
                                                margin: "0 auto",
                                                cursor: "pointer",
                                              }}
                                              
                                              onClick={() =>
                                                downloadAcceptancePDF([item])
                                              }
                                            />:item.timeStamp==1706339972078?<img
                                            src={pdfIcon}
                                            
                                            alt=""
                                            style={{
                                              display: "block",
                                              width: "40px",
                                              height: "40px",
                                              margin: "0 auto",
                                              cursor: "pointer",
                                            }}
                                            
                                            onClick={() =>
                                              downloadAcceptancePDF([item])
                                            }
                                          />:"N/A"}
                                          </td>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {email ==
                                      "parveen.gandhi@bharatpe.com" ? (
                                        <>
                                          <td data-label="Claim">
                                            {item.timeStamp==1702362155971?<img
                                              src={pdfIcon}
                                              
                                              alt=""
                                              style={{
                                                display: "block",
                                                width: "40px",
                                                height: "40px",
                                                margin: "0 auto",
                                                cursor: "pointer",
                                              }}
                                              
                                              onClick={() =>
                                                downloadClaimPDF([item])
                                              }
                                            />:"N/A"}
                                          </td>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      
                                    </tr>
                                  );
                                })
                              : " "}
                          </tbody>
                        </table>
                      </div>
                      <div className="row">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                          <div className="data-pagi float-lg-end mt-lg-0 mt-2">
                            <select
                              className="form-control"
                              id="floatingSelect"
                              value={perPage ? perPage : 10}
                              onChange={(e) => setPerPage(e.target.value)}
                              aria-label="Floating label select example"
                            >
                              <option value={10}>10</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                            <div
                              className="btn-toolbar"
                              role="toolbar"
                              aria-label="Toolbar with button groups"
                            >
                              <div
                                className="btn-group me-1"
                                role="group"
                                aria-label="First group"
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => setPage(+page - 1)}
                                  disabled={page <= 1}
                                >
                                  <i className="bi bi-chevron-left text-white"></i>
                                </button>
                              </div>
                              <div
                                className="btn-group me-1"
                                role="group"
                                aria-label="Second group"
                              >
                                <button
                                  type="button"
                                  className="btn text-primary"
                                >
                                  {page}
                                </button>
                              </div>
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Third group"
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={usersData.length < +perPage}
                                  onClick={() => setPage(+page + 1)}
                                >
                                  <i className="bi bi-chevron-right text-white"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4"></div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {showModal && (
            <div className="modal show" tabindex="-1" role="dialog">
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">More Details Overview</h5>
                    <button
                      type="button"
                      className="close"
                      onClick={toggleModal}
                    >
                      <span>&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="d-flex justify-content-center">
                      <div>
                        {/* Chart will be rendered here */}
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={options}
                        />
                      </div>
                      <div>
                        {/* Second chart will be rendered here */}
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={options1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={toggleModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Reporting;
