import React from 'react'
import { useEffect, useState } from "react";
import http from '../../services/httpService';
import auth from '../../services/authService';
import { Link } from "react-router-dom";


function GstComponent(props) {
     const email = auth.getUser().email;
    const [gstno, setGstNo] = useState("");
    const [gst, setGst] = useState(props.company.gst);
    const [verify, setVerify]=useState(false);
    const [wrongNo,setWrongNo]=useState(false);
    const [loading, setLoading]=useState(false);

    const handleSubmitOfGst = async (e) => {
        e.preventDefault();
        // console.log('verify gst cliked')
        setLoading(true);
        if (gstno.length === 15) {
          try {
            const res = await http.post2(
              "/getgstVerify",{gstno, email, company:props.company.company}
            );
            // console.log(res.data);
            // console.log(res.data)
            // setGst(res.data);
            // setVerify(true);
            // setWrongNo(false);
            if(res.data.status==200){
              setGst(res.data);
              setVerify(true);
              setLoading(false)
              props.onReload(true);
            }
            else if(res.status==400){
              setWrongNo(true);
              setLoading(false)
            }
          } catch (err) {
            setWrongNo(true);
            setLoading(false);
            console.log(err);
          }
        } else {
          alert("Please enter a valid GST Number");
        }
      };

    const handlechangeforgst = (e) => {
      const input = e.target.value;
      const alphanumericInput = input.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
      setWrongNo(false);
      setGstNo(alphanumericInput);
    };
  
    
    const fetchData = async () => {
      try {
        const response = await http.post2('/companiesGstDetails', {
          email,
          id:props.id,
          company:props.company.company
        });
        // console.log(response, 'response1')
        if (response.status === 200) {
          // console.log(response.data, 'response.data gstComponent');
          setGst(response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    
    // useEffect(() => {
    //   console.log(props)
    //   fetchData();
    // }, []);
    useEffect(() => {
        // console.log(props, 'gst');
        fetchData();
    }, [ gst?.status]);
    
  return (
    <div
    className="tab-pane fade show active"
    id={"nav-home"+props.company.company}
    role="tabpanel"
    aria-labelledby={"nav-home-tab"+props.company.company}
    tabIndex="0"
  >
    { !props.id?
    
    <form onSubmit={handleSubmitOfGst}>
        <div className="row align-items-center">
          <div className="col-md-5 mb-3">
            <label className="form-label form-label-new">
              GST Number
            </label>
            <input
              type="text"
              className="form-control form-control-new"
              placeholder=""
              value={(gst?.result?.source_output?.gstin ||gstno)}
              onChange={handlechangeforgst}
              disabled={gst?.status=='completed'}
            />
          </div>
          <div className="col-md-2 mb-3">
            <button
              type="submit"
              className="btn verify btn-primary"
              disabled={gst?.status=='completed' || gstno.length!=15 || loading}
            >
              {(gst?.status=='completed') ? "Verified" : "Verify"}
            </button>
          </div>
        </div>
    </form>:''
    }
    {gst?.status=='completed' && verify && !props.id ? (
      <div
        className="alert alert-success alert-dismissible fade show"
        role="alert"
      >
        Verification Successfully Completed!
        <button
          type="button"
          className="btn-close top-0 right-0"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
    ):""} 
    {
      gst?.status!='completed' && !wrongNo && !props.id ?
      <div
        className="alert alert-danger alert-dismissible fade show"
        role="alert"
      >
        Please Verify Your GST! 
        <button
          type="button"
          className="btn-close top-0 right-0"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>:''
    }
    {
      wrongNo && !props.id ?
      <div
        className="alert alert-danger alert-dismissible fade show"
        role="alert"
      >
        Verification unsuccessfull please check GST number!
        <button
          type="button"
          className="btn-close top-0 right-0"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>:''
    }
    {loading && <>Loading....</>}
    { gst?.status=='completed'?
      <table className="table table-bordered">
        <tbody className="bg-white">
          <tr>
            <th scope="row">GSTIN Status:</th>
            <td>
            {gst.status === 'completed' ? (gst.result?.source_output?.gstin_status || "N/A") : "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Legal Name of Business:</th>
            <td>
            {gst.status === "completed" ? (gst.result?.source_output?.legal_name || "N/A") : "N/A"}

            </td>
          </tr>
          <tr>
            <th scope="row">Constitution of Business:</th>
            <td>
            {gst.status === 'completed' ? (gst.result?.source_output?.constitution_of_business || "N/A") : "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Date of Registration:</th>
            <td>
            {gst.status === "completed" ? (gst.result?.source_output?.date_of_registration || "N/A") : "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Principal Place of Business:</th>
            <td>
            {gst.status === "completed" ? (gst.result?.source_output?.principal_place_of_business_fields?.principal_place_of_business_address?.location || "N/A") : "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Nature of Business Acitivity:</th>
            <td>
            {gst.status === "completed" ? (gst.result?.source_output?.nature_of_business_activity?.join(", ") || "N/A") : "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Trade Name:</th>
            <td>
            {/* {gst.status === "completed" ? (gst.result?.source_output?.date_of_registration || "N/A") : "N/A"} */}

            {gst.status=="completed" ? (gst.result?.source_output?.trade_name || "N/A"):"N/A"}

            </td>
          </tr>
          <tr>
            <th scope="row">Taxpayer Type:</th>
            <td>
              {gst.status=="completed" ? (gst.result?.source_output?.taxpayer_type || "N/A") : "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Administrative Office:</th>
            <td>
            {gst.status === "completed" ? (gst.result?.source_output?.principal_place_of_business_fields?.principal_place_of_business_address?.dst || "N/A") : "N/A"}

            </td>
          </tr>
          <tr>
            <th scope="row">Other Office:</th>
            <td>N/A</td> {/*Not Available from Api */}
          </tr>
          <tr>
            <th scope="row">Centre Jurisdiction Code:</th>
            <td>
            {/* {gst.status === "completed" ? (gst.result?.source_output?.date_of_registration || "N/A") : "N/A"} */}

              {gst.status=="completed" ? (gst.result?.centre_jurisdiction_code || "N/A" ): "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Date of Cancellation:</th>
            <td>
              {gst.status=="completed" ? (gst.result?.source_output?.date_of_cancellation || "N/A"): "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">GSTIN:</th>
            <td>
              {gst.status=="completed" ? (gst.result?.source_output?.gstin ||"N/A"): "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Last Updated Date:</th>
            <td>
              {gst.status=="completed" ? (gst.result?.source_output?.last_updated_date || "N/A" ): "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Source:</th>
            <td>
              {gst.status=="completed" ? (gst.result?.source_output?.source || "N/A"): "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">State Jurisdiction Code:</th>
            <td>
              {gst.status=="completed" ? (gst.result?.source_output?.state_jurisdiction_code || "N/A"): "N/A"}
            </td>
          </tr>
          <tr>
            <th scope="row">Status:</th>
            <td>
              {gst.status=="completed" ? (gst.result?.source_output?.status || "N/A"): "N/A"}
            </td>
          </tr>
        </tbody>
      </table>
     :""}
  </div>
  )
}

export default GstComponent