import { useEffect, useState } from "react";
import { IoCall } from "react-icons/io5";
import { BiEditAlt, BiSave } from "react-icons/bi";
import auth from "../services/authService";
import http from "../services/httpService";
import Navbar2 from "./navbar2";
import { Link } from "react-router-dom";
import agreementsIcon from "../asset/icons/agreements.svg";
import accountIcon from "../asset/icons/account.svg";
import membersIcon from "../asset/icons/members.svg";

import "./style.css";
const LeadTable = () => {
  const name = "Pratyush";
  const email=auth.getUser().email;
  const [value, setValue] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [ListsEmail, setListEmails] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [vemail, setVemail] = useState(false);
  const [addToEmail, setAddToEmail] = useState("");
  const [LeadData, setLeadData] = useState([]);
  const [isEditing, setIsEditing] = useState(
    Array(LeadData.length).fill(false)
  );
  const [UpdatedValues, setUpdatedValues] = useState([]);
  const [filterByName, setFilterByName] = useState("");
  const [filterByAction, setFilterByAction] = useState("All");
  const [filterByInterested, setFilterByInterested] = useState("All");
  // Create a new Date object
  const currentDate = new Date();
  // Get the day, month, and year components
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are 0-based, so add 1
  const year = currentDate.getFullYear();

  // Format the date components
  const formattedDate = `${day}/${month}/${year}`;
  // Create a new Date object
  const currentTime = new Date();
  // Get the hours and minutes components
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  // Determine whether it's AM or PM
  const amOrPm = hours >= 12 ? "PM" : "AM";
  // Convert hours from 24-hour format to 12-hour format
  const formattedHours = hours % 12 || 12;
  // Format the time components
  const formattedTime = `${formattedHours}:${
    minutes < 10 ? "0" : ""
  }${minutes} ${amOrPm}`;

  //Filteration Functionality
  const filterLeadData=LeadData.filter((item)=>{
    const nameMatch =
      filterByName ==="" ||
      item.name.toLowerCase()
      .includes(filterByName.toLowerCase());
    const actionMatch =
    filterByAction==="All"||
    item.action.toLowerCase()
    .includes(filterByAction.toLocaleLowerCase());
    const interestedMatch =
    filterByInterested==="All"||
    item.interested.toLowerCase()
    .includes(filterByInterested.toLocaleLowerCase());
    return nameMatch && actionMatch && interestedMatch;   
  })

  const handleChange = (email) => {
    // Change the value when the button is clicked
    setValue(email);
  };

  const showEmailInput = () => {
    setVemail(true);
  };

  //Post Request for Adding Emails Handler
  const HandlerForAddingEmails = () => {
    if (addToEmail && value) {
      const email = value;
      http.post2("/addEmail", { email, addToEmail })
        .then((res) => {
          // setListEmails(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleEditClick = (index) => {
    const updatedEditingState = [...isEditing];
    updatedEditingState[index] = true;
    setIsEditing(updatedEditingState);
  };

  const handleSaveClick = (index) => {
    const updatedEditingState = [...isEditing];
    updatedEditingState[index] = false;
    setIsEditing(updatedEditingState);
  };
  
  const inputsubmithandler = (index, email, value) => {
    let selectedValues = [];
    selectedValues = {
      [index]: {
        schedule: value,
        email: email,
      },
    };
    http.post2("/LeadActions", { selectedValues })
      .then((res) => {
        console.log("lead action", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdatedSubmit = (email, value, events) => {
    let getUpdatedValue = [];
    if (events === "inputUpdate") {
      getUpdatedValue = {
        name: email,
        updated: `${value} ${formattedTime} - ${formattedDate}`,
      };
    } else {
      getUpdatedValue = {
        name: email,
        updated: `(${events}) Change to ${value} ${formattedTime} - ${formattedDate}`,
      };
    }
    http
      .post2("/UpdateActions", { getUpdatedValue })
      .then((res) => {
        console.log("Updates", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    window.location.reload();
  };

  const fetchData2 = async () => {
    try {
      const response = await http.post2("/getCompanyName", {
        email,
      });
      if (response.status != 400) {
        setCompanyName(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleupdateSubmit = (index, email, value, events) => {
    let selectedValues = [];
    if (events === "action") {
      selectedValues = {
        [index]: {
          action: value,
          email: email,
        },
      };
    } else if (events === "interested") {
      selectedValues = {
        [index]: {
          interested: value,
          email: email,
        },
      };
    }
    
      http.post2("/LeadActions", { selectedValues })
      .then((res) => {
        console.log("lead action", res.data);
      })
      .catch((err) => {
        console.log(err);
      });

      window.location.reload();
  };

  useEffect(() => {
    http
      .get2("/getLeadData")
      .then((res) => {
        console.log(res.data, 'getleaddata')
        setLeadData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    http
      .get2("/Updates")
      .then((res) => {
        console.log(res.data, "updates")
        setUpdatedValues(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    if (value.length > 0) {
      http
        .post2("/getEmails", { value })
        .then((res) => {
          console.log(res.data, 'getEmails')
          setListEmails(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    fetchData2();
  }, []);


  return (
    <div class="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div class="col-xl-3">
          <div className="left-wrapper">
              <Navbar2 />
              <h2 className="admin-name">
                {companyName ? companyName: email}
              </h2>
              <Link to="/dashboard">
                <button
                  type="button"
                  className="btn btn-light btn-sm back-btn mb-3"
                >
                  <i className="bi bi-arrow-left"></i> Back
                </button>
              </Link>
              {/* <h3 className="small-des">
                   You’ve an upcoming hearing in 3 hrs
                </h3>
                <button className="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    Notifications
                </button> */}
              <div className="" id="collapseExample">
                <div className="dot-bg">
                  <h2 className="d-lg-block d-none">Notifications</h2>
                  <div
                    className="alert alert-primary bg-transparent"
                    role="alert"
                  >
                    No notification
                  </div>
                  {/* <div className="alert alert-primary bg-transparent" role="alert">
                        New Member <strong>‘Akasha Seth’</strong> added to the team. 
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        You have won the dispute against Deepak Jain. See the <a href="#" className="alert-link">Final Terms.</a>
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        2 upcoming Hearings tomorrow. <a href="#" className="alert-link">See full Schedule</a>
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        <div className="d-flex justify-content-between">
                            <div>
                                12 new in last 2 days
                            </div>
                            <div>
                                <a href="#" className="alert-link">view all</a>
                            </div>
                        </div>

                    </div> */}
                                        <div className="row row-cols-2">
                      <div className="col">
                        <Link to={"/addSales"} className="white-circle">
                          <img
                            src={agreementsIcon}
                            alt=""
                            style={{ display: "block", margin: "0 auto" }}
                          />
                          <h2>New Lead</h2>
                        </Link>
                      </div>
                      <div className="col">
                        <Link to="/account" className="white-circle">
                          <img
                            src={accountIcon}
                            alt=""
                            style={{ display: "block", margin: "0 auto" }}
                          />
                          <h2>Account</h2>
                        </Link>
                      </div>
                      <div className="col">
                        <Link to="/adminUsersPage" className="white-circle">
                          <img
                            src={membersIcon}
                            alt=""
                            style={{ display: "block", margin: "0 auto" }}
                          />
                          <h2>All Users </h2>
                        </Link>
                      </div>
                      {/* <div className="col">
                        <Link to="/disputes" className="white-circle">
                          <img
                            src={disputeIcon}
                            alt=""
                            style={{ display: "block", margin: "0 auto" }}
                          />
                          <h2>Dispute</h2>
                        </Link>
                      </div>
                      <div className="col">
                        <Link to="/account" className="white-circle">
                          <img
                            src={accountIcon}
                            alt=""
                            style={{ display: "block", margin: "0 auto" }}
                          />
                          <h2>Account</h2>
                        </Link>
                      </div> */}
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-9">
            <div class="right-wrapper ">
              <div class="org-data pb-5">
                <div class="row">
                  <div class="col-lg-12">
                    <table class="table table-bordered">
                      <thead>
                        <tr className="text-center">
                          <th scope="col">S.NO</th>
                          <th scope="col">Name</th>
                          <th scope="col">Action</th>
                          <th scope="col">Call</th>
                          <th scope="col">Interested</th>
                          <th scope="col">Schedule</th>
                          <th scope="col">Updates</th>
                          {/* <th scope='col'></th> */}
                          <th scope="col">Invoice</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filterLeadData.map((item, index) => (
                          <tr key={index}>
                            <td data-label="S.NO">{index + 1}</td>
                            <td data-label="Name">{item.name}</td>
                            {/* <td data-label="Action Date">Balram Gupta</td> */}
                           
                            <td data-label="Action"><select
                                          className="form-select"
                                          aria-label="Default select example"
                                          value={item.action}
                                          onChange={(e) => {
                                            handleupdateSubmit(
                                              index,
                                              item.email,
                                              e.target.value,
                                              "action"
                                            );
                                            handleUpdatedSubmit(
                                              item.email,
                                              e.target.value,
                                              "action"
                                            );
                                          }}
                                          // onClick={(e) => setLetterhead(e.target.value)}
                                        >
                                           <option value=''>Select</option>
                                <option value="New Lead">New Lead</option>
                                <option value="Progress">Progress</option>
                                <option value="Confirmed">Confirmed</option>
                                <option value="Paid">Paid</option>
                                        </select></td>
                            <td data-label="Call">
                              <IoCall />
                            </td>
                           
                            <td data-label="Interested"><select
                                          className="form-select"
                                          aria-label="Default select example"
                                          value={item.interested}
                                          onChange={(e) => {
                                            handleupdateSubmit(
                                              index,
                                              item.email,
                                              e.target.value,
                                              "interested"
                                            );
                                            handleUpdatedSubmit(
                                              item.email,
                                              e.target.value,
                                              "interested"
                                            );
                                          }}
                                        >
                                          <option value=''>select</option>
                                <option value="Membership">Membership</option>
                                <option value="Old Disputes">Old Disputes</option>
                                <option value="Dispute Resolution Paper">Dispute Resolution Paper</option>
                                        </select></td>
                            <td data-label="Schedule">
                              {isEditing[index] ? (
                                <div style={{ display: "flex" }}>
                                  <input
                                    type="datetime-local"
                                    className="form-control"
                                    placeholder="DD/MM/YY TIME"
                                    value={selectedValue[index]?.schedule || ""}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      setSelectedValue({
                                        [index]: {
                                          schedule: value,
                                          email: item.email,
                                        },
                                      });
                                    }}
                                    autoFocus
                                  />
                                  <button
                                    type="submit"
                                    className=""
                                    style={{
                                      border: "none",
                                      background: "white",
                                      marginLeft: "2px",
                                      fontSize: "20px",
                                    }}
                                    onClick={() => {
                                      inputsubmithandler(
                                        index,
                                        item.email,
                                        selectedValue[index]?.schedule
                                      );
                                      handleSaveClick(index);
                                    }}
                                  >
                                    <BiSave />
                                  </button>
                                </div>
                              ) : (
                                <div className="pt-2">
                                  {item.schedule}
                                  <button
                                    style={{
                                      border: "none",
                                      background: "white",
                                      marginLeft: "2px",
                                    }}
                                    onClick={() => {
                                      handleEditClick(index);
                                    }}
                                  >
                                    <BiEditAlt />
                                  </button>
                                </div>
                              )}
                            </td>
                            <td data-label="Updates">
                              <button
                                type="button"
                                class="btn btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#updates-1"
                                onClick={() => {
                                  handleChange(item.email);
                                }}
                              >
                                <i class="bi bi-eye text-white"></i>
                              </button>
                            </td>
                            <td data-label="Invoice">Generate Invoice</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/*<!-- /.org-data -->*/}
            </div>
            {/*<!-- /.right-wrapper -->*/}
          </div>
          {/* <!-- /.col-lg-9 --> */}
        </div>
        <div
          class="modal fade"
          id="updates-1"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                  Updates
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <form id="regForm" action="#" class="mt-0">
                  <div class="row">
                    <div class="col-lg-12 text-end mb-3 mt-2">
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-sm"
                        onClick={() => {
                          {
                            showEmailInput();
                          }
                        }}
                      >
                        <i class="bi bi-plus-lg text-primary"></i> Add
                      </button>
                    </div>
                    {vemail ? (
                      <div className="d-flex container">
                        <input
                          className="form-control"
                          placeholder="Enter Email"
                          style={{ width: "20rem" }}
                          value={addToEmail}
                          onChange={(e) => {
                            setAddToEmail(e.target.value);
                          }}
                        />
                        <button
                          className="btn btn-primary mx-2"
                          onClick={() => {
                            setVemail(false);
                            HandlerForAddingEmails();
                          }}
                        >
                          +
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-6 mb-3">
                      <label className="form-label form-label-new">
                        Send Email
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        {ListsEmail && ListsEmail.length > 0 ? (
                          ListsEmail.map((item, index) => (
                            <option key={index}>{item}</option>
                          ))
                        ) : (
                          <option>No emails available</option>
                        )}
                      </select>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label className="form-label form-label-new">
                        Email Type
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Test 1</option>
                        <option value="1">Test 2</option>
                        <option value="2">Test 3</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label class="form-label form-label-new">Add Updates</label>
                    <div className="d-flex">
                      <input
                        type="text"
                        class="form-control form-control-new"
                        onChange={(e) => {
                          const value = e.target.value;
                          setSelectedValue({
                            [0]: {
                              updated: value,
                              email: name,
                            },
                          });
                        }}
                      />
                      <button
                        type="submit"
                        className="btn btn-primary mx-4"
                        onClick={() =>
                          handleUpdatedSubmit(
                            name,
                            selectedValue[0]?.updated,
                            "inputUpdate"
                          )
                        }
                      >
                        ADD
                      </button>
                    </div>
                  </div>
                  <div class="row mt-2 mb-2">
                    <div class="col-md-12">
                      {(() => {
                        const reversedValues = UpdatedValues.slice().reverse();
                        const elements = [];
                        for (let i = 0; i < reversedValues.length; i++) {
                          const item = reversedValues[i];
                          elements.push(
                            <p key={i}>
                              <strong>{item.updates[0].name}:</strong>{" "}
                              {item.updates[0].updated}
                            </p>
                          );
                        }
                        return elements;
                      })()}
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LeadTable;
