import Navbar2 from "./navbar2";
import { Link } from "react-router-dom";
import auth from "../services/authService";
import http from "../services/httpService";
import { useState, useEffect } from "react";
import Loading from "./loading";

const AddMembers = () => {
  const email = auth.getUser().email;
  const [companyName, setCompanyName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [emailID, setEmailID] = useState("");
  const [userType, setUserType] = useState("");
  const [otherUsers, setOtherUsers]=useState({});

  const handleSaveAccess = async (e) => {
    e.preventDefault();
    try {
      const response = await http.post2("/addAccessEmails", {
        email,
        emailID,
        userType,
      });
      if (response.status != 400) {
        alert("User Saved");
        window.location.reload();
        // console.log("saved");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEmailChange = (e) => {
    const input = e.target.value;
    const lowercaseInput = input.replace(/[A-Z]/g, (char) =>
      char.toLowerCase()
    );
    setEmailID(lowercaseInput);
  };

  const fetchData2 = async () => {
    try {
      const response = await http.post2("/getCompanyName", {
        email,
      });
      if (response.status != 400) {
        setCompanyName(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData3 = async () => {
    try {
      const response = await http.post2("/allAddedUsers", {email});
      if (response.status != 400  || response.status!=500) {
        console.log(response.data);
        setOtherUsers(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData2();
    fetchData3();
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 50);
    return () => clearTimeout(timer);
  }, []);

  return isLoading ? (
    <Loading></Loading>
  ) : (
    <div className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3">
            <div className="left-wrapper">
              <Navbar2 />
              <h2 className="admin-name">
                {companyName ? companyName : email}
              </h2>
              <Link to="/dashboard">
                <button
                  type="button"
                  className="btn btn-light btn-sm back-btn mb-3"
                >
                  <i className="bi bi-arrow-left"></i> Back
                </button>
              </Link>
              {/* <h3 className="small-des">
                   You’ve an upcoming hearing in 3 hrs
                </h3>
                <button className="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    Notifications
                </button> */}
              <div className="" id="collapseExample">
                <div className="dot-bg">
                <h2 className="d-lg-block d-none">Notifications</h2>
                <div className="alert alert-primary bg-transparent" role="alert">
                  LRN successfully <strong>generated</strong> and <strong>posted</strong>!
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="right-wrapper">
              <div className="conciliation-application-form">
                <form id="regForm" action="#">
                  <div className="col-lg-12">
                    <div className="card card-new h-100">
                      <h2 className="mb-3">Add Members</h2>
                      <div className="row mb-3">
                        <div className="col-lg-4 mb-3">
                          <label className="form-label form-label-new">
                            Email ID:
                          </label>
                          <input
                            // type="email"
                            // name="email"
                            value={emailID}
                            onChange={(e) => handleEmailChange(e)}
                            className="form-control form-control-new"
                            placeholder="Enter Email"
                          />
                        </div>
                        {/* <div className="col-lg-4 mb-3">
                          <label className="form-label form-label-new">
                            User Type
                          </label>
                          <select
                            className="form-select"
                            value={userType}
                            onChange={(e) => setUserType(e.target.value)}
                            // aria-label="Default select example"
                          >
                            <option value="">Select Type </option>
                            <option value="NBFCBankTeam">NBFC Bank Team</option>
                            <option value="NBFCBankAdmin">NBFC Bank Admin</option>
                          </select>
                        </div> */}
                        <div className="col-lg-4 mb-3">
                          {/* <label className="form-label form-label-new">
                          </label> */}
                          <div className="mt-4 pt-2">
                            <button
                            //   type="submit"
                              className="btn btn-primary"
                              // disabled={!userType || !emailID}
                              disabled={!emailID}
                              onClick={handleSaveAccess}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                      <hr className="mt-2 mb-4"/>
                      <h2 className="mb-3">All Members</h2>
                      <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {otherUsers.length>0 ? (
                              <>
                                {otherUsers.map((item, index) => {
                                  return (
                                    <tr>
                                      <td data-label="Action Date">{item.emailID}</td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                    </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddMembers;
