// import React, {Component} from "react";

// class UnderDevelopment extends Component{
//     // componentDidMount(){
//     //     auth.UnderDevelopment();
//     //     this.props.history.push("/login");
//     // }
//     render(){
//         return<>
//                 {/* <br></br><br></br>
//                 <h1>Still Developing....</h1> */}
//                 <video class="bg-video" playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop"><source src="assets/mp4/bg.mp4" type="video/mp4" /></video>
//         {/* <!-- Masthead--> */}
//         <div class="masthead">
//             <div class="masthead-content">
//                 <div class="container-fluid px-4 px-lg-0">
//                     <h1 class="fst-italic lh-1 mb-4">Our Website is Coming Soon</h1>
//                     <p class="mb-5">We're working hard to finish the development of this site.</p>
//                 </div>
//             </div>
//             <img src="assets/img/private-court.png" alt=""/>
//         </div>
//             </>
//     }
// }

// export default UnderDevelopment;

// import React, { Component } from "react";
// import privatecourtLogo from "../asset/img/private-court.png";
// import videoFile from '../asset/mp4/bg.mp4';

// class UnderDevelopment extends Component {
//     componentDidMount() {
//         // Assuming auth.UnderDevelopment() is a function you want to call on component mount
//         // auth.UnderDevelopment();
//         // Assuming you want to redirect to "/login" after calling auth.UnderDevelopment()
//         // this.props.history.push("/login");
//     }

//     render() {
//         return (
//             <>
//                 <video className="bg-video" playsInline autoPlay muted loop>
//                     <source src={videoFile} type="video/mp4" />
//                 </video>
//                 <div className="masthead">
//                     <div className="masthead-content">
//                         <div className="container-fluid px-4 px-lg-0">
//                             <h1 className="fst-italic lh-1 mb-4">Our Website is Coming Soon</h1>
//                             <p className="mb-5">We're working hard to finish the development of this site.</p>
//                         </div>
//                     </div>
//                     <img src={privatecourtLogo} alt="" />
//                 </div>
//             </>
//         );
//     }
// }

// export default UnderDevelopment;

import React, { useEffect } from "react";
import privatecourtLogo from "../asset/img/private-court.png";
import videoFile from "../asset/mp4/bg.mp4";
import { Link } from "react-router-dom";
// import './style3.css'

const UnderDevelopment = (props) => {
    useEffect(() => {
        props.onRender(false);
      }, [props.underD]);

  return (
    <div>
      <video className="bg-video" playsInline autoPlay muted loop>
        <source src={videoFile} type="video/mp4" />
      </video>
      <div className="masthead">
        <div className="masthead-content">
          <div className="container-fluid px-4 px-lg-0">
            <h1 className="fst-italic lh-1 mb-4">Our application is under process</h1>
            <p className="mb-5">
              We're working hard to finish the development of application.
            </p>
            <div className="text-center">
                <Link to="/dashboard" className="">
                    <button
                    onClick={()=>props.onRender(true)}
                    type="button"
                    className="btn btn-light btn-sm back-btn mb-3"
                    >
                    <i className="bi bi-arrow-left"></i>Go To Dashboard
                    </button>
                </Link>
            </div>

          </div>
        </div>
        <img src={privatecourtLogo} alt="" />
      </div>
    </div>
  );
};

export default UnderDevelopment;