import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import auth from "../services/authService";
import http from "../services/httpService";
import Navbar2 from "./navbar2";
import Loading from "./loading";
import converter from 'number-to-words'
import { buildTextForPdf,convertToInteger, buildTextForPdfAddress, ConciliationPayrupikbuildTextForPdf, generatedConcilationCaseID, LRNbuildTextForPdf, AcceptancebuildTextForPdf,InvocationTextForPdf, buildTextForPdf3, ArbitrationbuildTextForPdf, getFormattedDate, getTimestamp, calculateDateAfter15Days, generateRandomInvoiceID } from "../logic/DownloadAll";
import { PDFDocument, StandardFonts } from 'pdf-lib';
import axios from 'axios';
import fontkit from '@pdf-lib/fontkit';
import Thane from '../files/LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf';
import Delhi from '../files/LETTERHEAD ALL ADDRESs Delhi Conciliation2.pdf';
import Acceptance_Thane from '../files/Acceptance_Thane.pdf';
import Acceptance_Gurugram from '../files/Letterhead Gurgaon.pdf';
import HearingNotice_Thane  from  "../files/HearingNoticeThane.pdf";
import Joylin from "../files/letterhead.pdf";
import Joylin2 from '../files/prernaInvocation.pdf';
import Joylin3 from '../files/prernaInvocation.pdf';
import Joylin4 from '../files/prernaInvocation.pdf';
import Joylin5 from "../files/prernaInvocation5.pdf"
import getImage from "../files/Vipin Sharma Mumbai Stamp and sign.png";
import getSign from "../files/vishalSign.png";
import getSign2 from "../files/Mahadev signature.png";
import getVTStamp from "../files/Vishal Sign and Stamp Arbitrator.png";
import newDelhi from "../files/LETTERHEAD ALL ADDRESs Delhi New.pdf";
import fontPathRegular from '../files/Poppins-Regular.ttf';
import fontPathMedium from '../files/Poppins-Medium.ttf';

const NBFCNotice = (props) => {
  const email = auth.getUser().email;
  // const [timeStamps, setTimeStamps] = useState([]);
  const [timeStamp, setTimeStamp] = useState("");
  const [comType, setComType] = useState("");
  const [letterhead, setLetterhead] = useState("");
  const [strategy, setStrategy] = useState("");
  const [noticeInfos, setNoticeInfo] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [startIndex, setStartIndex]=useState(0);
  const [indexTimeStamp, setIndexTimeStamp]=useState();

  const convertMillisecondToDate = (timeStamp) => {
    const date = new Date(timeStamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // const fetchData = async () => {
  //   try {
  //     const response = await http.post2("/sendTimeStamps", {
  //       email,
  //     });
  //     if (response.status != 400) {
  //       setTimeStamps(response.data.data);
  //       console.log(response.data.data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const fetchData3 = async () => {
    try {
      const response = await http.post("/getNoticeInfo", {
        email,
        page,
        perPage,
      });
      if (response.status != 400) {
        setNoticeInfo(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData2 = async () => {
    try {
      const response = await http.post2("/getCompanyName", {
        email,
      });
      if (response.status != 400) {
        setCompanyName(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleNoticeDownload=async(timeStamp, letterHead)=>{
     http
      .post2("/downloadNotices", { email, timeStamp, letterHead})
      .then((res) =>{
        // console.log(res.data);
        const data = new Uint8Array(Object.values(res.data.data));
        console.log(data);
        const blob = new Blob([data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "Generated Notices.pdf";
        link.click();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
      setIsLoading(false);
  }

  const handleNotice = async (event) => {
    event.preventDefault();
    const now = new Date();
    const timeStamp2 = now.getTime();
    setIsLoading(true);
    try {
      const response = await http.post("/setNoticeInfo", {
        data: {
          email,
          timeStamp,
          letterhead,
          strategy,
          comType,
          currTimeStamp: timeStamp2,
          approval:'No',
          status:'',
          companyName
        },
      });
      if (response.status != 400) {
        setIsLoading(false);
        console.log(response.data);
      }
    } catch (error) {
        setIsLoading(false);
      console.log(error);
    }

    // http
    //   .post2("/sendNotice", { email, timeStamp, currTimeStamp: timeStamp2})
    //   .then((res) => {
    //     const data = new Uint8Array(Object.values(res.data.data));
    //     console.log(data);
    //     const blob = new Blob([data], { type: "application/pdf" });
    //     const url = URL.createObjectURL(blob);
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.download = "Generated Notices.pdf";
    //     link.click();
    //     setIsLoading(false);
    //   })
    //   .catch((err) => {
    //     setIsLoading(false);
    //     console.log(err);
    //   });
    //   setIsLoading(false);

    //   http
    //   .post2("/sendNoticeEmail", { email, timeStamp })
    //   .then((res) => {
    //     // const data = new Uint8Array(Object.values(res.data.data));
    //     console.log(res.data);
    //     // const blob = new Blob([data], { type: "application/pdf" });
    //     // const url = URL.createObjectURL(blob);
    //     // const link = document.createElement("a");
    //     // link.href = url;
    //     // link.download = "Generated Notices.pdf";
    //     // link.click();
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

  };

  const ConciliationNotice = async (email, timeStamp, letterHead) => {
    timeStamp = +timeStamp;
    console.log(email, letterHead, timeStamp, "ConciliationNotice" );
    setIsLoading(true);
    let pdfFilePath;
    if (letterHead === "Joylin Rego, Mumbai") {
    const letterheadResponse = await axios.get('letterhead.pdf');
        pdfFilePath = await letterheadResponse.data;
    } else {
      if (letterHead === "Gurugaon") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Bangalore") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Ambernath") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Hyderabad") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Thane") {
        pdfFilePath= await fetch(Thane).then((res) => res.arrayBuffer());
      } else if (letterHead === "Delhi") {
        console.log(letterHead, "1111")
        pdfFilePath= await fetch(Delhi).then((res) => res.arrayBuffer());
        console.log(letterHead, "11112")
      } else if (letterHead === "New Delhi") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      }
    }

    // let caseID=await axios
    // .get('https://server3-pab9.onrender.com/getConcilationCaseID?type=ConcilationCaseID')
    // .then((res)=>+res.data)
    // .catch((err)=>{
    //   console.log(err);
    //   return +1;
    // });
    let caseID=1;

    let data = await axios
      .post("https://server1-jlfh.onrender.com/getCsvByTimestamp", {
        email,
        timeStamp,
      })
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        return [];
      });
      console.log(data.length);
      const combinedPdf = await PDFDocument.create();
      combinedPdf.registerFontkit(fontkit);
      
      // len=len>101?101:len;
      let j=0;
      // caseID=+caseID;
      data=data.filter((item)=>item.borrowerAddress);
      console.log(data.length);

      let endIndex=+startIndex+1000;
      if(endIndex>data.length) endIndex=data.length;
      console.log(endIndex,"endIndex");

    for (let i = startIndex; i <endIndex; i++){
      console.log(i);
      // let caseIDStr = generatedConcilationCaseID(caseID);
      // const scheduleDate = calculateDateAfter15Days();
      const pdfDoc = await PDFDocument.load(pdfFilePath);
      pdfDoc.registerFontkit(fontkit);
      const [fontDataRegular, fontDataMedium] = await Promise.all([
        fetch(fontPathRegular).then(response => response.arrayBuffer()),
        fetch(fontPathMedium).then(response => response.arrayBuffer())
      ]);
      const font = await combinedPdf.embedFont(fontDataRegular, { subset: true });
      const font1 = await combinedPdf.embedFont(fontDataMedium, { subset: true });
      const boldFont = await combinedPdf.embedFont(StandardFonts.TimesRomanBold);
      const boldFont1 = await combinedPdf.embedFont(StandardFonts.TimesRoman);
      const [newPage] = await combinedPdf.copyPages(pdfDoc, [0]);
      combinedPdf.addPage(newPage);
      let pageindex = j * 2;
      const firstPage = combinedPdf.getPages()[pageindex];
      const [SecondnewPage] = await combinedPdf.copyPages(pdfDoc, [1]);
      combinedPdf.addPage(SecondnewPage);
      let pageindex1 = j * 2 + 1;
      const secondPage = combinedPdf.getPages()[pageindex1];
      const word4 =`Subject: Letter of invitation as per the provisions of Section 62 of the Arbitration and Conciliation Act, 1996, for the resolution/conciliation of disputes arising out of your outstanding loan Rs. ${convertToInteger(data[i].claimAmount)}.`;
      const word6 = `The Claimant for ${data[i].claimantCompany}, having an address at ${data[i].claimantCompanyAddress}, is hereby directed to give the following notice to you: -`;
      const word7 = `You have obtained a loan from the Claimant vide Loan Agreement No.${data[i].loanID}. As per the terms and conditions of the loan granted to you by the Claimant, the terms of, which were specifically and expressly agreed upon between you and the Claimant, you were bound to repay the loan amount together with interest and other expenses. However, you have failed to comply with the financial terms and payments are due on your account. The total amount of Rs. ${convertToInteger(data[i].claimAmount)}/- as on ${data[i].asOnDate?data[i].asOnDate:"6th January 2024"} is outstanding in your loan account. Several demands have been made to you by the Claimant regarding the amount due, apart from other amounts/charges that you have avoided paying. Therefore, the Claimant has initiated/started to initiate appropriate legal proceedings as per the legal process to recover the above principal, as well as interest and other costs/fees.`;
      const word8 = `However, before taking any legal action, the Claimant has expressed its intention to explore the possibility of settlement through the Conciliation Process and, accordingly, intends to refer the dispute to PrivateCourt (Arbitration & Conciliation Organization) (CONTACT NO +91${data[i].privateCourtPhone?data[i].privateCourtPhone:"8035731373"}) which will work towards the amicable settlement of the dispute.`;
      const word9 = `You are hereby directed to attend the physical hearing scheduled on ${data[i].meetingDate}, at PrivateCourt: ${data[i].privateCourtAddress?data[i].privateCourtAddress:"208, 2nd Floor, Globe Business Park, Ambernath West, Thane - 421505"}, between ${data[i].meetingTime?data[i].meetingTime:"10:00 AM to 05:00 PM"}. For further information please contact +91${data[i].privateCourtPhone?data[i].privateCourtPhone:"8035731373"} or e-mail at legal@privatecourt.in `;
      const hindiWord1 =`विषय: आपके बकाया ऋण रु. ${convertToInteger(data[i].claimAmount)} से उत्पन्न विवादों के समाधान के लिए मध्यस्थता और सुलह अधिनियम, 1996 की धारा 62 के प्रावधानों के अनुसार निमंत्रण पत्र।`;
      const hindiWord2 = `दावेदार ${data[i].claimantCompany}, जिसका पता ${data[i].claimantCompanyAddress} है, को निम्नलिखित देने का निर्देश दिया जाता है। आपके लिए सूचना:-`;
      const hindiWord3 = `आपने ऋण अनुबंध संख्या ${data[i].loanID} के माध्यम से दावेदार से ऋण प्राप्त किया है। शर्तों के अनुसार और दावेदार द्वारा आपको दिए गए ऋण की शर्तें, जिनकी शर्तों पर विशेष रूप से और स्पष्ट रूप से सहमति व्यक्त की गई थी आपके और दावेदार के बीच, आप ब्याज और अन्य सहित ऋण राशि चुकाने के लिए बाध्य थे खर्चे। हालाँकि, आप वित्तीय शर्तों का पालन करने में विफल रहे हैं और भुगतान आपके खाते पर बकाया है। कुल राशि रु. ${convertToInteger(data[i].claimAmount)}/- आपके ऋण खाते में ${data[i].asOnDate?data[i].asOnDate:"6th जनवरी 2024"} तक बकाया है। अनेक दावेदार द्वारा आपसे अन्य राशियों/प्रभारों के अलावा देय राशि के संबंध में मांग की गई है कि आपने भुगतान करने से परहेज किया है। इसलिए, दावेदार ने उचित कानूनी कार्रवाई शुरू कर दी है उपरोक्त मूलधन, साथ ही ब्याज और अन्य लागत/शुल्क की वसूली के लिए कानूनी प्रक्रिया के अनुसार कार्यवाही।`;
      const hindiWord4 = `हालाँकि, कोई भी कानूनी कार्रवाई करने से पहले, दावेदार ने इसकी संभावना तलाशने का इरादा व्यक्त किया है सुलह प्रक्रिया के माध्यम से निपटान और, तदनुसार, विवाद को प्राइवेटकोर्ट में भेजने का इरादा है (मध्यस्थता और सुलह संगठन) (संपर्क संख्या +91${data[i].privateCourtPhone?data[i].privateCourtPhone:"8035731373"}) जो सौहार्दपूर्ण की दिशा में काम करेगा विवाद का निपटारा.`;
      const hindiWord5 = `आपको ${data[i].meetingDate} को प्राइवेटकोर्ट: ${data[i].privateCourtAddress?data[i].privateCourtAddress:"208, दूसरी मंजिल, ग्लोब बिजनेस पार्क, अंबरनाथ पश्चिम, ठाणे - 421505"}, में ${data[i].meetingTime?data[i].meetingTime:"सुबह 10:00 बजे से शाम 05:00"} बजे के बीच निर्धारित शारीरिक सुनवाई में भाग लेने के लिए निर्देशित किया जाता है। के लिए अधिक जानकारी के लिए कृपया +91${data[i].privateCourtPhone?data[i].privateCourtPhone:"8035731373"} पर संपर्क करें या legal@privatecourt.in पर ई-मेल करें।`;

      buildTextForPdf(`Ref No. ${data[i].caseID}`, boldFont, firstPage, 36, 685);
      buildTextForPdf("Date: " + data[i].noticeDate, boldFont, firstPage,420,660);
      buildTextForPdf(`To,`, boldFont, firstPage, 36, 660);
      let header=buildTextForPdf(`${data[i].borrowerName?`1. ${data[i].borrowerName} (Borrower)`:""}`, boldFont, firstPage, 36, 635);
      if(data[i].coBorrowerName){
        header=buildTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName} (Co-Borrower)`:""}`, boldFont, firstPage, 36, header.currentY-15);
      }
      if(data[i].borrowerAddress){
        header=buildTextForPdfAddress(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      
      if(data[i].borrowerEmail){
        header=buildTextForPdf(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      if(data[i].borrowerPhone){
        header=buildTextForPdf(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont, firstPage, 36, header.currentY-15);
      }

      // if(data[i].coBorrowerName){
      //   header=buildTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName} (Co-Borrower)`:""}`, boldFont, firstPage, 36, header.currentY-20);
      // }
      // if(data[i].coBorrowerAddress){
      //   header=buildTextForPdf(`${data[i].coBorrowerAddress?`Having address at: ${data[i].coBorrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }
      // else if(data[i].coBorrowerName){
      //   header=buildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }
      // if(data[i].coBorrowerEmail){
      //   header=buildTextForPdf(`${data[i].coBorrowerEmail?`E-mail: ${data[i].coBorrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }
      // if(data[i].phone){
      //   header=buildTextForPdf(`${data[i].phone?`Contact: ${data[i].phone}`:""}`, boldFont, firstPage, 36, header.currentY-15);
      // }
      // if(data[i]['Business name']){
      //   header=buildTextForPdf(`${data[i]['Business name']?`3. ${data[i]['Business name']}`:""}`, boldFont, firstPage, 36, header.currentY-20);
      // }
      // if(data[i]['borrower Business Address']){
      //   header=buildTextForPdf(`${data[i]['borrower Business Address']?`Having address at: ${data[i]['borrower Business Address']}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }
      // else if(data[i]['Business name']){
      //   header=buildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }

      // let header = buildTextForPdf(`${data[i].borrowerName}`,boldFont, firstPage, 36,650);
      // header = buildTextForPdf(word1, boldFont1, firstPage, 36, header.currentY - 15);
      // header = buildTextForPdf(word2, boldFont, firstPage, 36, header.currentY - 15);
      // header = buildTextForPdf( `${data[i].coBorrowerName}`, boldFont, firstPage, 36, header.currentY - 15);
      // header = buildTextForPdf(word31, boldFont1, firstPage, 36, header.currentY - 15 );
      // header = buildTextForPdf(word3, boldFont, firstPage, 36, header.currentY - 15 );

      header = buildTextForPdf(
        word4,
        boldFont,
        firstPage,
        36,
        header.currentY - 25
      );

      header = buildTextForPdf(
        "Respected Sir/Madam,",
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );

      header = buildTextForPdf(
        word6,
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );

      header = buildTextForPdf(
        word7,
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );

      header = buildTextForPdf(
        word8,
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );

      header = buildTextForPdf(
        word9,
        boldFont,
        firstPage,
        36,
        header.currentY - 25
      );

      header=buildTextForPdf(
        "Adv. Vishal Tiwari",
        boldFont,
        firstPage,
        430,
        77
      );

      header=buildTextForPdf(
        "PrivateCourt Registry",
        boldFont,
        firstPage,
        422,
        62
      );

      buildTextForPdf(
        "Date: " + data[i].noticeDate,
        boldFont,
        secondPage,
        420,
        660
      );

      buildTextForPdf(
        `संदर्भ संक्या: ${data[i].caseID}`,
        font,
        secondPage,
        36,
        685
      );

      buildTextForPdf(`To,`, boldFont, secondPage, 36, 660);
      let hindiHeader=buildTextForPdf(`${data[i].borrowerName?`1. ${data[i].borrowerName} (Borrower)`:""}`, boldFont, secondPage, 36, 635);
      if(data[i].coBorrowerName){ hindiHeader=buildTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName} (Co-Borrower)`:""}`, boldFont, secondPage, 36, hindiHeader.currentY-15);}
      hindiHeader=buildTextForPdfAddress(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      // hindiHeader=buildTextForPdf(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      // hindiHeader=buildTextForPdf(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont, secondPage, 36, hindiHeader.currentY-15);
      if(data[i].borrowerEmail){
        hindiHeader=buildTextForPdf(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      }
      if(data[i].borrowerPhone){
        hindiHeader=buildTextForPdf(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont, secondPage, 36, hindiHeader.currentY-15);
      }
      // if(data[i].coBorrowerName){
      //   hindiHeader=buildTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName} (Co-Borrower)`:""}`, boldFont, secondPage, 36, hindiHeader.currentY-20);
      // }

      // if(data[i].coBorrowerAddress){
      //   hindiHeader=buildTextForPdf(`${data[i].coBorrowerAddress?`Having address at: ${data[i].coBorrowerAddress}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      // }

      // else if(data[i].coBorrowerName){
      //   hindiHeader=buildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      // }

      // if(data[i].coBorrowerEmail){
      //   hindiHeader=buildTextForPdf(`${data[i].coBorrowerEmail?`E-mail: ${data[i].coBorrowerEmail}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      // }

      // if(data[i].phone){
      //   hindiHeader=buildTextForPdf(`${data[i].phone?`Contact: ${data[i].phone}`:""}`, boldFont, secondPage, 36, hindiHeader.currentY-15);
      // }

      // if(data[i]['Business name']){
      //   hindiHeader=buildTextForPdf(`${data[i]['Business name']?`3. ${data[i]['Business name']}`:""}`, boldFont, secondPage, 36, hindiHeader.currentY-20);
      // }

      // if(data[i]['borrower Business Address']){
      //   hindiHeader=buildTextForPdf(`${data[i]['borrower Business Address']?`Having address at: ${data[i]['borrower Business Address']}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      // }

      // else if(data[i]['Business name']){
      //   hindiHeader=buildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, secondPage, 36, header.currentY-15);
      // }

      hindiHeader = buildTextForPdf3(
        hindiWord1,
        font1,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );

      hindiHeader = buildTextForPdf3(
        "महोदय / महोदया,",
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );

      hindiHeader = buildTextForPdf3(
        hindiWord2,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );

      hindiHeader = buildTextForPdf3(
        hindiWord3,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );

      hindiHeader = buildTextForPdf3(
        hindiWord4,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );
      
      hindiHeader = buildTextForPdf3(
        hindiWord5,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );

      hindiHeader=buildTextForPdf(
        "Adv. Vishal Tiwari",
        boldFont,
        secondPage,
        430,
        77
      );

      hindiHeader=buildTextForPdf(
        "PrivateCourt Registry",
        boldFont,
        secondPage,
        422,
        62
      )
      j++;
      caseID++;
    }

    const combinedPdfBytes = await combinedPdf.save();
    const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Generated Conciliation.pdf";
    link.click();
    // await axios.get(`https://server3-pab9.onrender.com/postConcilationCaseID?type=ConcilationCaseID&value=${caseID}`)
    //   .then((res)=>{console.log(res.data); return res.data })
    //   .catch((err)=>{
    //     console.log(err);
    //   });
    setIsLoading(false);
  };

  const ConciliationNoticeSayyam = async (email, timeStamp, letterHead) => {
    timeStamp = +timeStamp;
    console.log(email, letterHead, timeStamp, "ConciliationNoticeSayyam" );
    setIsLoading(true);
    let pdfFilePath;
    if (letterHead === "Joylin Rego, Mumbai") {
    const letterheadResponse = await axios.get('letterhead.pdf');
        pdfFilePath = await letterheadResponse.data;
    } else {
      if (letterHead === "Gurugaon") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Bangalore") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Ambernath") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Hyderabad") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Thane") {
        pdfFilePath= await fetch(Thane).then((res) => res.arrayBuffer());
      } else if (letterHead === "Delhi") {
        console.log(letterHead, "1111")
        pdfFilePath= await fetch(Delhi).then((res) => res.arrayBuffer());
        console.log(letterHead, "11112")
      } else if (letterHead === "New Delhi") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      }
    }

    // let caseID=await axios
    // .get('https://server3-pab9.onrender.com/getConcilationCaseID?type=ConcilationCaseID')
    // .then((res)=>+res.data)
    // .catch((err)=>{
    //   console.log(err);
    //   return +1;
    // });
    let caseID=1;

    let data = await axios
      .post("https://server1-jlfh.onrender.com/getCsvByTimestamp", {
        email,
        timeStamp,
      })
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        return [];
      });
      console.log(data.length);
      const combinedPdf = await PDFDocument.create();
      combinedPdf.registerFontkit(fontkit);
      
      // len=len>101?101:len;
      let j=0;
      // caseID=+caseID;
      data=data.filter((item)=>item.borrowerAddress);
      console.log(data.length);
      let endIndex=+startIndex+1000;
      if(endIndex>data.length) endIndex=data.length;
      console.log(endIndex,"endIndex");
    for (let i = startIndex; i < endIndex; i++){
      console.log(i);
      // let caseIDStr = generatedConcilationCaseID(caseID);
      // const scheduleDate = calculateDateAfter15Days();
      const pdfDoc = await PDFDocument.load(pdfFilePath);
      pdfDoc.registerFontkit(fontkit);
      const [fontDataRegular, fontDataMedium] = await Promise.all([
        fetch(fontPathRegular).then(response => response.arrayBuffer()),
        fetch(fontPathMedium).then(response => response.arrayBuffer())
      ]);
      const font = await combinedPdf.embedFont(fontDataRegular, { subset: true });
      const font1 = await combinedPdf.embedFont(fontDataMedium, { subset: true });
      const boldFont = await combinedPdf.embedFont(StandardFonts.TimesRomanBold);
      const boldFont1 = await combinedPdf.embedFont(StandardFonts.TimesRoman);
      const [newPage] = await combinedPdf.copyPages(pdfDoc, [0]);
      combinedPdf.addPage(newPage);
      let pageindex = j * 2;
      const firstPage = combinedPdf.getPages()[pageindex];
      const [SecondnewPage] = await combinedPdf.copyPages(pdfDoc, [1]);
      combinedPdf.addPage(SecondnewPage);
      let pageindex1 = j * 2 + 1;
      const secondPage = combinedPdf.getPages()[pageindex1];
      const word4 =`Subject:  Letter of invitation as per the provisions of Section 62 of the Arbitration and Conciliation Act, 1996 (section 62 of the Arbitration and Conciliation Act, 1956) for resolution/conciliation of disputes arising out of your Loan Application No. ${data[i].loanID}`;
      const word6 = `The Claimant, M/s ${data[i].claimantCompany} having its registered office at ${data[i].claimantCompanyAddress}, is hereby directed to give the following notice you:-`;
      const word7 = `You have obtained a personal loan from the Claimant under loan application no. ${data[i].loanID}. As per the terms and conditions of the loan granted to you by the Claimant and the terms of which were specifically and expressly agreed upon between you and the Claimant, you were bound to repay the loan amount together with interest and other charges. However, you have failed to comply with the financial terms, and payments are due on your account. The total amount of Rs. ${convertToInteger(data[i].claimAmount)}/- is outstanding in your loan account. Several attempts have been made to you by the Claimant in order to recover the due amount, but you have been constantly evading from fulfilling your obligations.`;
      const word8 = `Therefore, the Claimant has initiated/started to initiate appropriate legal procedure to recover the above principal as well as interest and other charges. However, before taking any legal action, the Claimant has expressed its intention to explore the possibility of settlement through the Conciliation Process and accordingly intends to refer the dispute to PrivateCourt (Arbitration & Conciliation Organization) (CONTACT NO  +918035731373) who will work towards the amicable settlement of the dispute.`;
      const word9 = `If you wish to resolve the dispute without Conciliation proceeding then kindly make the payment of Rs. ${convertToInteger(data[i].claimAmount)}/- through https://easebuzz.in/pay/SayyamDnZU2 (payment link) and share the receipt through email at legal@privatecourt.in.`;
      const word10= `If you have dispute / concern / objection regarding the claim, then you may attend the conciliation camp at PrivateCourt, 208, 2nd Floor, Globe Business Park, Ambernath West, Thane - 421505, on 30th Aug 2024 between 11:00 AM TO 04:00 PM. For more information, kindly call us at +918035731373.`
      // const word12="For more information, kindly call us at +918035731373."

      const hindiWord1 = `संदर्भ : - आपके ऋण आवेदन संख्या ${data[i].loanID} में उठे विवादों के समाधान /सुलह अंतर्गत धारा 62 पंचाट एवं सुलह अधिनियम  1996 (section 62 of the Arbitration and Conciliation Act, 1956) के प्रावधानों के अनुसार आमंत्रण पत्र।`;
      const hindiWord2 = `महोदय / महोदया, दावेदार मै. ${data[i].claimantCompany} जिसका पंजीकृत कार्यालय ${data[i].claimantCompanyAddress}. पर स्थित है के निर्देशानुसार आप उपरोक्त नामित पत्र प्राप्तकर्ता को निम्न सूचना दी जाती है :- `;
      const hindiWord3 = `आपने दावेदार से ऋण आवेदन संख्या ${data[i].loanID} के तहत  व्यक्तिगत ऋण प्राप्त किया है।  शर्तों और नियमों के अनुसार दावेदार द्वारा आपको ऋण दी गयी और जिसके शर्तों  को आपके और दावेदार के मध्य विशेष एवं स्पष्ट रूप से सहमति हुई, आप ऋण राशि मय ब्याज एवं अन्य खर्चों को वापस करने के लिए पाबंद थे। हालांकि आप वित्तीय शर्तों के पालन करने व भुगतान करने में असफल रहे व और यह बकाया पैसा आपके खाते में अपेक्षित है। रूपए ${convertToInteger(data[i].claimAmount)}/- की कुल राशि आपके ऋण खाता में बकाया है। बकाया राशि की वसूली के लिए दावेदार द्वारा आपसे कई प्रयास किए गए हैं, लेकिन आप लगातार अपने दायित्वों को पूरा करने से बचते रहे हैं। `;
      const hindiWord4 = `अतएव दावेदार ने उपरोक्त मूलधन साथ ही साथ ब्याज एवं अन्य शुल्क वसूल करने हेतु उचित विधिक विधिक प्रक्रिया के अनुसार कार्यवाहियां आरंभ कर दिया है / आरंभ करने लगे हैं। `;
      const hindiWord5 = `हालांकि, कोई कानूनी कार्रवाई करने से पहले, दावेदार ने समझौता प्रक्रिया के माध्यम से निपटान की संभावना का पता लगाने का इरादा व्यक्त किया है और तदनुसार विवाद को प्राइवेटकोर्ट को उल्लेख करना चाहता है (मध्यस्थता और सुलह संगठन) (संपर्क नंबर +918035731373) जो एक सौहार्दपूर्ण समाधान के लिए एक मिलान करने वाले के रूप में कार्य करेगा।`;
      const hindiWord6 = `यदि आप बिना सुलह कार्यवाही के विवाद का समाधान करना चाहते हैं तो कृपया रूपए. ${convertToInteger(data[i].claimAmount)}/- का भुगतान https://easebuzz.in/pay/SayyamDnZU2 (भुगतान लिंक) के माध्यम से और रसीद को ईमेल के माध्यम से legal@privatecourt.in साझा करें।`;
      const hindiWord7 = `यदि आपके पास दावे के संबंध में विवाद / चिंता / आपत्ति है, तो आप 30th Aug 2024 11:00 से प्रातः 04:00 बजे तक। प्राइवेटकोर्ट, 208, दूसरी मंजिल, ग्लोब बिजनेस पार्क, अंबरनाथ पश्चिम, ठाणे - 421505 में सुलह शिविर में भाग ले सकते हैं। अधिक जानकारी के लिए कृपया हमें  +918035731373 पर कॉल करें।`
      buildTextForPdf(`Ref No. ${data[i].caseID}`, boldFont, firstPage, 36, 685);
      buildTextForPdf("Date: " + data[i].noticeDate, boldFont, firstPage,420,660);
      buildTextForPdf(`To,`, boldFont, firstPage, 36, 660);
      let header=buildTextForPdf(`${data[i].borrowerName?`1. ${data[i].borrowerName} (Borrower)`:""}`, boldFont, firstPage, 36, 635);
      if(data[i].coBorrowerName){
        header=buildTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName} (Co-Borrower)`:""}`, boldFont, firstPage, 36, header.currentY-15);
      }
      if(data[i].borrowerAddress){
        header=buildTextForPdfAddress(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      
      if(data[i].borrowerEmail){
        header=buildTextForPdf(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      if(data[i].borrowerPhone){
        header=buildTextForPdf(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont, firstPage, 36, header.currentY-15);
      }

      // if(data[i].coBorrowerName){
      //   header=buildTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName} (Co-Borrower)`:""}`, boldFont, firstPage, 36, header.currentY-20);
      // }
      // if(data[i].coBorrowerAddress){
      //   header=buildTextForPdf(`${data[i].coBorrowerAddress?`Having address at: ${data[i].coBorrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }
      // else if(data[i].coBorrowerName){
      //   header=buildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }
      // if(data[i].coBorrowerEmail){
      //   header=buildTextForPdf(`${data[i].coBorrowerEmail?`E-mail: ${data[i].coBorrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }
      // if(data[i].phone){
      //   header=buildTextForPdf(`${data[i].phone?`Contact: ${data[i].phone}`:""}`, boldFont, firstPage, 36, header.currentY-15);
      // }
      // if(data[i]['Business name']){
      //   header=buildTextForPdf(`${data[i]['Business name']?`3. ${data[i]['Business name']}`:""}`, boldFont, firstPage, 36, header.currentY-20);
      // }
      // if(data[i]['borrower Business Address']){
      //   header=buildTextForPdf(`${data[i]['borrower Business Address']?`Having address at: ${data[i]['borrower Business Address']}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }
      // else if(data[i]['Business name']){
      //   header=buildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }

      // let header = buildTextForPdf(`${data[i].borrowerName}`,boldFont, firstPage, 36,650);
      // header = buildTextForPdf(word1, boldFont1, firstPage, 36, header.currentY - 15);
      // header = buildTextForPdf(word2, boldFont, firstPage, 36, header.currentY - 15);
      // header = buildTextForPdf( `${data[i].coBorrowerName}`, boldFont, firstPage, 36, header.currentY - 15);
      // header = buildTextForPdf(word31, boldFont1, firstPage, 36, header.currentY - 15 );
      // header = buildTextForPdf(word3, boldFont, firstPage, 36, header.currentY - 15 );

      header = buildTextForPdf(
        word4,
        boldFont,
        firstPage,
        36,
        header.currentY - 25
      );

      header = buildTextForPdf(
        "Respected Sir/Madam,",
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );

      header = buildTextForPdf(
        word6,
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );

      header = buildTextForPdf(
        word7,
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );

      header = buildTextForPdf(
        word8,
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );

      header = buildTextForPdf(
        word9,
        boldFont,
        firstPage,
        36,
        header.currentY - 25
      );

      header = buildTextForPdf(
        word10,
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );


      header=buildTextForPdf(
        "Adv. Vishal Tiwari",
        boldFont,
        firstPage,
        430,
        77
      );

      header=buildTextForPdf(
        "PrivateCourt Registry",
        boldFont,
        firstPage,
        422,
        62
      );

      buildTextForPdf(
        "Date: " + data[i].noticeDate,
        boldFont,
        secondPage,
        420,
        660
      );

      buildTextForPdf(
        `संदर्भ संक्या: ${data[i].caseID}`,
        font,
        secondPage,
        36,
        685
      );

      buildTextForPdf(`To,`, boldFont, secondPage, 36, 660);
      let hindiHeader=buildTextForPdf(`${data[i].borrowerName?`1. ${data[i].borrowerName} (Borrower)`:""}`, boldFont, secondPage, 36, 635);
      if(data[i].coBorrowerName){ hindiHeader=buildTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName} (Co-Borrower)`:""}`, boldFont, secondPage, 36, hindiHeader.currentY-15);}
      hindiHeader=buildTextForPdfAddress(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      // hindiHeader=buildTextForPdf(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      // hindiHeader=buildTextForPdf(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont, secondPage, 36, hindiHeader.currentY-15);
      if(data[i].borrowerEmail){
        hindiHeader=buildTextForPdf(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      }
      if(data[i].borrowerPhone){
        hindiHeader=buildTextForPdf(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont, secondPage, 36, hindiHeader.currentY-15);
      }
      // if(data[i].coBorrowerName){
      //   hindiHeader=buildTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName} (Co-Borrower)`:""}`, boldFont, secondPage, 36, hindiHeader.currentY-20);
      // }

      // if(data[i].coBorrowerAddress){
      //   hindiHeader=buildTextForPdf(`${data[i].coBorrowerAddress?`Having address at: ${data[i].coBorrowerAddress}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      // }

      // else if(data[i].coBorrowerName){
      //   hindiHeader=buildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      // }

      // if(data[i].coBorrowerEmail){
      //   hindiHeader=buildTextForPdf(`${data[i].coBorrowerEmail?`E-mail: ${data[i].coBorrowerEmail}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      // }

      // if(data[i].phone){
      //   hindiHeader=buildTextForPdf(`${data[i].phone?`Contact: ${data[i].phone}`:""}`, boldFont, secondPage, 36, hindiHeader.currentY-15);
      // }

      // if(data[i]['Business name']){
      //   hindiHeader=buildTextForPdf(`${data[i]['Business name']?`3. ${data[i]['Business name']}`:""}`, boldFont, secondPage, 36, hindiHeader.currentY-20);
      // }

      // if(data[i]['borrower Business Address']){
      //   hindiHeader=buildTextForPdf(`${data[i]['borrower Business Address']?`Having address at: ${data[i]['borrower Business Address']}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      // }

      // else if(data[i]['Business name']){
      //   hindiHeader=buildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, secondPage, 36, header.currentY-15);
      // }

      hindiHeader = buildTextForPdf3(
        hindiWord1,
        font1,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );

      hindiHeader = buildTextForPdf3(
        "महोदय / महोदया,",
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );

      hindiHeader = buildTextForPdf3(
        hindiWord2,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );

      hindiHeader = buildTextForPdf3(
        hindiWord3,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );

      hindiHeader = buildTextForPdf3(
        hindiWord4,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );
      
      hindiHeader = buildTextForPdf3(
        hindiWord5,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );

      hindiHeader = buildTextForPdf3(
        hindiWord6,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );

      hindiHeader = buildTextForPdf3(
        hindiWord7,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );

      hindiHeader=buildTextForPdf(
        "Adv. Vishal Tiwari",
        boldFont,
        secondPage,
        430,
        77
      );

      hindiHeader=buildTextForPdf(
        "PrivateCourt Registry",
        boldFont,
        secondPage,
        422,
        62
      )
      j++;
      caseID++;
    }

    const combinedPdfBytes = await combinedPdf.save();
    const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Generated Conciliation.pdf";
    link.click();
    // await axios.get(`https://server3-pab9.onrender.com/postConcilationCaseID?type=ConcilationCaseID&value=${caseID}`)
    //   .then((res)=>{console.log(res.data); return res.data })
    //   .catch((err)=>{
    //     console.log(err);
    //   });
    setIsLoading(false);
  };

  const ConciliationAyeFinanceNotice = async (email, timeStamp, letterHead) => {
    timeStamp = +timeStamp;
    console.log(email, letterHead, timeStamp, "ConciliationNotice" );
    setIsLoading(true);
    let pdfFilePath;
    if (letterHead === "Joylin Rego, Mumbai") {
    const letterheadResponse = await axios.get('letterhead.pdf');
        pdfFilePath = await letterheadResponse.data;
    } else {
      if (letterHead === "Gurugaon") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Bangalore") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Ambernath") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Hyderabad") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Thane") {
        pdfFilePath= await fetch(Thane).then((res) => res.arrayBuffer());
      } else if (letterHead === "Delhi") {
        pdfFilePath= await fetch(Delhi).then((res) => res.arrayBuffer());
      } else if (letterHead === "New Delhi") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      }
    }

    const data = await axios
      .post("https://server1-jlfh.onrender.com/getCsvByTimestamp", {
        email,
        timeStamp,
      })
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        return [];
      });
      console.log(data.length);
      const combinedPdf = await PDFDocument.create();
      combinedPdf.registerFontkit(fontkit);
      
      
      let j=0;
    for (let i = 0; i < 5; i++){
      console.log(i);
      const pdfDoc = await PDFDocument.load(pdfFilePath);
      pdfDoc.registerFontkit(fontkit);
      const [fontDataRegular, fontDataMedium] = await Promise.all([
        fetch(fontPathRegular).then(response => response.arrayBuffer()),
        fetch(fontPathMedium).then(response => response.arrayBuffer())
      ]);
      const font = await combinedPdf.embedFont(fontDataRegular, { subset: true });
      const font1 = await combinedPdf.embedFont(fontDataMedium, { subset: true });
      const boldFont = await combinedPdf.embedFont(StandardFonts.TimesRomanBold);
      const boldFont1 = await combinedPdf.embedFont(StandardFonts.TimesRoman);
      const [newPage] = await combinedPdf.copyPages(pdfDoc, [0]);
      combinedPdf.addPage(newPage);
      let pageindex = j ;
      const firstPage = combinedPdf.getPages()[pageindex];
      // const [SecondnewPage] = await combinedPdf.copyPages(pdfDoc, [1]);
      // combinedPdf.addPage(SecondnewPage);
      // let pageindex1 = j * 2 + 1;
      // const secondPage = combinedPdf.getPages()[pageindex1];
      const word1 = `संदर्भ: - आपके ऋण खाता इकरारनामा सं. ${data[i].loanID} में उठे विवाद के समाधान /सुलह अंतर्गत धारा 62 पंचाट मध्यस्थता और सुलह अधिनियम 1996 (section 62 of the Arbitration and Concilliation Act, 1956) के प्रावधान के अनुसार आमंत्रण पत्र।`;
      const word2 = `महोदय / महोदया, दावेदार. आय फाइनेंस प्राइवेट लिलमटेड, Tower-B, Arya Samaj Rd, Block B, Greenwood City, Sector 45, Gurugram, Haryana 122003 पर स्थित है निर्देशानुसार आप उपरोक्त नामित पत्र प्राप्तकर्ता को निम्न सूचना दी जाती है:`;
      const word3 = `आपने दावेदार से ऋण खाता / इकरारनामा सं. ${data[i].loanID} के तहत ऋण प्राप्त किया है।`;
      const word4 = `शर्तों और नियमों के अनुसार दावेदार द्वारा आपको ऋण दी गयी और जिसके शर्तों  को आपने और दावेदार के मध्य विशेष एवं स्पष्ट रूप से सहमति हुई, आप ऋण राशि मय ब्याज एवं अन्य खर्चों को वापस करने के लिए पाबंद थे। हालांकि आप वित्तीय शर्तों के पालन करने व भुगतान करने में असफल रहे व और आपके खाते में अपेक्षित है। दिनांक 6th February तक रूपए ${data[i].claimAmount}/- की कुल राशि आपके ऋण खाता में बकाया है। जो आपके द्वारा अन्य रकमों /आरोपों के अलावा दावेदार को देय है, जो आप भुगतान करने से बचते रहे, इस संबंध में दावेदार द्वारा आपको अनेकों बार मांग की गयी।`;
      const word5 = `अतएव दावेदार ने उपरोक्त मूलधन साथ ही साथ ब्याज एवं अन्य खर्च /शुल्क वसूल करने हेतु उचित विधिक कार्यवाहियां / विधिक प्रक्रिया के अनुसार कार्यवाहियां आरंभ कर दिया है / आरंभ करने लगे हैं।`;
      const word6 = `हालांकि, किसी विधिक कार्यवाही करने से पूर्व दावेदार ने निपटान की संभावना का पता लगाने का इरादा बजरिए समाधान /सुलह प्रक्रिया को व्यक्त किया है और तदनुसार विवाद ${data[i].conciliator} को भेजने के लिए इच्छुक है जो एक सौहार्दपूर्ण समाधान के लिए एक मिलान करने वाले के रूप में कार्य करेगा। `;
      const word7 = `यदि आप भी उपरोक्त वर्णित मामले को सौहार्दपूर्ण ढंग से सुलह के जरिए निपटाने के इच्छुक हैं तो आपको सुलह / समाधान शिविर ${data[i].campAddress} पर दिनांक  ${data[i].asOnDate} को 11:00 AM TO 04:00 PM समय तक पहुंचने हेतु बुलाया जाता है।  यदि हालांकि, आप उक्त तिथि पर सुलह में आने के लिए असफल हुए तो यह आमंत्रण पत्र स्वयं खंडित / निरस्त माना जाएगा और इस स्थिति में दावेदार विधिक कार्यवाहियां या आपके विरुद्ध उक्त देयों की वसूली मय ब्याज, हर्ज़े -खर्चे के लिए बाध्य होगा जिसकी सारी जिम्मेदारी आपकी होगी। यह सूचना नोटिस दावेदार की अंतिम मांग पत्र उपरोक्त बकाये देयों की है।`;
      const word8 = `अधिक सहायता के लिए कृपया ग्राहक सेवा केंद्र या मैनेजर ${data[i].rcmName}, CONTACT ${data[i].privateCourtPhone} के साथ संपर्क करने में संकोच ना करें।  कृपया इस संचार को अनदेखा करें यदि आपने पहले ही अपने ऋण खाते पर उपरोक्त राशि का निपटारा और मोरेटोरियम कर दिया है।`;

      buildTextForPdf(`Ref No. ${data[i].caseID}`, boldFont, firstPage, 36, 685);
      buildTextForPdf("Date: 9-Feb-2024", boldFont, firstPage,420,660);
      buildTextForPdf(`To,`, boldFont, firstPage, 36, 660);
      let header=buildTextForPdf(`${data[i].borrowerName?`1. ${data[i].borrowerName} (Borrower)`:""}`, boldFont, firstPage, 36, 635);
      if(data[i].coBorrowerName){
        header=buildTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName} (Co-Borrower)`:""}`, boldFont, firstPage, 36, header.currentY-15);
      }
      header=buildTextForPdfAddress(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      if(data[i].borrowerEmail){
        header=buildTextForPdf(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      if(data[i].borrowerPhone){
        header=buildTextForPdf(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont, firstPage, 36, header.currentY-15);
      }

      header = buildTextForPdf3(
        word1,
        font,
        firstPage,
        36,
        header.currentY - 22
      );

      header = buildTextForPdf3(
        word2,
        font,
        firstPage,
        36,
        header.currentY - 22
      );

      header = buildTextForPdf3(
        word3,
        font,
        firstPage,
        36,
        header.currentY - 22
      );

      header = buildTextForPdf3(
        word4,
        font,
        firstPage,
        36,
        header.currentY - 22
      );

      header = buildTextForPdf3(
        word5,
        font,
        firstPage,
        36,
        header.currentY - 22
      );

      header = buildTextForPdf3(
        word6,
        font,
        firstPage,
        36,
        header.currentY - 22
      );

      header = buildTextForPdf3(
        word7,
        font,
        firstPage,
        36,
        header.currentY - 22
      );

      header = buildTextForPdf3(
        word8,
        font,
        firstPage,
        36,
        header.currentY - 22
      );

      header=buildTextForPdf(
        "Adv. Vishal Tiwari",
        boldFont,
        firstPage,
        430,
        77
      );

      header=buildTextForPdf(
        "PrivateCourt Registry",
        boldFont,
        firstPage,
        422,
        62
      );
      j++;
    }

    const combinedPdfBytes = await combinedPdf.save();
    const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Generated Conciliation.pdf";
    link.click();
    setIsLoading(false);
  };

  const ConciliationUnityBankNotice = async (email, timeStamp, letterHead) => {
    timeStamp = +timeStamp;
    console.log(email, letterHead, timeStamp, "ConciliationNotice" );
    setIsLoading(true);
    let pdfFilePath;
    if (letterHead === "Joylin Rego, Mumbai") {
    const letterheadResponse = await axios.get('letterhead.pdf');
        pdfFilePath = await letterheadResponse.data;
    } else {
      if (letterHead === "Gurugaon") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Bangalore") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Ambernath") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Hyderabad") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Thane") {
        pdfFilePath= await fetch(Thane).then((res) => res.arrayBuffer());
      } else if (letterHead === "Delhi") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "New Delhi") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      }
    }

    // let caseID=await axios
    // .get('https://server3-pab9.onrender.com/getConcilationCaseID?type=ConcilationCaseID')
    // .then((res)=>+res.data)
    // .catch((err)=>{
    //   console.log(err);
    //   return +1;
    // });
    let caseID=1;

    const data = await axios
      .post("https://server1-jlfh.onrender.com/getCsvByTimestamp", {
        email,
        timeStamp,
      })
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        return [];
      });
      console.log(data.length);
      const combinedPdf = await PDFDocument.create();
      combinedPdf.registerFontkit(fontkit);
      
      // len=len>101?101:len;
      let j=0;
      // caseID=+caseID;
    for(let i = 0; i < data.length; i++){
      console.log(i);
      const pdfDoc = await PDFDocument.load(pdfFilePath);
      pdfDoc.registerFontkit(fontkit);
      const [fontDataRegular, fontDataMedium] = await Promise.all([
        fetch(fontPathRegular).then(response => response.arrayBuffer()),
        fetch(fontPathMedium).then(response => response.arrayBuffer())
      ]);

      const font = await combinedPdf.embedFont(fontDataRegular, { subset: true });
      const font1 = await combinedPdf.embedFont(fontDataMedium, { subset: true });
      const boldFont = await combinedPdf.embedFont(StandardFonts.TimesRomanBold);
      const boldFont1 = await combinedPdf.embedFont(StandardFonts.TimesRoman);
      const [newPage] = await combinedPdf.copyPages(pdfDoc, [0]);
      combinedPdf.addPage(newPage);
      let pageindex = j * 2;
      const firstPage = combinedPdf.getPages()[pageindex];
      const [SecondnewPage] = await combinedPdf.copyPages(pdfDoc, [1]);
      combinedPdf.addPage(SecondnewPage);
      let pageindex1 = j * 2 + 1;
      const secondPage = combinedPdf.getPages()[pageindex1];
      const word4 ="Subject: Letter of invitation as per the provisions of Section 62 of the Arbitration and Conciliation Act, 1996, for the resolution/conciliation of disputes arising out of your outstanding loan.";
      const word6 = `The Claimant for ${data[i].claimantCompany}, having an address at ${data[i].claimantCompanyAddress}, is hereby directed to give the following notice to you: -`;
      const word7 = `You have obtained a loan from the Claimant vide Loan Agreement No.${data[i].loanID}. As per the terms and conditions of the loan granted to you by the Claimant, the terms of, which were specifically and expressly agreed upon between you and the Claimant, you were bound to repay the loan amount together with interest and other expenses. However, you have failed to comply with the financial terms and payments are due on your account. The total amount of Rs. ${convertToInteger(data[i].claimAmount)}/- as on ${data[i].asOnDate?data[i].asOnDate:"15th January 2024"} is outstanding in your loan account. Several demands have been made to you by the Claimant regarding the amount due, apart from other amounts/charges that you have avoided paying. Therefore, the Claimant has initiated/started to initiate appropriate legal proceedings as per the legal process to recover the above principal, as well as interest and other costs/fees.`;
      const word8 = `However, before taking any legal action, the Claimant has expressed its intention to explore the possibility of settlement through the Conciliation Process and, accordingly, intends to refer the dispute to PrivateCourt (Arbitration & Conciliation Organization) (CONTACT NO ${data[i].privateCourtPhone}) which will work towards the amicable settlement of the dispute.`;
      const word9 = `You are hereby directed to attend the Conciliation hearing scheduled ${data[i].meetingDate} on between ${data[i].meetingTime?data[i].meetingTime:"2:00 PM - 4:00 PM"} through below-mentioned VC link:`;
      const hindiWord1 ="विषय: आपके बकाया ऋण से उत्पन्न विवादों के समाधान/समाधान के लिए मध्यस्थता और सुलह अधिनियम, 1996 की धारा 62 के प्रावधानों के अनुसार निमंत्रण पत्र।";
      const hindiWord2 = `दावेदार ${data[i].claimantCompany}, जिसका पता ${data[i].claimantCompanyAddress} है, को निम्नलिखित देने का निर्देश दिया जाता है। आपके लिए सूचना:-`;
      const hindiWord3 = `आपने ऋण अनुबंध संख्या ${data[i].loanID} के माध्यम से दावेदार से ऋण प्राप्त किया है। शर्तों के अनुसार और दावेदार द्वारा आपको दिए गए ऋण की शर्तें, जिनकी शर्तों पर विशेष रूप से और स्पष्ट रूप से सहमति व्यक्त की गई थी आपके और दावेदार के बीच, आप ब्याज और अन्य सहित ऋण राशि चुकाने के लिए बाध्य थे खर्चे। हालाँकि, आप वित्तीय शर्तों का पालन करने में विफल रहे हैं और भुगतान आपके खाते पर बकाया है। कुल राशि रु. ${convertToInteger(data[i].claimAmount)}/- आपके ऋण खाते में ${data[i].asOnDate?data[i].asOnDate:"15th जनवरी 2024"} तक बकाया है। अनेक दावेदार द्वारा आपसे अन्य राशियों/प्रभारों के अलावा देय राशि के संबंध में मांग की गई है कि आपने भुगतान करने से परहेज किया है। इसलिए, दावेदार ने उचित कानूनी कार्रवाई शुरू कर दी है उपरोक्त मूलधन, साथ ही ब्याज और अन्य लागत/शुल्क की वसूली के लिए कानूनी प्रक्रिया के अनुसार कार्यवाही।`;
      const hindiWord4 = `हालाँकि, कोई भी कानूनी कार्रवाई करने से पहले, दावेदार ने इसकी संभावना तलाशने का इरादा व्यक्त किया है सुलह प्रक्रिया के माध्यम से निपटान और, तदनुसार, विवाद को प्राइवेटकोर्ट में भेजने का इरादा है (मध्यस्थता और सुलह संगठन) (संपर्क संख्या ${data[i].privateCourtPhone}) जो सौहार्दपूर्ण की दिशा में काम करेगा विवाद का निपटारा.`;
      const hindiWord5 = `आपको ${data[i].meetingDate} को ${data[i].meetingTime?data[i].meetingTime:"2:00 PM - 4:00 PM"} के बीच नीचे दिए गए वीसी लिंक के माध्यम से सुलह सुनवाई में भाग लेने का निर्देश दिया जाता है:`;

      buildTextForPdf(`Ref No. ${data[i].caseID}`, boldFont, firstPage, 36, 685);
      buildTextForPdf("Date: " + data[i].noticeDate, boldFont, firstPage,420,660);
      buildTextForPdf(`To,`, boldFont, firstPage, 36, 660);
      let header=buildTextForPdf(`${data[i].borrowerName?`1. ${data[i].borrowerName} (Borrower)`:""}`, boldFont, firstPage, 36, 635);
      if(data[i].coBorrowerName){
        header=buildTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName} (Co-Borrower)`:""}`, boldFont, firstPage, 36, header.currentY-15);
      }
      header=buildTextForPdfAddress(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      if(data[i].borrowerEmail){
        header=buildTextForPdf(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      if(data[i].borrowerPhone){
        header=buildTextForPdf(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont, firstPage, 36, header.currentY-15);
      }

      header = buildTextForPdf(
        word4,
        boldFont,
        firstPage,
        36,
        header.currentY - 25
      );

      header = buildTextForPdf(
        "Respected Sir/Madam,",
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );

      header = buildTextForPdf(
        word6,
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );

      header = buildTextForPdf(
        word7,
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );

      header = buildTextForPdf(
        word8,
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );

      header = buildTextForPdf(
        word9,
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );

      header=AcceptancebuildTextForPdf(`Join Zoom Meeting`, boldFont1, firstPage, 36, header.currentY-30);
      header=AcceptancebuildTextForPdf(`https://us06web.zoom.us/j/86751137338?pwd=L0cZW6Rznnu8ti5Ugec1ixta9Ctw40.1`, boldFont, firstPage, 36, header.currentY-15);
      header=AcceptancebuildTextForPdf(`Meeting ID: 867 5113 7338`, boldFont1, firstPage, 36, header.currentY-25);
      header=AcceptancebuildTextForPdf(`Passcode: 323181`, boldFont1, firstPage, 36, header.currentY-15);

      header=buildTextForPdf(
        "Adv. Vishal Tiwari",
        boldFont,
        firstPage,
        430,
        77
      );

      header=buildTextForPdf(
        "PrivateCourt Registry",
        boldFont,
        firstPage,
        422,
        62
      );

      buildTextForPdf(
        "Date: " + data[i].noticeDate,
        boldFont,
        secondPage,
        420,
        660
      );

      buildTextForPdf(
        `संदर्भ संक्या: ${data[i].caseID}`,
        font,
        secondPage,
        36,
        685
      );

      buildTextForPdf(`To,`, boldFont, secondPage, 36, 660);
      let hindiHeader=buildTextForPdf(`${data[i].borrowerName?`1. ${data[i].borrowerName} (Borrower)`:""}`, boldFont, secondPage, 36, 635);
      if(data[i].coBorrowerName){ hindiHeader=buildTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName} (Co-Borrower)`:""}`, boldFont, secondPage, 36, hindiHeader.currentY-15);}
      hindiHeader=buildTextForPdfAddress(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
  
      if(data[i].borrowerEmail){
        hindiHeader=buildTextForPdf(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      }
      if(data[i].borrowerPhone){
        hindiHeader=buildTextForPdf(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont, secondPage, 36, hindiHeader.currentY-15);
      }

      hindiHeader = buildTextForPdf3(
        hindiWord1,
        font1,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );

      hindiHeader = buildTextForPdf3(
        "महोदय / महोदया,",
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );

      hindiHeader = buildTextForPdf3(
        hindiWord2,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );

      hindiHeader = buildTextForPdf3(
        hindiWord3,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );

      hindiHeader = buildTextForPdf3(
        hindiWord4,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );
      
      hindiHeader = buildTextForPdf3(
        hindiWord5,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );

      hindiHeader=AcceptancebuildTextForPdf(`Join Zoom Meeting`, boldFont1, secondPage, 36, hindiHeader.currentY-30);
      hindiHeader=AcceptancebuildTextForPdf(`https://us06web.zoom.us/j/86751137338?pwd=L0cZW6Rznnu8ti5Ugec1ixta9Ctw40.1`, boldFont, secondPage, 36, hindiHeader.currentY-15);
      hindiHeader=AcceptancebuildTextForPdf(`Meeting ID: 867 5113 7338`, boldFont1, secondPage, 36, hindiHeader.currentY-25);
      hindiHeader=AcceptancebuildTextForPdf(`Passcode: 323181`, boldFont1, secondPage, 36, hindiHeader.currentY-15);

      hindiHeader=buildTextForPdf(
        "Adv. Vishal Tiwari",
        boldFont,
        secondPage,
        430,
        77
      );

      hindiHeader=buildTextForPdf(
        "PrivateCourt Registry",
        boldFont,
        secondPage,
        422,
        62
      )
      j++;
      caseID++;
    }

    const combinedPdfBytes = await combinedPdf.save();
    const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Generated Conciliation.pdf";
    link.click();
    setIsLoading(false);
  };

  const ConciliationNoticePayrupik = async (email, timeStamp, letterHead) => {
    timeStamp = +timeStamp;
    console.log(email, letterHead,timeStamp, "ConciliationNotice" );
    let pdfFilePath;
    if (letterHead === "Joylin Rego, Mumbai") {
    const letterheadResponse = await axios.get('letterhead.pdf');
        pdfFilePath = await letterheadResponse.data;
    } else {
      if (letterHead === "Gurugaon") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Bangalore") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Ambernath") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Hyderabad") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Thane") {
        pdfFilePath= await fetch(Thane).then((res) => res.arrayBuffer());
      } else if (letterHead === "Delhi") {
        pdfFilePath= await fetch(Delhi).then((res) => res.arrayBuffer());
      } else if (letterHead === "New Delhi") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      }
    }

    // let caseID=await axios
    // .get('https://server3-pab9.onrender.com/getConcilationCaseID?type=ConcilationCaseID')
    // .then((res)=>+res.data)
    // .catch((err)=>{
    //   console.log(err);
    //   return +1;
    // });

    let caseID=1;
    const data = await axios
      .post("https://server1-jlfh.onrender.com/getCsvByTimestamp", {
        email,
        timeStamp,
      })
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        return [];
      });
      console.log(data.length);
      const combinedPdf = await PDFDocument.create();
      combinedPdf.registerFontkit(fontkit);
      
      // len=len>101?101:len;
      let j=0;
      // caseID=+caseID;
    for (let i = 0; i < 2; i++){
      console.log(i);
      // let caseIDStr = generatedConcilationCaseID(caseID);
      // const scheduleDate = calculateDateAfter15Days();
      const pdfDoc = await PDFDocument.load(pdfFilePath);
      pdfDoc.registerFontkit(fontkit);
      const [fontDataRegular, fontDataMedium] = await Promise.all([
        fetch(fontPathRegular).then(response => response.arrayBuffer()),
        fetch(fontPathMedium).then(response => response.arrayBuffer())
      ]);
      const font = await combinedPdf.embedFont(fontDataRegular, { subset: true });
      const font1 = await combinedPdf.embedFont(fontDataMedium, { subset: true });
      const boldFont = await combinedPdf.embedFont(StandardFonts.TimesRomanBold);
      const boldFont1 = await combinedPdf.embedFont(StandardFonts.TimesRoman);
      const [newPage] = await combinedPdf.copyPages(pdfDoc, [0]);
      combinedPdf.addPage(newPage);
      let pageindex = j;
      const firstPage = combinedPdf.getPages()[pageindex];
      // const [SecondnewPage] = await combinedPdf.copyPages(pdfDoc, [1]);
      // combinedPdf.addPage(SecondnewPage);
      // let pageindex1 = j * 2 + 1;
      // const secondPage = combinedPdf.getPages()[pageindex1];
      const subject ="Sub: Empanelment of Conciliator and Settlement of Dispute (Outstanding Payment for SAYYAM INVESTMENTS PRIVATE LIMITED (PayRupik) (Claimant)) via Conciliation as per Arbitration and Conciliation Act , 1996.";
      const pointOne = `1. We have received the request for conducting Conciliation Proceedings through the claimant SAYYAM
                    INVESTMENTS PRIVATE LIMITED (PayRupik) to resolve the loan dispute of outstanding amount between
                    you and the Claimant for the Amount of Rs. ${data[i].totalLoan}/- along with the interest of 18% per annum from the date of
                    default.`;
      const pointTwo = `2. The PrivateCourt is an online dispute resolution body which conducts Arbitration/ Conciliation/ Mediation/
                    Negotiation though its Neutrals who are senior lawyers/ retired judges/President of the Organization. Being an
                    independent body, the PrivateCourt believes in resolving disputes with utmost fairness. These proceedings are
                    without prejudice to any/all other rights of the parties to approach any court/ forum/ tribunal/ authority. If either
                    party fails or neglect to attain the Conciliation Proceedings, then Non-Starter report will be passed by the Hon’ble
                    Sole Conciliator, pursuant to which the parties shall be at liberty to initiate court proceedings / Arbitration /
                    Criminal Complaint resolve their dispute or recover their claim`;

      const pointThree = `3. The Claimant states that you have not complied the terms and condition of the loan agreement executed between
                    you and the Claimant and hence till date an amount of Rs. ${data[i].totalLoan}/- is due and outstanidng.`;
      const pointFour = `4. You are hereby called to produce below-mentioned proof in reply to this Notice:`;
      const pointFourA = `a. Reciept of all payment made to SAYYAM INVESTMENTS PRIVATE LIMITED (PayRupik) confirming
                         all dues are clear and entire loan amount and interest is paid.`
      const pointFourB = `b. No dues certificate / confirmation of loan acount closure from SAYYAM INVESTMENTS PRIVATE
                         LIMITED (PayRupik).`
      const pointFive=`5. If you don’t have above-mentioned proofs or payment is pending to SAYYAM INVESTMENTS PRIVATE
                       LIMITED, you are directed to make payment at the below-mentioned payment link within 7 days of this notice:`
      const pointFiveLink=`https://easebuzz.in/pay/SayyamDnZU2`;
      const pointSix=`6. Kindly submit the above-mentioned proof at legal@privatecourt.in or call / whatsapp at 8976955539.`
      const pointSeven=`7. If you fail to make payment or furnish proof within 7 days of the reciept of this notice, matter shall be proceeded
                       for Concilaition / arbitration anytime thereafter by giving you intimation 24 hours prior to the hearing.`

      ConciliationPayrupikbuildTextForPdf(`Ref No. ${data[i].caseID}`, boldFont, firstPage, 36, 685);
      ConciliationPayrupikbuildTextForPdf(`Date: ${data[i].noticeDate}`, boldFont, firstPage,420,660);
      ConciliationPayrupikbuildTextForPdf(`To,`, boldFont, firstPage, 36, 660);

      let header=ConciliationPayrupikbuildTextForPdf(`${data[i].borrowerName?`1. ${data[i].borrowerName} (Borrower)`:""}`, boldFont, firstPage, 36, 635);
      header=ConciliationPayrupikbuildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      header=ConciliationPayrupikbuildTextForPdf(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      header=ConciliationPayrupikbuildTextForPdf(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont, firstPage, 36, header.currentY-15);
      if(data[i].coBorrowerName){
        header=ConciliationPayrupikbuildTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName} (Co-Borrower)`:""}`, boldFont, firstPage, 36, header.currentY-20);
      }
      if(data[i].coBorrowerAddress){
        header=ConciliationPayrupikbuildTextForPdf(`${data[i].coBorrowerAddress?`Having address at: ${data[i].coBorrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      else if(data[i].coBorrowerName){
        header=ConciliationPayrupikbuildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      if(data[i].coBorrowerEmail){
        header=ConciliationPayrupikbuildTextForPdf(`${data[i].coBorrowerEmail?`E-mail: ${data[i].coBorrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      if(data[i].phone){
        header=ConciliationPayrupikbuildTextForPdf(`${data[i].phone?`Contact: ${data[i].phone}`:""}`, boldFont, firstPage, 36, header.currentY-15);
      }

      if(data[i]['Business name']){
        header=ConciliationPayrupikbuildTextForPdf(`${data[i]['Business name']?`3. ${data[i]['Business name']}`:""}`, boldFont, firstPage, 36, header.currentY-20);
      }

      if(data[i]['borrower Business Address']){
        header=ConciliationPayrupikbuildTextForPdf(`${data[i]['borrower Business Address']?`Having address at: ${data[i]['borrower Business Address']}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      else if(data[i]['Business name']){
        header=ConciliationPayrupikbuildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }

      header = ConciliationPayrupikbuildTextForPdf(
        subject,
        boldFont,
        firstPage,
        36,
        header.currentY - 20
      );
      header = ConciliationPayrupikbuildTextForPdf(
        "Respected Sir/Madam,",
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointOne,
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointTwo,
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointThree,
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointFour,
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointFourA,
        boldFont1,
        firstPage,
        46,
        header.currentY - 14
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointFourB,
        boldFont1,
        firstPage,
        46,
        header.currentY - 14
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointFive,
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointFiveLink,
        boldFont,
        firstPage,
        36,
        header.currentY - 14
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointSix,
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );
      header = ConciliationPayrupikbuildTextForPdf(
        pointSeven,
        boldFont1,
        firstPage,
        36,
        header.currentY - 20
      );


      header=ConciliationPayrupikbuildTextForPdf(
        "Adv. Vishal Tiwari",
        boldFont,
        firstPage,
        430,
        77
      )

      header=ConciliationPayrupikbuildTextForPdf(
        "PrivateCourt Registry",
        boldFont,
        firstPage,
        422,
        62
      )

      j++;
      caseID++;
    }
    const combinedPdfBytes = await combinedPdf.save();
    const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Generated Conciliation.pdf";
    link.click();
    
  };

  const LRNNotice=async (email, timeStamp, letterHead)=>{
    // let pdfFilePath;
    // let { email, timeStamp } = req.body;
    // pdfFilePath = path.join(__dirname,"..","files","LRNNotice (2).pdf");
    timeStamp = +timeStamp;
    console.log(email, letterHead, timeStamp, "LRN Notice" );
    setIsLoading(true);
    let pdfFilePath;
    if (letterHead === "Joylin Rego, Mumbai") {
      pdfFilePath= await fetch(Joylin).then((res) =>res.arrayBuffer());
    } else {
      if (letterHead === "Gurugaon") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Bangalore") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Ambernath") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Hyderabad") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Thane") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Delhi") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "New Delhi") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      }
    }

    const data = await axios
    .post("https://server1-jlfh.onrender.com/getCsvByTimestamp", {
      email,
      timeStamp,
    })
    .then((res) => res.data
    )
    .catch((err) => {
      console.log(err);
      return [];
    });

    console.log(data.length, "data.length");
    const combinedPdf = await PDFDocument.create();
    combinedPdf.registerFontkit(fontkit);
    for(let i=0;i<data.length;i++){
      console.log(i, 'i');
      const loanID = data[i].loanID;
      const pdfDoc = await PDFDocument.load(pdfFilePath);
      pdfDoc.registerFontkit(fontkit);
      const boldFont =await combinedPdf.embedFont(StandardFonts.TimesRomanBold);
      const boldFont1=await combinedPdf.embedFont(StandardFonts.TimesRoman);
      const [newPage] = await combinedPdf.copyPages(pdfDoc, [0]);
      combinedPdf.addPage(newPage);
      let pageindex = i * 2;
      const firstPage = combinedPdf.getPages()[pageindex];
      const [SecondnewPage] = await combinedPdf.copyPages(pdfDoc, [1]);
      combinedPdf.addPage(SecondnewPage);
      let pageindex1 = i * 2 + 1;
      const secondPage = combinedPdf.getPages()[pageindex1];
      const refText=`Ref: “LOAN AGREEMENT” executed with ${data[i].borrowerName} (Borrower) ${data[i].borrowerName?`& ${data[i].coBorrowerName} (Co-borrower/Guarantor)`:""} having loan number ${data[i].loanID}.`
      const Subject=`SUB: Loan recall and demand notice against the outstanding loan amount of Rs.${data[i].claimAmount}/-`
      const firstPara=`I am concerned for ${data[i].claimantCompany} (herein referred to as my client), with its registered address at ${data[i].claimantCompanyAddress}. This serves as the final loan recall notice against the outstanding loan amount of Rs.${data[i].claimAmount}/-`;
      const PointOne=`1. My client is one of the leading Non-Banking Financial Services companies as per the provisions of the Reserve Bank of India Act, 1934, engaged in the business of providing financial assistance and loan facilities for Educational purposes.`;
      const PointTwo=`2. The Borrower approached my client and requested to sanction a loan facility for monthly instalments of Rs.${data[i].emi}/- against the total loan amount of Rs.${data[i].totalLoan}/- starting from ${data[i].emiStart}(start date) till ${data[i].emiEnd} (end date).`
      const PointThreeA=`a. the Borrower duly executed and signed the following documents: Demand Promissory Note and Loan Agreement.`
      const PointThreeB=`b. the Borrower has duly acknowledged all the terms and conditions of the abovementioned documents.`
      const PointFour=`4. That even after executing the above-mentioned documents and after acknowledging all the terms and conditions of the Loan Agreement by the Borrower, the loan was disbursed, and the same has been utilized by the Borrower.`
      const PointFive=`5. That even after promising to pay the monthly EMI on due time against the above- mentioned loan, The Borrower has miserably failed to pay the agreed monthly instalment amount as per the Loan Agreement. Hence, as of the current date, the total outstanding amount is Rs.${data[i].claimAmount}/-.`
      const PointSix=`6. My client states that it has many times reminded the Borrower to repay the loan amount under the above-mentioned Loan Agreement. That despite several reminders sent by my client, the Borrower has failed to pay the same and neglected to repay the Loan Amount.`
      const PointSeven=`7. My client states that due to the default in payments by the Borrower of the EMI amount and due to the delayed payment charges on the principal amount, the total amount due has now reached Rs.${data[i].claimAmount}/- (Total Outstanding).`
      const PointEight=`8. Hence, I, on behalf of my client, finally serve upon you this loan recall notice and direct you to make the payment of the outstanding loan amount of Rs.${data[i].claimAmount}/- within 7 days from the receipt of this notice. If you fail to make payment of the outstanding loan amount, I will be constrained to initiate legal action against all of you, at your cost and consequences.`
      LRNbuildTextForPdf("Date: 30-Jan-2024", boldFont, firstPage, 420, 670);
      LRNbuildTextForPdf(`Ref No. ${data[i].refNo}`, boldFont, firstPage, 36, 700);
      LRNbuildTextForPdf(`To,`, boldFont, firstPage, 36, 670);

      let header=LRNbuildTextForPdf(`${data[i].borrowerName?`1. ${data[i].borrowerName}(Borrower)`:""}`, boldFont, firstPage, 36, 650);
      header=LRNbuildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      if(data[i].borrowerEmail){
        header=LRNbuildTextForPdf(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      if(data[i].borrowerPhone){
        header=LRNbuildTextForPdf(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont, firstPage, 36, header.currentY-15);
      }
      if(data[i].coBorrowerName){
        header=LRNbuildTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName}(Co-Borrower)`:""}`, boldFont, firstPage, 36, header.currentY-20);
      }
      if(data[i].coBorrowerAddress){
        header=LRNbuildTextForPdf(`${data[i].coBorrowerAddress?`Having address at: ${data[i].coBorrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15)
      }
      if(data[i].coBorrowerEmail){
        header=LRNbuildTextForPdf(`${data[i].coBorrowerEmail?`E-mail: ${data[i].coBorrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      if(data[i].phone){
        header=LRNbuildTextForPdf(`${data[i].phone?`Contact: ${data[i].phone}`:""}`, boldFont, firstPage, 36, header.currentY-15);
      }
      if(data[i].businessName){
        header=LRNbuildTextForPdf(`${data[i].businessName?`3. ${data[i].businessName}`:""}`, boldFont, firstPage, 36, header.currentY-20);
      }
      if(data[i].businessAddress){
        header=LRNbuildTextForPdf(`${data[i].businessAddress?`Having address at: ${data[i].businessAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      // header=LRNbuildTextForPdf(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // header=LRNbuildTextForPdf(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // header=LRNbuildTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName}(Co-Borrower)`:""}`, boldFont, firstPage, 36, header.currentY-20);
      // header=LRNbuildTextForPdf(`${data[i].coBorrowerAddress?`Having address at: ${data[i].coBorrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // header=LRNbuildTextForPdf(`${data[i].coBorrowerEmail?`E-mail: ${data[i].coBorrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // header=LRNbuildTextForPdf(`${data[i].phone?`Contact: ${data[i].phone}`:""}`, boldFont1, firstPage, 36, header.currentY-15);

      // if(data[i]['Business name']){
      //   header=LRNbuildTextForPdf(`${data[i]['Business name']?`3. ${data[i]['Business name']}`:""}`, boldFont, firstPage, 36, header.currentY-20);
      // }

      // if(data[i]['borrower Business Address']){
      //   header=LRNbuildTextForPdf(`${data[i]['borrower Business Address']?`Having address at: ${data[i]['borrower Business Address']}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }

      // else if(data[i]['Business name']){
      //   header=LRNbuildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }
      header=LRNbuildTextForPdf(refText, boldFont, firstPage, 36, header.currentY-25);
      header=LRNbuildTextForPdf(Subject, boldFont, firstPage, 36, header.currentY-25);
      header=LRNbuildTextForPdf(`Respected Sir/Madam,`, boldFont1, firstPage, 36, header.currentY-20);
      header=LRNbuildTextForPdf(firstPara, boldFont1, firstPage, 36, header.currentY-25);
      header=LRNbuildTextForPdf(PointOne, boldFont1, firstPage,50, header.currentY-25);
      header=LRNbuildTextForPdf(PointTwo, boldFont1, firstPage, 50, header.currentY-25);
      header=LRNbuildTextForPdf(`3. Pursuant to the loan sanction letter:-`, boldFont1, firstPage, 50, header.currentY-25);
      header=LRNbuildTextForPdf(PointThreeA, boldFont1, firstPage, 60, header.currentY-25);
      header=LRNbuildTextForPdf(PointThreeB, boldFont1, firstPage, 60, header.currentY-25);
      header=LRNbuildTextForPdf(PointFour, boldFont1, firstPage, 50, header.currentY-25);
      header=LRNbuildTextForPdf(PointFive, boldFont1, firstPage, 50, header.currentY-25);
      header=LRNbuildTextForPdf(PointSix, boldFont1, secondPage, 50, 700);
      header=LRNbuildTextForPdf(PointSeven, boldFont1, secondPage, 50, header.currentY-25);
      header=LRNbuildTextForPdf(PointEight, boldFont1, secondPage, 50, header.currentY-25);

      // const combinedPdfBytes = await combinedPdf.save();
      // const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
      // const url = URL.createObjectURL(blob);
      // const link = document.createElement("a");
      // link.href = url;
      // link.download = "Generated LRN Notice.pdf";
      // link.click();
      // setIsLoading(false);
    }  
    const combinedPdfBytes = await combinedPdf.save();
    const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Generated LRN Notice.pdf";
    link.click();
    setIsLoading(false);
  };

  const LRNNoticeException=async (email, timeStamp, letterHead)=>{
    // let pdfFilePath;
    // let { email, timeStamp } = req.body;
    // pdfFilePath = path.join(__dirname,"..","files","LRNNotice (2).pdf");
    timeStamp = +timeStamp;
    console.log(email, letterHead, timeStamp, "LRN Notice" );
    setIsLoading(true);
    let pdfFilePath;
    if (letterHead === "Joylin Rego, Mumbai") {
      pdfFilePath= await fetch(Joylin4).then((res) =>res.arrayBuffer());
    } else {
      if (letterHead === "Gurugaon") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Bangalore") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Ambernath") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Hyderabad") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Thane") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Delhi") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "New Delhi") { 
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      }
    }

    const data = await axios
    .post("https://server1-jlfh.onrender.com/getCsvByTimestamp", {
      email,
      timeStamp,
    })
    .then((res) => res.data
    )
    .catch((err) => {
      console.log(err);
      return [];
    });

    console.log(data.length, "data.length");
    const combinedPdf = await PDFDocument.create();
    combinedPdf.registerFontkit(fontkit);
    for(let i=0;i<1;i++){
      console.log(i, 'i');
      const NewRef = generateRandomInvoiceID();
      const loanID = data[i].loanID;
      
      if (data[i].refNo === undefined || data[i].refNo === null) {
        const newData = {
          ...data[i],  
          refNo: NewRef,
        };
        axios
          .post("https://server1-jlfh.onrender.com/saveDataByLoanID", {
            email,
            loanID,
            data: newData,
          })
          .then((res) => {
            console.log("changes");
          })
          .catch((err) => {
            console.log(err);
          });
      }
      const pdfDoc = await PDFDocument.load(pdfFilePath);
      pdfDoc.registerFontkit(fontkit);
      // const [fontDataRegular, fontDataMedium] = await Promise.all([
      //   fetch(fontPathRegular).then(response => response.arrayBuffer()),
      //   fetch(fontPathMedium).then(response => response.arrayBuffer())
      // ]);
      // const font = await combinedPdf.embedFont(fontDataRegular, { subset: true });
      // const font1 = await combinedPdf.embedFont(fontDataMedium, { subset: true });
      const boldFont =await combinedPdf.embedFont(StandardFonts.TimesRomanBold);
      const boldFont1=await combinedPdf.embedFont(StandardFonts.TimesRoman);
      const [newPage] = await combinedPdf.copyPages(pdfDoc, [0]);
      combinedPdf.addPage(newPage);
      let pageindex = i * 2;
      const firstPage = combinedPdf.getPages()[pageindex];
      const [SecondnewPage] = await combinedPdf.copyPages(pdfDoc, [1]);
      combinedPdf.addPage(SecondnewPage);
      let pageindex1 = i * 2 + 1;
      const secondPage = combinedPdf.getPages()[pageindex1];
      // const pages = pdfDoc.getPages();
      // const firstPage = pages[0];
      const Subject=`REF: LEGAL DEMAND CUM LOAN RECALL NOTICE FOR ENTIRE LOAN OUTSTANDING AMOUNT OF RS. ${data[i].emi}, WITH RESPECT TO LOAN ID ${data[i].loanID}`
      const ParaOne=`Under the instructions from and behalf of our Client i.e., Resilient Digi Services Private Limited (Formerly known as Resilient TechServ Primate Limited) (“hereinafter referred to as “RDSPL”) having its registered office at 3rd Floor, Ramnath House, 18, Community Centre, Yusuf Sarai, New Delhi - 110049, India, we hereby serve upon you the following notice:`;
      const PointOne=`1. RDSPL is a company incorporated under the provisions of the Companies Act, 2013 and is inter-alia engaged acts as a lending service provider/service provider to facilitate prospective borrowers for credit products provided by regulated financial institutions in accordance with the extant guidelines of RBI through its mobile/web-based application BharatPe (“BharatPe” Platform). `;
      const PointTwo=`2. RDSPL has been authorized by the Lender to act as their collection service provider for recovery of dues from the customers/consumers facilitated through BharatPe Platform and issue this notice on behalf of the Lender.`//- at ${data[i].interest}% interest per annum.
      const PointThree=`3. You the above-named addressee registered yourself on the BharatPe platform expressed your intention to avail a loan facility from an RBI registered financial institution through the BharatPe Platform. Basis the aforesaid request and submission of the required documents, a credit/loan facility amounting to Rs. ${data[i].totalLoan} (hereinafter referred to as “Loan Facility”) vide Loan ID ${data[i].loanID} was sanctioned to you by ${data[i].claimantCompany} (hereinafter referred to as “Lender”).`
      const PointFour=`4. You the above-named addressee had executed various Loan Documents/accepted terms and conditions for availing the above-mentioned Loan Facility through the BharatPe Platform. The Loan Facility was to be repaid as per the repayment schedule agreed to in the Loan Documents and/or at each drawdown (if applicable).`
      const PointFive=`5. That you the above-named addressee had assured the Lender that the Loan Facility, if granted to you, the above-named addressee would repay promptly and regularly without any delay or default and would observe all the terms and conditions of the Loan Facility. That the Loan Facility had been granted to you the above-named addressee relying upon these undertaking and representations to the Lender.`
      const PointSix=`6. That the Loan Facility had been duly sanctioned and granted to you and you the above-named addressee are legally and contractually liable to make the payment as per the terms and conditions of the Loan Facility. Further, regular and timely repayments of the Loan Facility were the essence of the contract. That the Lender is at liberty to recall entire loan and to invoke legal action to recover its money.`
      const PointSeven=`7. That you have failed to maintain the financial discipline required under the loan agreement & have defaulted in the payment of the installments in violation of the agreed terms & conditions. Due to your default of the payment of the instalments and failure to abide by the repayment terms an amount of Rs. ${data[i].emi} as on ${data[i].emiStart} is outstanding against you in the above Loan Account.`
      const PointEight=`8. It is stated that the Lender (through BharatPe Platform) has given the Loan Facility based on your abovementioned assurances, representations which were false and it is also evident that you from the very beginning did not have any intention of repaying the Loan Facility to the Lender. That you have thereby cheated and deceived the Lender and have thus caused unlawful loss to the Lender and unlawful gain to yourself.`
      const PointNine=`9. That due to default committed by you the addressee and on account of your failure to maintain the financial discipline, our client hereby recalls the entire loan facility /credit facility, granted to you vide account bearing no. ${data[i].agreementNo} and calls upon you the addressee to pay the Entire Loan Outstanding/Foreclosure amount of Rs.${data[i].claimAmount} as on ${data[i].start} within 7 days from the date of this notice along with future interest and cost till the date of realization of Recalled Loan Amount in accordance with the loan document otherwise our client will be constrained to initiate appropriate proceedings against you the addressee as per the agreed terms & conditions of the loan agreement for the recovery of the amounts as mentioned hereinabove and that too at the cost and peril of yours. Please note that if you ignore the present Notice and fail to pay aforesaid sum then our client (acting on behalf of the Lender) and/or the Lender shall be constrained to initiate the legal remedies to recover its legal money.`
      const ParaTwo=`In case of non recovery of Foreclosure amount within speculative time mentioned above, RDSPL or Lender may reserve the right to initiate any of below mentioned legal remedy towards recovery of Loan Amount.`
      const ParaThree=`If your ECS or Cheque gets dishonoured, our client may file Criminal Complaint U/S 25 of Payment and Settlement System Act or U/S 138 of NI Act which has a maximum Imprisonment up to 2 years, and/or fine up to twice the amount of the electronic funds transfer or Cheque amount or both.`;
      const ParaFour=`Initiate legal proceedings for recovery including Arbitration Proceedings / recovery proceedings before the District court/Debt Recovery Tribunal and seek attachment of your movable/immovable assets, for the recovery of the dues of our client etc.`;
      const ParaFive=`Initiate appropriate criminal proceedings u/s 406 & 420 against you the above named addressee and also all other responsible and liable persons.`
      const ParaSix=`Share your default information with credit bureaus/agency, which may impact adversely impact your credit score and ability to take further loans from the financial institution`
      LRNbuildTextForPdf("Date: " + getFormattedDate(), boldFont, firstPage, 420, 670);
      LRNbuildTextForPdf(`Ref No. ${data[i].refNo?data[i].refNo:NewRef}`, boldFont, firstPage, 36, 700);
      LRNbuildTextForPdf(`To,`, boldFont, firstPage, 36, 670);

      // let header=LRNbuildTextForPdf(`${data[i].borrowerName?`1. ${data[i].borrowerName}(Borrower)`:""}`, boldFont, firstPage, 36, 650);
      // header=LRNbuildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // header=LRNbuildTextForPdf(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // header=LRNbuildTextForPdf(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont1, firstPage, 36, header.currentY-15);

      // header=LRNbuildTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName}(Co-Borrower)`:""}`, boldFont, firstPage, 36, header.currentY-20);
      // header=LRNbuildTextForPdf(`${data[i].coBorrowerAddress?`Having address at: ${data[i].coBorrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // header=LRNbuildTextForPdf(`${data[i].coBorrowerEmail?`E-mail: ${data[i].coBorrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // header=LRNbuildTextForPdf(`${data[i].phone?`Contact: ${data[i].phone}`:""}`, boldFont1, firstPage, 36, header.currentY-15);

      // if(data[i]['Business name']){
      //   header=LRNbuildTextForPdf(`${data[i]['Business name']?`3. ${data[i]['Business name']}`:""}`, boldFont, firstPage, 36, header.currentY-20);
      // }

      // if(data[i]['borrower Business Address']){
      //   header=LRNbuildTextForPdf(`${data[i]['borrower Business Address']?`Having address at: ${data[i]['borrower Business Address']}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }
      // else if(data[i]['Business name']){
      //   header=LRNbuildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }

      let header=LRNNoticeException(`${data[i].borrowerName?`1. ${data[i].borrowerName}(Borrower)`:""}`, boldFont, firstPage, 36, 650);
      header=LRNNoticeException(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      if(data[i].borrowerEmail){
        header=LRNNoticeException(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      if(data[i].borrowerPhone){
        header=LRNNoticeException(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }      
      if(data[i].coBorrowerName){
        header=LRNNoticeException(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName}(Co-Borrower)`:""}`, boldFont, firstPage, 36, header.currentY-25);
      }
      if(data[i].coBorrowerAddress){
        header=LRNNoticeException(`${data[i].coBorrowerAddress?`Having address at: ${data[i].coBorrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15)
      }
      else if(data[i].coBorrowerName){
        header=LRNNoticeException(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      if(data[i].coBorrowerEmail){
        header=LRNNoticeException(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName}(Co-Borrower)`:""}`, boldFont, firstPage, 36, header.currentY-25);
      }
      if(data[i].phone){
        header=LRNNoticeException(`${data[i].phone?`Contact: ${data[i].phone}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      if(data[i]['Business name']){
        header=LRNbuildTextForPdf(`${data[i]['Business name']?`3. ${data[i]['Business name']}`:""}`, boldFont, firstPage, 36, header.currentY-20);
      }
      if(data[i]['borrower Business Address']){
        header=LRNbuildTextForPdf(`${data[i]['borrower Business Address']?`Having address at: ${data[i]['borrower Business Address']}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      else if(data[i]['Business name']){
        header=LRNbuildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }

      header=LRNbuildTextForPdf(Subject, boldFont, firstPage, 36, header.currentY-25);
      header=LRNbuildTextForPdf(`Respected Sir/Madam,`, boldFont1, firstPage, 36, header.currentY-20);
      header=LRNbuildTextForPdf(ParaOne, boldFont1, firstPage, 36, header.currentY-25);
      header=LRNbuildTextForPdf(PointOne, boldFont1, firstPage,50, header.currentY-25);
      header=LRNbuildTextForPdf(PointTwo, boldFont1, firstPage, 50, header.currentY-25);
      // header=LRNbuildTextForPdf(`3. Pursuant to the loan sanction letter:-`, boldFont1, firstPage, 50, header.currentY-25);
      header=LRNbuildTextForPdf(PointThree, boldFont1, firstPage, 50, header.currentY-25);
      header=LRNbuildTextForPdf(PointFour, boldFont1, firstPage, 50, header.currentY-25);
      header=LRNbuildTextForPdf(PointFive, boldFont1, secondPage, 50, 700);
      header=LRNbuildTextForPdf(PointSix, boldFont1, secondPage, 50, header.currentY-25);
      header=LRNbuildTextForPdf(PointSeven, boldFont1, secondPage, 50, header.currentY-25);
      header=LRNbuildTextForPdf(PointEight, boldFont1, secondPage, 50, header.currentY-25);
      header=LRNbuildTextForPdf(PointNine, boldFont1, secondPage, 50, header.currentY-25);
      header=LRNbuildTextForPdf(ParaTwo, boldFont1, secondPage, 36, header.currentY-25);
      header=LRNbuildTextForPdf(ParaThree, boldFont1, secondPage, 36, header.currentY-25);
      header=LRNbuildTextForPdf(ParaFour, boldFont1, secondPage, 36, header.currentY-25);
      header=LRNbuildTextForPdf(ParaFive, boldFont1, secondPage, 36, header.currentY-25);
      header=LRNbuildTextForPdf(ParaSix, boldFont1, secondPage, 36, header.currentY-25);

      // const combinedPdfBytes = await combinedPdf.save();
      // const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
      // const url = URL.createObjectURL(blob);
      // const link = document.createElement("a");
      // link.href = url;
      // link.download = "Generated LRN Notice.pdf";
      // link.click();
      // setIsLoading(false);
    }  
    const combinedPdfBytes = await combinedPdf.save();
    const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Generated LRN Notice.pdf";
    link.click();
    setIsLoading(false);
  };

  const InvocationNotice=async (email, timeStamp, letterHead)=>{
    timeStamp = +timeStamp;
    console.log(email, letterHead, timeStamp, "Invocation Notice" );
    setIsLoading(true);
    let pdfFilePath;
    if (letterHead === "Joylin Rego, Mumbai") {
      pdfFilePath= await fetch(Joylin5).then((res) =>res.arrayBuffer());
    } else {
      if (letterHead === "Gurugaon") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Bangalore") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Ambernath") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Hyderabad") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Thane") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Delhi") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "New Delhi") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      }
    }

    const data = await axios
    .post("https://server1-jlfh.onrender.com/getCsvByTimestamp", {
      email,
      timeStamp,
    })
    .then((res) => res.data
    )
    .catch((err) => {
      console.log(err);
      return [];
    });

    console.log(data.length, "data.length");
    const combinedPdf = await PDFDocument.create();
    combinedPdf.registerFontkit(fontkit);
    for(let i=0;i<data.length;i++){
      console.log(i, 'i');
      const pdfDoc = await PDFDocument.load(pdfFilePath);
      pdfDoc.registerFontkit(fontkit);
      const boldFont =await combinedPdf.embedFont(StandardFonts.TimesRomanBold);
      const boldFont1=await combinedPdf.embedFont(StandardFonts.TimesRoman);
      const boldFont2= await combinedPdf. embedFont(StandardFonts.TimesRomanItalic);
      const [newPage] = await combinedPdf.copyPages(pdfDoc, [0]);
      combinedPdf.addPage(newPage);
      let pageindex = i * 2;
      const firstPage = combinedPdf.getPages()[pageindex];
      const [SecondnewPage] = await combinedPdf.copyPages(pdfDoc, [1]);
      combinedPdf.addPage(SecondnewPage);
      let pageindex1 = i * 2 + 1;
      const secondPage = combinedPdf.getPages()[pageindex1];
      const refText=`Ref: “LOAN AGREEMENT” executed with ${data[i].borrowerName} (Borrower) ${data[i].coBorrowerName?`& ${data[i].coBorrowerName} (Co-borrower/Guarantor)`:""} having loan number ${data[i].loanID}.`
      const Subject=`SUB: NOTICE FOR INVOCATION OF ARBITRATION AND NOMINATION OF ARBITRATOR TO ADJUDICATE THE CLAIM/DISPUTE.`
      const firstPara=`I am concerned for ${data[i].claimantCompany} (herein referred as my client), having its registered address at ${data[i].claimantCompanyAddress}, and this is the final loan recall notice against the outstanding loan amount of Rs. ${data[i].claimAmount}/-`;
      const PointOne=`1. My client is one of the leading Non-Banking Financial Service company as per the provisions of the Reserve Bank of India Act, 1934 engaged in the business of providing financial assistance and loan facilities for various purposes.`;
      const PointTwo=`2. The Borrowers approached my client and requested to sanction a loan facility for monthly instalments of Rs. ${data[i].emi}/- against the total loan amount of Rs. ${data[i].totalLoan}/- starting from ${data[i].emiStart}(start date) till ${data[i].emiEnd} (end date).`//- at ${data[i].interest}% interest per annum.
      const PointThree=`3. Pursuant to the sanction letter, the Borrowers duly executed and signed the following documents: `
      const PointThreeA=`a. Loan Application Form, Agreed EMI, Interest and Loan amount, Hypothecation and Mortgage Deed, Demand Promissory Note and Loan Agreement.`
      const PointThreeB=` b. The Borrowers have duly acknowledged all the terms and conditions of the above-mentioned documents.`
      const PointFour=`4. That after duly executing the above-mentioned documents and after acknowledging all the terms and conditions of the Loan Agreement by the Borrowers, the loan was disbursed and the same has been duly utilized by the Borrowers.`
      const PointFive=`5. That even after promising to pay the monthly installment on due time against the above-mentioned loan, The Borrowers have miserably failed to pay the agreed monthly installment amount as per the Loan Agreement, and hence till date the amount of Rs. ${data[i].claimAmount}/-.`
      const PointSix=`6. My client states that it has many times reminded the Borrowers to repay the loan amount under the above-said Loan Agreement. That despite several reminders sent by my client, the Borrowers have failed to pay the same and neglected to repay the Loan Amount.`
      const PointSeven=`My client states that due to the default in payment by the Borrowers of the monthly installment amount and duly to the delayed payment charges on the principal amount the total amount due has now reached Rs. ${data[i].claimAmount}/- (Total Outstanding).`
      const PointEight=`8. It is thus evident that disputes and differences have arisen between my client and you addressee with respectto the Loan Agreement. Concomitantly, as per Loan Agreement in terms of Clause ${data[i].clauseNo} of the GENERALTERMS AND CONDITIONS (T&C) —AS APPLICABLE TO ${data[i].loanType} LOAN FACILITIES OF ${data[i].claimantCompany}, the dispute is now to be resolved by arbitration. The relevant clause has been quoted herein below: -`
      const PointTen=`9. As per the above-mentioned clause my client hereby nominates below-mentioned arbitrators from the Panel of PrivateCourt (Arbitration and Conciliation Institution) having address at 704, 7th Floor, Palm Court, Mehrauli Gurgaon Road, Sector 16, Gurgaon - 122007.`
      const PointTenA=` a) Adv. Vipin Sharma b) Adv. Vishal Tiwari c) Adv. Prerna Sharma`
      const PointEleven=`10. That in the present case my client has a claim against you addressee for recovery of the outstanding loan amount of Rs. ${data[i].claimAmount}/- along with future applicable interest of ${data[i].interest}% as provided in the respective documents and hence in respect of the said claim, my client hereby raises this Arbitration Proceedings.`
      // 704, 7th Floor, Palm Court, Mehrauli Gurgaon Road, Sector 16, Gurgaon (Haryana), 122007.6-Jan-2024

      InvocationTextForPdf("Date: 17-May-2024", boldFont, firstPage, 420, 670);
      InvocationTextForPdf(`Ref No. ${data[i].refNo}`, boldFont, firstPage, 36, 700)
      InvocationTextForPdf(`To,`, boldFont, firstPage, 36, 670)
      let header=InvocationTextForPdf(`${data[i].borrowerName?`1. ${data[i].borrowerName}(Borrower)`:""}`, boldFont, firstPage, 36, 650);
      header=InvocationTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      if(data[i].borrowerEmail){
        header=InvocationTextForPdf(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      if(data[i].borrowerPhone){
        header=InvocationTextForPdf(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont, firstPage, 36, header.currentY-15);
      }

      if(data[i].coBorrowerName){
        header=InvocationTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName}(Co-Borrower)`:""}`, boldFont, firstPage, 36, header.currentY-20);
      }
      if(data[i].coBorrowerAddress){
        header=InvocationTextForPdf(`${data[i].coBorrowerAddress?`Having address at: ${data[i].coBorrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15)
      }

      if(data[i].coBorrowerEmail){
        header=InvocationTextForPdf(`${data[i].coBorrowerEmail?`E-mail: ${data[i].coBorrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }

      if(data[i].phone){
        header=InvocationTextForPdf(`${data[i].phone?`Contact: ${data[i].phone}`:""}`, boldFont, firstPage, 36, header.currentY-15);
      }

      if(data[i].businessName){
        header=InvocationTextForPdf(`${data[i].businessName?`3. ${data[i].businessName}`:""}`, boldFont, firstPage, 36, header.currentY-20);
      }

      if(data[i].businessAddress){
        header=InvocationTextForPdf(`${data[i].businessAddress?`Having address at: ${data[i].businessAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      header=InvocationTextForPdf(refText, boldFont, firstPage, 36, header.currentY-25);
      header=InvocationTextForPdf(Subject, boldFont, firstPage, 36, header.currentY-25);
      header=InvocationTextForPdf(`Respected Sir/Madam,`, boldFont1, firstPage, 36, header.currentY-20);
      header=InvocationTextForPdf(firstPara, boldFont1, firstPage, 36, header.currentY-20);
      header=InvocationTextForPdf(PointOne, boldFont1, firstPage,50, header.currentY-20);
      header=InvocationTextForPdf(PointTwo, boldFont1, firstPage, 50, header.currentY-20);
      header=InvocationTextForPdf(PointThree, boldFont1, firstPage, 50, header.currentY-20);
      header=InvocationTextForPdf(PointThreeA, boldFont1, firstPage, 60, header.currentY-20);
      header=InvocationTextForPdf(PointThreeB, boldFont1, firstPage, 60, header.currentY-20);
      header=InvocationTextForPdf(PointFour, boldFont1, firstPage, 50, header.currentY-20);
      header=InvocationTextForPdf(PointFive, boldFont1, firstPage, 50, header.currentY-20);
      header=InvocationTextForPdf(PointSix, boldFont1, firstPage, 50, header.currentY-20);
      header=InvocationTextForPdf(PointSeven, boldFont1, secondPage, 50, 700);
      header=InvocationTextForPdf(PointEight, boldFont1, secondPage, 50, header.currentY-20);
      // header=InvocationTextForPdf('Clause 14.6:-', boldFont1, secondPage, 60, header.currentY-20);
      header=InvocationTextForPdf(data[i].clause, boldFont2, secondPage, 60, header.currentY-20);
      header=InvocationTextForPdf(PointTen, boldFont1, secondPage, 50, header.currentY-20);
      header=InvocationTextForPdf(PointTenA, boldFont1, secondPage, 60, header.currentY-15);
      header=InvocationTextForPdf(PointEleven, boldFont1, secondPage, 50, header.currentY-20);
    }  
    const combinedPdfBytes = await combinedPdf.save();
    const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Generated Invocation Notice.pdf";
    link.click();
    setIsLoading(false);
  };

  const ArbitrationNotice=async(email, timeStamp, letterHead)=>{
    // let { email, timeStamp,letterHead } = req.body;
    // let pdfFilePath = path.join(__dirname,"..","files","Jolly.pdf");
    // let ImageFilePath=path.join(__dirname,"..","files","Joylin Sign and Stamp.png");
    // Create a new combined PDF document
    let start=0;
    // const existingPdf = fs.readFileSync(pdfFilePath);
    // const getImage=fs.readFileSync(ImageFilePath);
    // const pdfDoc1 = await PDFDocument.load(existingPdf);
    // const pdfDoc = await PDFDocument.load(existingPdf);
    // const pngImage = await pdfDoc.embedPng(getImage)
    // console.log(pdfDoc,pdfDoc1,'line main Function')

    timeStamp = +timeStamp;
    console.log(email, letterHead,timeStamp, "Arbitration Notice" );
    // console.log(JoylinSign);
    setIsLoading(true);
    let pdfFilePath;
    if (letterHead === "Joylin Rego, Mumbai") {
      pdfFilePath= await fetch(Joylin2).then((res) =>res.arrayBuffer());
    } else {
      if (letterHead === "Gurugaon") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Bangalore") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Ambernath") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Hyderabad") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Thane") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Delhi") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "New Delhi") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      }
    }
    
    const data = await axios
    .post("https://server1-jlfh.onrender.com/getCsvByTimestamp", {
      email,
      timeStamp,
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });

    console.log(data.length, "Data length");
    const combinedPdf = await PDFDocument.create();
    combinedPdf.registerFontkit(fontkit);
    // const boldFont =await pdfDoc.embedFont(StandardFonts.TimesRomanBold);
    // const boldFont1=await pdfDoc.embedFont(StandardFonts.TimesRoman);
  
    for(let i=0;i<data.length;i++){
      console.log(i);
      const NewRef = generateRandomInvoiceID();
      const loanID = data[i].loanID;

      if (data[i].refNo === undefined || data[i].refNo === null) {
        const newData = {
          ...data[i],  
          refNo: NewRef,
        };
        axios
          .post("https://server1-jlfh.onrender.com/saveDataByLoanID", {
            email,
            loanID,
            data: newData,
          })
          .then((res) => {
            console.log("changes");
          })
          .catch((err) => {
            console.log(err);
          });
      }
      const pdfDoc = await PDFDocument.load(pdfFilePath);

      let pages = pdfDoc.getPages();
      pdfDoc.registerFontkit(fontkit);
      const [fontDataRegular, fontDataMedium] = await Promise.all([
        fetch(fontPathRegular).then(response => response.arrayBuffer()),
        fetch(fontPathMedium).then(response => response.arrayBuffer())
      ]);
      // const imageBytes = await fetch(JoylinSign).then((res) => res.arrayBuffer());
      // const response = await axios.get(JoylinSign, { responseType: 'arraybuffer' });
      // const imageBytes = response.data;
      // const uint8Array = new Uint8Array(imageBytes);
      // console.log(uint8Array);
      // const [jpgImage] = await combinedPdf.embedJpg(uint8Array);
      // console.log(jpgImage, imageBytes);
    
      const font = await combinedPdf.embedFont(fontDataRegular, { subset: true });
      const font1 = await combinedPdf.embedFont(fontDataMedium, { subset: true });
      const boldFont =await combinedPdf.embedFont(StandardFonts.TimesRomanBold);
    const boldFont1=await combinedPdf.embedFont(StandardFonts.TimesRoman);
    // const [jpgImage] = await combinedPdf.embedJpg(imageBytes);
    // console.log(jpgImage)
    const [newPage]=await combinedPdf.copyPages(pdfDoc, [0]);
    combinedPdf.addPage(newPage);
    // let pageindex=i*2;
    const firstPage=combinedPdf.getPages()[start]; 
    const concered=`I am concerned for ${data[i].claimantCompany} (herein referred to as my client), having its registered address at Z-18. IIIrd Floor, Zoone-1, M.P. Nagar, Bhopal – 462011, and this notice is for invoking the Arbitration clause as duly provided in the agreement executed between my client and you addressee. `
    const PointOne=`1. My client is one of the leading Non-Banking Financial Services companies as per the provisions of the Reserve Bank of India Act, 1934, engaged in the business of providing financial assistance and loan facilities for Educational purposes. `
    const PointTwo=`2. The Borrower approached my client and requested to sanction a loan facility for monthly instalments of Rs.${data[i].emi}/- (Rupees ${converter.toWords(data[i].emi)}) against the total loan amount of Rs.${data[i].totalLoan}/- (Rupees ${converter.toWords(data[i].totalLoan)}) starting from ${data[i].emiStart} until ${data[i].emiEnd}.`
    const PointThreeA=`a. the Borrower duly executed and signed the following documents: Demand Promissory Note and Loan Agreement.`
    const PointThreeB=`b. the Borrower has duly acknowledged all the terms and conditions of the above-mentioned documents. `
    const PointFour=`4. That even after executing the above-mentioned documents and after acknowledging all the terms and conditions of the Loan Agreement by the Borrower, the loan was disbursed, and the same has been utilized by the Borrower.`
    const PointFive=`5. That even after promising to pay the monthly EMI on due time against the above-mentioned loan, the Borrower has miserably failed to pay the agreed monthly instalment amount as per the Loan Agreement. Hence, as of the current date, the total outstanding amount is Rs.${data[i].totalLoan}/- (Rupees ${converter.toWords(data[i].totalLoan)} Only). `
    const PointSix=`6. My client states that it has many times reminded the Borrower to repay the loan amount under the abovementioned Loan Agreement. That despite several reminders sent by my client, the Borrower has failed to pay the same and neglected to repay the Loan Amount. `
    const PointSeven=`7. My client states that due to the default in payments by the Borrower of the EMI amount and due to the delayed payment charges on the principal amount, the total amount due has now reached Rs.${data[i].claimAmount}/- (Rupees ${converter.toWords(data[i].claimAmount)} Only) (Total Outstanding). `
    const PointEight=`8. It is thus evident that disputes and differences have arisen between my client and you addressee with respect to the Loan Agreement. Concomitantly, in terms of Clause 04 of the GENERAL TERMS AND CONDITIONS OF LOAN, the dispute is now to be resolved by arbitration. The relevant clause has been quoted herein below: -`
    const testingParagraph=`${data[i]['Dispute Resolution Clause']}`
    const PointNine=`9. As per the above-mentioned clause my client hereby nominates below-mentioned arbitrators from the Panel of PrivateCourt (Arbitration and Conciliation Institution) having address at 208 - Globe Business Park, MIDC, Kalyan Badlapur Road, Ambernath West, Thane- 421505: `;
    const PointNineA=`a. Adv. Vipin Sharma (Having address at: Onlooker Building, 4th Floor, Office No.32, Sir. P.M. Road, Opposite Axis Bank Fort Branch, Mumbai, Bora Bazar Precinct, Ballard Estate, Fort, Mumbai – 400001) `
    const PointNineB=`b. Adv. Amod Dalela (G-30, Masjid Moth, Greater Kailash-II, New Delhi-110048) and`
    const PointNineC=`c. Adv. Prerna Sharma (Flat 101 Building 8D, Alica Nagar, Lokhandwala Township, Kandivali East Mumbai, Pin Code-400 101)`
    const PointD=`(all the advocates with more than 10 years of experience), one of whom to be appointed as sole arbitrator to adjudicate this dispute. `
    const LastPara=`That in the present case my client has a claim against you addressee for recovery of the outstanding loan amount of Rs. ${data[i].claimAmount}/- (Rupees ${converter.toWords(data[i].claimAmount)} Only) along with future applicable interest as provided in the respective documents and hence in respect of the said claim, my client hereby raises these Arbitration Proceedings. `
    // let firstPage=pages[start];
    let header=ArbitrationbuildTextForPdf("Date: " + getFormattedDate(), boldFont, firstPage, 420, 700,pdfDoc, combinedPdf); 
    header=ArbitrationbuildTextForPdf(`To,`, boldFont, firstPage, 36, 685,pdfDoc, combinedPdf)
    header=ArbitrationbuildTextForPdf(`${data[i].borrowerName} (BORROWER)`, boldFont, firstPage, 36, header.currentY-20,pdfDoc, combinedPdf)
    header=ArbitrationbuildTextForPdf(`${data[i].borrowerAddress}`, boldFont1, firstPage, 36, header.currentY-20,pdfDoc, combinedPdf)
    header=ArbitrationbuildTextForPdf(`E-mail: ${data[i].borrowerEmail} Mobile:- ${data[i].borrowerPhone} `, boldFont1, firstPage, 36, header.currentY-15,pdfDoc, combinedPdf);
    header=ArbitrationbuildTextForPdf(`Ref: “LOAN AGREEMENT “executed with ${data[i].borrowerName} (Borrower) having Loan ID No: ${data[i].loanID} `, boldFont1, firstPage, 36, header.currentY-40,pdfDoc, combinedPdf);
    header=ArbitrationbuildTextForPdf(`SUB: NOTICE FOR INVOCATION OF ARBITRATION AND NOMINATION OF ARBITRATOR TO ADJUDICATE THE CLAIM/DISPUTE. `, boldFont, firstPage, 36, header.currentY-25,pdfDoc, combinedPdf);
    header=ArbitrationbuildTextForPdf(`Sir,`, boldFont1, firstPage, 36, header.currentY-20,pdfDoc, combinedPdf)
    header=ArbitrationbuildTextForPdf(concered, boldFont1, firstPage, 36, header.currentY-20,pdfDoc, combinedPdf)
    header=ArbitrationbuildTextForPdf(PointOne, boldFont1, firstPage, 50, header.currentY-25,pdfDoc, combinedPdf)
    header=ArbitrationbuildTextForPdf(PointTwo, boldFont1, firstPage, 50, header.currentY-25,pdfDoc, combinedPdf)
    header=ArbitrationbuildTextForPdf(`3. Pursuant to the loan sanction letter`, boldFont1, firstPage, 50, header.currentY-25,pdfDoc, combinedPdf);
    header=ArbitrationbuildTextForPdf(PointThreeA, boldFont1, firstPage, 60, header.currentY-25,pdfDoc, combinedPdf);
    header=ArbitrationbuildTextForPdf(PointThreeB, boldFont1, firstPage, 60, header.currentY-25,pdfDoc, combinedPdf);
    header=ArbitrationbuildTextForPdf(PointFour, boldFont1, firstPage, 50, header.currentY-25,pdfDoc, combinedPdf);
    header=ArbitrationbuildTextForPdf(PointFive, boldFont1, firstPage, 50, header.currentY-25,pdfDoc, combinedPdf);
    header=ArbitrationbuildTextForPdf(PointSix, boldFont1, firstPage, 50, header.currentY-25,pdfDoc, combinedPdf);
    // combinedPdf.addPage()
    // const secondPage=combinedPdf.getPages()[start+1];
    header=ArbitrationbuildTextForPdf(PointSeven, boldFont1, header.currentPage, 50, header.currentY-25,pdfDoc, combinedPdf);
    header=ArbitrationbuildTextForPdf(PointEight, boldFont1, header.currentPage, 50, header.currentY-25,pdfDoc, combinedPdf);
    // header=ArbitrationbuildTextForPdf(`Arbitration:`,boldFont1,header.currentPage,60,header.currentY-25,pdfDoc, combinedPdf);
    header=ArbitrationbuildTextForPdf(testingParagraph,boldFont1,header.currentPage,50,header.currentY-25,pdfDoc, combinedPdf);
    header=ArbitrationbuildTextForPdf(PointNine,boldFont1,header.currentPage,50,header.currentY-25,pdfDoc, combinedPdf);
    header=ArbitrationbuildTextForPdf(PointNineA,boldFont1,header.currentPage,60,header.currentY-25,pdfDoc, combinedPdf);
    header=ArbitrationbuildTextForPdf(PointNineB,boldFont1,header.currentPage,60,header.currentY-25,pdfDoc, combinedPdf);
    header=ArbitrationbuildTextForPdf(PointNineC,boldFont1,header.currentPage,60,header.currentY-25,pdfDoc, combinedPdf);
    header=ArbitrationbuildTextForPdf(PointD,boldFont1,header.currentPage,60,header.currentY-25,pdfDoc, combinedPdf);
    header=ArbitrationbuildTextForPdf(LastPara,boldFont1,header.currentPage,36,header.currentY-25,pdfDoc, combinedPdf);
    // console.log(header.currentPage);
    // combinedPdf.copyPages(header.currentPage, [0]);
    const page=header.currentPage;
    // const imageBytes = await fetch(JoylinSign).then((res) => res.arrayBuffer());

    // const [jpgImage] = await page.embedJpg(imageBytes);
    // const response = await axios.get(JoylinSign, { responseType: 'arraybuffer' });
    //   const imageBytes = response.data;
    //   const uint8Array = new Uint8Array(imageBytes);

    //   console.log(uint8Array);
    //   const [jpgImage] = await combinedPdf.embedJpg(uint8Array);
    // console.log(jpgImage)
    // const { width, height } = jpgImage.scale(0.5); // Adjust the scale as needed

    // page.drawImage(jpgImage, {
    //   x: 400,
    //   y: header.currentY-120,
    //   width,
    //   height
    // })
    // const pdfImage = new PDFImage(JoylinSign);

    // Draw the image on the PDF page
    // page.drawImage(pdfImage, {x: 400, y: header.currentY - 120, width: 100, height: 100});
    start+=2;
    }
    const combinedPdfBytes = await combinedPdf.save();
    const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url; 
    link.download = "Generated Arbitration Notice.pdf";
    link.click();
    setIsLoading(false);
  };

  const AcceptanceNotice= async(email, timeStamp, letterHead)=>{
    timeStamp = +timeStamp;
    console.log(email, letterHead,timeStamp, "Acceptance Notice" );
    setIsLoading(true);
    let pdfFilePath;
    if (letterHead === "Joylin Rego, Mumbai") {
    const letterheadResponse = await axios.get('letterhead.pdf');
        pdfFilePath = await letterheadResponse.data;
    } else {
      if (letterHead === "Gurugaon") {
        pdfFilePath= await fetch(Acceptance_Gurugram).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Bangalore") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Concilation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Ambernath") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Concilation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Hyderabad") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Concilation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Thane") {
        pdfFilePath= await fetch(Acceptance_Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Delhi") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Concilation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "New Delhi") {
        pdfFilePath= await fetch(newDelhi).then((res) =>res.arrayBuffer());
      }
    }
    const data = await axios
      .post("https://server1-jlfh.onrender.com/getCsvByTimestamp", {
        email,
        timeStamp,
      })
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        return [];
      });
      console.log(data.length);
      const combinedPdf = await PDFDocument.create();
      combinedPdf.registerFontkit(fontkit);
      let len=data.length;

    // const combinedPdf = await PDFDocument.create();
    let j=0;
    for(let i=0;i<data.length;i++){
      console.log(i);
      // const NewRef = generateRandomInvoiceID();
      // const loanID = data[i].loanID;
      
      const arrayBuffer1 = await fetch(getImage).then(res => res.arrayBuffer());
      const arrayBuffer2 = await fetch(getSign).then(res => res.arrayBuffer());
      const arrayBuffer3 = await fetch(getSign2).then(res => res.arrayBuffer());
      const arrayBuffer4 = await fetch(getVTStamp).then(res=> res.arrayBuffer());
      // console.log(arrayBuffer4, "arrayBuffer4");
      const pdfDoc = await PDFDocument.load(pdfFilePath);
      // const image4 = await pdfDoc.embedPng(arrayBuffer1);
      const pngImage = await combinedPdf.embedPng(arrayBuffer1);
      const pngImage1 = await combinedPdf.embedPng(arrayBuffer2);
      const pngImage2 = await combinedPdf.embedPng(arrayBuffer3);
      const pngImage3 = await combinedPdf.embedPng(arrayBuffer4);

      // const pngImage1= await combinedPdf.embedPng(arrayBuffer2);
      const boldFont =await combinedPdf.embedFont(StandardFonts.TimesRomanBold);
      const boldFont1=await combinedPdf.embedFont(StandardFonts.TimesRoman);
      // const pages = pdfDoc.getPages();
      // const firstPage = pages[0];
     const [newPage]=await combinedPdf.copyPages(pdfDoc,[0]);
     combinedPdf.addPage(newPage);
     let pageindex=j*3;
     const firstPage=combinedPdf.getPages()[pageindex];
     const scheduleDate=calculateDateAfter15Days();
     const subject=`Sub: ARBITRATOR ORGANIZATION’S DECLARATION FOR ACCEPTANCE OF RESPONSIBILITY AND STATEMENT OF INDEPENDENCE`
     const PointOne=`I, the undersigned, the Registrar of PrivateCourt, do hereby declare that I accept to appoint an Arbitrator in the instant case, and I do not have a financial and personal interest in the outcome of the Award, which is likely to disqualify me to appoint an impartial and independent arbitrator in the above-referred Arbitration Case.`
     const PointTwo=`There is no such ground of the past or present relationship with or interest in any of the parties or in relation to the subject matter in dispute or other kinds, which is likely to give rise to justifiable doubts as to my independence or impartiality, more specifically laid down in the Fifth and Sixth Schedule to the Arbitration and Conciliation Act, 1996 as amended till date.`
     const PointThree=`I further declare that my organization does not have any relationship with the parties or counsels or the subject matter of the dispute falling under any of the categories specified in the Seventh Schedule of the Act.`
     const PointFour=`It is also declared by me that my Organization shall act as an independent and impartial Arbitration Organization.`
     const PointFive=`We hereby Appoint Advocate Vishal Tiwari as the sole Arbitrator to adjudicate your dispute`
     const PointSix=`The matter is kept on ${data[i].meetingDate}, 12:00 PM to 01:00 PM (IST) India for the 1st Arbitration Hearing through the below-mentioned Zoom link:`
     const LastPara1=`I, the undersigned, Advocate Vishal Tiwari, do hereby accept the responsibility of Sole Arbitrator at the request of PrivateCourt.`
     const LastPara2=`I am in receipt of the ARBITRATION ORGANIZATION DECLARATION FORM; and hence, have given my consent to act as Arbitrator vide this Arbitrator’s Confirmation Letter.`
     const LastPara3=`There is no such ground of the past or present relationship with or interest in any of the parties or in relation to the subject matter in dispute or other kinds, which is likely to give rise to justifiable doubts as to my independence or impartiality, more specifically laid down in the Fifth and Sixth Schedule to the Arbitration and Conciliation Act, 1996 as amended till date`
      const address=data[i].claimantCompanyAddress;
    //  let header=buildTextForPdf1("Date: " + getFormattedDate(), boldFont, firstPage, 420, 700,pdfDoc);
     let header=AcceptancebuildTextForPdf("ARBITRATION ORGANIZATION DECLARATION FORM ", boldFont1, firstPage, 150, 680);
     header=AcceptancebuildTextForPdf("Re: Arbitration Between", boldFont1, firstPage, 240, header.currentY-15);
     header=AcceptancebuildTextForPdf(`${data[i].claimantCompany}`, boldFont1, firstPage, 200, header.currentY-15);
     header=AcceptancebuildTextForPdf("…Claimant", boldFont1, firstPage, 250, header.currentY-15);
     header=AcceptancebuildTextForPdf(`${data[i].borrowerName}`, boldFont1, firstPage, 240, header.currentY-15);
     header=AcceptancebuildTextForPdf("…Respondent", boldFont1, firstPage, 250, header.currentY-15);
     header=AcceptancebuildTextForPdf(`(Case ID - ${data[i].caseID})`, boldFont1, firstPage, 220, header.currentY-15);
     header=AcceptancebuildTextForPdf(subject, boldFont1, firstPage, 36, header.currentY-25);
     header=AcceptancebuildTextForPdf(PointOne, boldFont1, firstPage, 36, header.currentY-25);
     header=AcceptancebuildTextForPdf(PointTwo, boldFont1, firstPage, 36, header.currentY-25);
     header=AcceptancebuildTextForPdf(PointThree, boldFont1, firstPage, 36, header.currentY-25);
     header=AcceptancebuildTextForPdf(PointFour, boldFont1, firstPage, 36, header.currentY-25);
     header=AcceptancebuildTextForPdf(PointFive, boldFont1, firstPage, 36, header.currentY-25);
     header=AcceptancebuildTextForPdf(PointSix, boldFont1, firstPage, 36, header.currentY-25);
     header=AcceptancebuildTextForPdf(`Join Zoom Meeting`, boldFont1, firstPage, 36, header.currentY-30);
     header=AcceptancebuildTextForPdf(`https://us06web.zoom.us/j/87227736696?pwd=tcjJqo5yoNsx5Dlgk2n8DIH9DBOKRk.1`, boldFont, firstPage, 36, header.currentY-15);
     header=AcceptancebuildTextForPdf(`Meeting ID: 872 2773 6696`, boldFont1, firstPage, 36, header.currentY-25);
     header=AcceptancebuildTextForPdf(`Passcode: 674051`, boldFont1, firstPage, 36, header.currentY-15);
     const page1=header.currentPage;
     page1.drawImage(pngImage2, {
      x: 350,
      y: header.currentY-50,
      width: 100,
      height: 50,
    })
      const today = new Date();
      const day = today.getDate();
      const month = today.getMonth()+1;
      const year = today.getFullYear();
     header=AcceptancebuildTextForPdf(`Date: `+data[i].noticeDate, boldFont, firstPage, 36, header.currentY-70,pdfDoc);
     header=AcceptancebuildTextForPdf(`Signature: Mr. Mahadev Gitte(Registrar)`, boldFont, firstPage, 280, header.currentY,pdfDoc);
     header=AcceptancebuildTextForPdf(`Place: `+data[i].seat, boldFont, firstPage, 36, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`Mobile: 8976955539`, boldFont, firstPage, 320, header.currentY,pdfDoc);
     const [SecondnewPage] = await combinedPdf.copyPages(pdfDoc, [1]);
      combinedPdf.addPage(SecondnewPage);
      let pageindex1=j*3+1;
      const secondPage = combinedPdf.getPages()[pageindex1];
     header=AcceptancebuildTextForPdf("E-mail: legal@privatecourt.in", boldFont, secondPage, 36, 700,pdfDoc);
     header=AcceptancebuildTextForPdf(`C.C`, boldFont1, secondPage, 45, header.currentY-50,pdfDoc);
     header=AcceptancebuildTextForPdf(`1.${data[i].borrowerName}(Borrower)`, boldFont, secondPage, 45, header.currentY-25,pdfDoc);
     if(data[i].coBorrowerName){
      header=AcceptancebuildTextForPdf(`2.${data[i].coBorrowerName}(Co-Borrower)`, boldFont, secondPage, 45, header.currentY-15,pdfDoc);
     }
     header=AcceptancebuildTextForPdf(`having address at:`, boldFont1, secondPage, 48, header.currentY-25,pdfDoc);
     header=AcceptancebuildTextForPdf(`${data[i].borrowerAddress}`, boldFont1, secondPage, 48, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`Contact :${data[i].borrowerPhone}`, boldFont1, secondPage, 48, header.currentY-15,pdfDoc);
    //  header=AcceptancebuildTextForPdf(`E-mail: ${data[i].borrowerEmail}`, boldFont1, secondPage, 48, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`3. ${data[i].claimantCompany}`, boldFont, secondPage, 48, header.currentY-30,pdfDoc);
     header=AcceptancebuildTextForPdf(address, boldFont1, secondPage, 48, header.currentY-15,pdfDoc);
     const [thirdnewPage] = await combinedPdf.copyPages(pdfDoc, [2]);
      combinedPdf.addPage(thirdnewPage);
      let pageindex2=j*3+2;
      const thirdPage = combinedPdf.getPages()[pageindex2];
     header=AcceptancebuildTextForPdf("ARBITRATION ORGANIZATION DECLARATION FORM ", boldFont1, thirdPage, 150, 670,pdfDoc);
     header=AcceptancebuildTextForPdf("Re: Arbitration Between", boldFont1, thirdPage, 240, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`${data[i].claimantCompany}`, boldFont1, thirdPage, 200, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf("…Claimant", boldFont1, thirdPage, 250, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf("Vs", boldFont1, thirdPage, 275, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`${data[i].borrowerName}`, boldFont1, thirdPage, 240, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf("…Respondent", boldFont1, thirdPage, 250, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`(Case ID - ${data[i].caseID})`, boldFont1, thirdPage, 240, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(LastPara1, boldFont1, thirdPage, 36, header.currentY-25,pdfDoc);
     header=AcceptancebuildTextForPdf(LastPara2, boldFont1, thirdPage, 36, header.currentY-25,pdfDoc);
     header=AcceptancebuildTextForPdf(LastPara3, boldFont1, thirdPage, 36, header.currentY-25,pdfDoc);
     const page=header.currentPage;
    //  page.drawImage(pngImage3, {
    //   x: 400,
    //   y: header.currentY-120,
    //   width: 100,
    //   height: 70,
    // });
    header=AcceptancebuildTextForPdf('Arbitrator',boldFont, thirdPage, 400, header.currentY-130, pdfDoc);
    header=AcceptancebuildTextForPdf('Adv. VISHAL TIWARI',boldFont, thirdPage, 380, header.currentY-15, pdfDoc);
     header=AcceptancebuildTextForPdf(`C.C`, boldFont1, thirdPage, 45, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`1.${data[i].borrowerName}(Borrower)`, boldFont, thirdPage, 45, header.currentY-25,pdfDoc);
     if(data[i].coBorrowerName){
      header=AcceptancebuildTextForPdf(`2.${data[i].coBorrowerName}(Co-Borrower)`, boldFont, thirdPage, 45, header.currentY-15,pdfDoc);
     }
     header=AcceptancebuildTextForPdf(`having address at:`, boldFont1, thirdPage, 48, header.currentY-25,pdfDoc);
     header=AcceptancebuildTextForPdf(`${data[i].borrowerAddress}`, boldFont1, thirdPage, 48, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`Contact :${data[i].borrowerPhone}`, boldFont1, thirdPage, 48, header.currentY-15,pdfDoc);
    //  header=AcceptancebuildTextForPdf(`E-mail: ${data[i].borrowerEmail}`, boldFont1, thirdPage, 48, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`3. ${data[i].claimantCompany}`, boldFont, thirdPage, 48, header.currentY-30,pdfDoc);
     header=AcceptancebuildTextForPdf(address, boldFont1, thirdPage, 48, header.currentY-15,pdfDoc);
     j++;
  }
  const combinedPdfBytes = await combinedPdf.save();
  const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "Generated Acceptance Letter.pdf";
  link.click();
  setIsLoading(false);
  };

  const HearingNotice=async(email, timeStamp, letterHead)=>{
    timeStamp = +timeStamp;
    console.log(email, letterHead,timeStamp, "Acceptance Notice" );
    setIsLoading(true);
    let pdfFilePath;
    if (letterHead === "Joylin Rego, Mumbai") {
      pdfFilePath= await fetch(Joylin).then((res) =>res.arrayBuffer());
    } else {
      if (letterHead === "Gurugaon") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Bangalore") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Ambernath") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Hyderabad") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Thane") {
        pdfFilePath= await fetch(HearingNotice_Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "Delhi") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      } else if (letterHead === "New Delhi") {
        pdfFilePath= await fetch(Thane).then((res) =>res.arrayBuffer());
      }
    }
    const data = await axios
      .post("https://server1-jlfh.onrender.com/getCsvByTimestamp", {
        email,
        timeStamp,
      })
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        return [];
      });
      console.log(data.length);
      const combinedPdf = await PDFDocument.create();
      combinedPdf.registerFontkit(fontkit);
      let len=data.length;
    for(let i=0;i<data.length;i++){
      console.log(i);
      const NewRef = generateRandomInvoiceID();
      const loanID = data[i].loanID;
      
      if (data[i].refNo === undefined || data[i].refNo === null) {
        const newData = {
          ...data[i], 
          refNo: NewRef,
        };
        axios
          .post("https://server1-jlfh.onrender.com/saveDataByLoanID", {
            email,
            loanID,
            data: newData,
          })
          .then((res) => {
            console.log("changes");
          })
          .catch((err) => {
            console.log(err);
          });
      }

      const pdfDoc = await PDFDocument.load(pdfFilePath);
      //  const pngImage = await combinedPdf.embedPng(getImage)
      //  const pngImage1 = await combinedPdf.embedPng(getSign);
       const boldFont =await combinedPdf.embedFont(StandardFonts.TimesRomanBold);
       const boldFont1=await combinedPdf.embedFont(StandardFonts.TimesRoman);
      //  const pages = pdfDoc.getPages();
      //  const firstPage = pages[0];
       const [newPage]=await combinedPdf.copyPages(pdfDoc,[0]);
       combinedPdf.addPage(newPage);
       let pageindex=i;
     const firstPage=combinedPdf.getPages()[pageindex];
     const scheduleDate=calculateDateAfter15Days();
     const subject=`Sub: 3rd Hearing Notice of Arbitration Proceedings.` 
     const PointOne=`1. The matter was kept on 28th November 2023 for the 2nd hearing of the Arbitration proceeding.`
     const PointTwo=`2. Both the Claimant and Respondent present`
     const PointThree=`3. Respondent files application for allowing opportunity to amicably settle the dispute.`
     const PointFour=`4. Both Parties are directed to Physically appear for the 3rd hearing on ${scheduleDate} at 05 PM at PDR Organization Pvt Ltd (PrivateCourt), 62, Third Floor, 3rd Street, Laxmi Nagar, Delhi - 110092 for order on interim relief. `
     let header=AcceptancebuildTextForPdf("BEFORE PRIVATECOURT ARBITRATION ORGANIZATION", boldFont1, firstPage, 150, 680);
     header=AcceptancebuildTextForPdf("Re: Arbitration Between", boldFont1, firstPage, 240, header.currentY-15);
     header=AcceptancebuildTextForPdf(`${data[i].claimantCompany}`, boldFont1, firstPage, 200, header.currentY-15);
     header=AcceptancebuildTextForPdf("…Claimant", boldFont1, firstPage, 250, header.currentY-15);
     header=AcceptancebuildTextForPdf("VS", boldFont1, firstPage, 270, header.currentY-20);
     header=AcceptancebuildTextForPdf(`${data[i].borrowerName}`, boldFont1, firstPage, 200, header.currentY-20);
     header=AcceptancebuildTextForPdf("…Respondent", boldFont1, firstPage, 250, header.currentY-15);
     header=AcceptancebuildTextForPdf(`(Case ID - ${data[i].refNo})`, boldFont1, firstPage, 220, header.currentY-15);
     header=AcceptancebuildTextForPdf(subject, boldFont, firstPage, 150, header.currentY-30);
     header=AcceptancebuildTextForPdf(PointOne, boldFont1, firstPage, 70, header.currentY-30);
     header=AcceptancebuildTextForPdf(PointTwo, boldFont1, firstPage, 70, header.currentY-20);
     header=AcceptancebuildTextForPdf(PointThree, boldFont1, firstPage, 70, header.currentY-20);
     header=AcceptancebuildTextForPdf(PointFour, boldFont1, firstPage, 70, header.currentY-20);
     const page1=header.currentPage;
    //  page1.drawImage(pngImage, {
    //   x: 380,
    //   y: header.currentY-80,
    //   width: 50,
    //   height: 50,
    // })
     header=AcceptancebuildTextForPdf(`Signature: Mr. VISHAL TIWARI (Registrar)`, boldFont1, firstPage, 300, header.currentY-100,pdfDoc);
     header=AcceptancebuildTextForPdf(`Mobile: 8976955539`, boldFont1, firstPage, 340, header.currentY-15,pdfDoc);
    //  const pdfBytes = await pdfDoc.save();
  // Set the response headers for file download
      // res.setHeader('Content-Type', 'application/pdf');
      // res.setHeader('Content-Disposition', 'attachment; filename="LetterHead.pdf"');
      // res.end(pdfBytes);
  }
  const combinedPdfBytes = await combinedPdf.save();
  const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "Generated Concilation.pdf";
  link.click();
  setIsLoading(false);
  };

  const handleDownload=async(email, timeStamp, letterHead, textType)=>{
    console.log(email, timeStamp, letterHead, textType , "handleDownload")
    if(textType=="Conciliation"){
      if(email=="payrupik@privatecourt.in"){
        ConciliationNoticePayrupik(email, timeStamp, letterHead);
      }
      else if(email=="unitybank@privatecourt.in"){
        ConciliationNotice(email, timeStamp, letterHead);
      }
      else if(email=='ayefinance@privatecourt.in'){
        ConciliationAyeFinanceNotice(email, timeStamp, letterHead);
      }
      else if(email=='sayyam@privatecourt.in'){
        ConciliationNoticeSayyam(email, timeStamp, letterHead)
      }
      else{
        ConciliationNotice(email, timeStamp, letterHead);
      }
    }
    else if(textType=='LRN'){
      // LRNNotice(email, timeStamp, letterHead);
      LRNNotice(email, timeStamp, letterHead);
    }
    else if(textType=="Arbitration"){
      ArbitrationNotice(email, timeStamp, letterHead);
    }
    else if(textType=="Acceptance"){
      AcceptanceNotice(email, timeStamp, letterHead);
    }
    else if(textType=="Hearing 1"){
      HearingNotice(email, timeStamp, letterHead);
    }
    else if(textType=='Invocation'){
        InvocationNotice(email, timeStamp, letterHead);
    }
  }

  const handleStartIndexChange = (e, indexTimeStamp) => {
    setStartIndex(e.target.value);
    setIndexTimeStamp(indexTimeStamp);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // fetchData();
    fetchData2();
  }, []);

  useEffect(() => {
    fetchData3();
  }, [page, perPage]);

  return isLoading ? (
    <Loading></Loading>
  ) : (
    <div className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3">
            <div className="left-wrapper">
              <Navbar2 />
              <h2 className="admin-name">
                {companyName ? companyName : email}
              </h2>
              <Link to="/dashboard">
                <button
                  type="button"
                  className="btn btn-light btn-sm back-btn mb-3"
                >
                  <i className="bi bi-arrow-left"></i> Back
                </button>
              </Link>
              {/* <h3 className="small-des">
                   You’ve an upcoming hearing in 3 hrs
                </h3> */}
                <button className="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    Notifications
                </button>
              <div className="" id="collapseExample">
                <div className="dot-bg">
                  
                  <h2 className="d-lg-block d-none">Notifications</h2>
                      <div className="alert alert-primary bg-transparent" role="alert">
                        LRN successfully <strong>generated</strong> and <strong>posted</strong>!
                      </div>
                  {/* <div
                    className="alert alert-primary bg-transparent"
                    role="alert"
                  >
                    No notification
                  </div> */}
                  {/* <div className="alert alert-primary bg-transparent" role="alert">
                        New Member <strong>‘Akasha Seth’</strong> added to the team. 
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        You have won the dispute against Deepak Jain. See the <a href="#" className="alert-link">Final Terms.</a>
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        2 upcoming Hearings tomorrow. <a href="#" className="alert-link">See full Schedule</a>
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        <div className="d-flex justify-content-between">
                            <div>
                                12 new in last 2 days
                            </div>
                            <div>
                                <a href="#" className="alert-link">view all</a>
                            </div>
                        </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="right-wrapper">
              <div className="conciliation-application-form">
                <form id="regForm" action="#">
                  <div className="col-lg-12">
                    <div className="card card-new h-100">
                      <h2 className="mb-3">Notice Info</h2>
                      {/* <div className="row">
                        <div className="col-lg-3 mb-3">
                          <label className="form-label form-label-new">
                            Select Upload date
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onClick={(e) => setTimeStamp(e.target.value)}
                          >
                            <option value="">Select date</option>
                            {timeStamps.map((item, index) => {
                              return (
                                <option key={item} value={item}>
                                  {convertMillisecondToDate(item)}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-lg-3 mb-3">
                          <label className="form-label form-label-new">
                            Choose Communication Type
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onClick={(e) => setComType(e.target.value)}
                          >
                            <option value="">Select Communication Type</option>
                            <option value="DPD">DPD</option>
                            <option value="Loan Recall Notices">Loan Recall Notices</option>
                            <option value="Invocation Notice">Invocation Notice</option>
                            <option value="Acceptance Notice">Acceptance Notice</option>
                            <option value="Conciliation Notice">Conciliation Notice</option>
                            <option value="Arbitration Hearing Notice">Arbitration Hearing Notice</option>
                          </select>
                        </div>
                        <div className="col-lg-3 mb-3">
                          <label className="form-label form-label-new">
                            Choose Letterhead
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onClick={(e) => setLetterhead(e.target.value)}
                          >
                            <option value="">Select Letterhead</option>
                            <option value="Joylin Rego, Mumbai">Joylin Rego, Mumbai</option>
                            <option value="New Delhi">New Delhi</option>
                            <option value="Delhi">Delhi</option>
                            <option value="Bangalore">Bangalore</option>
                            <option value="Ambernath">Ambernath</option>
                            <option value="Hyderabad">Hyderabad</option>
                            <option value="Thane">Thane</option>
                            <option value="Gurugaon">Gurugaon</option>
                          </select>
                        </div>
                        <div className="col-lg-3 mb-3">
                          <label className="form-label form-label-new">
                            Communication Strategy
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onClick={(e) => setStrategy(e.target.value)}
                          >
                            <option value="">Select Strategy</option>
                            <option>Digital</option>
                            <option>Postal</option>
                            <option>Digital & Postal</option>
                          </select>
                        </div>
                        <div className="col-lg-12 mb-3">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={handleNotice}
                            disabled={
                              !timeStamp || !comType || !letterhead || !strategy
                            }
                          >
                            Generate Notices
                          </button>
                        </div>
                      </div> */}
                      <div className="org-data nbfc-table mt-3">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">Action Date</th>
                              <th scope="col">Letterhead</th>
                              <th scope="col">Sheet</th>
                              <th scope="col">Single Approval</th>
                              <th scope='col'>Bulk Approval</th>
                              <th scope="col">Start Index</th>
                              <th scope="col">View details</th>
                              <th scope="col">Download</th>
                            </tr>
                          </thead>
                          <tbody>
                            {noticeInfos.length>0 ? (
                              <>
                                {noticeInfos.map((item, index) => {
                                  return (
                                    <tr>
                                      <td data-label="Action Date">{convertMillisecondToDate(item.timeStamp)}</td>
                                      <td data-label="Letterhead">{item.letterhead}</td>
                                      <td data-label="Sheet">{item.sheetName}</td>
                                      <td data-label="Single Approval">{item.approval}</td>
                                      <td data-label="Bulk Approval">{item.bulkApproval?item.bulkApproval:""}</td>
                                      {/* <td data-label="Status">{item.status}</td> */}
                                      <td data-label="Start Index">
                                        <input
                                          placeholder="Enter Index"
                                          type="text"
                                          value={item.timeStamp===indexTimeStamp?startIndex:0}
                                          onChange={(e)=>handleStartIndexChange(e, item.timeStamp)}
                                          className="form-control"
                                        />
                                      </td>
                                      <td data-label="View More Deatils">
                                        <button type="button" class="btn btn-primary"
                                        onClick={()=>{(props.history.push(`/noticeDetail/${item.timeStamp}`))}}>
                                        <i class="bi bi-eye text-white"></i>
                                        </button>
                                      </td>
                                      <td data-label="Download">
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          disabled={item.bulkApproval=='No'}
                                          onClick={()=>handleDownload(email, item.timeStamp, item.letterhead, item.textType)}
                                          // onClick={()=>handleNoticeDownload(email, item.timeStamp, item.letterhead)}
                                        >
                                          <i className="bi bi-download text-white"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                        <div className="row">
                          <div className="col-lg-3"></div>
                          <div className="col-lg-4">
                            <div className="data-pagi float-lg-end mt-lg-0 mt-2">
                              <select
                                className="form-control"
                                id="floatingSelect"
                                value={perPage ? perPage : 10}
                                onChange={(e) => setPerPage(e.target.value)}
                                aria-label="Floating label select example"
                              >
                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                              <div
                                className="btn-toolbar"
                                role="toolbar"
                                aria-label="Toolbar with button groups"
                              >
                                <div
                                  className="btn-group me-1"
                                  role="group"
                                  aria-label="First group"
                                >
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => setPage(+page - 1)}
                                    disabled={page <= 1}
                                  >
                                    <i className="bi bi-chevron-left text-white"></i>
                                  </button>
                                </div>
                                <div
                                  className="btn-group me-1"
                                  role="group"
                                  aria-label="Second group"
                                >
                                  <button
                                    type="button"
                                    className="btn text-primary"
                                  >
                                    {page}
                                  </button>
                                </div>
                                <div
                                  className="btn-group"
                                  role="group"
                                  aria-label="Third group"
                                >
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={noticeInfos.length < +perPage}
                                    onClick={() => setPage(+page + 1)}
                                  >
                                    <i className="bi bi-chevron-right text-white"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="updates-1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl">
          <div class="modal-content">
              <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Communication Details</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="org-data nbfc-table mt-3">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">
                          Loan ID
                        </th>
                        <th scope="col">                                
                          Borrower Name                               
                        </th>
                        <th scope="col">
                          Email                               
                        </th>
                        <th scope="col">
                          Phone                                
                        </th>
                        <th scope="col">
                          Post                                  
                        </th>    
                        <th scope="col">
                          Whatsapp                                  
                        </th>                                        
                      </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td data-label="Loan ID">

                            </td>
                            <td data-label="Borrower Name">

                            </td>
                            <td data-label="Email">

                            </td>
                            <td data-label="Phone">

                            </td>
                            <td data-label="Post">

                            </td>
                            <td data-label="Whatsapp">

                            </td>                     
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary">Save changes</button>
              </div>
          </div>
          </div>
      </div>
    </div>
  );
};
export default NBFCNotice;
