import { Link } from "react-router-dom";

const CardComponent = ({title, text, route='/' }) => {
    return (
      <div className="card text-white bg-primary mb-3 shadow">
        {/* <div className="card-header">{header}</div> */}
        <div className="card-body">
          <p className="card-text text-white">{text}</p>
          <h1 className="card-title text-truncate text-white">{title?parseInt(title):'...'}</h1>
          <p className="text-end text-white" ><Link to={route}><p className="text-white">more</p></Link></p>
        </div>
      </div>
    );
  };

  export default CardComponent;