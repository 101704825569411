import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from 'yup';
import Navbar2 from "./navbar2";
import { Link, withRouter } from "react-router-dom";
import auth from "../services/authService";
import http from "../services/httpService";
import Loading from "./loading";


const NBFCForm = (props) => {
    const email=auth.getUser().email;
    const [loanID, setLoanID] = useState(null);
    const [companyName, setCompanyName]=useState('');
    const [initialValues, setInitialValues]= useState({
      loanID: '', 
      agreementNo: '', 
      borrowerName: '', 
      borrowerAddress: '', 
      borrowerEmail: '', 
      borrowerPhone: '', 
      borrowerPanNumber: '', 
      coBorrowerName: '', 
      coBorrowerAddress: '', 
      coBorrowerEmail: '', 
      coPANNumber: '', 
      interest: '', 
      phone: '', 
      gstNumber: '', 
      cin: '', 
      vehicleNo: '', 
      rcNo: '', 
      drivingLicenceNo: '', 
      loanType: '',
      emi: '', 
      totalLoan: '', 
      emiStart: '', 
      emiEnd: '', 
      claimAmount: '', 
      seat: '', 
      bankAccountNo: '', 
      ifscCode: '', 
      disputeResolutionPaperID:"",
      claimantCompany:'',
      associateName:'',
      modificationTime:[]
    })
    const validationSchema = Yup.object().shape({
          NbfcDetails: Yup.object().shape({
          loanID: Yup.string().required('Loan ID is required'),
          agreementNo: Yup.string().required('Agreement No is required'),
          borrowerName: Yup.string().required('Borrower Name is required'),
          borrowerAddress: Yup.string().required('Borrower Address is required'),
          borrowerEmail: Yup.string().email('Invalid email address').required('Borrower Email is required'),
          borrowerPhone: Yup.string().required('Borrower Phone is required'),
          borrowerPanNumber: Yup.string().required('Borrower PAN Number is required'),
          coBorrowerName: Yup.string(),
          coBorrowerAddress: Yup.string(),
          coBorrowerEmail: Yup.string().email('Invalid email address'),
          coPANNumber: Yup.string(),
          interest: Yup.string(),
          phone: Yup.string(),
          gstNumber: Yup.string(),
          cin: Yup.string(),
          vehicleNo: Yup.string(),
          rcNo: Yup.string(),
          drivingLicenceNo: Yup.string(),
          loanType: Yup.string().required('Loan Type is required'),
          emi: Yup.string().required('EMI is required'),
          totalLoan: Yup.string().required('Total Loan is required'),
          emiStart: Yup.date().required('EMI Start date is required'),
          emiEnd: Yup.date().required('EMI End date is required'),
          claimAmount: Yup.string().required('Claim Amount is required'),
          seat: Yup.string(),
          bankAccountNo: Yup.string(),
          ifscCode: Yup.string(),
        }),
      });

      const saveData=async(values)=>{
        try{
          if(loanID){
            let response=await http.post('/saveDataByLoanID', {email, loanID, data:values});
            console.log(response.data, 'after Save');
            if(response.data=="CsvData updated successfully"){
              props.history.push('/ndetailedview');
            }
          }
        }
        catch(error){
          console.log(error);
        }
      }

      const handleSubmit=(values)=>{
        // let date = new Date().toLocaleDateString();
        // let time = new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        const now = new Date();
        const timeStamp= now.getTime();
        values.modificationTime.push(timeStamp);
        saveData(values);
        console.log(values);
      }


      const fetchData2 = async () => {
        try {
          const response = await http.post2('/getCompanyName', {
            email,
          });
          if (response.status != 400) {
            // console.log(response.data, 'response.data gstComponent');
            setCompanyName(response.data);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }

      const fetchData = async () => {
        const { loanID } = props.match.params;
        if (loanID) {
          try {
            let response = await http.post("/getCsvDatas", {
              search: loanID,
              email: email
            });
            console.log(response.data, "getDataByLoanID")
            setLoanID(loanID);
            setInitialValues(prevValues => ({...prevValues, ...response.data.data[0]}));
            console.log(initialValues);
          } catch (err) {
            console.log(err);
          }
        }
      };

      const [isLoading, setIsLoading] = useState(true);
      useEffect(() => {
        const timer = setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
      }, []);
      useEffect(()=>{
        fetchData();
        fetchData2();
      }, [loanID]);
  return (
    isLoading? <Loading></Loading>:
    <div className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3">
            <div className="left-wrapper">
              <Navbar2/>
              <h2 className="admin-name">{companyName?companyName:email}</h2>
              <Link to="/dashboard">
                  <button
                    type="button"
                    className="btn btn-light btn-sm back-btn mb-3"
                  >
                    <i className="bi bi-arrow-left"></i> Back
                  </button>
                </Link>
              {/* <h3 className="small-des">You’ve an upcoming hearing in 3 hrs</h3> */}
              {/* <button
                className="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none mb-3"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Notifications
              </button> */}
              <div className="" id="collapseExample">
                <div className="dot-bg">
                  <h2 className="d-lg-block d-none">Notifications</h2>
                  <div className="alert alert-primary bg-transparent" role="alert">
                    No notification
                  </div>
                </div> 
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="right-wrapper">
              <div className="conciliation-application-form">
                <Formik 
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}

                  >
              {({ values}) => (
                <Form>
                  <div className="col-lg-12">
                    <div className="card card-new h-100">
                      <h2 className="mb-3">NBFC DISPUTE</h2>
                      <div className="row">
                      <div className="col-lg-4 mb-3">
                          <label htmlFor="disputeResolutionPaperID"  className="form-label form-label-new">
                            Dispute Resolution Paper ID
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            id="disputeResolutionPaperID"
                            name="disputeResolutionPaperID"
                          />
                           {/* <ErrorMessage name="disputeResolutionPaperID" component="div" className="text-danger" /> */}
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="claimantCompany"  className="form-label form-label-new">
                            Claimant Company
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            id="claimantCompany"
                            name="claimantCompany"
                          />
                           {/* <ErrorMessage name="claimantCompany" component="div" className="text-danger" /> */}
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="associateName"  className="form-label form-label-new">
                            Associate Name
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            id="associateName"
                            name="associateName"
                          />
                           {/* <ErrorMessage name="associateName" component="div" className="text-danger" /> */}
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="loanID"  className="form-label form-label-new">
                            Loan ID
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            id="loanID"
                            disabled={true}
                            name="loanID"
                          />
                           {/* <ErrorMessage name="loanID" component="div" className="text-danger" /> */}
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="agreementNo" className="form-label form-label-new">
                            Agreement No
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"     
                            id="agreementNo"
                            name="agreementNo"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="borrowerName" className="form-label form-label-new">
                            Borrower Name
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            
                            id="borrowerName"
                            name="borrowerName"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="borrowerAddress" className="form-label form-label-new">
                            Borrower Address
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            id="borrowerAddress"
                            name="borrowerAddress"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="borrowerEmail" className="form-label form-label-new">
                            Borrower Email
                          </label>
                          <Field
                            type="email"
                            className="form-control form-control-new"   
                            id="borrowerEmail"
                            name="borrowerEmail"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="borrowerPhone" className="form-label form-label-new">
                            Borrower Phone
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            
                            id="borrowerPhone"
                            name="borrowerPhone"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="borrowerPanNumber"  className="form-label form-label-new">
                            Borrower Pan Number
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            
                            id="borrowerPanNumber"
                            name="borrowerPanNumber"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="coBorrowerName" className="form-label form-label-new">
                            Co - Borrower Name
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            id="coBorrowerName"
                            name="coBorrowerName"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="coBorrowerAddress" className="form-label form-label-new">
                            Co - Borrower Address
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            id="coBorrowerAddress"
                            name="coBorrowerAddress"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="coBorrowerEmail" className="form-label form-label-new">
                            Co - Borrower Email
                          </label>
                          <Field
                            type="email"
                            className="form-control form-control-new"
                            id="coBorrowerEmail"
                            name="coBorrowerEmail"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="coPANNumber" className="form-label form-label-new">
                            Co - PAN Number
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            
                            id="coPANNumber"
                            name="coPANNumber"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="interest" className="form-label form-label-new">
                            Interest
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            
                            id="interest"
                            name="interest"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="phone" className="form-label form-label-new">Phone</label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            
                            id="phone"
                            name="phone"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="gstNumber" className="form-label form-label-new">
                            GST Number of Any
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            
                            id="gstNumber"
                            name="gstNumber"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="cin" className="form-label form-label-new">CIN</label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            id="cin"
                            name="cin"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="vehicleNo" className="form-label form-label-new">
                            Vehicle No
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"                          
                            id="vehicleNo"
                            name="vehicleNo"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="rcNo" className="form-label form-label-new">RC No</label>
                          <Field
                            type="text"
                            className="form-control form-control-new"              
                            id="rcNo"
                            name="rcNo"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="drivingLicenceNo" className="form-label form-label-new">
                            Driving License No
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            id="drivingLicenceNo"
                            name="drivingLicenceNo"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label  htmlFor="loanType" className="form-label form-label-new">
                            Loan Type
                          </label>
                          <Field
                            as='select'
                            type='text'
                            className="form-select"
                            id="loanType"
                            name="loanType"
                          >
                            {/* <option selected>1</option> */}
                            <option value='New Lead'>New Lead</option>
                            <option value='Progress'>Progress</option>
                            <option value='Confirmed'>Confirmed</option>
                            <option value='Paid'>Paid</option>
                            {/* <option value="3">4</option>
                            <option value="3">5</option> */}
                          </Field>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="emi" className="form-label form-label-new">EMI</label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            id="emi"
                            name="emi"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="totalLoan" className="form-label form-label-new">
                            Total Loan
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            id="totalLoan"
                            name="totalLoan"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="emiStart" className="form-label form-label-new">
                            EMI Start
                          </label>
                          <Field
                            type="date"
                            className="form-control form-control-new"
                            id="emiStart"
                            name="emiStart"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="emiEnd" className="form-label form-label-new">
                            EMI End
                          </label>
                          <Field
                            type="date"
                            className="form-control form-control-new"
                            id="emiEnd"
                            name="emiEnd"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="claimAmount" className="form-label form-label-new">
                            Claim Amount
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            id="claimAmount"
                            name="claimAmount"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="seat" className="form-label form-label-new">Seat</label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            id="seat"
                            name="seat"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="bankAccountNo" className="form-label form-label-new">
                            Bank Account No
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            id="bankAccountNo"
                            name="bankAccountNo"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="ifscCode" className="form-label form-label-new">
                            IFSC Code
                          </label>
                          <Field
                            type="text"
                            className="form-control form-control-new"
                            id="ifscCode"
                            name="ifscCode"
                          />
                        </div>
                        <div className="col-lg-12 mb-3">
                          <button
                            type="submit"
                            // onClick={()=>handleSubmit(values)}
                            className="btn btn-primary">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>)}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NBFCForm);