import { buildTextForPdf,convertToInteger, buildTextForPdfAddress, ConciliationPayrupikbuildTextForPdf, generatedConcilationCaseID, LRNbuildTextForPdf, AcceptancebuildTextForPdf,InvocationTextForPdf, buildTextForPdf3, ArbitrationbuildTextForPdf, getFormattedDate, getTimestamp, calculateDateAfter15Days, generateRandomInvoiceID } from "../logic/DownloadAll";
import { PDFDocument, StandardFonts } from 'pdf-lib';
import axios from 'axios';
import fontkit from '@pdf-lib/fontkit';
import Thane from '../files/LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf';
import fontPathRegular from '../files/Poppins-Regular.ttf';
import fontPathMedium from '../files/Poppins-Medium.ttf';

const ConciliationUnityBank= async(letterHead, data)=> {
    
    let pdfFilePath;
    if (letterHead === "Joylin Rego, Mumbai") {
    const letterheadResponse = await axios.get('letterhead.pdf');
        pdfFilePath = await letterheadResponse.data;
    } else {
      if (letterHead === "Gurugaon") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Bangalore") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Ambernath") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Hyderabad") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else
       if (letterHead === "Thane") {
        pdfFilePath= await fetch(Thane).then((res) => res.arrayBuffer());
      } else if (letterHead === "Delhi") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "New Delhi") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf');
        pdfFilePath = await letterheadResponse.data;
      }
    }

    const combinedPdf = await PDFDocument.create();
      combinedPdf.registerFontkit(fontkit);
      let j=0;
    for (let i = 0; i < data.length; i++){
      console.log(i);
    //   let data[i].caseID = generatedConcilationCaseID(caseID);
    //   const scheduleDate = calculateDateAfter15Days();
      const pdfDoc = await PDFDocument.load(pdfFilePath);
      pdfDoc.registerFontkit(fontkit);
      const [fontDataRegular, fontDataMedium] = await Promise.all([
        fetch(fontPathRegular).then(response => response.arrayBuffer()),
        fetch(fontPathMedium).then(response => response.arrayBuffer())
      ]);
      const font = await combinedPdf.embedFont(fontDataRegular, { subset: true });
      const font1 = await combinedPdf.embedFont(fontDataMedium, { subset: true });
      const boldFont = await combinedPdf.embedFont(StandardFonts.TimesRomanBold);
      const boldFont1 = await combinedPdf.embedFont(StandardFonts.TimesRoman);
      const [newPage] = await combinedPdf.copyPages(pdfDoc, [0]);
      combinedPdf.addPage(newPage);
      let pageindex = j * 2;
      const firstPage = combinedPdf.getPages()[pageindex];
      const [SecondnewPage] = await combinedPdf.copyPages(pdfDoc, [1]);
      combinedPdf.addPage(SecondnewPage);
      let pageindex1 = j * 2 + 1;
      const secondPage = combinedPdf.getPages()[pageindex1];
      const word4 ="Subject: Letter of invitation as per the provisions of Section 62 of the Arbitration and Conciliation Act, 1996, for the resolution/conciliation of disputes arising out of your outstanding loan.";
      const word6 = `The Claimant for ${data[i].claimantCompany}, having an address at ${data[i].claimantCompanyAddress}, is hereby directed to give the following notice to you: -`;
      const word7 = `You have obtained a loan from the Claimant vide Loan Agreement No.${data[i].loanID}. As per the terms and conditions of the loan granted to you by the Claimant, the terms of, which were specifically and expressly agreed upon between you and the Claimant, you were bound to repay the loan amount together with interest and other expenses. However, you have failed to comply with the financial terms and payments are due on your account. The total amount of Rs. ${convertToInteger(data[i].claimAmount)}/- as on 06th December 2023 is outstanding in your loan account. Several demands have been made to you by the Claimant regarding the amount due, apart from other amounts/charges that you have avoided paying. Therefore, the Claimant has initiated/started to initiate appropriate legal proceedings as per the legal process to recover the above principal, as well as interest and other costs/fees.`;
      const word8 = `However, before taking any legal action, the Claimant has expressed its intention to explore the possibility of settlement through the Conciliation Process and, accordingly, intends to refer the dispute to PrivateCourt (Arbitration & Conciliation Organization) (CONTACT NO ${data[i].privateCourtPhone}) which will work towards the amicable settlement of the dispute.`;
      const word9 = `You are hereby directed to attend the Conciliation hearing scheduled ${data[i].meetingDate} on between ${data[i].meetingTime} through below-mentioned VC link:`;
      const hindiWord1 =`विषय: आपके बकाया ऋण से उत्पन्न विवादों के समाधान/समाधान के लिए मध्यस्थता और सुलह अधिनियम, 1996 की धारा 62 के प्रावधानों के अनुसार निमंत्रण पत्र।`;
      const hindiWord2 = `दावेदार ${data[i].claimantCompany}, जिसका पता ${data[i].claimantCompanyAddress} है, को निम्नलिखित देने का निर्देश दिया जाता है। आपके लिए सूचना:-`;
      const hindiWord3 = `आपने ऋण अनुबंध संख्या ${data[i].loanID} के माध्यम से दावेदार से ऋण प्राप्त किया है। शर्तों के अनुसार और दावेदार द्वारा आपको दिए गए ऋण की शर्तें, जिनकी शर्तों पर विशेष रूप से और स्पष्ट रूप से सहमति व्यक्त की गई थी आपके और दावेदार के बीच, आप ब्याज और अन्य सहित ऋण राशि चुकाने के लिए बाध्य थे खर्चे। हालाँकि, आप वित्तीय शर्तों का पालन करने में विफल रहे हैं और भुगतान आपके खाते पर बकाया है। कुल राशि रु. आपके ऋण खाते में 06 दिसम्बर 2023 तक रु. ${convertToInteger(data[i].claimAmount)}/- बकाया है। अनेक दावेदार द्वारा आपसे अन्य राशियों/प्रभारों के अलावा देय राशि के संबंध में मांग की गई है कि आपने भुगतान करने से परहेज किया है। इसलिए, दावेदार ने उचित कानूनी कार्रवाई शुरू कर दी है उपरोक्त मूलधन, साथ ही ब्याज और अन्य लागत/शुल्क की वसूली के लिए कानूनी प्रक्रिया के अनुसार कार्यवाही।`;
      const hindiWord4 = `हालाँकि, कोई भी कानूनी कार्रवाई करने से पहले, दावेदार ने इसकी संभावना तलाशने का इरादा व्यक्त किया है सुलह प्रक्रिया के माध्यम से निपटान और, तदनुसार, विवाद को प्राइवेटकोर्ट में भेजने का इरादा है (मध्यस्थता और सुलह संगठन) (संपर्क संख्या ${data[i].privateCourtPhone}) जो सौहार्दपूर्ण की दिशा में काम करेगा विवाद का निपटारा.`; 
      const hindiWord5 = `आपको ${data[i].meetingDate} को ${data[i].meetingTime} के बीच नीचे दिए गए वीसी लिंक के माध्यम से सुलह सुनवाई में भाग लेने का निर्देश दिया जाता है:`;
 
      buildTextForPdf(`Ref No. ${data[i].caseID}`, boldFont, firstPage, 36, 685);
      buildTextForPdf("Date: " + data[i].noticeDate, boldFont, firstPage,420,660);
      buildTextForPdf(`To,`, boldFont, firstPage, 36, 660);

      let header=buildTextForPdf(`${data[i].borrowerName?`1. ${data[i].borrowerName} (Borrower)`:""}`, boldFont, firstPage, 36, 635);
      if(data[i].coBorrowerName){
        header=buildTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName} (Co-Borrower)`:""}`, boldFont, firstPage, 36, header.currentY-15);
      }
      header=buildTextForPdfAddress(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      if(data[i].borrowerEmail){
        header=buildTextForPdf(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      }
      if(data[i].borrowerPhone){
        header=buildTextForPdf(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont, firstPage, 36, header.currentY-15);
      }

      // let header=buildTextForPdf(`${data[i].borrowerName?`1. ${data[i].borrowerName} (Borrower)`:""}`, boldFont, firstPage, 36, 635);
      // header=buildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // if(data[i].borrowerEmail){
      //   header=buildTextForPdf(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }
      // if(data[i].borrowerPhone){
      //   header=buildTextForPdf(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont, firstPage, 36, header.currentY-15);
      // }
      // if(data[i].coBorrowerName){
      //   header=buildTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName} (Co-Borrower)`:""}`, boldFont, firstPage, 36, header.currentY-20);
      // }
      // if(data[i].coBorrowerAddress){
      //   header=buildTextForPdf(`${data[i].coBorrowerAddress?`Having address at: ${data[i].coBorrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }
      // else if(data[i].coBorrowerName){
      //   header=buildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }
      // if(data[i].coBorrowerEmail){
      //   header=buildTextForPdf(`${data[i].coBorrowerEmail?`E-mail: ${data[i].coBorrowerEmail}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }
      // if(data[i].phone){
      //   header=buildTextForPdf(`${data[i].phone?`Contact: ${data[i].phone}`:""}`, boldFont, firstPage, 36, header.currentY-15);
      // }

      // if(data[i]['Business name']){
      //   header=buildTextForPdf(`${data[i]['Business name']?`3. ${data[i]['Business name']}`:""}`, boldFont, firstPage, 36, header.currentY-20);
      // }

      // if(data[i]['borrower Business Address']){
      //   header=buildTextForPdf(`${data[i]['borrower Business Address']?`Having address at: ${data[i]['borrower Business Address']}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }
      // else if(data[i]['Business name']){
      //   header=buildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, firstPage, 36, header.currentY-15);
      // }

      // let header = buildTextForPdf(`${data[i].borrowerName}`,boldFont, firstPage, 36,650);
      // header = buildTextForPdf(word1, boldFont1, firstPage, 36, header.currentY - 15);
      // header = buildTextForPdf(word2, boldFont, firstPage, 36, header.currentY - 15);
      // header = buildTextForPdf( `${data[i].coBorrowerName}`, boldFont, firstPage, 36, header.currentY - 15);
      // header = buildTextForPdf(word31, boldFont1, firstPage, 36, header.currentY - 15 );
      // header = buildTextForPdf(word3, boldFont, firstPage, 36, header.currentY - 15 );
      header = buildTextForPdf(
        word4,
        boldFont,
        firstPage,
        36,
        header.currentY - 25
      );
      header = buildTextForPdf(
        "Respected Sir/Madam,",
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );
      header = buildTextForPdf(
        word6,
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );
      header = buildTextForPdf(
        word7,
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );
      header = buildTextForPdf(
        word8,
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );
      header = buildTextForPdf(
        word9,
        boldFont1,
        firstPage,
        36,
        header.currentY - 25
      );

      header=buildTextForPdf(`Join Zoom Meeting`, boldFont1, firstPage, 36, header.currentY-30);
      header=buildTextForPdf(`https://us06web.zoom.us/j/86751137338?pwd=L0cZW6Rznnu8ti5Ugec1ixta9Ctw40.1`, boldFont, firstPage, 36, header.currentY-15);
      header=buildTextForPdf(`Meeting ID: 867 5113 7338`, boldFont1, firstPage, 36, header.currentY-25);
      header=buildTextForPdf(`Passcode: 323181`, boldFont1, firstPage, 36, header.currentY-15);

      header=buildTextForPdf(
        "Adv. Vishal Tiwari",
        boldFont,
        firstPage,
        430,
        77
      )

      header=buildTextForPdf(
        "PrivateCourt Registry",
        boldFont,
        firstPage,
        422,
        62
      );
      // const secondPage = pages[1];
      buildTextForPdf(
        "Date: " + data[i].noticeDate,
        boldFont,
        secondPage,
        420,
        660
      );
      buildTextForPdf(
        `संदर्भ संक्या: ${data[i].caseID}`,
        font,
        secondPage,
        36,
        685
      );
      buildTextForPdf(`To,`, boldFont, secondPage, 36, 660);
      
      buildTextForPdf(`To,`, boldFont, secondPage, 36, 660);
      let hindiHeader=buildTextForPdf(`${data[i].borrowerName?`1. ${data[i].borrowerName} (Borrower)`:""}`, boldFont, secondPage, 36, 635);
      if(data[i].coBorrowerName){ hindiHeader=buildTextForPdf(`${data[i].coBorrowerName?`2. ${data[i].coBorrowerName} (Co-Borrower)`:""}`, boldFont, secondPage, 36, hindiHeader.currentY-15);}
      hindiHeader=buildTextForPdfAddress(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
  
      if(data[i].borrowerEmail){
        hindiHeader=buildTextForPdf(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      }
      if(data[i].borrowerPhone){
        hindiHeader=buildTextForPdf(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont, secondPage, 36, hindiHeader.currentY-15);
      }


      // let hindiHeader=buildTextForPdf(`${data[i].borrowerName?`1. ${data[i].borrowerName} (Borrower)`:""}`, boldFont, secondPage, 36, 635);
      // hindiHeader=buildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      // if(data[i].borrowerEmail){
      //   hindiHeader=buildTextForPdf(`${data[i].borrowerEmail?`E-mail: ${data[i].borrowerEmail}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      // }

      // if(data[i].borrowerPhone){
      //   hindiHeader=buildTextForPdf(`${data[i].borrowerPhone?`Contact: ${data[i].borrowerPhone}`:""}`, boldFont, secondPage, 36, hindiHeader.currentY-15);
      // }
      // else if(data[i].coBorrowerName){
      //   hindiHeader=buildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      // }

      // if(data[i].coBorrowerEmail){
      //   hindiHeader=buildTextForPdf(`${data[i].coBorrowerEmail?`E-mail: ${data[i].coBorrowerEmail}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      // }

      // if(data[i].phone){
      //   hindiHeader=buildTextForPdf(`${data[i].phone?`Contact: ${data[i].phone}`:""}`, boldFont, secondPage, 36, hindiHeader.currentY-15);
      // }

      // if(data[i]['Business name']){
      //   hindiHeader=buildTextForPdf(`${data[i]['Business name']?`3. ${data[i]['Business name']}`:""}`, boldFont, secondPage, 36, hindiHeader.currentY-20);
      // }

      // if(data[i]['borrower Business Address']){
      //   hindiHeader=buildTextForPdf(`${data[i]['borrower Business Address']?`Having address at: ${data[i]['borrower Business Address']}`:""}`, boldFont1, secondPage, 36, hindiHeader.currentY-15);
      // }

      // else if(data[i]['Business name']){
      //   hindiHeader=buildTextForPdf(`${data[i].borrowerAddress?`Having address at: ${data[i].borrowerAddress}`:""}`, boldFont1, secondPage, 36, header.currentY-15);
      // }

      hindiHeader = buildTextForPdf3(
        hindiWord1,
        font1,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );
      hindiHeader = buildTextForPdf3(
        "महोदय / महोदया,",
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );
      hindiHeader = buildTextForPdf3(
        hindiWord2,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );
      hindiHeader = buildTextForPdf3(
        hindiWord3,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );
      hindiHeader = buildTextForPdf3(
        hindiWord4,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );
      hindiHeader = buildTextForPdf3(
        hindiWord5,
        font,
        secondPage,
        36,
        hindiHeader.currentY - 25
      );

      hindiHeader=buildTextForPdf(`Join Zoom Meeting`, boldFont1, secondPage, 36, hindiHeader.currentY-30);
      hindiHeader=buildTextForPdf(`https://us06web.zoom.us/j/86751137338?pwd=L0cZW6Rznnu8ti5Ugec1ixta9Ctw40.1`, boldFont, secondPage, 36, hindiHeader.currentY-15);
      hindiHeader=buildTextForPdf(`Meeting ID: 867 5113 7338`, boldFont1, secondPage, 36, hindiHeader.currentY-25);
      hindiHeader=buildTextForPdf(`Passcode: 323181`, boldFont1, secondPage, 36, hindiHeader.currentY-15);


      hindiHeader=buildTextForPdf(
        "Adv. Vishal Tiwari",
        boldFont,
        secondPage,
        430,
        77
      );

      hindiHeader=buildTextForPdf(
        "PrivateCourt Registry",
        boldFont,
        secondPage,
        422,
        62
      )
      j++;
    }
    const combinedPdfBytes = await combinedPdf.save();
    const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Generated Conciliation.pdf";
    link.click();
};

export default ConciliationUnityBank; 