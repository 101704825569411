import { Link, withRouter } from "react-router-dom";
import auth from "../services/authService";
import http from "../services/httpService.js";
import Navbar2 from "./navbar2";
import CsvUploader2 from "./csvUplode2";
import { useEffect, useState } from "react";
import Loading from "./loading";
import PDFfile from "../asset/DRPAgreement.pdf";
import disputeIcon from "../asset/icons/disputes.svg";
import agreementsIcon from "../asset/icons/agreements.svg";
import reportIcon from "../asset/icons/report.svg";
import accountIcon from "../asset/icons/account.svg";

const NBFCTable = (props) => {
  const email = auth.getUser().email;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [loanType, setLoanType] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [usersData, setUsersData] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const fetchData2 = async () => {
    try {
      const response = await http.post2("/getCompanyName", {
        email,
      });
      if (response.status != 400) {
        // console.log(response.data, 'response.data gstComponent');
        setCompanyName(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    try {
      let response = await http.post("/getCsvDatas", {
        email,
        page,
        perPage,
        search,
        loanType,
      });
      console.log(response.data);
      setUsersData(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    fetchData2();
  }, []);
  useEffect(() => {
    fetchData();
  }, [page, perPage, search, loanType]);

  return isLoading ? (
    <Loading></Loading>
  ) : (
    <div className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3">
            <div className="left-wrapper">
              <Navbar2 />
              <h2 className="admin-name">
                {companyName ? companyName : email}
              </h2>
              <Link to="/dashboard">
                <button
                  type="button"
                  className="btn btn-light btn-sm back-btn mb-3"
                >
                  <i className="bi bi-arrow-left"></i> Back
                </button>
              </Link>
              <div className="left-wrapper">
                {/* <button
                className="btn btn-primary d-block w-100 btn-lg mt-3 d-lg-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Filter
              </button> */}
                <div className="" id="collapseExample">
                  <div className="data-filter">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        id="floatingSelect"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        //   value={filterByName}
                        //   onChange={(e)=>{setFilterByName(e.target.value)}}
                        aria-label="Floating label select example"
                      />
                      <label>
                        Search By Borrower Name, Loan ID, Agreement No
                      </label>
                    </div>
                    {/* <h2 className="d-lg-block d-none">Notifications</h2>
                  <div className="alert alert-primary bg-transparent" role="alert">
                  LRN successfully <strong>generated</strong> and <strong>posted</strong>!
                  </div> */}
                    {/* <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      id="floatingSelect"
                      value={filterByName}
                      onChange={(e)=>{setFilterByName(e.target.value)}}
                      aria-label="Floating label select example"
                    />
                    <label>Search By Ref No</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      id="floatingSelect"
                      value={filterByName}
                      onChange={(e)=>{setFilterByName(e.target.value)}}
                      aria-label="Floating label select example"
                    />
                    <label>Search By Loan ID</label>
                  </div> */}
                    {/* <div className="form-floating mb-3">
                    <select
                      className="form-select"
                      id="floatingSelect"
                      value={loanType}
                      onChange={(e)=>setLoanType(e.target.value)}
                      aria-label="Floating label select example"
                    >
                      <option value=''>All</option>
                      <option value='New Lead'>New Lead</option>
                      <option value='Progress'>Progress</option>
                      <option value='Confirmed'>Confirmed</option>
                      <option value='Paid'>Paid</option>
                    </select>
                    <label>Search By Loan Type</label>
                  </div> */}
                  </div>
                  <div className="dot-bg">
                    <h2 className="d-lg-block d-none">Perform Tasks</h2>
                    <div className="row row-cols-2">
                      <div className="col">
                        <Link to={"/notice"} className="white-circle">
                          <img
                            src={agreementsIcon}
                            alt=""
                            style={{ display: "block", margin: "0 auto" }}
                          />
                          <h2>Notice details</h2>
                        </Link>
                      </div>
                      <div className="col">
                        {/* <Link to="/dispute" className="white-circle">
                          <img
                            src={accountIcon}
                            alt=""
                            style={{ display: "block", margin: "0 auto" }}
                          />
                          <h2>Create New</h2>
                        </Link> */}

                        <Link to="/reporting" className="white-circle">
                          <img
                            src={reportIcon}
                            style={{ display: "block", margin: "0 auto" }}
                            alt=""
                          />
                          <h2>Reports</h2>
                        </Link>
                      </div>
                    </div>
                    <h2 className="d-lg-block d-none">Notifications</h2>
                    <div
                      className="alert alert-primary bg-transparent"
                      role="alert"
                    >
                      LRN successfully <strong>generated</strong> and{" "}
                      <strong>posted</strong>!
                    </div>

                    {/* <div className="col">
                        <Link to="/disputes" className="white-circle">
                          <img
                            src={disputeIcon}
                            alt=""
                            style={{ display: "block", margin: "0 auto" }}
                          />
                          <h2>Dispute</h2>
                        </Link>
                      </div>
                      <div className="col">
                        <Link to="/account" className="white-circle">
                          <img
                            src={accountIcon}
                            alt=""
                            style={{ display: "block", margin: "0 auto" }}
                          />
                          <h2>Account</h2>
                        </Link>
                      </div> 
                    </div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="right-wrapper">
              <div className="conciliation-application-form">
                <form id="regForm" action="#">
                  <div className="col-lg-12">
                    <div className="card card-new h-100">
                      <h2 className="mb-3">NBFC DISPUTE</h2>

                      <div className="row mb-4">
                        <CsvUploader2 />
                      </div>

                      <div className="org-data nbfc-table">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">RefNo</th>
                              <th scope="col">LoanID</th>

                              <th scope="col">Borrower</th>
                              <th scope="col">Claim-Amount</th>
                              {/* <th scope="col">
                                            Dispute Resolution Paper ID                                 
                                            </th> */}
                              <th scope="col">Claimant</th>
                              {/* <th scope="col">
                                                Associate Name                             
                                            </th> */}
                              {/* <th scope="col">
                                                                               
                                            </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {usersData.length > 0
                              ? usersData.map((item, index) => {
                                  return (
                                    <tr>
                                      <td data-label="RefNo">{item.refNo}</td>
                                      <td
                                        data-label="LoanID"
                                        onClick={() => {
                                          props.history.push(
                                            `/nviewdetail/${item.loanID}`
                                          );
                                        }}
                                        style={{ textDecoration: "underline" }}
                                      >
                                        {item.loanID}
                                      </td>
                                      <td data-label="Borrower">
                                        {item.borrowerName}
                                      </td>
                                      <td data-label="Claim-Amount">
                                        {item.claimAmount}
                                      </td>
                                      <td data-label="Claimant">
                                        {item.claimantCompany}
                                      </td>
                                      {/* <td data-label="View More Deatils">
                                                  <button type="button" className="btn btn-primary"
                                                  onClick={()=>{(props.history.push(`/nviewdetail/${item.loanID}`))}}>
                                                    <i className="bi bi-eye text-white"></i>
                                                  </button>
                                                </td> */}
                                      {/* <td>
                                                  <button type="button" className="btn btn-primary"
                                                    onClick={()=>{(props.history.push(`/ndetail/${item.loanID}`))}}>
                                                    <i className="bi bi-pencil text-white"></i>
                                                  </button>
                                                </td> */}
                                    </tr>
                                  );
                                })
                              : ""}
                          </tbody>
                        </table>
                      </div>
                      <div className="row">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                          <div className="data-pagi float-lg-end mt-lg-0 mt-2">
                            <select
                              className="form-control"
                              id="floatingSelect"
                              value={perPage ? perPage : 10}
                              onChange={(e) => setPerPage(e.target.value)}
                              aria-label="Floating label select example"
                            >
                              <option value={10}>10</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                            <div
                              className="btn-toolbar"
                              role="toolbar"
                              aria-label="Toolbar with button groups"
                            >
                              <div
                                className="btn-group me-1"
                                role="group"
                                aria-label="First group"
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => setPage(+page - 1)}
                                  disabled={page <= 1}
                                >
                                  <i className="bi bi-chevron-left text-white"></i>
                                </button>
                              </div>
                              <div
                                className="btn-group me-1"
                                role="group"
                                aria-label="Second group"
                              >
                                <button
                                  type="button"
                                  className="btn text-primary"
                                >
                                  {page}
                                </button>
                              </div>
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Third group"
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={usersData.length < +perPage}
                                  onClick={() => setPage(+page + 1)}
                                >
                                  <i className="bi bi-chevron-right text-white"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4"></div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(NBFCTable);
