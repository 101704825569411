import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { MdDownload } from "react-icons/md";
import auth from "../services/authService";
import http from "../services/httpService";
import Navbar2 from "./navbar2";


const HistoryNotice = (props) => {
  const email = auth.getUser().email;
  const [companyName, setCompanyName] = useState("");
  // const [loanID, setLoanID]=useState("");
  const [initialValues, setInitialValues] = useState({});

  const downloadPDFLink = (loanID,e) => {
    e.preventDefault();

    const pdfUrl = `https://privatecourtdocs.s3.ap-south-1.amazonaws.com/lrnbp0823/${loanID}+-+LRN.pdf`;
    const newTab = window.open(pdfUrl, "_blank");
    if (newTab) {
      newTab.focus();
    } else {
      console.error("Failed to open PDF in a new tab.");
    }
  };

  const fetchData = async () => {
    const { loanID } = props.match.params;
    // setLoanID(loanID);
    console.log(loanID, "loanId");
    if (loanID) {
      try {
        let response = await http.post("/getCsvDatas", {
          search: loanID,
          email: email,
        });
        console.log(response.data, "getDataByLoanID");
        setInitialValues(response.data.data[0]);
      } catch (err) {
        console.log(err);
      }
    }
  };


  const fetchData2 = async () => {
    try {
      const response = await http.post2("/getCompanyName", {
        email,
      });
      if (response.status != 400) {
        // console.log(response.data, 'response.data gstComponent');
        setCompanyName(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // console.log(initialValues)
  useEffect(() => {
    // console.log(loanID)
    fetchData2();
    fetchData();
  }, []);

  return (
    <div className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3">

            <div className="left-wrapper">
              <Navbar2 />
              <h2 className="admin-name">
                {companyName ? companyName : email}
              </h2>
              <Link to="/dashboard">
                <button
                  type="button"
                  className="btn btn-light btn-sm back-btn mb-3"
                >
                  <i className="bi bi-arrow-left"></i> Back
                </button>
              </Link>
              <div className="" id="collapseExample">
                <div className="dot-bg">
                  <h2 className="d-lg-block d-none">Notifications</h2>
                  <div
                    className="alert alert-primary bg-transparent"
                    role="alert"
                  >
                    LRN successfully <strong>generated</strong> and{" "}
                    <strong>posted</strong>!
                  </div>
                  {/* <div className="alert alert-primary bg-transparent" role="alert">
                        New Member <strong>‘Akasha Seth’</strong> added to the team. 
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        You have won the dispute against Deepak Jain. See the <a href="#" className="alert-link">Final Terms.</a>
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        2 upcoming Hearings tomorrow. <a href="#" className="alert-link">See full Schedule</a>
                    </div>
                    <div className="alert alert-primary bg-transparent" role="alert">
                        <div className="d-flex justify-content-between">
                            <div>
                                12 new in last 2 days
                            </div>
                            <div>
                                <a href="#" className="alert-link">view all</a>
                            </div>
                        </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="right-wrapper">
              <div className="conciliation-application-form">
                <form id="regForm" action="#">
                  <div className="col-lg-12">
                    <div className="card card-new h-100">
                      <h2 className="mb-3">NBFC DISPUTE</h2>
                      <div className="row mb-4">{/* <CsvUploader2/> */}</div>
                      <div className="org-data nbfc-table">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">Notice of Email</th>
                              <th scope="col">Date of Notice</th>
                              <th scope="col">Status</th>
                              <th scope="col">DownLoad Notice</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td data-label="Notice of Email">LRN Notice</td>
                              <td data-label="Date of Notice">
                                {initialValues.LRNDate}
                              </td>
                              <td data-label="Status">
                                {initialValues.emailStatus}
                              </td>
                              <td data-label="download">
                                <button className="btn btn-sm btn-primary"
                                 disabled={
                                  email !=
                                  "parveen.gandhi@bharatpe.com"
                                }
                                onClick={(e) =>
                                  downloadPDFLink(props.match.params.loanID, e)
                                }
                                >
                                  <i className="bi bi-download text-white"></i>
                                </button>

                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HistoryNotice;
