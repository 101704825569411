import { buildTextForPdf,convertToInteger, generatedConcilationCaseID, LRNbuildTextForPdf, AcceptancebuildTextForPdf,InvocationTextForPdf, buildTextForPdf3, ArbitrationbuildTextForPdf, getFormattedDate, getTimestamp, calculateDateAfter15Days, generateRandomInvoiceID } from "../logic/DownloadAll";
import { PDFDocument, StandardFonts } from 'pdf-lib';
import axios from 'axios';
import fontkit from '@pdf-lib/fontkit';
import Thane from '../files/LETTERHEAD ALL ADDRESS-Thane Conciliation.pdf';
import fontPathRegular from '../files/Poppins-Regular.ttf';
import fontPathMedium from '../files/Poppins-Medium.ttf';
import Acceptance_Thane from '../files/Acceptance_Thane.pdf';
import getImage from "../files/Vipin Sharma Mumbai Stamp and sign.png";
import getSign from "../files/vishalSign.png";


const AcceptanceNotice= async(letterHead,data)=>{
    // let letterHead="Thane";
    let pdfFilePath;
    if (letterHead === "Joylin Rego, Mumbai") {
    const letterheadResponse = await axios.get('letterhead.pdf');
        pdfFilePath = await letterheadResponse.data;
    } else {
      if (letterHead === "Gurugaon") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Concilation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Bangalore") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Concilation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Ambernath") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Concilation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Hyderabad") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Concilation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "Thane") {
        pdfFilePath= await fetch(Acceptance_Thane).then((res) =>
        res.arrayBuffer()
      );
      } else if (letterHead === "Delhi") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Concilation.pdf');
        pdfFilePath = await letterheadResponse.data;
      } else if (letterHead === "New Delhi") {
        const letterheadResponse = await axios.get('LETTERHEAD ALL ADDRESS-Thane Concilation.pdf');
        pdfFilePath = await letterheadResponse.data;
      }
    }
    // const data = await axios
    //   .post("https://server1-jlfh.onrender.com/getCsvByTimestamp", {
    //     email,
    //     timeStamp,
    //   })
    //   .then((res) => res.data)
    //   .catch((err) => {
    //     console.log(err);
    //     return [];
    //   });
      console.log(data.length);
      const combinedPdf = await PDFDocument.create();
      combinedPdf.registerFontkit(fontkit);
      let len=data.length;

    // const combinedPdf = await PDFDocument.create();
    let j=0;
    for(let i=0;i<data.length;i++){
      console.log(i);
      const NewRef = generateRandomInvoiceID();
      const loanID = data[i].loanID;
      
      const arrayBuffer1 = await fetch(getImage).then(res => res.arrayBuffer());
      const arrayBuffer2 = await fetch(getSign).then(res => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(pdfFilePath);
      // const image4 = await pdfDoc.embedPng(arrayBuffer1);
      const pngImage = await combinedPdf.embedPng(arrayBuffer1);
      const pngImage1 = await combinedPdf.embedPng(arrayBuffer2);
      // const pngImage1= await combinedPdf.embedPng(arrayBuffer2);
      const boldFont =await combinedPdf.embedFont(StandardFonts.TimesRomanBold);
      const boldFont1=await combinedPdf.embedFont(StandardFonts.TimesRoman);
      // const pages = pdfDoc.getPages();
      // const firstPage = pages[0];
     const [newPage]=await combinedPdf.copyPages(pdfDoc,[0]);
     combinedPdf.addPage(newPage);
     let pageindex=j*3;
     const firstPage=combinedPdf.getPages()[pageindex];
     const scheduleDate=calculateDateAfter15Days();
     const subject=`Sub: ARBITRATOR ORGANIZATION’S DECLARATION FOR ACCEPTANCE OF RESPONSIBILITY AND STATEMENT OF INDEPENDENCE`
     const PointOne=`I, the undersigned, the Registrar of PrivateCourt, do hereby declare that I accept to appoint an Arbitrator in the instant case, and I do not have a financial and personal interest in the outcome of the Award, which is likely to disqualify me to appoint an impartial and independent arbitrator in the above-referred Arbitration Case.`
     const PointTwo=`There is no such ground of the past or present relationship with or interest in any of the parties or in relation to the subject matter in dispute or other kinds, which is likely to give rise to justifiable doubts as to my independence or impartiality, more specifically laid down in the Fifth and Sixth Schedule to the Arbitration and Conciliation Act, 1996 as amended till date.`
     const PointThree=`I further declare that my organization does not have any relationship with the parties or counsels or the subject matter of the dispute falling under any of the categories specified in the Seventh Schedule of the Act.`
     const PointFour=`It is also declared by me that my Organization shall act as an independent and impartial Arbitration Organization.`
     const PointFive=`We hereby Appoint Advocate Vipin Sharma as the sole Arbitrator to adjudicate your dispute`
     const PointSix=`The matter is kept on ${data[i].meetingDate}, 12:00 PM to 1: 00 PM (IST) India for the 1st Arbitration Hearing through the below-mentioned Zoom link:`
     const LastPara1=`I, the undersigned, Advocate Vipin Sharma, do hereby accept the responsibility of Sole Arbitrator at the request of PrivateCourt.`
     const LastPara2=`I am in receipt of the ARBITRATION ORGANIZATION DECLARATION FORM; and hence, have given my consent to act as Arbitrator vide this Arbitrator’s Confirmation Letter.`
     const LastPara3=`There is no such ground of the past or present relationship with or interest in any of the parties or in relation to the subject matter in dispute or other kinds, which is likely to give rise to justifiable doubts as to my independence or impartiality, more specifically laid down in the Fifth and Sixth Schedule to the Arbitration and Conciliation Act, 1996 as amended till date`
      const address=`902, Tower A, Naman Midtown, Senapati Bapat Marg, Mumbai 400013`
    //  let header=buildTextForPdf1("Date: " + getFormattedDate(), boldFont, firstPage, 420, 700,pdfDoc);
     let header=AcceptancebuildTextForPdf("ARBITRATION ORGANIZATION DECLARATION FORM ", boldFont1, firstPage, 150, 680);
     header=AcceptancebuildTextForPdf("Re: Arbitration Between", boldFont1, firstPage, 240, header.currentY-15);
     header=AcceptancebuildTextForPdf(`${data[i].claimantCompany}`, boldFont1, firstPage, 200, header.currentY-15);
     header=AcceptancebuildTextForPdf("…Claimant", boldFont1, firstPage, 250, header.currentY-15);
     header=AcceptancebuildTextForPdf(`${data[i].borrowerName}`, boldFont1, firstPage, 240, header.currentY-15);
     header=AcceptancebuildTextForPdf("…Respondent", boldFont1, firstPage, 250, header.currentY-15);
     header=AcceptancebuildTextForPdf(`(Case ID - ${data[i].caseID})`, boldFont1, firstPage, 220, header.currentY-15);
     header=AcceptancebuildTextForPdf(subject, boldFont1, firstPage, 36, header.currentY-25);
     header=AcceptancebuildTextForPdf(PointOne, boldFont1, firstPage, 36, header.currentY-25);
     header=AcceptancebuildTextForPdf(PointTwo, boldFont1, firstPage, 36, header.currentY-25);
     header=AcceptancebuildTextForPdf(PointThree, boldFont1, firstPage, 36, header.currentY-25);
     header=AcceptancebuildTextForPdf(PointFour, boldFont1, firstPage, 36, header.currentY-25);
     header=AcceptancebuildTextForPdf(PointFive, boldFont1, firstPage, 36, header.currentY-25);
     header=AcceptancebuildTextForPdf(PointSix, boldFont1, firstPage, 36, header.currentY-25);
     header=AcceptancebuildTextForPdf(`Join Zoom Meeting`, boldFont1, firstPage, 36, header.currentY-30);
     header=AcceptancebuildTextForPdf(`https://us06web.zoom.us/j/86751137338?pwd=L0cZW6Rznnu8ti5Ugec1ixta9Ctw40.1`, boldFont, firstPage, 36, header.currentY-15);
     header=AcceptancebuildTextForPdf(`Meeting ID: 867 5113 7338`, boldFont1, firstPage, 36, header.currentY-25);
     header=AcceptancebuildTextForPdf(`Passcode: 323181`, boldFont1, firstPage, 36, header.currentY-15);
     const page1=header.currentPage;
     page1.drawImage(pngImage1, {
      x: 380,
      y: header.currentY-40,
      width: 50,
      height: 50,
    })
      const today = new Date();
      const day = today.getDate();
      const month = today.getMonth()+1;
      const year = today.getFullYear();
     header=AcceptancebuildTextForPdf(`Date: `+data[i].noticeDate, boldFont, firstPage, 36, header.currentY-70,pdfDoc);
     header=AcceptancebuildTextForPdf(`Signature: Mr. VISHAL TIWARI(Registrar)`, boldFont, firstPage, 280, header.currentY,pdfDoc);
     header=AcceptancebuildTextForPdf(`Place: Raipur`, boldFont, firstPage, 36, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`Mobile: 8976955539`, boldFont, firstPage, 320, header.currentY,pdfDoc);
     const [SecondnewPage] = await combinedPdf.copyPages(pdfDoc, [1]);
      combinedPdf.addPage(SecondnewPage);
      let pageindex1=j*3+1;
      const secondPage = combinedPdf.getPages()[pageindex1];
     header=AcceptancebuildTextForPdf("E-mail: legal@privatecourt.in", boldFont, secondPage, 36, 700,pdfDoc);
     header=AcceptancebuildTextForPdf(`C.C`, boldFont1, secondPage, 45, header.currentY-50,pdfDoc);
     header=AcceptancebuildTextForPdf(`1.${data[i].borrowerName}`, boldFont1, secondPage, 45, header.currentY-25,pdfDoc);
     header=AcceptancebuildTextForPdf(`having address at:`, boldFont1, secondPage, 48, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`${data[i].borrowerAddress}`, boldFont1, secondPage, 48, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`Contact :${data[i].borrowerPhone}`, boldFont1, secondPage, 48, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`E-mail: ${data[i].borrowerEmail}`, boldFont1, secondPage, 48, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`2. ${data[i].claimantCompany}`, boldFont1, secondPage, 48, header.currentY-30,pdfDoc);
     header=AcceptancebuildTextForPdf(address, boldFont1, secondPage, 48, header.currentY-15,pdfDoc);
     const [thirdnewPage] = await combinedPdf.copyPages(pdfDoc, [2]);
      combinedPdf.addPage(thirdnewPage);
      let pageindex2=j*3+2;
      const thirdPage = combinedPdf.getPages()[pageindex2];
     header=AcceptancebuildTextForPdf("ARBITRATION ORGANIZATION DECLARATION FORM ", boldFont1, thirdPage, 150, 670,pdfDoc);
     header=AcceptancebuildTextForPdf("Re: Arbitration Between", boldFont1, thirdPage, 240, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`${data[i].claimantCompany}`, boldFont1, thirdPage, 200, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf("…Claimant", boldFont1, thirdPage, 250, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf("Vs", boldFont1, thirdPage, 275, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`${data[i].borrowerName}`, boldFont1, thirdPage, 240, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf("…Respondent", boldFont1, thirdPage, 250, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`(Case ID - ${data[i].caseID})`, boldFont1, thirdPage, 240, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(LastPara1, boldFont1, thirdPage, 36, header.currentY-25,pdfDoc);
     header=AcceptancebuildTextForPdf(LastPara2, boldFont1, thirdPage, 36, header.currentY-25,pdfDoc);
     header=AcceptancebuildTextForPdf(LastPara3, boldFont1, thirdPage, 36, header.currentY-25,pdfDoc);
     const page=header.currentPage;
     page.drawImage(pngImage, {
      x: 400,
      y: header.currentY-120,
      width: 120,
      height: 100,
      })
     header=AcceptancebuildTextForPdf(`C.C`, boldFont1, thirdPage, 45, header.currentY-150,pdfDoc);
     header=AcceptancebuildTextForPdf(`1. ${data[i].borrowerName}`, boldFont1, thirdPage, 45, header.currentY-25,pdfDoc);
     header=AcceptancebuildTextForPdf(`having address at:`, boldFont1, thirdPage, 48, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`${data[i].borrowerAddress}`, boldFont1, thirdPage, 48, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`Contact :${data[i].borrowerPhone}`, boldFont1, thirdPage, 48, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`E-mail: ${data[i].borrowerEmail}`, boldFont1, thirdPage, 48, header.currentY-15,pdfDoc);
     header=AcceptancebuildTextForPdf(`2. ${data[i].claimantCompany}`, boldFont1, thirdPage, 48, header.currentY-30,pdfDoc);
     header=AcceptancebuildTextForPdf(address, boldFont1, thirdPage, 48, header.currentY-15,pdfDoc);
     j++;
  }
  const combinedPdfBytes = await combinedPdf.save();
  const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "Generated Acceptance Letter.pdf";
  link.click();
  };

  export default AcceptanceNotice;