import { useEffect, useState } from "react";
import auth from "../services/authService";
import http from "../services/httpService";
import Navbar2 from "./navbar2";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Link } from "react-router-dom";
import Loading from "./loading.jsx";

const GraphReporting = (props) => {
  const email = auth.getUser().email;
  const [isLoading, setIsLoading] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const [low1, setLow1] = useState(0);
  const [low2, setLow2] = useState(0);
  const [cases, setCases] = useState("");
  const [whatsappcount, setWhatsappcount] = useState("");
  const [emailcount, setEmailcount] = useState("");
  const [high, setHigh] = useState(0);
  const [needupdated, setNeedupdated] = useState(0);

  const options1 = {
    accessibility: {
      enabled: true, 
    },
    chart: {
      type: "column",
      width: 300, 
      height: 300, 
    },
    title: {
      text: "Communication",
    },
    xAxis: {
      categories: ["Communication"],
    },
    yAxis: {
      title: {
        text: "Number of Communications",
      },
    },
    series: [
      {
        name: "Phone",
        data: [10],
      },
      {
        name: "WhatsApp",
        data: [whatsappcount],
      },
      {
        name: "Email",
        data: [emailcount],
      },
      {
        name: "Postal",
        data: [2],
      },
    ],
  };

  const options = {
    accessibility: {
      enabled: true,
    },
    chart: {
      type: "pie",
      width: 300,
      height: 300,
    },
    title: {
      text: "Probability",
    },
    series: [
      {
        name: "Probability",
        data: [
          {
            name: "High",
            y: high,
          },
          {
            name: "Low",
            y: low1 + low2,
          },
          {
            name: "Need to Update",
            y: needupdated,
          },
        ],
      },
    ],
  };

  const fetchData2 = async () => {
    try {
      const response = await http.post2("/getCompanyName", {
        email,
      });
      if (response.status != 400) {
        setCompanyName(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const CalculateAllProb = async () => {
    http
      .post2(`/calculateAllProb`, { email: email })
      .then((res) => {
        setHigh(res.data.high);
        setNeedupdated(res.data.need);
        setLow1(res.data.low1);
        setLow2(res.data.low2);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const whatsappcases = async () => {
    http
      .post2(`/calculateWhatsapp`, { email: email })
      .then((res) => {
        setWhatsappcount(res.data.cases);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const emailcases = async () => {
    http
      .post2(`/calculateEmail`, { email: email })
      .then((res) => {
        setEmailcount(res.data.cases);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData2();
    CalculateAllProb();
    whatsappcases();
    emailcases();
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return isLoading ? (
    <Loading></Loading>
  ) : (
    <div className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3">
            <div className="left-wrapper">
              <Navbar2 />
              <h2 className="admin-name">
                {companyName ? companyName : email}
              </h2>
              {/* <Link to="/dashboard">
                <button
                  type="button"
                  className="btn btn-light btn-sm back-btn mb-3"
                >
                  <i className="bi bi-arrow-left"></i> Back
                </button>
              </Link> */}
              <Link to="/reporting">
                  <button
                    type="button"
                    className="btn btn-light btn-sm back-btn mb-3"
                  >
                    <i className="bi bi-arrow-left"></i> Back
                  </button>
                </Link>
              <div className="" id="collapseExample">
                <div className="dot-bg">
                  <h2 className="d-lg-block d-none">Notifications</h2>
                  <div
                    className="alert alert-primary bg-transparent"
                    role="alert"
                  >
                    LRN successfully <strong>generated</strong> and{" "}
                    <strong>posted</strong>!
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="right-wrapper">
              <div className="col-lg-12">
                <div className="card card-new h-100">
                  <h2 className="mb-3">NBFC DISPUTE</h2>
                  <div className="org-data pb-5">
                    <div className="row">
                      <div className="col-lg-11">
                        {/* /////////// */}
                        <div className="d-flex justify-content-center">
                      <div>
                        {/* Chart will be rendered here */}
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={options}
                        />
                      </div>
                      <div>
                        {/* Second chart will be rendered here */}
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={options1}
                        />
                      </div>
                    </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphReporting;
