const keyName1="user";

function login(obj){
    let str=JSON.stringify(obj);
    localStorage.setItem(keyName1, str);
}

function logout(){
    localStorage.removeItem(keyName1);
}

function getUser(){
    let str=localStorage.getItem(keyName1);
    let obj=str?JSON.parse(str):null;
    return obj;
}

export default{
    login,
    logout,
    getUser,
}